import { type FC } from 'react'
import { IconProps } from 'website/components/types'

const Phone2Icon: FC<IconProps> = ({
  className,
  fill = 'white'
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M17.4077 13.6476V15.8698C17.4086 16.0761 17.3663 16.2803 17.2837 16.4694C17.201 16.6584 17.0798 16.8281 16.9278 16.9675C16.7758 17.107 16.5963 17.2132 16.4009 17.2793C16.2054 17.3454 15.9984 17.3699 15.7929 17.3513C13.5135 17.1037 11.324 16.3248 9.40031 15.0773C7.61055 13.94 6.09315 12.4226 4.95587 10.6328C3.704 8.70037 2.92494 6.50022 2.68179 4.21059C2.66328 4.00575 2.68763 3.7993 2.75328 3.60439C2.81892 3.40947 2.92444 3.23036 3.0631 3.07846C3.20176 2.92655 3.37054 2.80519 3.55868 2.72209C3.74681 2.63899 3.95019 2.59597 4.15587 2.59577H6.37809C6.73758 2.59224 7.08608 2.71954 7.35865 2.95395C7.63122 3.18836 7.80926 3.51388 7.85957 3.86985C7.95337 4.58101 8.12731 5.27928 8.37809 5.95133C8.47775 6.21646 8.49932 6.5046 8.44024 6.78161C8.38116 7.05862 8.24391 7.31289 8.04476 7.51429L7.10402 8.45503C8.1585 10.3095 9.69398 11.845 11.5485 12.8995L12.4892 11.9587C12.6906 11.7596 12.9449 11.6223 13.2219 11.5633C13.4989 11.5042 13.787 11.5257 14.0522 11.6254C14.7242 11.8762 15.4225 12.0501 16.1336 12.1439C16.4935 12.1947 16.8221 12.3759 17.057 12.6532C17.2919 12.9304 17.4167 13.2843 17.4077 13.6476Z"
        stroke={fill}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Phone2Icon
