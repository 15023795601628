import { type FC } from 'react'
import { cnx } from '@carfluent/common'
import { useLocation } from 'react-router-dom'

import SharedStateHook, { StoreBranches, defaultInstance } from 'website/store'
import DrawerMenu from 'website/components/DrawerMenu'
import ExtendedImage from 'website/components/ExtendedImage'
import Login from 'website/components/Login'
import List from 'website/components/List'
import SearchBar from 'website/components/SearchBar'
import LocalBusinessSchema from 'website/components/LocalBusinessSchema'
import DealerLocationsPopover from 'website/components/_base/DealerLocationsPopover'
import DealerInfoFragment from 'website/components/_base/DealerInfoFragment'
import DynamicComponent from 'website/components/DynamicComponent'

import { type HeaderProps, SupportedComponents } from 'website/components/types'
import { useLayoutStyles } from 'website/styles/useLayoutStyles'
import { useComponentStyles } from 'website/styles/useComponentStyles'
import { isIndependentCoverageFlow, isIndependentPaymentShareFlow } from 'utils/urlHelpers'

import CLASS_NAME from './styles'

const useSharedState = SharedStateHook<Store.Dealership>(StoreBranches.Dealership)

const Header: FC<HeaderProps> = ({
  nameInLayout,
  template,
  layout,
  variant,
  variantProps,
  className,
  componentProps,
  useDealFlowNavigation = false
}) => {
  const { pathname } = useLocation()
  const componentStylesCls = useComponentStyles(SupportedComponents.Header, variant, variantProps)
  const templateCls = useLayoutStyles({ template, layout })

  const [dealerInfo] = useSharedState(defaultInstance(StoreBranches.Dealership))
  const lastDealership = dealerInfo.dealerships[dealerInfo.dealerships.length - 1]
  const dealership = dealerInfo?.dealerships?.[0]
  const hasDealerInfoFragment = componentProps.DealerInfoFragment != null

  const wrapperClass = cnx(
    nameInLayout ?? SupportedComponents.Header,
    CLASS_NAME,
    componentStylesCls.root,
    className
  )

  const isHiddenHeader = isIndependentCoverageFlow(pathname) ||
    isIndependentPaymentShareFlow(pathname)

  if (isHiddenHeader) {
    return null
  }

  const websiteUrl = window.location.href

  return (
    <div className={wrapperClass}>
      <div className={cnx(componentStylesCls.content, templateCls)}>
        <DrawerMenu
          {...componentProps.DrawerMenu}
          useDealFlowNavigation={useDealFlowNavigation}
        />

        {!useDealFlowNavigation && (componentProps.SearchBar != null) && (
          <SearchBar
            {...componentProps.SearchBar}
            className='header-search-bar'
            shouldResetOnSearch
          />
        )}

        {!useDealFlowNavigation && (componentProps.SearchBar != null) && (
          <SearchBar
            {...componentProps.SearchBar}
            className='header-search-bar'
            shouldResetOnSearch
          />
        )}

        {(lastDealership != null) && (
          <ExtendedImage
            {...componentProps.ExtendedImage}
            src={lastDealership.logoUrl ?? componentProps.ExtendedImage.src}
          />
        )}

        <List {...componentProps.List} />

        {!useDealFlowNavigation && !hasDealerInfoFragment && (
           <DealerLocationsPopover
             className='dealership-phone'
           />
        )}

        {!useDealFlowNavigation && hasDealerInfoFragment && (lastDealership != null) && (
          <DealerInfoFragment
            {...componentProps.DealerInfoFragment}
            phone={lastDealership.phone}
            addressData={{
              address: lastDealership.address1,
              city: lastDealership.city,
              state: lastDealership.state,
              zipCode: lastDealership.zipCode
            }}
          />
        )}

        {(componentProps.Login != null) && (
          <Login
            {...componentProps.Login}
            useDealFlowNavigation={useDealFlowNavigation}
          />
        )}

        {(componentProps.Login1 != null) && (
          <Login
            {...componentProps.Login1}
            useDealFlowNavigation={useDealFlowNavigation}
          />
        )}

        {(componentProps.Login2 != null) && (
          <Login
            {...componentProps.Login2}
            useDealFlowNavigation={useDealFlowNavigation}
          />
        )}

        {(componentProps?.SubHeader != null) && (
          <DynamicComponent {...componentProps.SubHeader} nameInLayout='SubHeader' />
        )}
      </div>

      <LocalBusinessSchema
        schemaProps={
          dealership != null
            ? {
                name: dealership.dealerName,
                url: websiteUrl,
                logo: dealership.logoUrl,
                telephone: dealership.phone ?? '',
                email: '',
                images: [],
                address: {
                  streetAddress: dealership.address1 ?? '',
                  addressLocality: dealership.city ?? '',
                  addressRegion: dealership.state ?? '',
                  postalCode: dealership.zipCode ?? '',
                  addressCountry: 'US'
                },
                dealerBusinessHours: dealership.dealerBusinessHours
              }
            : null
        }
      />
    </div>
  )
}

export default Header
