import { type ChangeEvent, type FC } from 'react'
import { cnx, FormInput, FormMaskedInput, Button } from '@carfluent/common'
import get from 'lodash/get'

import StaticDocumentsApiProvider from 'api/static_documents.api'
import FormCheckbox from 'components/common/form_checkbox'
import IconSVG from 'website/components/icons'
import { type Lead } from 'website/api/types/leads'
import I18N from 'website/configs/i18n/en/index.json'

import { useRequestCall } from './hook'
import CLASS_NAME from './styles'

interface RequestCallProps {
  isOpened: boolean
  onClose: () => void
  onSubmit?: (lead: Lead) => void
}

const RequestCall: FC<RequestCallProps> = ({
  isOpened,
  ...otherProps
}) => {
  const {
    isClosing,
    values,
    errors,
    onChange,
    onBlur,
    onCancel,
    onSubmit,
    touched,
    isSubmitting
  } = useRequestCall(otherProps)

  return (
    <div
      onClick={onCancel}
      className={cnx(CLASS_NAME, isOpened && 'is-opened', isClosing && 'is-closing')}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className='request-call-content'
      >
        <div className='request-call-header'>
          Request a call

          <span onClick={onCancel}>
            <IconSVG.Close />
          </span>
        </div>

        <div className='request-call-form-content'>
          <p className='request-call-form-title'>
            {get(I18N, 'chat.callFormTitle')}
          </p>

          <div className='personal-info-wrapper'>
            <FormInput
              id='firstName'
              label='First name'
              onChange={onChange}
              onBlur={onBlur}
              value={values.firstName}
              error={errors.firstName}
              touched={touched.firstName}
              className='first-name-input'
            />

            <FormInput
              id='lastName'
              label='Last name'
              onChange={onChange}
              onBlur={onBlur}
              value={values.lastName}
              error={errors.lastName}
              touched={touched.lastName}
            />
          </div>

          <FormMaskedInput
            id='phoneNumber'
            label='Phone number'
            onChange={onChange}
            onBlur={onBlur}
            value={values.phoneNumber}
            error={errors.phoneNumber}
            touched={touched.phoneNumber}
            mask='phone'
          />

          <FormCheckbox
            classes='field-terms'
            errors={errors.isAgreementAccepted ?? ''}
            id='isAgreementAccepted'
            iconCheckbox={null}
            iconChecked={null}
            label={
              <p className='terms-text'>
                I provide consent to be contacted via text, call, or email. Use as subject to {' '}
                <a
                  className='terms-link'
                  href={StaticDocumentsApiProvider.getTermsAndConditionsUrl()}
                  rel='noreferrer'
                  target='_blank'
                >
                  Terms
                </a>
                .
              </p>
            }
            onChange={(evt: ChangeEvent<HTMLInputElement>): void => {
              onChange('isAgreementAccepted', evt.target.checked)
            }}
            value={values.isAgreementAccepted}
          />

          <div className='empty-space' />

          <div className='action-bar'>
            <Button
              className='request-call-cancel-button'
              variant='outlined'
              onClick={onCancel}
            >
              Cancel
            </Button>

            <Button
              className='request-call-submit-button'
              onClick={onSubmit}
              isDisabled={isSubmitting || !values.isAgreementAccepted}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RequestCall
