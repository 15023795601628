import { type ButtonTextConfig } from 'website/types/components'
import TypographyTheme from './Typography'

const defaultButtonTextConfig: Required<ButtonTextConfig> = {
  color: 'inherit',
  textAlign: 'left',
  textDecorationStyle: 'none',
  fontWeight: 400
}

const ButtonText = ({
  textAlign,
  textDecorationStyle,
  fontWeight,
  color
}: ButtonTextConfig): string => `
  & .MuiButton-root {
    padding: 0;
    margin: 0;
    text-transform: none;
    color: ${color ?? defaultButtonTextConfig.color};
    font-weight: ${fontWeight ?? defaultButtonTextConfig.fontWeight};
    text-decoration: ${textDecorationStyle ?? defaultButtonTextConfig.textDecorationStyle};
    background-color: transparent;

    ${TypographyTheme.body2}

    &:hover {
      background-color: transparent;
    }

    & > span {
      display: inline-block;
      text-align: ${textAlign ?? defaultButtonTextConfig.textAlign};
    }
  }
`

export default {
  Button: {
    default: {
      root: `
        & .MuiButton-root.MuiButton-contained {
          background-color: var(--buttonMainColor);
          color: var(--buttonTextColor);
          box-shadow: none;
          

          &:hover {
            background-color: var(--buttonMainColor);
            color: var(--buttonTextColor);
            box-shadow: none;
            
            .MuiButton-label {
              opacity: 0.92;
            }
          }

          &.Mui-disabled {
            opacity: 0.6;
          }
        }
      `
    },
    text: {
      root: ButtonText({ textAlign: 'left' })
    },
    textRight: {
      root: ButtonText({ textAlign: 'right' })
    },
    textWithConfig: {
      root: ButtonText
    },
    textTabStyled: {
      root: ButtonText({
        textAlign: 'center',
        fontWeight: 500,
        color: '#333'
      })
    },
    outlinedSearch: {
      root: `
        .MuiButton-root {
          max-height: 56px;
          min-height: 56px;
          border-radius: 12px;
          border-color: var(--mainOutlinedButtonColor);
          color: var(--mainOutlinedButtonColor);
        }
      `
    },
    back: {
      root: `
        .MuiButtonBase-root {
          padding: 0;
          border-radius: 12px;
          background: #fff;
          box-shadow: none;
          text-transform: capitalize;
          text-decoration: underline;
          font-weight: 700;
          font-size: 16px;
          box-shadow: none;
          
          :hover {
            background: #fff;
            box-shadow: none;
          }
        }
      `
    },
    search: {
      root: `
        & .MuiButton-root {
          max-height: 48px;
          min-height: 48px;
          box-sizing: border-box;
          border-radius: 12px;
        }
      `
    },
    showMore: {
      root: `
        && .MuiButton-root {
          max-height: 36px;
          min-height: 36px;
          box-sizing: border-box;
          background-color: #fff;
          box-shadow: none;
          padding: 13px 8px;
          border-radius: 8px;
          border: 1px solid #DBDBDB;
          color: var(--textBlack);
          text-transform: none;
          text-align: center;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.25px;

          &:hover {
            box-shadow: none;
            border-radius: 8px;
            border: 1px solid #DBDBDB;
            background: rgba(219, 219, 219, 0.30);
            color: var(--textBlack);
          }
        }
      `
    },
    filters: {
      root: `
        & .MuiButton-root {
          background-color: transparent;
          color: #000;

          & .MuiButton-label {
            text-transform: capitalize;
          }
        }
      `
    },
    icon: {
      root: `
        & .MuiButtonBase-root.MuiIconButton-root {
          padding: 0;
        }
      `
    }
  }
}
