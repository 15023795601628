import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatCurrency } from '@carfluent/common'

import { calculatePrequalify, getTooltip } from 'website/components/VehicleCard/parser'
import CalcWebsiteRoutes, { WebsiteRoutes } from 'website/routing/constants'
import { type GetPrequalifiedResponse } from 'website/api/types/financing'

export interface UseVehicleCardProps {
  emptyImage?: string
  monthlyPaymentDetails?: API.VehicleMonthlyPayment | null
  openVehicleOnClick?: boolean
  prequalify?: GetPrequalifiedResponse<number>
  vehicle?: API.VehicleItem
}

export interface UseVehicleCardReturn {
  engineStr: string
  isDisabledLink: boolean
  isPlaceholderImg: boolean
  imgSrc: string | undefined
  onCardClick: () => void
  paymentInfo: any
  price: string
  tooltip: string
}

const DEFAULT_PREQUALIFY = {
  downPayment: null,
  apr: 0,
  term: null
}

const useVehicleCards = ({
  emptyImage,
  monthlyPaymentDetails,
  openVehicleOnClick = true,
  prequalify = DEFAULT_PREQUALIFY,
  vehicle
}: UseVehicleCardProps): UseVehicleCardReturn => {
  const navigate = useNavigate()
  const salePrice = vehicle?.salePrice ?? 0

  const parsedPrequalify = calculatePrequalify({
    ...prequalify,
    price: salePrice
  })

  const isPrequalify = parsedPrequalify != null

  const tooltip = getTooltip({
    term: (isPrequalify ? prequalify?.term : monthlyPaymentDetails?.term) ?? null,
    apr: (isPrequalify ? prequalify?.apr : monthlyPaymentDetails?.apr) ?? 0
  })

  const paymentInfo = parsedPrequalify ?? (
    monthlyPaymentDetails != null
      ? { monthlyPayment: monthlyPaymentDetails.monthlyPayment, cashDown: monthlyPaymentDetails.cashDown }
      : null
  )

  const isDisabledLink = vehicle?.id == null || !openVehicleOnClick

  const imgSrc = vehicle?.media?.thumbnail ?? vehicle?.mainImageUrl ?? emptyImage
  const price = (vehicle?.salePrice != null && salePrice >= 1000)
    ? formatCurrency(salePrice)
    : 'Call'

  const engineStr = (vehicle?.engine != null || vehicle?.fuelType != null)
    ? `${vehicle.engine ?? ''} ${vehicle.fuelType ?? ''}`.trim()
    : '-'

  const isPlaceholderImg = imgSrc === emptyImage

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onCardClick = useCallback(() => {
    const to = isDisabledLink
      ? WebsiteRoutes.NotFound
      : CalcWebsiteRoutes.Vehicle(vehicle?.dealerId, vehicle?.id)

    navigate(to)
  }, [
    isDisabledLink,
    navigate,
    vehicle?.dealerId
  ])

  // ========================================== //

  return {
    engineStr,
    imgSrc,
    isDisabledLink,
    isPlaceholderImg,
    onCardClick,
    paymentInfo,
    price,
    tooltip
  }
}

export default useVehicleCards
