import { type FC } from 'react'
import { type IconProps } from 'website/components/types'

const IconSVGPersonalizationImage: FC<IconProps> = ({
  fill = 'var(--mainColor)',
  height = 56,
  width = 56
}) => {
  return (
    <svg width={width} height={height} viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M46.094 48.7358C51.565 48.7358 56.0002 44.2723 56.0002 38.7664C56.0002 33.2604 51.565 28.7969 46.094 28.7969C40.623 28.7969 36.1879 33.2604 36.1879 38.7664C36.1879 44.2723 40.623 48.7358 46.094 48.7358Z' fill='#F3F3F3' />
      <path d='M22.9313 17.0905C27.6207 17.0905 31.4223 13.2647 31.4223 8.54527C31.4223 3.82585 27.6207 0 22.9313 0C18.2418 0 14.4403 3.82585 14.4403 8.54527C14.4403 13.2647 18.2418 17.0905 22.9313 17.0905Z' fill='#F3F3F3' />
      <path d='M14.1515 55.9998C21.9672 55.9998 28.3031 49.6234 28.3031 41.7577C28.3031 33.892 21.9672 27.5156 14.1515 27.5156C6.33578 27.5156 -0.00012207 33.892 -0.00012207 41.7577C-0.00012207 49.6234 6.33578 55.9998 14.1515 55.9998Z' fill='#F3F3F3' />
      <path d='M29.2937 47.2908C41.0173 47.2908 50.5212 37.7262 50.5212 25.9276C50.5212 14.1291 41.0173 4.56445 29.2937 4.56445C17.5701 4.56445 8.06628 14.1291 8.06628 25.9276C8.06628 37.7262 17.5701 47.2908 29.2937 47.2908Z' fill='white' />
      <path d='M49.0914 18.2227C49.0984 18.4149 49.1055 18.6143 49.1055 18.8066C49.1055 30.6062 39.6027 40.1698 27.8781 40.1698C18.8564 40.1698 11.1509 34.5014 8.07996 26.5116C8.39129 38.0405 17.7667 47.2908 29.2932 47.2908C41.0179 47.2908 50.5207 37.7272 50.5207 25.9276C50.5207 23.2145 50.0112 20.6153 49.0914 18.2227Z' fill={fill} fillOpacity={0.8} />
      <path d='M29.2927 47.8257C17.2921 47.8257 7.53455 38.0058 7.53455 25.9285C7.53455 13.8512 17.2921 4.03125 29.2927 4.03125C41.2933 4.03125 51.0508 13.8512 51.0508 25.9285C51.0508 38.0058 41.2862 47.8257 29.2927 47.8257ZM29.2927 5.09941C17.8794 5.09941 8.59592 14.4422 8.59592 25.9285C8.59592 37.4148 17.8794 46.7576 29.2927 46.7576C40.706 46.7576 49.9895 37.4148 49.9895 25.9285C49.9895 14.4494 40.706 5.09941 29.2927 5.09941Z' fill='#101010' />
      <path d='M23.64 22.0182L17.9723 15.2461L12.3046 22.0182H23.64Z' fill='white' />
      <path d='M23.6369 22.0182L17.9691 15.2461L19.0942 22.0182H23.6369Z' fill={fill} fillOpacity={0.8} />
      <path d='M23.6391 22.5523H12.3036C12.0984 22.5523 11.9073 22.4312 11.8224 22.246C11.7375 22.0538 11.7658 21.833 11.9003 21.6764L17.568 14.9042C17.7732 14.6621 18.1765 14.6621 18.3817 14.9042L24.0495 21.6764C24.1839 21.833 24.2122 22.0609 24.1273 22.246C24.0282 22.4312 23.8443 22.5523 23.6391 22.5523ZM13.4428 21.4841H22.4928L17.9642 16.0792L13.4428 21.4841Z' fill='#101010' />
      <path d='M34.9422 22.0182L29.2745 15.2461L23.6068 22.0182H34.9422Z' fill='white' />
      <path d='M34.9406 22.0182L29.2728 15.2461L30.3979 22.0182H34.9406Z' fill={fill} fillOpacity={0.8} />
      <path d='M34.9398 22.5523H23.6044C23.3992 22.5523 23.2081 22.4312 23.1232 22.246C23.0383 22.0538 23.0666 21.833 23.201 21.6764L28.8688 14.9042C29.0669 14.6621 29.4773 14.6621 29.6825 14.9042L35.3502 21.6764C35.4846 21.833 35.5129 22.0609 35.428 22.246C35.329 22.4312 35.145 22.5523 34.9398 22.5523ZM24.7436 21.4841H33.7935L29.265 16.0792L24.7436 21.4841Z' fill='#101010' />
      <path d='M46.2692 22.0182L40.6014 15.2461L34.9408 22.0182H46.2692Z' fill='white' />
      <path d='M46.2687 22.0182L40.601 15.2461L41.7331 22.0182H46.2687Z' fill={fill} fillOpacity={0.8} />
      <path d='M46.2675 22.5523H34.932C34.7268 22.5523 34.5358 22.4312 34.4508 22.246C34.3659 22.0538 34.3943 21.833 34.5287 21.6764L40.1964 14.9042C40.4016 14.6621 40.8049 14.6621 41.0101 14.9042L46.6779 21.6764C46.8123 21.833 46.8406 22.0609 46.7557 22.246C46.6637 22.4312 46.4727 22.5523 46.2675 22.5523ZM36.0783 21.4841H45.1283L40.5997 16.0792L36.0783 21.4841Z' fill='#101010' />
      <path d='M29.2728 15.2461L34.9406 22.0182L40.6012 15.2461H29.2728Z' fill='white' />
      <path d='M36.0659 15.2461L34.9408 22.0182L40.6014 15.2461H36.0659Z' fill={fill} fillOpacity={0.8} />
      <path d='M34.9396 22.5532C34.7839 22.5532 34.6353 22.482 34.5362 22.3609L28.8686 15.5888C28.7341 15.4321 28.7058 15.2042 28.7907 15.0191C28.8756 14.8339 29.0666 14.7129 29.2718 14.7129H40.6073C40.8125 14.7129 41.0035 14.8339 41.0885 15.0191C41.1734 15.2042 41.1451 15.4321 41.0106 15.5888L35.3429 22.3609C35.2438 22.482 35.0952 22.5532 34.9396 22.5532ZM30.4111 15.781L34.9396 21.1859L39.4681 15.781H30.4111Z' fill='#101010' />
      <path d='M17.9359 15.2461L23.6037 22.0182L29.2713 15.2461H17.9359Z' fill='white' />
      <path d='M24.7318 15.2461L23.6068 22.0182L29.2745 15.2461H24.7318Z' fill={fill} fillOpacity={0.8} />
      <path d='M23.6027 22.5532C23.447 22.5532 23.2984 22.482 23.1993 22.3609L17.5316 15.5888C17.3972 15.4321 17.3689 15.2042 17.4538 15.0191C17.5387 14.8339 17.7297 14.7129 17.9349 14.7129H29.2704C29.4756 14.7129 29.6666 14.8339 29.7516 15.0191C29.8365 15.2042 29.8081 15.4321 29.6737 15.5888L24.006 22.3609C23.9069 22.482 23.7583 22.5532 23.6027 22.5532ZM19.0741 15.781L23.6027 21.1859L28.1312 15.781H19.0741Z' fill='#101010' />
      <path d='M12.3046 22.0195H23.6047L29.2865 42.3074L12.3046 22.0195Z' fill='white' />
      <path d='M19.0951 22.0195H23.6024L29.2842 42.3074L19.0951 22.0195Z' fill={fill} fillOpacity={0.8} />
      <path d='M29.2855 42.8404C29.137 42.8404 28.9813 42.7763 28.8822 42.6482L11.9003 22.3603C11.7658 22.2036 11.7375 21.9757 11.8224 21.7906C11.9073 21.5983 12.0984 21.4844 12.3036 21.4844H23.6037C23.8443 21.4844 24.0494 21.641 24.1131 21.876L29.795 42.1639C29.8658 42.406 29.7526 42.6624 29.5332 42.7835C29.4554 42.8262 29.3705 42.8404 29.2855 42.8404ZM13.4428 22.5525L28.1039 40.0703L23.1933 22.5525H13.4428Z' fill='#101010' />
      <path d='M46.267 22.0195H34.9386L29.285 42.3074L46.267 22.0195Z' fill='white' />
      <path d='M46.267 22.0195H41.7314L29.285 42.3074L46.267 22.0195Z' fill={fill} fillOpacity={0.8} />
      <path d='M29.2837 42.8417C29.1988 42.8417 29.1139 42.8203 29.0361 42.7776C28.8167 42.6637 28.7035 42.4002 28.7743 42.1581L34.4208 21.8702C34.4845 21.6423 34.6967 21.4785 34.9302 21.4785H46.2657C46.4709 21.4785 46.6619 21.5996 46.7468 21.7847C46.8318 21.9699 46.8035 22.1977 46.669 22.3544L29.6871 42.6423C29.588 42.7776 29.4394 42.8417 29.2837 42.8417ZM35.3406 22.5538L30.4583 40.0787L45.1265 22.5538H35.3406Z' fill='#101010' />
      <path d='M29.2887 42.3074L23.6068 22.0195H34.9422L29.2887 42.3074Z' fill='white' />
      <path d='M29.285 42.3074L30.3959 22.0195H34.9386L29.285 42.3074Z' fill={fill} fillOpacity={0.8} />
      <path d='M29.2855 42.8417C29.0449 42.8417 28.8398 42.685 28.7761 42.45L23.0942 22.1621C23.0517 21.9984 23.08 21.8274 23.1791 21.6921C23.2781 21.5568 23.4338 21.4785 23.6037 21.4785H34.9391C35.1018 21.4785 35.2646 21.5568 35.3637 21.6921C35.4627 21.8274 35.4981 21.9984 35.4486 22.155L29.802 42.4429C29.7313 42.685 29.5261 42.8417 29.2855 42.8417ZM24.3042 22.5538L29.2855 40.3351L34.2386 22.5538H24.3042Z' fill='#101010' />
      <path d='M49.3664 39.4794C49.1895 40.6472 48.4041 41.6299 47.3003 42.0572L38.4202 45.4753C36.637 46.1589 34.6558 45.1549 34.1393 43.3105C33.7642 41.9575 34.5143 40.5404 35.8516 40.1132L37.6064 39.5506C42.5453 38.4967 45.4747 36.6381 47.3074 36.6096C49.0127 36.5882 49.699 38.0694 49.3664 39.4794Z' fill='white' />
      <path d='M47.9436 36.6816C47.7526 37.8281 46.9743 38.7895 45.8917 39.2025L37.0115 42.6206C35.9855 43.0123 34.91 42.8414 34.068 42.2788C34.0255 42.6135 34.0467 42.9624 34.1458 43.3042C34.6623 45.1557 36.6436 46.1598 38.4267 45.469L47.3068 42.0509C48.4036 41.6308 49.1961 40.641 49.373 39.4731C49.656 38.2625 49.1748 36.9878 47.9436 36.6816Z' fill={fill} fillOpacity={0.8} />
      <path d='M37.2661 46.223C36.6789 46.223 36.0986 46.0877 35.5679 45.8171C34.6198 45.34 33.9193 44.4783 33.6292 43.4529C33.1692 41.8151 34.0749 40.1202 35.6882 39.6004L37.443 39.0378C40.0399 38.4753 42.0423 37.7133 43.6556 37.1009C45.1486 36.5312 46.3303 36.0826 47.2997 36.0684C47.3138 36.0684 47.328 36.0684 47.3421 36.0684C48.1841 36.0684 48.9129 36.3959 49.387 36.9941C49.9177 37.6635 50.1016 38.6248 49.8823 39.5719C49.67 40.9178 48.7572 42.0572 47.4907 42.5485L38.6105 45.9666C38.1789 46.1375 37.7261 46.223 37.2661 46.223ZM47.3492 37.1436C47.3421 37.1436 47.3279 37.1436 47.3209 37.1436C46.5354 37.1508 45.4316 37.5709 44.0377 38.105C42.3961 38.7316 40.3512 39.5078 37.719 40.0704L36.0137 40.6187C34.9453 40.9605 34.3509 42.0785 34.6552 43.1609C34.8604 43.9086 35.3557 44.5139 36.0491 44.8629C36.7425 45.2118 37.5209 45.2474 38.2355 44.9697L47.1157 41.5516C48.0355 41.1955 48.6936 40.3695 48.8422 39.3939C48.8422 39.3797 48.8493 39.3654 48.8493 39.3512C48.9978 38.7103 48.8917 38.0765 48.5591 37.6635C48.2902 37.3145 47.8799 37.1436 47.3492 37.1436Z' fill='#101010' />
      <path d='M51.3052 39.4297C49.9396 38.2832 46.154 41.7939 36.3116 43.6596C37.6065 43.1682 38.0735 42.4348 38.1159 41.7084C38.3353 38.4256 35.512 38.5752 35.512 38.5752C20.9995 38.7461 32.0378 35.2781 12.3104 35.1855V47.9892C12.3104 47.9892 34.0403 55.4165 50.4916 42.6769C50.4916 42.6769 53.0883 40.9251 51.3052 39.4297Z' fill='white' />
      <path d='M51.3052 39.4303C51.0576 39.2238 50.725 39.1739 50.3005 39.2309C49.8972 39.9857 49.0764 40.5412 49.0764 40.5412C35.1229 51.351 17.3767 47.648 12.3104 46.2736V47.9898C12.3104 47.9898 34.0403 55.4171 50.4916 42.6775C50.4916 42.6775 53.0883 40.9257 51.3052 39.4303Z' fill={fill} fillOpacity={0.8} />
      <path d='M26.9858 50.6462C18.5939 50.6462 12.254 48.5384 12.1337 48.4957C11.9214 48.4245 11.7728 48.218 11.7728 47.9901V35.1864C11.7728 35.044 11.8295 34.9087 11.9285 34.809C12.0276 34.7093 12.162 34.6523 12.3035 34.6523C22.9951 34.7022 24.8207 35.7632 26.4339 36.6961C27.75 37.458 28.8892 38.1203 35.4981 38.042C35.5334 38.042 36.8637 37.9779 37.7906 38.9321C38.4345 39.5872 38.7176 40.5343 38.6397 41.7449C38.6185 42.0298 38.5477 42.3502 38.3779 42.6706C42.3899 41.7307 45.2273 40.5557 47.2085 39.7368C49.4869 38.7897 50.7464 38.2698 51.6309 39.0104C52.3456 39.6086 52.4658 40.2495 52.4446 40.6768C52.3738 41.9941 50.9375 42.9982 50.7676 43.1122C43.1328 49.0511 34.281 50.6462 26.9858 50.6462ZM12.8342 47.5984C15.7211 48.4743 35.2716 53.7866 50.1662 42.2576C50.4988 42.0298 51.3691 41.2963 51.3974 40.634C51.4045 40.5343 51.4186 40.221 50.9658 39.8436C50.5695 39.516 49.3312 40.0287 47.626 40.7337C45.2697 41.7093 41.6965 43.1834 36.4108 44.1874C36.1419 44.2373 35.8731 44.0735 35.8023 43.81C35.7245 43.5394 35.866 43.2617 36.1278 43.1691C37.0547 42.8131 37.55 42.3146 37.5925 41.6808C37.6491 40.7907 37.4722 40.1284 37.0547 39.6941C36.4603 39.0816 35.5547 39.1172 35.5405 39.1172C28.6345 39.2027 27.3679 38.4692 25.9174 37.6289C24.4456 36.7744 22.7828 35.8131 12.8413 35.7347V47.5984H12.8342Z' fill='#101010' />
      <path d='M31.3712 44.792C29.581 44.792 27.5149 44.6353 25.1869 44.3291C24.8968 44.2935 24.6916 44.0229 24.727 43.731C24.7624 43.439 25.0383 43.2325 25.3214 43.2681C31.3712 44.0728 34.5482 43.6526 36.1474 43.1541C36.4233 43.0687 36.7276 43.2254 36.8125 43.5102C36.8974 43.795 36.7417 44.0941 36.4587 44.1796C35.1426 44.5855 33.4373 44.792 31.3712 44.792Z' fill='#101010' />
      <path d='M12.3021 35.1934H6.64148V48.0113H12.3021V35.1934Z' fill={fill} fillOpacity={0.8} />
      <path d='M9.47181 46.587H6.64148V35.1934H10.887V45.1628C10.887 45.9462 10.2572 46.587 9.47181 46.587Z' fill='white' />
      <path d='M12.3025 48.5443H6.64189C6.35178 48.5443 6.11121 48.3021 6.11121 48.0102V35.1923C6.11121 34.9003 6.35178 34.6582 6.64189 34.6582H12.3025C12.5927 34.6582 12.8332 34.9003 12.8332 35.1923V48.0102C12.8332 48.3021 12.5997 48.5443 12.3025 48.5443ZM7.17258 47.4761H11.7719V35.7264H7.17258V47.4761Z' fill='#101010' />
      <path d='M9.4726 45.8754C9.86338 45.8754 10.1802 45.5566 10.1802 45.1633C10.1802 44.77 9.86338 44.4512 9.4726 44.4512C9.08181 44.4512 8.76501 44.77 8.76501 45.1633C8.76501 45.5566 9.08181 45.8754 9.4726 45.8754Z' fill='#101010' />
      <path d='M21.0471 11.4373H20.0706C19.7805 11.4373 19.5399 11.1952 19.5399 10.9032C19.5399 10.6113 19.7805 10.3691 20.0706 10.3691H21.0471C21.3372 10.3691 21.5778 10.6113 21.5778 10.9032C21.5778 11.1952 21.3372 11.4373 21.0471 11.4373Z' fill='#101010' />
      <path d='M24.3103 11.4373H23.3338C23.0437 11.4373 22.8031 11.1952 22.8031 10.9032C22.8031 10.6113 23.0437 10.3691 23.3338 10.3691H24.3103C24.6004 10.3691 24.8409 10.6113 24.8409 10.9032C24.8409 11.1952 24.6074 11.4373 24.3103 11.4373Z' fill='#101010' />
      <path d='M22.1897 13.5743C21.8996 13.5743 21.6591 13.3322 21.6591 13.0402V12.0575C21.6591 11.7656 21.8996 11.5234 22.1897 11.5234C22.4799 11.5234 22.7204 11.7656 22.7204 12.0575V13.0402C22.7204 13.3322 22.4869 13.5743 22.1897 13.5743Z' fill='#101010' />
      <path d='M22.1897 10.2833C21.8996 10.2833 21.6591 10.0412 21.6591 9.74921V8.7665C21.6591 8.47454 21.8996 8.23242 22.1897 8.23242C22.4799 8.23242 22.7204 8.47454 22.7204 8.7665V9.74921C22.7204 10.0483 22.4869 10.2833 22.1897 10.2833Z' fill='#101010' />
      <path d='M30.9548 10.0135H29.9783C29.6882 10.0135 29.4476 9.77135 29.4476 9.47939C29.4476 9.18743 29.6882 8.94531 29.9783 8.94531H30.9548C31.2449 8.94531 31.4855 9.18743 31.4855 9.47939C31.4855 9.77135 31.2449 10.0135 30.9548 10.0135Z' fill='#101010' />
      <path d='M34.2252 10.0135H33.2488C32.9587 10.0135 32.7181 9.77135 32.7181 9.47939C32.7181 9.18743 32.9587 8.94531 33.2488 8.94531H34.2252C34.5154 8.94531 34.7559 9.18743 34.7559 9.47939C34.7559 9.77135 34.5154 10.0135 34.2252 10.0135Z' fill='#101010' />
      <path d='M32.1004 12.1485C31.8103 12.1485 31.5697 11.9064 31.5697 11.6144V10.6317C31.5697 10.3398 31.8103 10.0977 32.1004 10.0977C32.3905 10.0977 32.6311 10.3398 32.6311 10.6317V11.6144C32.6311 11.9064 32.3905 12.1485 32.1004 12.1485Z' fill='#101010' />
      <path d='M32.1004 8.85946C31.8103 8.85946 31.5697 8.61735 31.5697 8.32538V7.34267C31.5697 7.05071 31.8103 6.80859 32.1004 6.80859C32.3905 6.80859 32.6311 7.05071 32.6311 7.34267V8.32538C32.6311 8.62447 32.3905 8.85946 32.1004 8.85946Z' fill='#101010' />
      <path d='M43.2746 32.8006H42.2981C42.008 32.8006 41.7675 32.5585 41.7675 32.2665C41.7675 31.9745 42.008 31.7324 42.2981 31.7324H43.2746C43.5647 31.7324 43.8053 31.9745 43.8053 32.2665C43.8053 32.5585 43.5647 32.8006 43.2746 32.8006Z' fill='#101010' />
      <path d='M46.5363 32.8006H45.5599C45.2698 32.8006 45.0292 32.5585 45.0292 32.2665C45.0292 31.9745 45.2698 31.7324 45.5599 31.7324H46.5363C46.8264 31.7324 47.067 31.9745 47.067 32.2665C47.067 32.5585 46.8335 32.8006 46.5363 32.8006Z' fill='#101010' />
      <path d='M44.4144 34.9376C44.1242 34.9376 43.8837 34.6955 43.8837 34.4035V33.4208C43.8837 33.1288 44.1242 32.8867 44.4144 32.8867C44.7045 32.8867 44.945 33.1288 44.945 33.4208V34.4035C44.945 34.6955 44.7115 34.9376 44.4144 34.9376Z' fill='#101010' />
      <path d='M44.4144 31.6466C44.1242 31.6466 43.8837 31.4045 43.8837 31.1125V30.1298C43.8837 29.8378 44.1242 29.5957 44.4144 29.5957C44.7045 29.5957 44.945 29.8378 44.945 30.1298V31.1125C44.945 31.4116 44.7115 31.6466 44.4144 31.6466Z' fill='#101010' />
      <path d='M12.572 30.6639H11.5955C11.3054 30.6639 11.0648 30.4217 11.0648 30.1298C11.0648 29.8378 11.3054 29.5957 11.5955 29.5957H12.572C12.8621 29.5957 13.1027 29.8378 13.1027 30.1298C13.1027 30.4217 12.8621 30.6639 12.572 30.6639Z' fill='#101010' />
      <path d='M15.8337 30.6639H14.8572C14.5671 30.6639 14.3265 30.4217 14.3265 30.1298C14.3265 29.8378 14.5671 29.5957 14.8572 29.5957H15.8337C16.1238 29.5957 16.3644 29.8378 16.3644 30.1298C16.3644 30.4217 16.1309 30.6639 15.8337 30.6639Z' fill='#101010' />
      <path d='M13.7103 32.8009C13.4201 32.8009 13.1796 32.5587 13.1796 32.2668V31.2841C13.1796 30.9921 13.4201 30.75 13.7103 30.75C14.0004 30.75 14.2409 30.9921 14.2409 31.2841V32.2668C14.2409 32.5587 14.0074 32.8009 13.7103 32.8009Z' fill='#101010' />
      <path d='M13.7103 29.5099C13.4201 29.5099 13.1796 29.2677 13.1796 28.9758V27.9931C13.1796 27.7011 13.4201 27.459 13.7103 27.459C14.0004 27.459 14.2409 27.7011 14.2409 27.9931V28.9758C14.2409 29.2749 14.0074 29.5099 13.7103 29.5099Z' fill='#101010' />
    </svg>
  )
}

export default IconSVGPersonalizationImage
