import { type FC } from 'react'
import { FormInput, Button, cnx } from '@carfluent/common'

import { ValidationLength } from 'constants/validation/constants'

import useVinNumber from './hook'
import CLASS_NAME from './styles'
import translate from 'website/utils/translate'

const VIN_ID = 'vin'

interface VinFormProps {
  states: {
    tradeInVehicle: string
  }
}

const VinForm: FC<VinFormProps> = ({ states }) => {
  const {
    errors,
    onBlur,
    onChange,
    onSubmit,
    touched,
    values,
    isLoading
  } = useVinNumber({ states })

  return (
    <div className={cnx(CLASS_NAME, 'vin-form')}>
      <FormInput
        id={VIN_ID}
        className='form-field cf-input'
        label='VIN number'
        value={values[VIN_ID]}
        error={errors[VIN_ID]}
        touched={touched[VIN_ID]}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={ValidationLength.VIN_MAX}
      />

      <Button
        className='cf-submit-button'
        onClick={onSubmit}
        isLoading={isLoading}
      >
        {translate('GET MY OFFER')}
      </Button>
    </div>
  )
}

export default VinForm
