import type { FC } from 'react'
import { useComponentStyles } from 'website/styles/useComponentStyles'
import { type InfoLinkProps, SupportedComponentNames } from 'website/components/types'

const InfoLink: FC<InfoLinkProps> = ({
  variant,
  icon,
  title,
  content,
  actionTitle,
  onClick
}) => {
  const componentStylesCls = useComponentStyles(SupportedComponentNames.InfoLink, variant)

  return (
    <div onClick={onClick} className={componentStylesCls.root}>
      <p className='link-title'>{title}</p>

      <div className='link-description'>
        {content}
      </div>

      <div className='carfax-icon'>
        {icon}
      </div>

      <div className='link-handler-wrapper'>
        <p className='link-action-title'>{actionTitle}</p>

        <svg className='link-handler' width='33' height='32' viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <rect className='hover-rect' x='0.5' width='32' height='32' rx='16' fill='transparent' fillOpacity='0' />
          <rect className='rect-border' x='1' y='0.5' width='31' height='31' rx='15.5' strokeOpacity='0.5' />
          <g clipPath='url(#clip0_7280_160746)'>
            <path
              d='M24.3167 15.5576C24.3165 15.5574 24.3163 15.5572 24.3161 15.557L21.0504 12.307C20.8057 12.0635 20.41 12.0644 20.1665 12.3091C19.923 12.5538 19.9239 12.9495 20.1686 13.193L22.3612 15.375H9.125C8.77981 15.375 8.5 15.6548 8.5 16C8.5 16.3452 8.77981 16.625 9.125 16.625H22.3612L20.1686 18.807C19.9239 19.0505 19.923 19.4462 20.1665 19.6909C20.41 19.9356 20.8058 19.9364 21.0504 19.693L24.3162 16.443C24.3163 16.4428 24.3165 16.4426 24.3167 16.4424C24.5615 16.1981 24.5607 15.8011 24.3167 15.5576Z'
            />
          </g>
          <defs>
            <clipPath id='clip0_7280_160746'>
              <rect width='16' height='16' fill='white' transform='translate(8.5 8)' />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  )
}

export default InfoLink
