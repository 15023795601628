import { css } from '@emotion/css'

const ICON_SIZE = '52px'

export default css(`  
  .cf-spec-item {
    display: grid;
    grid-template-rows: ${ICON_SIZE} auto;
    grid-row-gap: 4px;
    grid-template-columns: 1fr;
    grid-template-areas: 'ExtendedImage' 'Text';
    justify-items: center;

    @media(min-width: 768px) {
      grid-template-rows: minmax(${ICON_SIZE}, auto);
      grid-template-columns: ${ICON_SIZE} auto;
      grid-row-gap: 12px;
      grid-column-gap: 8px;
      grid-template-areas: 'ExtendedImage Text';
      justify-items: flex-start;
    }

    .ExtendedImage {
      width: ${ICON_SIZE};
      height: ${ICON_SIZE};
    }
    
    .Text > div {
      margin: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      div, p {
        margin: 0px;
        color: var(--textCaption);
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.15px;
      }

      div {
        align-items: center;
      }

      @media(min-width: 768px) {
        &, div {
          align-items: flex-start;
          text-align: left;
        }
      }
    }
  }
  
  .additional-info {
    .ExtendedTypography {
      font-size: 16px;
    }
    
    h6.ExtendedTypography {
      color: var(--textBlack);
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
    }
  }
`)
