import { type FC } from 'react'

import RadioLabelControl from 'website/components/RadioLabelControl'
import Checkbox from 'website/components/_base/Checkbox'
import { type ConditionSubOptionProps } from 'website/components/types'

import CLASS_NAME from './styles'

interface SubOptionsProps {
  type: 'radio' | 'checkbox'
  value?: number[] | null
  options: ConditionSubOptionProps[]
  onChange: (value: number) => void
}

const SubOptions: FC<SubOptionsProps> = ({
  type,
  value: _value,
  onChange: _onChange,
  options
}) => {
  const value = _value ?? []

  const onChange = (id: number) => () => _onChange(id)

  return (
    <div className={CLASS_NAME}>
      {options.map((option) => type === 'checkbox'
        ? (
          <Checkbox
            key={option.value}
            className='sub-option-checkbox'
            value={value.includes(option.value)}
            label={option.title}
            onChange={onChange(option.value)}
          />
          )
        : (
          <RadioLabelControl
            key={option.value}
            label={option.title}
            checked={value.includes(option.value)}
            onChange={onChange(option.value)}
          />
          )
      )}
    </div>
  )
}

export default SubOptions
