import { css } from '@emotion/css'

export default {
  default: css`
    cursor: pointer;
    color: white;
    padding: 16px;
    height: 188px; /* 220 - 32 */
    display: flex;
    align-items: end;
    position: relative;
    flex-direction: row;
    border-radius: 12px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    justify-content: space-between;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.70) 100%);
    }

    &:hover{
      &:before {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.85) 100%);
      }

      .category-arrow-icon {
        background: rgba(255, 255, 255, 1);

        path {
          fill: rgba(32, 195, 158, 1);
        }
      }
    }

    .category-text {
      font-size: 24px;
      line-height: 33px;
      font-family: Montserrat;

      h3 {
        font-size: 24px;
        font-weight: 500;
      }

      p {
        font-size: 24px;
        font-weight: 700;
      }
    }

    .category-text,
    .category-arrow-icon {
      z-index: 1;
      position: relative;
    }

    .category-arrow-icon {
      width: 40px;
      height: 40px;
      cursor: pointer;
      border-radius: 50%;
      align-items: center;
      display: inline-flex;
      justify-content: center;
      border: 1px solid rgba(255, 255, 255, 1);
    }
  `,
  secondary: css`
    padding: 24px 0 0;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    overflow: hidden;

    .car-under-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      padding: 0 40px;

      .label {
        color: rgba(34, 36, 41, 0.56);
        font-family: Inter;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: 0.72px;
        text-transform: uppercase;
      }
    }

    :hover {
      .image-wrapper {
        img {
          width: 110%;
          height: 110%;
        }
      }

      .car-under-title {
        svg {
          g {
            opacity: .24;
          }
        }
      }
    }

    .image-wrapper {
      overflow: hidden;
      width: 100%;
      padding-top: 62%;
      position: relative;

      img {
        transition: all 0.3s;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .price {
      color: #222429;
      font-family: "Plus Jakarta Sans";
      font-size: 28px;
      font-weight: 700;
      line-height: 120%;
      margin-bottom: 27px;
      padding: 0 40px;
    }

    @media (min-width: 1280px) {
      padding: 40px 0 0;

      .price {
        margin-bottom: 38px;
        font-size: 32px;
      }
    }

    @media (min-width: 1440px) {
      .price {
        margin-bottom: 48px;
        font-size: 40px;
      }
    }
  `
}
