import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import useHelmet from 'website/hooks/useHelmet'

import { type HelmetProps, SupportedComponents } from 'website/components/types'

const Helmet: FC<HelmetProps> = ({
  nameInLayout,
  title,
  meta,
  ogTags
}) => {
  const url = window.location.href

  useHelmet({
    title,
    meta,
    canonical: url,
    ogTags: ogTags ?? []
  })

  return (
    <div className={cnx(nameInLayout ?? SupportedComponents.Helmet, 'helmet-container')} />
  )
}
export default Helmet
