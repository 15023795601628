import { type FC, useCallback } from 'react'
import { cnx } from '@carfluent/common'

import { type HeadTitleProps, SupportedComponents } from 'website/components/types'
import SharedStateHook, { StoreBranches, defaultInstance } from 'website/store'

import CLASS_NAME from './styles'

const useSharedState = SharedStateHook<Store.Dealership>(StoreBranches.Dealership)

const H1Title: FC<HeadTitleProps> = ({ title, nameInLayout }) => {
  const [dealerInfo] = useSharedState(defaultInstance(StoreBranches.Dealership))

  const dealership = dealerInfo?.dealerships?.[0]
  // this function replaces the placeholders {{dealerName}}, {{dealerCity}}, and {{dealerState}} from i18n in template strings json
  const updatedDealerInfoInText = useCallback((originalText: string): string =>
    originalText.replace(/\{\{(dealerName|dealerCity|dealerState)\}\}/g,
      (_, p1) => {
        switch (p1) {
          case 'dealerName': return dealership?.dealerName ?? ''
          case 'dealerCity': return dealership?.city ?? ''
          case 'dealerState': return dealership?.state ?? ''
          default: return ''
        }
      }), [dealership])

  return (
    <h1
      className={
        cnx(nameInLayout ?? SupportedComponents.HeadTitle, CLASS_NAME, 'visually-hidden-title')
      }
    >
      {updatedDealerInfoInText(title)}
    </h1>
  )
}

export default H1Title
