import { type FC, useMemo } from 'react'
import { cnx } from '@carfluent/common'

import { ExtendedTypographyProps, SupportedComponents } from 'website/components/types'
import tagMapper from './tagMapper'
import { createStyleClass } from './styles'

const ExtendedTypography: FC<ExtendedTypographyProps> = ({
  children,
  showEllipsis = false,
  isHoverable = false,
  nameInLayout = SupportedComponents.ExtendedTypography,
  variant,
  color,
  fontWeight,
  fontSize,
  textAlign,
  lineHeight,
  className,
  value
}) => {
  const cls = useMemo(() => {
    return createStyleClass({ variant, color, fontWeight, fontSize, textAlign, lineHeight, showEllipsis, isHoverable })
  }, [variant, color, fontWeight, lineHeight, textAlign, showEllipsis, isHoverable])

  const TextTag = tagMapper(variant)
  const resolvedValue = value ?? children

  if (typeof resolvedValue === 'string') {
    return (
      <TextTag
        className={cnx(nameInLayout, cls, className)}
        dangerouslySetInnerHTML={{ __html: resolvedValue }}
      />
    )
  }

  return (
    <TextTag className={cnx(nameInLayout, cls, className)}>
      {resolvedValue}
    </TextTag>
  )
}

export default ExtendedTypography
