import get from 'lodash-es/get'
import EN from 'website/configs/i18n/en/index.json'

export interface TranslateConfig {
  lang: KnownLanguages
}

export type KnownLanguages = 'en'

const DEFAULT_CONFIG: TranslateConfig = {
  lang: 'en'
}

const LANGUAGES: Record<KnownLanguages, Record<string, string>> = {
  en: EN
}

const translate = (key: string, config: TranslateConfig = DEFAULT_CONFIG): string => {
  const dict = LANGUAGES[config.lang]

  if (dict == null) {
    return key
  }

  return get(dict, key, key)
}

export default translate
