import { css } from '@emotion/css'

export default css`
  --windowTitleColor: #222429;
    
  width: 100%;
  height: 100%;
  position: fixed;
  background: white;
  display: flex;
  flex-direction: column;

  .chat-preview-first-message {
    color: #000;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4px;
    margin-bottom: 12px;
  }

  .emoji-picker {
    position: absolute;
    bottom: 0;
    right: 56px;
    z-index: 1;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;

    .emoji-toggle {
      display: flex;
    }
  }
 
  @media screen and (min-width: 1024px) {
    width: 375px;
    height: 540px;
    right: 24px;
    bottom: 106px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.20);
  }

  .chat-preview-content {
    border-radius: 12px;
    border: 1px solid #DCDEE0;
    background: #F7F8FA;

    .chat-preview-item {
      border-bottom: 1px solid #DCDEE0;
      display: flex;
      align-items: center;
      padding: 16px;
      justify-content: space-between;
      cursor: pointer;
      transition: all 0.3s ease;

      :hover {
        background: #EBECF0;
      }

      svg {
        height: 16px;
      }

      :last-child {
        border-bottom: none;
      }
    }
  }
  
  .chat-header {
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--windowTitleColor);
    color: var(--textWhite);
    font-weight: 500;
    padding: 0 16px;
    cursor: pointer;

    > svg {
      opacity: 0.48;
    }
    
    @media screen and (min-width: 1024px) {
      position: relative;
      z-index: 4;
    }
  }
  
  .chat-content {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
    padding: 16px;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer and Edge */
  }
  
  .chat-content::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }

  .chat-textarea-wrapper {
    position: relative;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    display: flex;

    textarea {
      padding-right: 82px;
      background: #fff;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.5px;
      font-family: Roboto;

      &::placeholder {
        color: rgba(33, 33, 33, 0.4);
      }
    }
    
    .chat-send-icon {
      height: 56px;
      width: 48px;
      display: flex;
      align-items: center;
      padding-left: 12px;
      position: absolute;
      box-sizing: border-box;
      top: 0;
      right: 0;
      cursor: pointer;
      
      &.disabled {
        opacity: .3;
        cursor: not-allowed;
      }
    }
  }
  
  .request-call-button {
    &.cf-button-root.cf-button-variant-outlined.cf-button-color-primary {
      height: 48px;
      width: 100%;
      background: white;
      border-radius: 0;
      border: 1px solid rgba(0, 0, 0, 0.08);
      
      .cf-button-content {
        color: var(--mainColor);
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
      }
    }
  }
`
