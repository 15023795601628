import { formatInteger, formatDecimal } from './format_number'

type NullableValue = string | number | null

export const formatCheckoutValueForView = (
  value: string | number | null,
  startPrefix: string,
  endPrefix: string,
  isDecimal: boolean): string => {
  const emptyValue = '-'

  if (value === null) {
    return emptyValue
  }

  let formattedValue = ''

  if (isDecimal) {
    formattedValue = formatDecimal(value)
  } else if (typeof (value) === 'number') {
    formattedValue = formatInteger(Math.abs(value), emptyValue, emptyValue, true)
  } else {
    formattedValue = value
  }

  if (formattedValue === '0') {
    return emptyValue
  }

  return `${startPrefix}${formattedValue}${endPrefix}`
}

export const concatForView = (values: NullableValue[], concatSymbol = ''): string | null => {
  const isAllElementsWithValue = values.every(Boolean)

  return isAllElementsWithValue ? values.join(`${concatSymbol} `) : null
}

export const formatIntegerForView = (value: string | number | null, suffix: string = ''): string | null => {
  if (value === null || value === 0) {
    return null
  }

  const valueInteger = formatInteger(value)

  return `${valueInteger}${suffix}`
}

export const joinPartsToStr = (sep: string, ...args: Array<string | number | null | undefined>): string => {
  return args.filter(Boolean).join(sep).trim()
}

export const joinPartsBySpace = joinPartsToStr.bind(null, ' ')
