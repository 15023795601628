import { type FC } from 'react'
import { type IconProps } from 'website/components/types'

const Map2Icon: FC<IconProps> = ({
  fill = '#FFF'
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M16.6693 8.51432C16.6693 13.6995 10.0026 18.144 10.0026 18.144C10.0026 18.144 3.33594 13.6995 3.33594 8.51432C3.33594 6.74621 4.03832 5.05052 5.28856 3.80028C6.5388 2.55004 8.23449 1.84766 10.0026 1.84766C11.7707 1.84766 13.4664 2.55004 14.7166 3.80028C15.9669 5.05052 16.6693 6.74621 16.6693 8.51432Z"
        stroke={fill}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M9.99957 10.7374C11.2269 10.7374 12.2218 9.74249 12.2218 8.51519C12.2218 7.28789 11.2269 6.29297 9.99957 6.29297C8.77227 6.29297 7.77734 7.28789 7.77734 8.51519C7.77734 9.74249 8.77227 10.7374 9.99957 10.7374Z"
        stroke={fill}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Map2Icon
