export default {
  default: `
font-family: Roboto, sans-serif;
font-weight: 400;
line-height: 1.5;
font-size: 16px;
`,

  body2: `
font-size: 14px;

@media (min-width: 376px) {
  font-size: 16px;
}
`,
  smallText: `
  font-size: 12px;
`,
  textMain: `
font-size: 12px;

@media (min-width: 767px) {
  font-size: 14px;
}

@media (min-width: 1440px) {
  font-size: 16px;
}
`,

  textInfo: `
font-size: 14px;

@media (min-width: 1200px) {
  font-size: 20px;
}
`,

  text1: `
font-size: 14px;
`,

  text2: `
font-size: 16px;

@media (min-width: 767px) {
  font-size: 18px;
}
`,

  subtitle1: `
font-size: 16px;
font-weight: 700;

@media (min-width: 767px) {
  font-size: 18px;
}
`,
  subtitle1Thin: `
font-size: 14px;
font-weight: 400;

@media (min-width: 767px) {
  font-size: 24px;
}
`,

  h1: `
font-family: Montserrat;
font-size: 32px;
font-weight: 700;

@media (min-width: 767px) {
  font-size: 40px;
}
`,
  header1Thin: `
font-size: 34px;
line-height: 36px;
font-weight: 400;

@media (min-width: 767px) {
  font-size: 48px;
  line-height: 56px;
  font-weight: 400;
}`,

  header1: `
font-size: 34px;
line-height: 36px;
font-weight: 400;

@media (min-width: 767px) {
  font-size: 44px;
  line-height: 56px;
  font-weight: 700;
}
`,

  h2: `
font-size: 18px;
font-weight: 700;

@media (min-width: 376px) {
  font-size: 20px;
}

@media (min-width: 1440px) {
  font-size: 24px;
}
`,
  h3: `
  font-family: Montserrat;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;

  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 36px;
  }
`,
  h4: `
  font-size: 16px;

  @media (min-width: 1200px) {
    font-size: 20px;
    font-weight: 500;
  }
`,
  h4Thin: `
  font-size: 16px;

  @media (min-width: 768px) {
    font-size: 20px;
  }
  `,
  header4: `
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`,
  body1: `
font-size: 16px;

@media (min-width: 765px) {
  font-size: 20px;
}
`,
  noCarInfoTitle: `
  font-size: 34px;
  line-height: 36px;
`
}
