import { type FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { cnx, formatCurrency } from '@carfluent/common'

import { WebsiteRoutes } from 'website/routing/constants'

import { type CarCategoryCardProps } from '../types'
import SvgIcon from '../_base/SvgIcon'
import CLASS_NAME from './styles'
import IconSVG from '../icons'

const CarCategoryCard: FC<CarCategoryCardProps> = ({ image, label, price, variant = 'default', background }) => {
  const navigate = useNavigate()

  const onCarCategorySelect = (): void => {
    navigate(`${WebsiteRoutes.Inventory}?price=0-${price}`)
  }

  if (variant === 'default') {
    return (
      <div
        className={cnx('carCategoryCard', CLASS_NAME[variant])}
        onClick={onCarCategorySelect}
        style={{
          backgroundImage: `url(${image})`
        }}
      >
        <div className='category-text'>
          <h3>{label}</h3>

          <p>{formatCurrency(price)}</p>
        </div>

        <span className='category-arrow-icon'><SvgIcon type='arrowRightAlt' /></span>
      </div>
    )
  }

  return (
    <div
      style={{ background }}
      className={cnx('CarCategoryCard', CLASS_NAME[variant])}
      onClick={onCarCategorySelect}
    >
      <div className='car-under-title'>
        <p className='label'>{label}</p> <IconSVG.ArrowRight />
      </div>

      <p className='price'>{formatCurrency(price)}</p>

      <div className='image-wrapper'>
        <img src={image} alt='image' />
      </div>
    </div>
  )
}

export default CarCategoryCard
