import { css } from '@emotion/css'

export default css(`
  grid-area: DealerInfoFragment;

  a {
    display: flex;
  }
  
  .title {
    color: rgba(255, 255, 255, 0.56);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.56px;
    margin-bottom: 16px;
  }

  .phone-block, .address-block {
    text-decoration: none;
    color: #000;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }
`)
