import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import { StaticDocumentsApiProvider } from 'api/static_documents.api'
import SharedStateHook, { defaultInstance, StoreBranches } from 'website/store'
import ExtendedTypography from 'website/components/_base/ExtendedTypography'
import { useLayoutStyles } from 'website/styles/useLayoutStyles'
import {
  SupportedComponents,
  type GetPrequalifiedDisclosureProps
} from 'website/components/types'

const useSharedState = SharedStateHook<Store.Dealership>(StoreBranches.Dealership)

const GetPrequalifiedDisclosure: FC<GetPrequalifiedDisclosureProps> = ({
  nameInLayout,
  className,
  template,
  layout,
  rootRef,
  ...otherProps
}) => {
  const [dealerInfo] = useSharedState(defaultInstance(StoreBranches.Dealership))
  const _template = template ?? { name: 'flex-full-row' }
  const templateCls = useLayoutStyles({ template: _template, layout })

  const termsAndConditionsLink = StaticDocumentsApiProvider.getTermsAndConditionsUrl()
  const privacyPolicyLink = StaticDocumentsApiProvider.getPrivacyPolicyUrl()

  const firstDealerShip = dealerInfo?.dealerships?.[0]

  return (
    <div
      ref={rootRef}
      className={cnx(nameInLayout ?? SupportedComponents.Text, className)}
      {...otherProps}
    >
      <div className={templateCls}>
        <ExtendedTypography>
          By clicking the "Get pre-qualified", I consent to have my credit file accessed for purposes of
          prequalifying for a vehicle loan. I agree to the{' '}

          <a href={privacyPolicyLink ?? ''} target='_blank' rel='noopener noreferrer'>Privacy Policy</a>,{' '}

          <a href={termsAndConditionsLink ?? ''} target='_blank' rel='noopener noreferrer'>Terms and Conditions</a>{' '}

          and I acknowledge I may be contacted by Сarfluent or {firstDealerShip?.dealerName}. I understand that I might not prequalify
          depending on the prequalification criteria.
        </ExtendedTypography>
      </div>
    </div>
  )
}

export default GetPrequalifiedDisclosure
