import { type FC, type ReactNode, useMemo } from 'react'
import { cnx } from '@carfluent/common'
import { Link } from 'react-router-dom'
import { ExtendedImageProps, SupportedComponents } from 'website/components/types'
import CLASS_NAME from './styles'

const ExtendedImage: FC<ExtendedImageProps> = ({
  className,
  nameInLayout,
  src,
  href,
  alt,
  width,
  height,
  isBackground,
  backgroundSize
}) => {
  const imgStyle = useMemo(() => {
    return (isBackground === true && src != null)
      ? {
          background: `url(${src}) no-repeat center center/${backgroundSize ?? 'cover'}`
        }
      : {}
  }, [backgroundSize, isBackground, src])

  const sizeStyle = useMemo(() => {
    const style: KeyVal = {}

    if (width != null) {
      style.width = width
    }

    if (height != null) {
      style.height = height
    }

    return style
  }, [width, height])

  const renderContent = (): ReactNode => {
    const image = isBackground === true
      ? <div className={CLASS_NAME} style={{ ...imgStyle, ...sizeStyle }} />
      : src == null
        ? null
        : (
          <img
            style={sizeStyle}
            src={src ?? ''}
            alt={alt ?? 'image'}
          />
          )

    if (href != null) {
      return href.includes('http')
        ? (
          <a
            href={href}
            target='_blank'
            rel='noopener noreferrer'
            className={CLASS_NAME}
          >
            {image}
          </a>
          )
        : (
          <Link to={href} className={CLASS_NAME}>{image}</Link>
          )
    } else {
      return image
    }
  }

  return (
    <div className={cnx(nameInLayout ?? SupportedComponents.ExtendedImage, className)}>
      {renderContent()}
    </div>
  )
}

export default ExtendedImage
