import { type FC } from 'react'
import { MessageDialog, cnx } from '@carfluent/common'

import thankfulUser from 'website/assets/thankfulUser.png'

import CLASS_NAME from './styles'

interface ThankForYourInterestDialogProps {
  isOpen: boolean
  onActionBtnClick: () => void
}

const ThankForYourInterestDialog: FC<ThankForYourInterestDialogProps> = ({ isOpen, onActionBtnClick }) => {
  return (
    <MessageDialog
      isOpen={isOpen}
      className={cnx(CLASS_NAME, 'cf-thank-for-your-interest-dialog')}
      textSubmit='back to car'
      onSubmit={onActionBtnClick}
    >
      <img src={thankfulUser} alt='Thankful user' />

      <div className='label-text-big'>
        <p> Thank you </p>
        <p> for your interest </p>
      </div>

      <p className='label-text'>
        We will contact you shortly.
      </p>
    </MessageDialog>
  )
}

export default ThankForYourInterestDialog
