import {
  type JsonTemplate as BaseJsonTemplate,
  type JsonTemplateData as BaseJsonTemplateData
} from 'website/siteGenerator/types'

/**
 * WARNING: it is important each template ends with 'Template' in the name
 */
export const AvailableTemplates = {
  HeaderTemplate: 'headerTemplate',
  FooterTemplate: 'footerTemplate',
  AboutUsPromosForMainPageTemplate: 'aboutUsPromosForMainPageTemplate',
  AboutUsPromosForAboutUsPageTemplate: 'aboutUsPromosForAboutUsPageTemplate',
  FinancingPageTemplate: 'financingPageTemplate',
  TradePageTemplate: 'tradePageTemplate',
  TradeSellBlock: 'tradeSellBlockTemplate',
  AboutUsPageTemplate: 'aboutUsPageTemplate',
  DealerInfoTemplate: 'dealerInfoTemplate',
  IncludedWithCarAdsTemplate: 'includedWithCarAdsTemplate',
  RootTemplate: 'rootTemplate',
  PrequalifiedFormTemplate: 'prequalifiedFormTemplate',
  PrequalifiedFormSuccessTemplate: 'prequalifiedFormSuccessTemplate',
  PrequalifiedFormErrorTemplate: 'prequalifiedFormErrorTemplate',
  InventoryPageTemplate: 'inventoryPageTemplate',
  VehicleDetailsTemplate: 'vehicleDetailsTemplate',
  PrequalifiedFormSSNIncomeTemplate: 'prequalifiedFormSSNIncomeTemplate',
  UnsubscribePageTemplate: 'unsubscribePageTemplate',
  UnsubscribedPageTemplate: 'unsubscribedPageTemplate',
  LocationsPageTemplate: 'locationsPageTemplate',
  DealershipLocationPageTemplate: 'dealershipLocationPageTemplate',
  HomePageTemplate: 'homePageTemplate'
} as const

export type JsonTemplate = BaseJsonTemplate<ValueOf<typeof AvailableTemplates>>
export type JsonTemplateData = BaseJsonTemplateData<ValueOf<typeof AvailableTemplates>>
