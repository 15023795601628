import React, { type FC, useMemo } from 'react'
import { cnx } from '@carfluent/common'

import { SupportedComponents, ComponentPropsMap } from 'website/components/types'
import type {
  DealerLocationsMenuProps,
  DynamicComponentProps,
  FiltersDrawerTogglerProps,
  HeaderProps,
  SocialsProps,
  MapProps,
  ScheduleProps,
  CarouselProps,
  VehicleCardProps,
  RecommendedVehiclesProps,
  SearchBarProps,
  ButtonProps,
  DealerInfoProps,
  DealerInfoFragmentProps,
  VehicleInfiniteScrollProps,
  VehiclesFilterProps,
  VehiclesFilterChipsProps,
  VehiclesSearchProps,
  TextProps,
  ListProps,
  VehiclesSortingProps,
  LoginProps,
  GalleryVehicleViewProps,
  VehicleBriefInfoProps,
  VehicleSpecsOverviewProps,
  VehicleViewContentProps,
  TextWithFillerProps,
  RequestHelpOrDriveProps,
  InfoCardProps,
  ModalProps,
  CarAppraisalFormProps,
  BannerProps,
  CustomerReviewsProps,
  TooltipTextProps,
  FeedbackFormProps,
  DealershipLocationInfoProps,
  DealerLocationsListProps,
  SearchBarRoundedProps
} from 'website/components/types'

import Header from 'website/components/Header'
import FooterInfo from 'website/components/FooterInfo'
import Login from 'website/components/Login'
import Carousel from 'website/components/Carousel'
import CarBodyStylePanel from 'website/components/CarBodyStylePanel'
import Filler from 'website/components/Filler'
import DrawerMenu from 'website/components/DrawerMenu'
import RouteOutlet from 'website/components/RouteOutlet'
import ExtendedImage from 'website/components/ExtendedImage'
import ExtendedTypography from 'website/components/_base/ExtendedTypography'
import LazyImage from 'website/components/LazyImage'
import Schedule from 'website/components/Schedule'
import Map from 'website/components/Map'
import MainSearchSection from 'website/components/MainSearchSection'
import Text from 'website/components/Text'
import Socials from 'website/components/Socials'
import VehicleCard from 'website/components/VehicleCard'
import RecommendedVehicles from 'website/components/RecommendedVehicles'
import SearchBar from 'website/components/SearchBar'
import Button from 'website/components/Button'
import VehicleInfiniteScroll from 'website/components/VehicleInfiniteScroll'
import VehiclesFilter from 'website/components/VehiclesFilter'
import VehiclesFilterChips from 'website/components/VehiclesFilterChips'
import VehiclesSorting from 'website/components/VehiclesSorting'
import VehiclesSearch from 'website/components/VehiclesSearch'
import DealerInfo from 'website/components/DealerInfo'
import DealerInfoFragment from 'website/components/_base/DealerInfoFragment'
import List from 'website/components/List'
import GalleryVehicleView from 'website/components/GalleryVehicleView'
import FiltersDrawerToggler from 'website/components/FiltersDrawerToggler'
import VehicleViewContent from 'website/components/VehicleView/VehicleViewContent'
import VehicleBriefInfo from 'website/components/VehicleView/VehicleViewContent/components/VehicleBriefInfo'
import VehicleSpecsOverview from 'website/components/VehicleView/VehicleViewContent/components/VehicleSpecsOverview'
import RequestHelpOrDrive from 'website/components/RequestHelpOrDrive'
import TextWithFiller from 'website/components/TextWithFiller'
import GetPrequalifiedComponent from 'website/components/GetPrequalifiedComponent'
import InfoCard from 'website/components/InfoCard'
import Modal from 'website/components/Modal'
import CarAppraisalForm from 'website/components/CarAppraisalForm'
import Banner from 'website/components/Banner'
import CustomerReviews from 'website/components/CustomerReviews'
import GetPrequalifiedRedirectComponent from 'website/components/GetPrequalifiedRedirectComponent'
import GetPrequalifiedForm from 'website/components/GetPrequalifiedForm'
import GetPrequalifiedSuccess from 'website/components/GetPrequalifiedSuccess'
import TooltipText from 'website/components/TooltipText'
import TradeInDetailsFormPage from 'website/components/TradeInDetailsFormPage'
import TradeInCar from 'website/components/TradeInCar'
import TradeInUserDetailsForm from 'website/components/TradeInUserDetailsForm'
import DealershipLocations from 'website/components/DealershipLocations'
import DealerLocationsList from 'website/components/DealerLocationsList'
import GetDirections from 'website/components/GetDirections'
import DealershipLocationBanner from '../DealershipLocations/components/DealershipLocationBanner'
import ZipCodeLocation from '../ZipCodeLocation'
import ZipCodeLocationModal from '../ZipCodeLocationUpdateModal'
import SsnYearIncomeForm from '../SsnYearIncomeForm'
import FeedbackForm from '../FeedbackForm'
import UnsubscribeButton from '../UnsubscribeButton'
import DealershipLocationInfo from '../DealershipLocations/components/DealershipLocationInfo'
import DealerLocationsMenu from '../DealerLocationsMenu'
import CarCategoryCard from '../CarCategoryCard'
import PopularCarsPanel from '../PopularCarsPanel'
import HomePageVideoPlayer from '../HomePageVideoPlayer'
import Helmet from '../Helmet'
import H1Title from '../H1Title'
import LocalBusinessSchema from '../LocalBusinessSchema'
import ExtendedIconSVG from '../ExtendedIconSVG'
import SearchBarRounded from '../SearchBarRounded'

import { useComponentStyles } from 'website/styles/useComponentStyles'
import { useLayoutStyles } from 'website/styles/useLayoutStyles'
import FeatureOptionList from 'website/components/FeatureOptionList'
import Messenger from 'website/components/Messenger'
import DescriptionsCarousel from '../DescriptionsCarousel'
import CarUnderPriceList from '../CarUnderPriceList'

const MIN_COMPONENT_NAME_LENGTH = 2

const DynamicComponent: FC<DynamicComponentProps> = ({
  nameInLayout,
  template,
  layout,
  componentProps,
  className,
  variant
}) => {
  const componentStylesCls = useComponentStyles(nameInLayout, variant)
  const templateCls = useLayoutStyles({ template, layout })

  const pageComponents = useMemo(() => {
    if (componentProps == null) {
      return null
    }

    return Array.from(new Set(Object.keys(componentProps)))
  }, [componentProps])

  if (pageComponents == null) {
    return null
  }

  return (
    <div className={cnx(nameInLayout, componentStylesCls.root, className)}>
      <div className={cnx(componentStylesCls.content, templateCls)}>
        {
          /**
           * sometimes componentMapper might be used outside Dynamic - in List, for example.
           * There componentName would be the same and setting nameInTheLayout does not make sense.
           * That is why we separate - obligatory key and optional componentName.
           */
          pageComponents.map(component => componentMapper(component, component, componentProps))
        }
      </div>
    </div>
  )
}

export default DynamicComponent

/**
 *
 * @param component Component name. If not found in Supported Components
 * or in componentProps map it would be a Dynamic Component
 * @param propsMap component props. It also accepts Record<string any> because it can be a Dynamic Component
 * @returns React component with props passed to it
 */
export const componentMapper = (
  key: string,
  component?: string,
  propsMap?: Partial<ComponentPropsMap>
): React.ReactNode => {
  const props = {
    key,
    nameInLayout: key
  }

  /**
   * Route Outlet does not need any props from configs
   */
  if (component === SupportedComponents.RouteOutlet) {
    return <RouteOutlet {...props} {...propsMap?.[component]} />
  }

  /**
   * This typeGuard is very high level - it does not check type internals
   * Besides it means that if any Supported Component does not need  props
   * we must add those even empty to the componentProps section
   */

  if (component == null || propsMap?.[component] == null) {
    return <DynamicComponent {...props} />
  }

  const componentNameParts = /^([a-zA-Z]+)\d*$/.exec(component)

  if (componentNameParts == null || componentNameParts.length < MIN_COMPONENT_NAME_LENGTH) {
    return null
  }

  const componentName = componentNameParts[1]

  /**
   * DD-TODO:
   * Think of the best way how to type components and their mapping.
   * Maybe add more type Guards or think of how to map props to components in a better way
   *
   * ! - exclamation marks should be fine as the check was already passed for the presence
   * of componentProps config
   */

  switch (componentName) {
    case SupportedComponents.Header: {
      return <Header {...props} {...propsMap[component] as HeaderProps} />
    }
    case SupportedComponents.FooterInfo: {
      return <FooterInfo {...props} {...propsMap[component]} />
    }
    case SupportedComponents.ExtendedImage: {
      return <ExtendedImage {...props} {...propsMap[component]} />
    }
    case SupportedComponents.ExtendedTypography: {
      return <ExtendedTypography {...props} {...propsMap[component]} />
    }
    case SupportedComponents.LazyImage: {
      return <LazyImage {...props} {...propsMap[component]} />
    }
    case SupportedComponents.CarBodyStylePanel: {
      return <CarBodyStylePanel {...props} {...propsMap[component]} />
    }
    case SupportedComponents.Login: {
      return <Login {...props} {...propsMap[component] as LoginProps} />
    }
    case SupportedComponents.Carousel: {
      return <Carousel {...props} {...propsMap[component] as CarouselProps} />
    }
    case SupportedComponents.Filler: {
      return <Filler {...props} {...propsMap[component]} />
    }
    case SupportedComponents.Schedule: {
      return <Schedule {...props} {...propsMap[component] as ScheduleProps} />
    }
    case SupportedComponents.DrawerMenu: {
      return <DrawerMenu {...props} {...propsMap[component]} />
    }
    case SupportedComponents.Map: {
      return <Map {...props} {...propsMap[component] as MapProps} />
    }
    case SupportedComponents.MainSearchSection: {
      return <MainSearchSection {...props} {...propsMap[component]} />
    }
    case SupportedComponents.Socials: {
      return <Socials {...props} {...propsMap[component] as SocialsProps} />
    }
    case SupportedComponents.RecommendedVehicles: {
      return <RecommendedVehicles {...props} {...propsMap[component] as RecommendedVehiclesProps} />
    }
    case SupportedComponents.DealershipLocations: {
      return <DealershipLocations {...props} {...propsMap[component] as RecommendedVehiclesProps} />
    }
    case SupportedComponents.DealershipLocationBanner: {
      return <DealershipLocationBanner {...props} {...propsMap[component] as BannerProps} />
    }
    case SupportedComponents.DealershipLocationInfo: {
      return <DealershipLocationInfo {...props} {...propsMap[component] as DealershipLocationInfoProps} />
    }
    case SupportedComponents.DealerLocationsMenu: {
      return <DealerLocationsMenu {...props} {...propsMap[component] as DealerLocationsMenuProps} />
    }
    case SupportedComponents.DealerLocationsList: {
      return <DealerLocationsList {...props} {...propsMap[component] as DealerLocationsListProps} />
    }
    case SupportedComponents.List: {
      return <List {...props} {...propsMap[component] as ListProps} />
    }
    case SupportedComponents.Text: {
      return <Text {...props} {...propsMap[component] as TextProps} />
    }
    case SupportedComponents.VehicleCard: {
      return <VehicleCard {...props} {...propsMap[component] as VehicleCardProps} />
    }
    case SupportedComponents.SearchBar: {
      return <SearchBar {...props} {...propsMap[component] as SearchBarProps} />
    }
    case SupportedComponents.Button: {
      return <Button {...props} {...propsMap[component] as ButtonProps} />
    }
    case SupportedComponents.VehicleInfiniteScroll: {
      return <VehicleInfiniteScroll {...props} {...propsMap[component] as VehicleInfiniteScrollProps} />
    }
    case SupportedComponents.DealerInfo: {
      return <DealerInfo {...props} {...propsMap[component] as DealerInfoProps} />
    }
    case SupportedComponents.DealerInfoFragment: {
      return <DealerInfoFragment {...props} {...propsMap[component] as DealerInfoFragmentProps} />
    }
    case SupportedComponents.VehiclesFilter: {
      return <VehiclesFilter {...props} {...propsMap[component] as VehiclesFilterProps} />
    }
    case SupportedComponents.VehiclesFilterChips: {
      return <VehiclesFilterChips {...props} {...propsMap[component] as VehiclesFilterChipsProps} />
    }
    case SupportedComponents.VehiclesSorting: {
      return <VehiclesSorting {...props} {...propsMap[component] as VehiclesSortingProps} />
    }
    case SupportedComponents.VehicleViewContent: {
      return <VehicleViewContent {...props} {...propsMap[component] as VehicleViewContentProps} />
    }
    case SupportedComponents.VehicleBriefInfo: {
      return <VehicleBriefInfo {...props} {...propsMap[component] as VehicleBriefInfoProps} />
    }
    case SupportedComponents.VehicleSpecsOverview: {
      return <VehicleSpecsOverview {...props} {...propsMap[component] as VehicleSpecsOverviewProps} />
    }
    case SupportedComponents.FeatureOptionList: {
      return <FeatureOptionList {...props} {...propsMap[component] as VehicleSpecsOverviewProps} />
    }
    case SupportedComponents.GalleryVehicleView: {
      return <GalleryVehicleView {...props} {...propsMap[component] as GalleryVehicleViewProps} />
    }
    case SupportedComponents.VehiclesSearch: {
      return <VehiclesSearch {...props} {...propsMap[component] as VehiclesSearchProps} />
    }
    case SupportedComponents.FiltersDrawerToggler: {
      return <FiltersDrawerToggler {...props} {...propsMap[component] as FiltersDrawerTogglerProps} />
    }
    case SupportedComponents.RequestHelpOrDrive: {
      return <RequestHelpOrDrive {...props} {...propsMap[component] as RequestHelpOrDriveProps} />
    }
    case SupportedComponents.TextWithFiller: {
      return <TextWithFiller {...props} {...propsMap[component] as TextWithFillerProps} />
    }
    case SupportedComponents.GetPrequalifiedComponent: {
      return <GetPrequalifiedComponent {...props} {...propsMap[component]} />
    }
    case SupportedComponents.GetPrequalifiedRedirectComponent: {
      return <GetPrequalifiedRedirectComponent {...props} {...propsMap[component]} />
    }
    case SupportedComponents.GetPrequalifiedForm: {
      return <GetPrequalifiedForm {...props} {...propsMap[component]} />
    }
    case SupportedComponents.GetPrequalifiedSuccess: {
      return <GetPrequalifiedSuccess {...props} {...propsMap[component]} />
    }
    case SupportedComponents.InfoCard: {
      return <InfoCard {...props} {...propsMap[component] as InfoCardProps} />
    }
    case SupportedComponents.Modal: {
      return <Modal {...props} {...propsMap[component] as ModalProps} />
    }
    case SupportedComponents.CarAppraisalForm: {
      return <CarAppraisalForm {...props} {...propsMap[component] as CarAppraisalFormProps} />
    }
    case SupportedComponents.Banner: {
      return <Banner {...props} {...propsMap[component] as BannerProps} />
    }
    case SupportedComponents.CustomerReviews: {
      return <CustomerReviews {...props} {...propsMap[component] as CustomerReviewsProps} />
    }
    case SupportedComponents.TooltipText: {
      return <TooltipText {...props} {...propsMap[component] as TooltipTextProps} />
    }
    case SupportedComponents.TradeInDetailsFormPage: {
      return <TradeInDetailsFormPage {...props} {...propsMap[component]} />
    }
    case SupportedComponents.TradeInCar: {
      return <TradeInCar {...props} {...propsMap[component]} />
    }
    case SupportedComponents.TradeInUserDetailsForm: {
      return <TradeInUserDetailsForm {...props} {...propsMap[component]} />
    }
    case SupportedComponents.ZipCodeLocation: {
      return <ZipCodeLocation {...props} {...propsMap[component]} />
    }
    case SupportedComponents.ZipCodeLocationModal: {
      return <ZipCodeLocationModal {...props} {...propsMap[component]} />
    }
    case SupportedComponents.SsnYearIncomeForm: {
      return <SsnYearIncomeForm {...props} {...propsMap[component]} />
    }
    case SupportedComponents.FeedbackForm: {
      return <FeedbackForm {...props} {...propsMap[component] as FeedbackFormProps} />
    }
    case SupportedComponents.UnsubscribeButton: {
      return <UnsubscribeButton {...props} {...propsMap[component]} />
    }
    case SupportedComponents.Messenger: {
      return <Messenger {...props} {...propsMap[component]} />
    }
    case SupportedComponents.CarCategoryCard: {
      return <CarCategoryCard {...props} {...propsMap[component]} />
    }
    case SupportedComponents.PopularCarsPanel: {
      return <PopularCarsPanel {...props} {...propsMap[component]} />
    }
    case SupportedComponents.HomePageVideoPlayer: {
      return <HomePageVideoPlayer {...props} {...propsMap[component]} />
    }
    case SupportedComponents.Helmet: {
      return <Helmet {...props} {...propsMap[component]} />
    }
    case SupportedComponents.HeadTitle: {
      return <H1Title {...props} {...propsMap[component]} />
    }
    case SupportedComponents.LocalBusinessSchema: {
      return <LocalBusinessSchema {...props} {...propsMap[component]} />
    }
    case SupportedComponents.ExtendedIconSVG: {
      return <ExtendedIconSVG {...props} {...propsMap[component]} />
    }
    case SupportedComponents.GetDirections: {
      return <GetDirections {...props} {...propsMap[component]} />
    }
    case SupportedComponents.SearchBarRounded: {
      return <SearchBarRounded {...props} {...propsMap[component] as SearchBarRoundedProps} />
    }
    case SupportedComponents.DescriptionsCarousel: {
      return <DescriptionsCarousel {...props} {...propsMap[component]} />
    }
    case SupportedComponents.CarUnderPriceList: {
      return <CarUnderPriceList {...props} {...propsMap[component]} />
    }

    default: {
      return <DynamicComponent {...props} {...propsMap[component]} />
    }
  }
}
