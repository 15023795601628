import { useMemo, type FC } from 'react'
import { cnx } from '@carfluent/common'

import SharedStateHook, { StoreBranches, defaultInstance } from 'website/store'

import { type MainSearchSectionProps, SupportedComponents } from '../types'
import SearchBar from '../SearchBar'
import Text from '../Text'
import CLASS_NAME from './styles'
import SearchBarRounded from '../SearchBarRounded'

const useSharedSettingsState = SharedStateHook<Store.Settings>(StoreBranches.Settings)

const MainSearchSection: FC<MainSearchSectionProps> = ({
  componentProps,
  nameInLayout = SupportedComponents.MainSearchSection,
  searchType = 'default'
}) => {
  const [{ settings }] = useSharedSettingsState(defaultInstance(StoreBranches.Settings))
  const imgStyle = useMemo(() => {
    const headquartersId = process.env.REACT_APP_HEADQUARTERS_ID ?? ''

    return { background: `url(${process.env.REACT_APP_FILES ?? ''}/api/v1/download/headquarters/${headquartersId}/home-image) no-repeat center center/cover` }
  }, [])

  return (
    <div className={cnx(nameInLayout, CLASS_NAME)} style={imgStyle}>
      {/* we need second div for compatibility with layout system */}
      <div>
        <Text {...componentProps.Text1} nameInLayout='Text1' value={settings.homePageHeading1 ?? componentProps.Text1.value} />
        <Text {...componentProps.Text2} nameInLayout='Text2' value={settings.homePageHeading2 ?? componentProps.Text1.value} />
        {searchType === 'default' ? <SearchBar {...componentProps.SearchBar} /> : <SearchBarRounded {...componentProps?.SearchBarRounded} />}
      </div>
    </div>
  )
}

export default MainSearchSection
