import { css } from '@emotion/css'

export default css`
  --windowTitleColor: #FFF;
 
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
  top: 0;
  left: 0;
  transition: opacity .3s;
  background: rgba(0, 0, 0, 0.54);

  .request-call-content {
    position: absolute;
    left: 0;
    top: -100%;
    height: 100%;
    background: white;
    transition: top .3s;
    transition-delay: .3s;
    width: 100%;
    display: flex;
    flex-direction: column;
    
    .request-call-form-content {
      padding: 20px 16px;
      display: flex;
      flex-direction: column;
      flex: 1;

      .request-call-form-title {
        color: #222429;
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 16px;
      }
      
      .personal-info-wrapper {
        margin-bottom: 24px;

        .first-name-input {
          margin-bottom: 24px;
        }
      }
 
      .field-terms {
        margin-top: 24px; 
          
        .terms-text {
          color: #222429;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
            
          .terms-link {
            color: var(--mainColor);
            text-decoration: none;
          }
        }

        .checkbox {
          .MuiIconButton-label {
            color: #C2C2C2;
          }
            
          &.MuiCheckbox-colorSecondary.Mui-checked {
            .MuiIconButton-label {
              color: var(--mainColor) !important;
            }
          }

          padding: 1px 8px 1px 0;
          align-self: flex-start;
            
          img {
            width: 18px;
            height: 18px;
          }
        }
      }
      
      .empty-space {
        flex: 1;
      }
      
      .action-bar {
        padding: 16px 16px 0;
        background: #FAFAFA;
        margin-left: calc(-1 * var(--pageOffset) / 2);
        margin-right: calc(-1 * var(--pageOffset) / 2);
          
        .cf-button-content {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }

        .request-call-submit-button.cf-button-root.cf-button-variant-contained.cf-button-color-primary {
          height: 48px;
          padding: 0;
          width: 100%;
          background: var(--mainColor);
        }
        
        .request-call-cancel-button.cf-button-root.cf-button-variant-outlined.cf-button-color-primary {
          display: none;
        }
      }
      
      @media screen and (min-width: 1024px) {
        padding: 16px;

        .empty-space {
          display: none;
        }
        
        .personal-info-wrapper {
          display: flex;
          justify-content: space-between;

          .first-name-input {
            margin-bottom: 0;
          }

          > div {
            width: calc(50% - 8px);
          }
        }

        .action-bar {
          padding: 0;
          background: white;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;
          margin-left: 0;
          margin-right: 0;
          
          .request-call-submit-button.cf-button-root.cf-button-variant-contained.cf-button-color-primary {
            height: 56px;
            padding: 0;
            width: calc(50% - 6px);
          }

          .request-call-cancel-button.cf-button-root.cf-button-variant-outlined.cf-button-color-primary {
            display: flex;
            width: calc(50% - 6px);
          }
        }
      }
    }
    
    .request-call-header {
      display: flex;
      background: var(--windowTitleColor);
      border-bottom: 1px solid #DCDEE0;
      justify-content: space-between;
      align-items: center;
      height: 55px;
      padding: 0 14px 0 24px;
      color: #222429;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;

      span {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
    
    @media screen and (min-width: 1024px) {
      .request-call-header {
        display: none;
      }

      height: auto;
      padding-top: 56px;
    }
  }
  
  &.is-opened {
    opacity: 1;
    z-index: 1;
    
    .request-call-content {
      top: 0;
    }

    &.is-closing {
      transition-delay: 0.1s;
      opacity: 0;

      .request-call-content {
        transition-delay: 0s;
        top: -100%;
      }
    }
  }
`
