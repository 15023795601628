import { type FC } from 'react'
import { cnx, Dropdown2 } from '@carfluent/common'
import SvgIcon from 'website/components/_base/SvgIcon'
import useMediaQueryByBreakpoints from 'website/hooks/useMediaQueryByBreakpoints'

interface DealerInfoSwitcherProps {
  activeIndex: number
  className?: string
  dealershipNames: string[]
  mode?: 'list' | 'dropdown' | 'auto'
  onClick: (index: number) => void
  title?: string | null
}

const MIN_FULL_VIEW_POINT = 375

const DealerInfoSwitcher: FC<DealerInfoSwitcherProps> = ({
  activeIndex,
  className,
  dealershipNames,
  onClick,
  mode = 'auto',
  title
}) => {
  const { isMatchingBreakpoints } =
    useMediaQueryByBreakpoints([MIN_FULL_VIEW_POINT])

  const isDropdown = (mode === 'dropdown') ||
    ((mode === 'auto') && (isMatchingBreakpoints !== true))

  const hasTitle = title != null

  return (
    <div className={cnx('DealerInfoSwitcher', isDropdown && hasTitle && 'with-header', className)}>
      {hasTitle && <h3 className='dealer-info-switched-header'>{title}</h3>}

      {isDropdown && (
        <Dropdown2
          className='dealer-info-switcher-dropdown'
          id='dealer-info-switcher-dropdown'
          disableClearable
          mode='select'
          onChange={(_id, value) => {
            onClick(dealershipNames.indexOf(value ?? ''))
          }}
          options={dealershipNames}
          value={dealershipNames[activeIndex]}
        />
      )}

      {!isDropdown && (
        dealershipNames.map((name, i) => (
          <div
            key={name}
            className={cnx('dealer-info-switcher-item', i === activeIndex && 'active')}
            onClick={() => onClick(i)}
          >
            <span>{name}</span>
            <SvgIcon type='arrowRight' />
          </div>
        ))
      )}
    </div>
  )
}

export default DealerInfoSwitcher
