import type { FC } from 'react'
import type { IconProps } from '../types'

export const SmileEmojiSVG: FC<IconProps> = ({ fill = '#212121', width = 20, height = 20, opacity = '0.8' }) => (
  <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M12.916 9.16797C13.6064 9.16797 14.166 8.60833 14.166 7.91797C14.166 7.22761 13.6064 6.66797 12.916 6.66797C12.2257 6.66797 11.666 7.22761 11.666 7.91797C11.666 8.60833 12.2257 9.16797 12.916 9.16797Z' fill={fill} fillOpacity={opacity} />
    <path d='M7.08398 9.16797C7.77434 9.16797 8.33398 8.60833 8.33398 7.91797C8.33398 7.22761 7.77434 6.66797 7.08398 6.66797C6.39363 6.66797 5.83398 7.22761 5.83398 7.91797C5.83398 8.60833 6.39363 9.16797 7.08398 9.16797Z' fill={fill} fillOpacity={opacity} />
    <path d='M9.99935 13.3346C8.76602 13.3346 7.70768 12.6596 7.12435 11.668H5.73268C6.39935 13.3763 8.05768 14.5846 9.99935 14.5846C11.941 14.5846 13.5993 13.3763 14.266 11.668H12.8743C12.291 12.6596 11.2327 13.3346 9.99935 13.3346ZM9.99102 1.66797C5.39102 1.66797 1.66602 5.4013 1.66602 10.0013C1.66602 14.6013 5.39102 18.3346 9.99102 18.3346C14.5993 18.3346 18.3327 14.6013 18.3327 10.0013C18.3327 5.4013 14.5993 1.66797 9.99102 1.66797ZM9.99935 16.668C6.31602 16.668 3.33268 13.6846 3.33268 10.0013C3.33268 6.31797 6.31602 3.33464 9.99935 3.33464C13.6827 3.33464 16.666 6.31797 16.666 10.0013C16.666 13.6846 13.6827 16.668 9.99935 16.668Z' fill={fill} fillOpacity={opacity} />
  </svg>
)

export default SmileEmojiSVG
