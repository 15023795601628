import { css } from '@emotion/css'

export const DEFAULT_CLASS_NAME = css`
  overflow-x: hidden;

  .component-container {
    display: grid;
    grid-template-rows: auto auto;
    grid-row-gap: 16px;

    .body-style-container {
      display: grid;
      grid-template-columns: repeat(10, 121.6px);
      gap: 16px;
      overflow-x: auto;

      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .body-style-item {
        height: 120px;
        background-color: #fff;
        border-radius: 12px;
        box-shadow: 0px 4px 12px 0px #0000000A;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        
        img {
          max-width: 100px;
        }

        :hover {
          cursor: pointer;
          box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10);
        }

        p {
          margin-bottom: 16px;
        }
      }
    }

    @media (min-width: 768px) {
      grid-row-gap: 32px;
    }
  }
`

const SECONDARY_CLASS_NAME = css`
  margin: 0 auto;

  .component-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 24px 40px -24px rgba(51, 54, 61, 0.04);
    overflow: hidden;
    border: 1px solid #E6E7EB;
    
    .body-style-item {
      display: flex;
      justify-content: space-between;
      padding: 9px 8px 9px 20px;
      align-items: center;
      cursor: pointer;
      transition: background .2s;
      min-height: 56px;
      box-sizing: border-box;
      border-right: 1px solid #E6E7EB;
      border-bottom: 1px solid #E6E7EB;

      img {
        display: none;
      }

      :nth-child(2n) {
        border-right: none;
      }

      :nth-last-child(-n+2) {
        border-bottom: none;
      }

      :hover {
        background: linear-gradient(0deg, rgba(65, 110, 235, 0.08) 0%, rgba(65, 110, 235, 0.08) 100%), #FFF;
      }

      .small-text {
        color: #222429;
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }

  @media (min-width: 768px) {
    width: min-content;
    .component-container {
      grid-template-columns: repeat(5, minmax(130px, 1fr));

      .body-style-item {
        :nth-child(2n) {
          border-right: 1px solid #E6E7EB;
        }

        :nth-last-child(-n+2) {
          border-bottom: 1px solid #E6E7EB;
        }

        :nth-last-child(-n+5) {
          border-bottom: none;
        }

        :nth-child(5n) {
          border-right: none;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .component-container {
      grid-template-columns: repeat(5, minmax(189px, 1fr));

      .body-style-item {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 12px 6px 6px;

        .small-text {
          font-size: 14px;
        }

        p {
          margin-bottom: 4px;
        }

        img {
          display: block;
          max-height: 40px;
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .component-container {
      grid-template-columns: repeat(5, 1fr);

      .body-style-item {
        flex-direction: row;
        justify-content: space-between;
        padding: 9px 8px 9px 20px;

        img {
          max-height: auto;
        }

        p {
          margin: 0;
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .component-container {
      .body-style-item {
        .small-text {
          font-size: 16px;
        }
      }
    }
  }
`
export default {
  default: DEFAULT_CLASS_NAME,
  secondary: SECONDARY_CLASS_NAME
}
