import { css } from '@emotion/css'

export default css(`
  background-color: #fff;
  margin-bottom: 32px;
  padding: 65px 16px;
  box-sizing: border-box;

  .feedback-content {
    margin: 0px auto auto;
    max-width: 600px;

    h1 {
      text-align: left;
    }

    h3 {
      font-family: Roboto;
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.18px;
      text-align: left;
      color: #101010;
      margin-bottom: 24px;
    }

    .yes-no {
      display: grid;
      grid-column-gap: 24px;
      grid-template-columns: min-content min-content;
      margin-bottom: 32px;
    }

    textarea {
      width: 100%;
      max-width: 600px;
      height: 136px;
      padding: 16px;
      font-size: 16px;
      background-color: #FAFAFA;
      margin-bottom: 32px;
      border-radius: 12px;
      box-sizing: border-box;
      outline: none;
      resize: none;
      border: none;
    }

    .cf-button-root {
      margin-top: 40px;
      width: 100%;
      max-width: 600px;

      &.cf-button-variant-contained.cf-button-color-primary {
        background-color: var(--buttonMainColor);

        :hover {
          background-color: var(--buttonMainColor);
        }
      }
    }
  }

  &.feedback-provided, &.feedback-submitted {
    min-height: calc(100vh - 400px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: 1200px) {
      min-height: calc(100vh - 600px);
    }

    h1 {
      text-align: center;
      color: #101010;
    }

    > div:first-child {
      width: min(600px, 90vw);
      height: unset;
      background-color: #FAFAFA;
      padding: 32px 24px;
      margin: 27px 16px 32px;
      border-radius: 12px;
      text-align: center;

      p {
        margin-bottom: 4px;
        line-height: 20px;
        font-size: 14px;
        letter-spacing: 0.25px;
      }
    }

    .google-review {
      width: min(600px, 90vw);
      margin: 0px 16px 32px;

      &.cf-button-variant-contained.cf-button-color-primary {
        background-color: var(--buttonMainColor);
        
        :hover {
          background-color: var(--buttonMainColor);
        }
      }
    }
  }

  .feedback-content, &.feedback-submitted, &.feedback-provided {
    h1 {
      font-family: Montserrat;
      font-size: 40px;
      font-weight: 500;
      line-height: 56px;
      letter-spacing: 0px;
      margin-bottom: 32px;

      @media (max-width: 600px) {
        font-size: 32px;
        line-height: 40px;
      }
    }
  }
`)
