import coupeImg from 'website/assets/bs-coupe.png'
import crossoverImg from 'website/assets/bs-crossover.png'
import convertibleImg from 'website/assets/bs-convertible.png'
import hatchbackImg from 'website/assets/bs-hatchback.png'
import minivanImg from 'website/assets/bs-minivan.png'
import truckImg from 'website/assets/bs-pickuptruck.png'
import sedanImg from 'website/assets/bs-sedan.png'
import suvImg from 'website/assets/bs-suv.png'
import wagonImg from 'website/assets/bs-wagon.png'
import otherImg from 'website/assets/bs-other.png'

import coupeSecondaryImg from 'website/assets/bs-secondary-coupe.svg'
import crossoverSecondaryImg from 'website/assets/bs-secondary-crossover.svg'
import convertibleSecondaryImg from 'website/assets/bs-secondary-convertible.svg'
import hatchbackSecondaryImg from 'website/assets/bs-secondary-hatchback.svg'
import minivanSecondaryImg from 'website/assets/bs-secondary-minivan.svg'
import truckSecondaryImg from 'website/assets/bs-secondary-pick-up.svg'
import sedanSecondaryImg from 'website/assets/bs-secondary-sedan.svg'
import suvSecondaryImg from 'website/assets/bs-secondary-suv.svg'
import wagonSecondaryImg from 'website/assets/bs-secondary-wagon.svg'
import otherSecondaryImg from 'website/assets/bs-secondary-other.svg'

import audiImg from 'website/assets/makes/audi.svg'
import bmwImg from 'website/assets/makes/bmw.svg'
import chevroletImg from 'website/assets/makes/chevrolet.svg'
import fordImg from 'website/assets/makes/ford.svg'
import hondaImg from 'website/assets/makes/honda.svg'
import hyundaiImg from 'website/assets/makes/hyundai.svg'
import jeepImg from 'website/assets/makes/jeep.svg'
import kiaImg from 'website/assets/makes/kia.svg'
import mazdaImg from 'website/assets/makes/mazda.svg'
import mercedesImg from 'website/assets/makes/mercedes.svg'
import nissanImg from 'website/assets/makes/nissan.svg'
import ramImg from 'website/assets/makes/ram.svg'
import subaruImg from 'website/assets/makes/subaru.svg'
import teslaImg from 'website/assets/makes/tesla.svg'
import toyotaImg from 'website/assets/makes/toyota.svg'

import isMultirooftop from 'website/utils/isMultirooftop'

import type {
  ActiveFilters,
  FilterBodyStyleKey,
  FilterMakesKey,
  FilterName,
  FilterRangeKey,
  FilterRenderType,
  GeneratedFilter,
  RangeFilterConfig,
  SortOption,
  VehiclesSorting
} from './appTypes/filters'
import { BODY_STYLES, COLORS, DRIVETRAINS, FUELTYPES, TRANSMISSIONS } from './constants'

function getFromMap<K, V> (map: Map<K, V>, key: K): V {
  const result = map.get(key)
  if (result === undefined) {
    throw new Error(`Key not found: ${String(key)}`)
  }
  return result
}

const sortOptions: SortOption[] = [
  { sortField: 'make', sortOrder: 'Ascending', title: 'Make A-Z' },
  { sortField: 'createdDate', sortOrder: 'Descending', title: 'Recently added' },
  { sortField: 'salePrice', sortOrder: 'Ascending', title: 'Price: lowest first' },
  { sortField: 'salePrice', sortOrder: 'Descending', title: 'Price: highest first' },
  { sortField: 'year', sortOrder: 'Descending', title: 'Year: newest first' },
  { sortField: 'year', sortOrder: 'Ascending', title: 'Year: oldest first' },
  { sortField: 'mileage', sortOrder: 'Ascending', title: 'Lowest mileage' }
]

if (isMultirooftop()) {
  sortOptions.unshift(
    { sortField: 'closest', sortOrder: 'Ascending', title: 'Closest' }
  )
}

export const VEHICLE_SORTING_OPTIONS = sortOptions

export const DEFAULT_SORTING: VehiclesSorting = {
  sortField: VEHICLE_SORTING_OPTIONS[0].sortField,
  sortOrder: VEHICLE_SORTING_OPTIONS[0].sortOrder
}

export const RANGE_FILTER_CONFIGS = new Map<FilterRangeKey, RangeFilterConfig>()
  .set('year', { min: 1970, max: new Date().getFullYear() + 1, step: 1 })
  .set('mileage', { min: 0, max: 500000, step: 1000 })
  .set('price', { min: 0, max: 500000, step: 1000 })

export const BODY_STYLE_MAP = new Map<FilterBodyStyleKey, string>()
  .set('Coupe', coupeImg)
  .set('Crossover', crossoverImg)
  .set('Convertible', convertibleImg)
  .set('Hatchback', hatchbackImg)
  .set('Minivan/Van', minivanImg)
  .set('Pickup', truckImg)
  .set('Sedan', sedanImg)
  .set('SUV', suvImg)
  .set('Wagon', wagonImg)
  .set('Other', otherImg)

export const BODY_STYLE_SECONDARY_MAP = new Map<FilterBodyStyleKey, string>()
  .set('Coupe', coupeSecondaryImg)
  .set('Crossover', crossoverSecondaryImg)
  .set('Convertible', convertibleSecondaryImg)
  .set('Hatchback', hatchbackSecondaryImg)
  .set('Minivan/Van', minivanSecondaryImg)
  .set('Pickup', truckSecondaryImg)
  .set('Sedan', sedanSecondaryImg)
  .set('SUV', suvSecondaryImg)
  .set('Wagon', wagonSecondaryImg)
  .set('Other', otherSecondaryImg)

export const VEHICLE_FILTERS_TYPE_MAP = new Map<FilterName, FilterRenderType>()
  .set('year', 'range')
  .set('mileage', 'range')
  .set('price', 'range')
  .set('make', 'singleDropdown')
  .set('model', 'multiDropdown')
  .set('trim', 'singleDropdown')
  .set('bodyStyle', 'tile')
  .set('dealerId', 'list')
  .set('exteriorColor', 'color')
  .set('interiorColor', 'color')
  .set('transmission', 'list')
  .set('drivetrain', 'list')
  .set('fuelType', 'list')

/**
 * order of keys represents order of filters in UI.
 * Map guarantees order of keys.
 */
export const FILTERS_CONFIG = new Map<FilterName, GeneratedFilter>()
  .set('make', {
    id: 'make',
    name: 'Make',
    config: { items: [] },
    type: getFromMap(VEHICLE_FILTERS_TYPE_MAP, 'make')
  })
  .set('model', {
    id: 'model',
    name: 'Model',
    type: getFromMap(VEHICLE_FILTERS_TYPE_MAP, 'model'),
    config: { items: [] },
    isDisabled: (appliedFilters?: ActiveFilters) => {
      const makes = appliedFilters?.make?.items
      return makes == null || makes.length === 0
    }
  })
  .set('price', {
    id: 'price',
    name: 'Price',
    startAdornment: '$',
    type: getFromMap(VEHICLE_FILTERS_TYPE_MAP, 'price'),
    config: { from: null, to: null },
    ...RANGE_FILTER_CONFIGS.get('price')
  })
  .set('mileage', {
    id: 'mileage',
    name: 'Mileage',
    type: getFromMap(VEHICLE_FILTERS_TYPE_MAP, 'mileage'),
    config: { from: null, to: null },
    ...RANGE_FILTER_CONFIGS.get('mileage')
  })
  .set('bodyStyle', {
    id: 'bodyStyle',
    name: 'Body style',
    type: getFromMap(VEHICLE_FILTERS_TYPE_MAP, 'bodyStyle'),
    config: { items: BODY_STYLES.map(name => ({ name, count: 0 })) }
  })
  .set('dealerId', {
    id: 'dealerId',
    name: 'Location',
    type: getFromMap(VEHICLE_FILTERS_TYPE_MAP, 'dealerId'),
    config: { items: [] }
  })
  .set('year', {
    id: 'year',
    name: 'Year',
    isCollapsible: true,
    type: getFromMap(VEHICLE_FILTERS_TYPE_MAP, 'year'),
    config: { from: null, to: null },
    ...RANGE_FILTER_CONFIGS.get('year')
  })
  .set('trim', {
    id: 'trim',
    name: 'Trim',
    isCollapsible: true,
    type: getFromMap(VEHICLE_FILTERS_TYPE_MAP, 'trim'),
    config: { items: [] },
    isDisabled: (appliedFilters?: ActiveFilters) => {
      const models = appliedFilters?.model?.items
      return models == null || models.length === 0
    }
  })
  .set('exteriorColor', {
    id: 'exteriorColor',
    name: 'Exterior color',
    isCollapsible: true,
    type: getFromMap(VEHICLE_FILTERS_TYPE_MAP, 'exteriorColor'),
    config: { items: COLORS.map(name => ({ name, count: 0 })) }
  })
  .set('interiorColor', {
    id: 'interiorColor',
    name: 'Interior color',
    isCollapsible: true,
    type: getFromMap(VEHICLE_FILTERS_TYPE_MAP, 'interiorColor'),
    config: { items: COLORS.map(name => ({ name, count: 0 })) }
  })
  .set('transmission', {
    id: 'transmission',
    name: 'Transmission',
    isCollapsible: true,
    type: getFromMap(VEHICLE_FILTERS_TYPE_MAP, 'transmission'),
    config: { items: TRANSMISSIONS.map(name => ({ name, count: 0 })) }
  })
  .set('drivetrain', {
    id: 'drivetrain',
    name: 'Drivetrain',
    isCollapsible: true,
    type: getFromMap(VEHICLE_FILTERS_TYPE_MAP, 'drivetrain'),
    config: { items: DRIVETRAINS.map(name => ({ name, count: 0 })) }
  })
  .set('fuelType', {
    id: 'fuelType',
    name: 'Fuel type',
    isCollapsible: true,
    type: getFromMap(VEHICLE_FILTERS_TYPE_MAP, 'fuelType'),
    config: { items: FUELTYPES.map(name => ({ name, count: 0 })) }
  })

export const POPULAR_CARS_MAP = new Map<FilterMakesKey, string>()
  .set('Toyota', toyotaImg)
  .set('Chevrolet', chevroletImg)
  .set('Jeep', jeepImg)
  .set('BMW', bmwImg)
  .set('Mazda', mazdaImg)
  .set('Honda', hondaImg)
  .set('Nissan', nissanImg)
  .set('Hyundai', hyundaiImg)
  .set('Mercedes-Benz', mercedesImg)
  .set('Kia', kiaImg)
  .set('Ford', fordImg)
  .set('Tesla', teslaImg)
  .set('Ram', ramImg)
  .set('Subaru', subaruImg)
  .set('Audi', audiImg)
