import { css } from '@emotion/css'
import { Responsive } from 'constants/constants'

const REMOVE_UNDERLINE_FROM_INPUT_FIELD = `
  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:after, {
    border-bottom: none;
  }
`

export default css(`
  &&.cf-modal-container {

    .cf-modal-content-scroll-wrapper {
      padding: 0;
    }

    .cf-modal-title {
      color: var(--titleMainColor);

      @media screen and (max-width: ${Responsive.MobileMaxWidth}px){
        border-bottom: 1px solid rgba(0, 0, 0, 0.24);
      }

      .cf-icon-button {
        background-color: #FFFFFF;
      }
    }

    .cf-modal-content {
      display: flex;
      flex-direction: column;
      min-width: 280px;
      max-width: 480px; 
      padding: 40px 20px 0 20px;

      @media screen and (min-width: ${Responsive.MobileMaxWidth}px) {
        margin: 40px 40px 0;
        padding: 0;
      }

      @media screen and (min-width: ${Responsive.WideMobileScreen}px) {
        min-width: 480px;
      }
    }

    .cf-label-text {
      margin-bottom: 24px;
      color: var(--textMainColor);
      font-family: Roboto;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;

      @media screen and (max-width: ${Responsive.MobileMaxWidth}px){
        max-width: 239px;
      }
    }

    .cf-number-input {
      margin-bottom: 24px;

      .MuiInputLabel-root {
        color: var(--textMainColor);
        letter-spacing: 0.15px;
        
        &.Mui-error {
          color: var(--errorColor);
        }
      }

      .MuiFilledInput-root {
        border: 1px solid transparent;
      }

      .MuiFormControl-root, .MuiFilledInput-root {
        ${REMOVE_UNDERLINE_FROM_INPUT_FIELD}
        width: 100%;
        background-color: transparent;

        &.Mui-error {
          border-radius: 12px;
          border: 1px solid var(--errorColor);
        }

        .MuiInputBase-input {
          height: 100%;
          border-radius: 12px;
          background-color: var(--buttonDisabledColor);
          color: var(--textMainColor);

          :focus, :not(:placeholder-shown) {
            background-color: var(--buttonActiveColor);
          }
        }
      }
    }

    .cf-btn-lost-access {
      align-self: flex-start;
      margin-left: 16px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
    }

    .cf-btn-send-code {
      margin-top: 48px;
      background-color: #101010;

      :hover { 
        background-color: var(--cf-button-color-primary-hover);
      }

      &.cf-button-disabled {
        background-color: var(--buttonDisabledColor);
      }

      .cf-button-content {
        font-family: Roboto;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 1.25px;
        text-transform: uppercase;
      }
    }
  }
`)
