import { type FC, type MouseEvent, useEffect } from 'react'
import { cnx } from '@carfluent/common'

import { type GetDirectionsProps, SupportedComponents } from 'website/components/types'
import Button from 'website/components/Button'
import { joinPartsBySpace } from 'utils/view_helper'
import { isTruthy } from 'utils/common'

import CLASS_NAME from './styles'

const GetDirections: FC<GetDirectionsProps> = ({
  addressData,
  nameInLayout = SupportedComponents.GetDirections,
  text = 'Get Directions',
  startIcon = 'directions',
  selector = '',
  ...buttonProps
}) => {
  const address = joinPartsBySpace(...Object.values(addressData ?? {}))
  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onClick = (): void => {
    const baseUrl = 'https://www.google.com/maps/search/?api=1&query='
    const _address = isTruthy(address)
      ? address
      : (document.querySelector(selector)?.textContent ?? '')

    const encodedAddress = encodeURIComponent(_address ?? '')
    window.open(`${baseUrl}${encodedAddress}`, '_blank')
  }

  // ========================================== //

  return (
    <Button
      {...buttonProps}
      className={cnx(CLASS_NAME, isTouchDevice && 'touch-device')}
      nameInLayout={nameInLayout}
      onClick={onClick}
      startIcon={startIcon}
      text={text}
    />
  )
}

export default GetDirections
