import type { FC } from 'react'
import { cnx } from '@carfluent/common'
import { SocialsProps, SupportedComponents } from 'website/components/types'
import { useComponentStyles } from 'website/styles/useComponentStyles'

const Socials: FC<SocialsProps> = ({
  nameInLayout = SupportedComponents.Socials,
  text,
  links
}) => {
  const componentStyles = useComponentStyles(SupportedComponents.Socials)

  return (
    <div className={cnx(nameInLayout, componentStyles.root)}>
      <p className='cf-socials-title'>{text}</p>

      <div className={componentStyles.content}>
        {
          (links ?? []).map(data => (
            <a
              key={data.src}
              href={data.href}
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={data.src} alt={data.alt} />
            </a>
          ))
        }
      </div>
    </div>
  )
}

export default Socials
