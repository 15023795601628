import { css } from '@emotion/css'

export default css(`
  width: 100%;
  
  .g-title {
    padding: 24px 0 16px;

    @media screen and (min-width: 1024px) {
      padding: 32px 0 16px;
    }
  }
  
  .g-element-hidden {
    height: 0;
    padding: 0;
    overflow: hidden;
  }

  .visually-hidden-title {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
  }
`)
