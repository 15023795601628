const textEllipsis = `
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const CARD_HEIGHT_PX = '362px'
const IMAGE_HEIGHT_PX = '192px'

export const vehicleCardStyles = `
  border-radius: 12px;
  border: 1px solid #EBEBEB;
  overflow: hidden;
  background: white;
  box-sizing: border-box;
  height: ${CARD_HEIGHT_PX};
  max-height: ${CARD_HEIGHT_PX};
  padding: 8px;

  :hover {
    border: 1px solid #CDCFD1;
  }
`

export default {
  VehicleCard: {
    default: {
      root: `
       ${vehicleCardStyles}

       .card-wrapper {
          color: #000;
          cursor: pointer;
          text-decoration: none;
          
          &.card-disabled {
            cursor: arrow;
            pointer-events: none;
          }

          &.card-placeholder {
            .LazyImage {
              background: #F7F8FA;
              display: flex;
              justify-content: center;
              align-items: center;
              
              img {
                width: 120px;
                height: 54px;
                margin-top: -20px;
              }
            }
            
            .empty-img-subtitle {
              position: absolute;
              top: calc(50% + 18px);
              left: 50%;
              transform: translate(-50%, 0);
              text-align: center;
              font-weight: 500;
              font-size: 14px;
              color: #101010;
              
              span {
                font-size: 12px;
                font-weight: 400;
                color: rgba(34, 36, 41, 0.56);
                line-height: 16px;
              }
            }
          }
        }        

        .LazyImage {
          border-radius: 8px;
          height: ${IMAGE_HEIGHT_PX};

          & img {
            border-radius: 8px;
            object-fit: cover;
          }
        }

        .card-img-wrapper {
          position: relative;
        }

        .card-distance-info {
          position: absolute;
          padding: 8px;
          height: 16px;
          width: max-content;
          background: #212121CC;
          color: #fff;
          border-radius: 8px;
          top: 8px;
          left: 8px;
          display: flex;
          align-items: center;
          font-size: 14px;
          box-sizing: content-box;

          path {
            fill: #fff;
          }
        }
         
        .content-wrapper {
          padding: 4px;
          padding-top: 12px;
          height: 154px;
          box-sizing: border-box;
          display: grid;
          grid-template-areas: 
            'carInfoName carInfoName'
            'carInfoMiles carInfoEngine'
            'carPrice carPrice';
          grid-template-rows: auto minmax(20px, auto) auto;
          grid-template-columns: 1fr 1fr;
          row-gap: 12px;
          column-gap: 8px;
          
          .car-name-block {
            grid-area: carInfoName;
          }
           
          .car-name {
            font-weight: 500;
            line-height: 24px;
            ${textEllipsis}
          }
          
          .car-trim {
            font-size: 14px;
            color: rgba(33, 33, 33, 0.80);
            line-height: 20px;
            min-height: 20px;
            ${textEllipsis}
          }
          
          .car-info-miles {
            grid-area: carInfoMiles;
            font-size: 14px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.80);
            ${textEllipsis}
          }
          
          .car-info-engine {
            grid-area: carInfoEngine;
            text-align: right;
            line-height: 20px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.80);
            ${textEllipsis}
          }
          
          .car-info-badge {
            display: flex;
            background-color: var(--vehicleCardBadgeBgColor);
            color: var(--vehicleCardBadgeColor);
            border-radius: 8px;
            gap: 8px;
            padding: 8px;
            
            svg {
              width: 18px;
              min-width: 18px;
              height: 18px;
            }
            
            p {
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
          
          .price-info {
            grid-area: carPrice;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px;

            > p {
              font-size: 24px;
              font-weight: 500;
              line-height: 36px;
            }

            > div, p {
              width: calc(50% - 8px)
            }

            .price-details {
              &.is-loading {
                display: flex;
                justify-content: flex-end;
              }
              
              .price-per-month {
                font-size: 12px;
                color: rgba(34, 36, 41, 0.80);
                width: 100%;
                text-align: right;
                line-height: 20px;
                text-decoration: underline;
                position: relative;
                
                :hover > .prequalify-tooltip {
                  display: block;
                }
                
                .prequalify-tooltip {
                  :hover {
                    display: block;
                  }
                  
                  text-align: left;
                  display: none;
                  position: absolute;
                  max-width: 266px;
                  padding: 4px 12px;
                  border-radius: 4px;
                  background: #212121;
                  top: 0;
                  right: 0;
                  color: white;
                  transform: translate(0px, -100%);
                  width: max-content;
                  font-size: 14px;
                }
              }
              
              .cash-down {
                width: 100%;
                text-align: right;
                color: rgba(34, 36, 41, 0.56);
                text-align: right;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
              }
            }
          }
        }
      `
    }
  }
}
