import { css } from '@emotion/css'

export default css(`
  .dealership-phone {
    grid-area: DealerInfoFragment;
    justify-self: flex-end;
  }
  
  .DealerInfoFragment {
    width: 100%;
    display: grid !important;
    grid-template-columns: 40px 12px 40px;
    grid-template-areas: "address . phone";
    gap: 0;
    
    @media (min-width: 1024px) {
      grid-template-areas: ". phone . address";
      grid-template-columns: 1fr auto 16px auto;
      justify-content: end;
      justify-items: stretch;
    }
    
    .phone-block,
    .address-block {
      box-sizing: border-box;
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.16);
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--textWhite);
      
      @media (min-width: 1024px) {
        width: 100%;
        border: none;
        justify-content: end;
      }
      
      svg {
        transform: none;
      }
    }
    
    .phone-block {
      grid-area: phone;
      
      .phone-value {
        display: none;
        
        @media (min-width: 1024px) {
          display: block;
        }
      }
    }
    
    .address-block {
      grid-area: address;
      
      .address-value {
        display: none;
        
        @media (min-width: 1024px) {
          display: block;
        }
      }
    }
  }
`)
