import type { FC } from 'react'
import { IconProps } from 'website/components/types'

const Menu2Icon: FC<IconProps> = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M2.5 10H17.5" stroke={fill} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2.5 5H17.5" stroke={fill} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2.5 15H17.5" stroke={fill} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default Menu2Icon
