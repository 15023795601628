import { useEffect, useRef, type FC, type MouseEvent } from 'react'
import { cnx } from '@carfluent/common'

import { VehicleMediaTypeEnum, type GalleryMediaProps } from 'website/components/types'
import playIcon from 'website/assets/play_circle_outline.png'

export type ImageBackgroundType = 'cover' | 'contain'

interface GalleryItemProps {
  media: GalleryMediaProps
  index: number
  isActive?: boolean
  isSelected?: boolean
  imgBackgroundType?: ImageBackgroundType
  isVideoControlsAreShown?: boolean
  onClick: (e: MouseEvent<HTMLElement>) => void
}

const GalleryItem: FC<GalleryItemProps> = ({
  media,
  index,
  isActive = false,
  isVideoControlsAreShown = false,
  imgBackgroundType = 'cover',
  isSelected = false,
  onClick
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onVideoClick = (): void => {
    if (videoRef.current != null && isSelected && imgBackgroundType === 'contain') {
      /**
       * OP-NOTE: Remove focus from the video element to prevent it from interfering
       * with swipe gestures. This ensures that the video doesn't capture focus or
       * trigger unintended behavior during drag.
       */
      videoRef.current.blur()

      if (videoRef.current.paused) {
        void videoRef.current?.play()
      } else {
        void videoRef.current?.pause()
      }
    }
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    if (videoRef.current != null && (!isSelected || !isActive) && isVideoControlsAreShown && !videoRef.current.paused) {
      videoRef.current.pause()
    }
  }, [isSelected, isActive, isVideoControlsAreShown])

  // ========================================== //

  return (
    <div
      className={cnx('gallery-item', isSelected && 'selected')}
      onClick={onClick}
      data-idx={index}
    >
      {
        media.vehicleMediaTypeId === VehicleMediaTypeEnum.Video && isSelected && imgBackgroundType !== 'contain' &&
          <img className='play-icon-img' src={playIcon} alt='Play video' />
      }
      {
        media.vehicleMediaTypeId === VehicleMediaTypeEnum.Image
          ? <img src={media.url} alt='gallery item' className={imgBackgroundType} />
          : (
            <video
              ref={videoRef}
              preload='none'
              className='video-player'
              poster={media?.thumbnail}
              controls={isVideoControlsAreShown}
              {...(!isTouchDevice && { onClick: onVideoClick })}
            >
              <source
                src={media?.url} className={imgBackgroundType} type='video/mp4'
              />
            </video>
            )
        }
    </div>
  )
}

export default GalleryItem
