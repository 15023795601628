import lowestPriceCarsBackground from 'website/assets/car-under-1.png'
import middlePriceCarsBackground from 'website/assets/car-under-2.png'
import highestPriceCarsBackground from 'website/assets/car-under-3.png'

const assets: Record<string, string> = {
  lowestPriceCarsBackground,
  middlePriceCarsBackground,
  highestPriceCarsBackground,

  /** nested templates */
  /** -- they are replaced during parsing if proper order is fulfilled -- */
  tradeSellBlockTemplate: '',
  aboutUsPromosForMainPageTemplate: '',
  dealerInfoTemplate: ''
}

export default assets
