import type { FC } from 'react'
import { Modal, Button, FormMaskedInput, cnx } from '@carfluent/common'

import PhoneNumberRecoveryModal from 'components/login/PhoneNumberRecoveryModal'
import SendCodeModal from 'components/login/SendCodeModal'

import useLoginFlow from './hook'

import CLASS_NAME from './styles'

const LoginFlowModalsTracker: FC = () => {
  const {
    loginStep, setLoginStep, isLoginFlow,
    phoneNumber, supportPhoneNumber,
    onChange, onSubmit, onBlur,
    values, errors, touched, onClose
  } = useLoginFlow()

  const isDisabled = errors.phoneNumber != null || values.phoneNumber === ''

  return (
    isLoginFlow
      ? (
        <>
          <Modal
            isOpen={loginStep === 'login'}
            onClose={onClose}
            title='Log in'
            className={cnx(CLASS_NAME, 'cf-login-flow-modal')}
          >
            <label className='cf-label-text'>
              Please enter your phone number to log in
            </label>

            <FormMaskedInput
              className='cf-number-input'
              id='phoneNumber'
              label='Phone number'
              mask='phone'
              onChange={onChange}
              onBlur={onBlur}
              value={values.phoneNumber}
              error={errors.phoneNumber}
              touched={touched.phoneNumber}
              dataTestId='login-phone-number'
            />

            <Button
              className='cf-btn-lost-access'
              onClick={() => setLoginStep('phoneRecovery')}
              variant='text'
              dataTestId='login-btn-lost-access'
            >
              Lost access to phone number?
            </Button>

            <Button
              className='cf-btn-send-code'
              isDisabled={isDisabled}
              onClick={onSubmit}
              dataTestId='login-btn-submit'
            >
              Log in
            </Button>
          </Modal>

          <PhoneNumberRecoveryModal
            supportPhoneNumber={supportPhoneNumber}
            isModalOpen={loginStep === 'phoneRecovery'}
            onCloseModal={onClose}
          />

          <SendCodeModal
            isLogin
            phoneNumber={phoneNumber}
            isModalOpen={loginStep === 'code'}
            onCloseModal={onClose}
          />
        </>
        )
      : null
  )
}

export default LoginFlowModalsTracker
