import type { FC } from 'react'
import { cn } from '@carfluent/common'

import Text from 'website/components/Text'
import Button from 'website/components/Button'

import type { GetPrequalifiedSuccessProps } from 'website/components/types'
import { useComponentStyles } from 'website/styles/useComponentStyles'
import { SupportedComponents } from 'website/components/types'
import SharedStateHook, { defaultInstance, StoreBranches } from 'website/store'
import ExtendedIconSVG from '../ExtendedIconSVG'

const useSharedState = SharedStateHook<Store.PrequalifyState>(StoreBranches.Prequalify)

const GetPrequalifiedSuccess: FC<GetPrequalifiedSuccessProps> = ({
  nameInLayout = SupportedComponents.GetPrequalifiedSuccess,
  variant,
  componentProps
}) => {
  const componentStylesCls = useComponentStyles(SupportedComponents.GetPrequalifiedSuccess, variant)
  const [{ apr, downPayment, term }] = useSharedState(defaultInstance(StoreBranches.Prequalify))

  const termValue = term != null ? `${term} Months` : '-'
  const downPaymentValue = downPayment != null ? `$${downPayment}` : '-'

  return (
    <div className={cn(nameInLayout, componentStylesCls.root)}>
      <div>
        <div className='cf-main-block'>
          <Text {...componentProps.Text1} value={`${apr}%`} />
          <Text {...componentProps.Text2} value='Starting APR' />
        </div>
        <div className='cf-split-blocks'>
          <div className='cf-split-block'>
            <div className='cf-image-wrapper'>
              <ExtendedIconSVG {...componentProps.ExtendedIconSVG1} />
            </div>
            <div>
              <Text {...componentProps.Text3} />
              <Text value={termValue} {...componentProps.Text4} />
            </div>
          </div>
          <div className='cf-split-block'>
            <div className='cf-image-wrapper'>
              <ExtendedIconSVG {...componentProps.ExtendedIconSVG2} />
            </div>
            <div>
              <Text {...componentProps.Text5} />
              <Text {...componentProps.Text6} value={downPaymentValue} />
            </div>
          </div>
        </div>
        <Button
          text='Go shop cars with your terms'
          variant='search'
          navTo='/inventory'
        />
      </div>
    </div>
  )
}

export default GetPrequalifiedSuccess
