import { type FC, useEffect } from 'react'
import { cnx, DateTimePicker, FormMaskedInput, FormInput, FormTextArea } from '@carfluent/common'

import { StaticDocumentsApiProvider } from 'api/static_documents.api'

import Modal from 'website/components/Modal'
import Button from 'website/components/Button'

import { ServiceType } from 'website/api/types'
import { ModalTexts } from 'website/hooks/vehicleRequestService/texts'
import { useComponentStyles } from 'website/styles/useComponentStyles'
import useVehicleRequestService from 'website/hooks/vehicleRequestService'
import ThankForYourInterestDialog from 'components/dialogs/ThankForYourInterest'
import ExtendedTypography from 'website/components/_base/ExtendedTypography'
import ReCaptcha from 'components/common/ReCaptcha'
import {
  SupportedComponentNames,
  type VehicleServiceRequestModalFormProps
} from 'website/components/types'

import CLASS_NAME from './styles'

const MUI_INPUT_PROPS = { disableUnderline: true }
const HTML_INPUT_PROPS = { autoComplete: 'off' }
const FIELD_CLASSES = { wrapper: 'form-field' }

const VehicleServiceRequestModalForm: FC<VehicleServiceRequestModalFormProps> = ({
  formVariant,
  dealerId,
  vehicleInfo,
  serviceType,
  modalVariant,
  isModalOpen,
  onCloseModal,
  handleSubmit,
  vehicleId
}) => {
  const formStylesCls = useComponentStyles(
    SupportedComponentNames.VehicleServiceRequestModalForm,
    formVariant
  )

  const {
    isLoading,
    isDatePickerOpen,
    isThankDialogOpen,
    values,
    touched,
    errors,
    onDateChange,
    onSubmit,
    onDatePickerBlur,
    onDatePickerClose,
    onDatePickerClick,
    onBlur,
    onChange,
    onClose,
    setThankDialogOpen,
    refRecaptcha
  } = useVehicleRequestService({
    dealerId,
    serviceType,
    vehicleInfo,
    onSubmit: handleSubmit ?? onCloseModal,
    onClose: onCloseModal,
    vehicleId
  })

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [isModalOpen])

  // ========================================== //
  //                   RENDER                   //
  // ========================================== //

  const nameOfDisclosures = serviceType === ServiceType.Financing ? '"Get pre-qualified"' : '"Send Request"'

  return (
    <>
      <Modal
        title={ModalTexts[serviceType].title}
        intro={ModalTexts[serviceType].intro}
        isOpen={isModalOpen}
        variant={modalVariant}
        onClose={onClose}
        className={CLASS_NAME}
      >
        <div className={cnx('form-container', formStylesCls.root)}>
          <FormInput
            className='form-field'
            error={errors.customerFirstName ?? undefined}
            id='customerFirstName'
            label='First name'
            touched={touched.customerFirstName}
            value={values.customerFirstName}
            onChange={onChange}
            onBlur={onBlur}
          />

          <FormInput
            className='form-field'
            error={errors.customerLastName ?? undefined}
            id='customerLastName'
            label='Last name'
            touched={touched.customerLastName}
            value={values.customerLastName}
            onChange={onChange}
            onBlur={onBlur}
          />

          <FormInput
            className='form-field'
            error={errors.customerEmail ?? undefined}
            id='customerEmail'
            label='Email'
            onKeyDown={(e) => {
              if (e.key === ' ' || e.key === 'Spacebar') {
                e.preventDefault()
              }
            }}
            touched={touched.customerEmail}
            value={values.customerEmail}
            onChange={onChange}
            onBlur={onBlur}
          />

          <FormMaskedInput
            className='form-field'
            error={errors.customerPhoneNumber ?? undefined}
            id='customerPhoneNumber'
            label='Phone number'
            mask='phone'
            touched={touched.customerPhoneNumber}
            value={values.customerPhoneNumber}
            onChange={onChange}
            onBlur={onBlur}
          />

          {(serviceType === ServiceType.TestDrive) && (
            <DateTimePicker
              autoOk
              classes={FIELD_CLASSES}
              disablePast
              error={errors.dateTime ?? undefined}
              format='MM/dd/yyyy HH:mm'
              id='dateTime'
              inputProps={HTML_INPUT_PROPS}
              InputProps={MUI_INPUT_PROPS}
              label='Date & time'
              onBlur={onDatePickerBlur}
              onChange={onDateChange}
              onClose={onDatePickerClose}
              onClick={onDatePickerClick}
              open={isDatePickerOpen}
              touched={touched.dateTime}
              value={values.dateTime}
            />
          )}

          <FormTextArea
            className={cnx('Comments', 'form-field')}
            error={errors.customerComments ?? undefined}
            id='customerComments'
            label='Comments'
            touched={touched.customerComments}
            value={values.customerComments}
            onChange={onChange}
            onBlur={onBlur}
            maxRows={6}
          />

          <div className='disclosure-block'>
            <ExtendedTypography>
              By clicking the {nameOfDisclosures} button below,
              I consent to receive information by email, telephone call
              (including those made using an automatic telephone dialing system or an artificial
              or pre-recorded voice), and/or SMS message from or on behalf of this dealership
              and/or their representatives at any contact information I provide above, even if
              I am on a state or federal Do-Not-Call list. Messaging and data rates may apply.
              This acknowledgment constitutes my written consent to receive such communications.
              For more details, please review our {' '}
              <a
                href={StaticDocumentsApiProvider.getPrivacyPolicyUrl()}
                target='_blank'
                rel='noopener noreferrer'
                className='disclosure-link'
              >
                Privacy Policy
              </a>

              {' '}and{' '}

              <a
                href={StaticDocumentsApiProvider.getTermsAndConditionsUrl()}
                target='_blank'
                rel='noopener noreferrer'
                className='disclosure-link'
              >
                Terms and Conditions
              </a>
              .
              {' '}
            </ExtendedTypography>
          </div>

          <Button
            className='form-button'
            nameInLayout=''
            onClick={onClose}
            text='Cancel'
            variant='outlined'
          />

          <Button
            className='form-button'
            nameInLayout=''
            isLoading={isLoading}
            onClick={onSubmit}
            text={ModalTexts[serviceType].submitBtn}
            variant='search'
          />

          <ThankForYourInterestDialog
            isOpen={isThankDialogOpen}
            onActionBtnClick={() => { setThankDialogOpen(false) }}
          />
        </div>

        <ReCaptcha
          badge='bottomleft'
          ref={refRecaptcha}
          size='invisible'
          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA ?? ''}
        />
      </Modal>
    </>
  )
}

export default VehicleServiceRequestModalForm
