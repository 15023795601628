import { type SiteConfigs } from 'website/__templates/types'
import fillTemplateVariables from 'website/siteGenerator/utils/fillTemplateVariables'

import templates, { parsedTemplatesCache } from './templates'
import getHeaderConfigs from './customerAppTemplates/header'
import i18nDict from './i18n/en/index.json'

export { getHeaderConfigs }

let configJson: SiteConfigs | null = null

export default ((): SiteConfigs => {
  if (configJson != null) {
    return configJson
  }

  fillTemplateVariables(templates, parsedTemplatesCache, i18nDict)
  configJson = JSON.parse(parsedTemplatesCache.rootTemplate) as SiteConfigs
  return configJson
})()

export const getCssVars = (variable: string, selector?: string): string | null => {
  const elem = selector == null
    ? document.documentElement
    : document.querySelector(selector)

  if (elem == null) {
    return null
  }

  const styles = getComputedStyle(elem)
  return styles.getPropertyValue(variable)?.trim() ?? null
}

export { default as globalCss } from './theme/global'
export { default as theme } from './theme'
export * from './appTypes/filters'
export * from './constants'
export * from './filters'
