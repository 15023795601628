import prequalifiedIcon from 'website/assets/Template1/prequalified.svg'
import carIcon from 'website/assets/Template1/car.svg'
import lenderIcon from 'website/assets/Template1/lender.svg'
import carWithShadow from 'website/assets/Template1/carWithShadow.svg'

const assets: Record<string, string> = {
  prequalifiedIcon,
  carIcon,
  carImage2: carWithShadow,
  lenderIcon
}

export default assets
