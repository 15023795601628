import { type FC, type MouseEvent } from 'react'
import { cnx } from '@carfluent/common'
import { useNavigate } from 'react-router-dom'

import SharedStateHook, { StoreBranches, defaultInstance } from 'website/store'
import { type DealerLocationsListProps, SupportedComponents } from 'website/components/types'
import { useComponentStyles } from 'website/styles/useComponentStyles'
import ComponentRoot from 'website/components/_base/ComponentRoot'

const COMPONENT_NAME = SupportedComponents.DealerLocationsList
const useSharedState = SharedStateHook<Store.Dealership>(StoreBranches.Dealership)

const DealerLocationsList: FC<DealerLocationsListProps> = ({
  config,
  onClick,
  variant
}) => {
  const [dealerInfo] = useSharedState(defaultInstance(StoreBranches.Dealership))
  const navigate = useNavigate()
  const componentStyles = useComponentStyles(COMPONENT_NAME, variant)
  const hasMultipleLocations = dealerInfo.dealerships.length > 1

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const openLocation = (to: string, event: MouseEvent): void => {
    event.stopPropagation()
    onClick?.()
    navigate(to)
  }

  // ========================================== //

  return (
    <ComponentRoot
      nameInLayout={COMPONENT_NAME}
      classes={{
        root: cnx(componentStyles.root),
        content: cnx(componentStyles.content)
      }}
      onClick={(event) => {
        openLocation('/locations', event)
      }}
    >
      <p
        className='locations-link'
        style={{ color: config?.color, fontWeight: config?.fontWeight }}
      >
        Location{hasMultipleLocations ? 's' : ''}
      </p>

      <ul className='locations-list'>
        {dealerInfo.dealerships.map((dealer) => (
          <li
            className='locations-list-item'
            key={dealer.id}
            onClick={(event) => {
              openLocation(`/locations/${dealer.id}`, event)
            }}
          >
            {dealer.dealerName}
          </li>
        ))}
      </ul>
    </ComponentRoot>
  )
}

export default DealerLocationsList
