import { type FC, useEffect, useMemo, useState } from 'react'

import { useSearchParams } from 'react-router-dom'
import FilesApiProvider from 'website/api/files.api'

import VideoPlayerDialog from '../_base/VideoPlayerDialog'

const URL_QUERY_IDS = ['videoId', 'guid']

const HomePageVideoPlayer: FC = () => {
  const [searchParams] = useSearchParams()
  const [isPlayerOpen, setIsPlayerOpen] = useState(false)
  const [videoUrl, setVideoUrl] = useState('')

  const [videoId, guid] = useMemo(() => {
    return URL_QUERY_IDS.map(query => searchParams.get(query))
  }, [searchParams])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    const runEffect = async (): Promise<void> => {
      if ((videoId == null) || (guid == null)) {
        return
      }

      const response = await fetch(FilesApiProvider.getCustomVideoUrl(videoId, guid))
      if (response.status === 404) {
        window.location.replace('/404')
      } else {
        setVideoUrl(FilesApiProvider.getCustomVideoUrl(videoId, guid))
        setIsPlayerOpen(true)
      }
    }

    void runEffect()
  }, [videoId, guid])

  // ========================================== //

  return (
    <VideoPlayerDialog
      videoUrl={videoUrl}
      isOpen={isPlayerOpen}
      onClose={() => setIsPlayerOpen(false)}
    />
  )
}

export default HomePageVideoPlayer
