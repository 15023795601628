export const getTextareaValuesWithEmoji = (textarea: HTMLTextAreaElement, value: string, emoji: string): string => {
  const cursorPosition = textarea.selectionStart ?? 0

  const text =
        value.slice(0, cursorPosition) +
        emoji +
        value.slice(cursorPosition)

  const newCursorPosition = cursorPosition + emoji.length

  setTimeout(() => {
    textarea?.setSelectionRange(
      newCursorPosition,
      newCursorPosition
    )
  }, 10)

  return text
}
