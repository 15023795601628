import { css } from '@emotion/css'

export default css(`
  position: relative;

  .dealer-info-header {
    grid-area: Title;
  }
  
  .DealerInfoSwitcher {
    &.with-header {
      display: grid;
      row-gap: 12px;
    }
  }

  .cf-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`)
