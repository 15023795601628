import { type FC, useEffect, useMemo } from 'react'
import { type LocalBusinessProps, type LocalBusinessItemProps } from 'website/components/types'
import { generateOpeningHoursSpecification } from './utils'

const LocalBusinessSchema: FC<LocalBusinessProps> = ({
  schemaProps
}) => {
  const {
    name,
    url,
    logo,
    telephone,
    email,
    address,
    images,
    dealerBusinessHours
  } = schemaProps ?? {}

  const openingHoursSpecification = dealerBusinessHours != null && generateOpeningHoursSpecification(dealerBusinessHours)

  const schema: LocalBusinessItemProps = useMemo(() => ({
    '@context': 'https://schema.org',
    '@type': 'UseCarDealer',
    name: name ?? '',
    url: url ?? '',
    logo: logo ?? '',
    telephone: telephone ?? '',
    email: email ?? '',
    image: images ?? [],
    address: {
      '@type': 'PostalAddress',
      streetAddress: address?.streetAddress ?? '',
      addressLocality: address?.addressLocality ?? '',
      addressRegion: address?.addressRegion ?? '',
      postalCode: address?.postalCode ?? '',
      addressCountry: address?.addressCountry ?? ''
    },
    openingHoursSpecification: openingHoursSpecification ?? []
  }), [
    name,
    url,
    logo,
    telephone,
    email,
    address,
    images,
    openingHoursSpecification
  ])

  useEffect(() => {
    const script = document.createElement('script')
    script.type = 'application/ld+json'

    script.textContent = JSON.stringify(schema, null, 2)

    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
    }
  }, [schema])

  return null
}

export default LocalBusinessSchema
