import { type FC } from 'react'
import { type IconProps } from 'website/components/types'

const IconSVGTachometer: FC<IconProps> = ({
  fill = '#20C39E',
  height = 18,
  width = 18
}) => {
  return (
    <svg width={width} height={height} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.8375 11.625C8.1375 11.925 8.525 12.072 9 12.066C9.475 12.06 9.825 11.888 10.05 11.55L14.25 5.25L7.95 9.45C7.6125 9.675 7.4345 10.0188 7.416 10.4813C7.3975 10.9438 7.538 11.325 7.8375 11.625ZM9 3C9.7375 3 10.447 3.10325 11.1285 3.30975C11.81 3.51625 12.4505 3.8255 13.05 4.2375L11.625 5.1375C11.2125 4.925 10.7845 4.76575 10.341 4.65975C9.8975 4.55375 9.4505 4.5005 9 4.5C7.3375 4.5 5.92175 5.08425 4.75275 6.25275C3.58375 7.42125 2.9995 8.837 3 10.5C3 11.025 3.072 11.5438 3.216 12.0563C3.36 12.5688 3.563 13.05 3.825 13.5H14.175C14.4625 13.025 14.672 12.5312 14.8035 12.0188C14.935 11.5063 15.0005 10.975 15 10.425C15 9.975 14.9468 9.5375 14.8403 9.1125C14.7338 8.6875 14.5745 8.275 14.3625 7.875L15.2625 6.45C15.6375 7.0375 15.9345 7.6625 16.1535 8.325C16.3725 8.9875 16.488 9.675 16.5 10.3875C16.5125 11.1 16.4313 11.7813 16.2563 12.4313C16.0813 13.0813 15.825 13.7 15.4875 14.2875C15.35 14.5125 15.1625 14.6875 14.925 14.8125C14.6875 14.9375 14.4375 15 14.175 15H3.825C3.5625 15 3.3125 14.9375 3.075 14.8125C2.8375 14.6875 2.65 14.5125 2.5125 14.2875C2.1875 13.725 1.9375 13.128 1.7625 12.4965C1.5875 11.865 1.5 11.1995 1.5 10.5C1.5 9.4625 1.697 8.4905 2.091 7.584C2.485 6.6775 3.0225 5.884 3.7035 5.2035C4.3845 4.522 5.181 3.98425 6.093 3.59025C7.005 3.19625 7.974 2.9995 9 3Z'
        fill={fill}
      />
    </svg>

  )
}

export default IconSVGTachometer
