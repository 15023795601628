import { css } from '@emotion/css'

export default css`
  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding-right: 16px;

    .title {
      color: #222429;
      font-family: "Plus Jakarta Sans";
      font-size: 24px;

      font-weight: 600;
      line-height: 140%; 
    }
  }

  .list-wrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(304px, 442px));
    column-gap: 16px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  @media (min-width: 375px) {
    .title-wrapper {
      padding-right: 32px;
    }
  }

  @media (min-width: 1024px) {
    .title-wrapper {
      padding-right: 0px;

      .title {
        font-size: 20px;
      }
    }
  }

  @media (min-width: 1440px) {
    .title-wrapper {
      .title {
        font-size: 24px;
      }
    }
  }
`
