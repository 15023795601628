import { css } from '@emotion/css'

export default css`
  position: relative;
`

export const POPOVER_CLASS_NAME = css(`
  &&.cf-popover-content {
    padding: 0;
    width: 352px;
    z-index: 1300;
    max-height: 450px;
    margin: 12px 0px !important;
  }
`)
