import { type FC, useState, useEffect } from 'react'
import { cnx, Loader } from '@carfluent/common'

import { type DealerInfoProps, SupportedComponents } from 'website/components/types'
import SharedStateHook, { StoreBranches, defaultInstance } from 'website/store'
import { useComponentStyles } from 'website/styles/useComponentStyles'
import { isTruthy } from 'utils/common'
import Schedule from 'website/components/Schedule'
import Map from 'website/components/Map'
import DealerInfoFragment from 'website/components/_base/DealerInfoFragment'
import DealerLocationsList from 'website/components/DealerLocationsList'
import GetDirections from 'website/components/GetDirections'

import DealerInfoSwitcher from './DealerInfoSwitcher'
import CLASS_NAME from './styles'

const useSharedState = SharedStateHook<Store.Dealership>(StoreBranches.Dealership)
const useSharedDealershipIdState = SharedStateHook<Store.ReviewDealership>(StoreBranches.ReviewDealershipId)
const DEFAULT_IDX = 0

const DealerInfo: FC<DealerInfoProps> = ({
  addressConfig,
  componentProps,
  dealershipsListMode = 'auto',
  multiDealershipHeader,
  phoneConfig,
  singleDealershipHeader,
  shouldUseDropdownHeader = false,
  title: contactsTitle
}) => {
  const [selectedIdx, setSelectedIdx] = useState(DEFAULT_IDX)
  const [, setDealershipId] = useSharedDealershipIdState(defaultInstance(StoreBranches.ReviewDealershipId))
  const [dealerInfo] = useSharedState(defaultInstance(StoreBranches.Dealership))

  const dealership = dealerInfo.dealerships[selectedIdx]
  const isMultiDealership = dealerInfo.dealerships.length > 1

  const componentStyles = useComponentStyles(
    SupportedComponents.DealerInfo,
    isMultiDealership ? 'multiDealership' : 'default'
  )

  const title = isMultiDealership ? multiDealershipHeader : singleDealershipHeader
  const hasTitle = !shouldUseDropdownHeader && isTruthy(title)

  const addressData = {
    address: dealership?.address1,
    city: dealership?.city,
    state: dealership?.state,
    zipCode: dealership?.zipCode
  }

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onItemClick = (idx: number): void => {
    const newId = dealerInfo.dealerships[idx].id

    setSelectedIdx(idx)
    setDealershipId({
      lastUpdateTs: Date.now(),
      dealershipId: newId
    })
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    if (dealerInfo.dealerships.length > 0) {
      // initial setup dealershipId after first fetch dealer info
      setDealershipId((prev) => {
        return prev.dealershipId == null
          ? ({
              lastUpdateTs: Date.now(),
              dealershipId: dealerInfo.dealerships[DEFAULT_IDX].id
            })
          : prev
      })
    }
  }, [dealerInfo.dealerships, setDealershipId])

  // ========================================== //

  return (
    <div
      id='#contacts'
      className={cnx(
        CLASS_NAME,
        SupportedComponents.DealerInfo,
        componentStyles.root,
        !isMultiDealership && 'single-location'
      )}
    >
      <div className={cnx(componentStyles.content, !hasTitle && 'no-title-row')}>
        {
          dealership != null
            ? (
              <>
                {hasTitle && <h3 className='dealer-info-header'>{title}</h3>}

                {isMultiDealership && (
                  <DealerInfoSwitcher
                    dealershipNames={dealerInfo.dealerships.map(d => d.dealerName)}
                    activeIndex={selectedIdx}
                    onClick={onItemClick}
                    mode={dealershipsListMode}
                    title={shouldUseDropdownHeader ? title : null}
                  />
                )}

                <DealerInfoFragment
                  phone={dealership.phone}
                  addressData={{
                    address: dealership.address1,
                    city: dealership.city,
                    state: dealership.state,
                    zipCode: dealership.zipCode
                  }}
                  phoneConfig={phoneConfig}
                  addressConfig={addressConfig}
                  title={contactsTitle}
                />

                <Schedule
                  {...componentProps.Schedule}
                  hours={dealership.dealerBusinessHours}
                />

                {(componentProps.Map != null) && (
                  <Map
                    {...componentProps.Map}
                    addressData={addressData}
                  />
                )}

                {(componentProps.DealerLocationsList != null) && (
                  <DealerLocationsList {...componentProps.DealerLocationsList} />
                )}

                {(componentProps.GetDirections != null) && (
                  <GetDirections
                    {...componentProps.GetDirections}
                    addressData={addressData}
                  />
                )}
              </>
              )
            : <Loader size='large' />
        }
      </div>
    </div>
  )
}

export default DealerInfo
