import { css } from '@emotion/css'
import { MAIN_COLOR } from 'website/configs/theme/variables/constants'

interface StyleProps {
  itemWidth: string
  transitionVelocity?: number
  styleSelected?: boolean
}

const createStyleClass = ({
  itemWidth,
  transitionVelocity = 300,
  styleSelected = true
}: StyleProps): string => {
  return css(`
    height: 100%;
    position: relative;

    & .gallery-arrow {
      cursor: pointer;
      position: absolute;
      width: var(--galleryArrowSize);
      height: var(--galleryArrowSize);
      top: 50%;
      transform: translate(0, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      border-radius: 50%;
      background: #fff;
      border: 0;

      &:hover {
        background: rgba(255,255,255,0.8);
      }

      &.right {
        right: 0px;
      }

      &.left {
        left: 0px;
      }

      &.disabled {
        cursor: not-allowed;
        background: rgba(255,255,255,0.8);
      }
    }

    & .gallery-container {
      height: 100%;
      overflow-x: hidden;
      touch-action: pan-x;
      position: relative;

      & .gallery-dots-container {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, 0);
        width: calc(5 * var(--galleryDotSize) + 4 * var(--galleryDotGap));
        height: var(--galleryDotSize);
        z-index: 1;
        overflow: hidden;

        & .gallery-dots-list {
          position: absolute;
          display: flex;
          flex-wrap: nowrap;
          left: 0px;
          transform: translateX(0);
          transition: left .3s;
          
          & .gallery-dot {
            width: var(--galleryDotSize);
            min-width: var(--galleryDotSize);
            height: var(--galleryDotSize);
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.5);

            &.active {
              background: #fff;
            }

            &:not(:last-child) {
              margin-right: var(--galleryDotGap);
            }
          }
        }
      }

      & .gallery-items-wrapper {
        display: flex;
        height: 100%;
        will-change: transform;
        user-select: none;
        transition: transform ${transitionVelocity}ms ease-in;

        .play-icon-img {
          display: none;
        }
      }

      & .gallery-item {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: ${itemWidth};
        min-width: ${itemWidth};
        position: relative;
        z-index: 10;
        color: #fff;
        position: relative;

        .play-icon-img {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 1;
          width: 80px;
          margin: auto;
          cursor: pointer;
        }

        .video-player {
          display: block;
          cursor: pointer;
          max-width: 100%;
          max-height: 100%;
          user-select: none;

          @media (max-width: 764px) {
            max-height: 80%;
          }
        }

        &:not(:last-child) {
          padding-right: ${itemWidth === '100%' ? '0px' : 'var(--galleryThumbnailGap)'};
        }

        img,
        video {
          ${
            styleSelected
              ? `& {
                  border: 2px solid transparent;
                }`
              : ''
          }

          border-radius: 4px;
          margin: 0 auto;
          box-sizing: border-box;

          @media screen and (max-width: 480px) {
            border-radius: 0;
          }

          &.cover {
            width: 100%;
            max-height: 100%;
            object-fit: cover;
          }

          &.contain {
            object-fit: contain;
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
          }
        },

        ${
          styleSelected
            ? `&.selected img,
              &.selected video {
                border: 2px solid ${MAIN_COLOR};
              }
              `
            : ''
        }
      }
    }
`)
}

export default createStyleClass
