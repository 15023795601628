import { css } from '@emotion/css'

export default css`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr;
  grid-template-areas: "title" "indicator" "img"  "text" "Button" "arrows";

  .title {
    color: #222429;
    font-family: "Plus Jakarta Sans";
    font-size: 24px;
    font-weight: 600;
    grid-area: title;
  }

  .text {
    color: rgba(34, 36, 41, 0.80);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    grid-area: text;
  }

  .arrow-wrapper {
    display: flex;
    gap: 12px;
    grid-area: arrows;

    .arrow {
      width: 32px;
      height: 32px;
      border-radius: 8px;
      margin: 0!important;
      cursor: pointer;
      background: #EBECF0;

      &.disabled {
        opacity: 0.4;
        cursor: default;

        :hover {
          background: #EBECF0;
        }
      }

      :hover {
        background: linear-gradient(0deg, rgba(34, 36, 41, 0.08) 0%, rgba(34, 36, 41, 0.08) 100%), #EBECF0;
      }

      svg {
        path {
          stroke: #222429;
          fill: #222429;
          fill-opacity: 1;
        }
      }

      &.left {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .Button {
    width: 100%;

    .MuiButton-label {
      text-transform: none;
      font-family: Inter;
      font-weight: 600;
    }

    .MuiButton-root.MuiButton-contained {
      :hover {
        background: linear-gradient(0deg, rgba(247, 248, 250, 0.08) 0%, rgba(247, 248, 250, 0.08) 100%), #416EEB!important;
      }
    }

    @media (min-width: 768px) {
      width: 115px;
    }
  }

  img {
    grid-area: img;
    width: 100%;
  }

  .wrapper-indicator {
    display: flex;
    align-items: center;
    gap: 8px;
    grid-area: indicator;
    margin-top: -8px;

    .indicator {
      width: 40px;
      height: 2px;
      border-radius: 8px;
      background: #E6E7EB;

      &.active {
        background: var(--mainColor);
      }
    }
  }

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "img ." "img title" "img indicator" "img text" "img Button" "img arrows";
    column-gap: 48px;

    .title {
      font-size: 26px;
      font-weight: 700;
    }

    .text {
      font-size: 14px;
    }
  }

  @media (min-width: 1280px) {
    column-gap: 64px;
  }

  @media (min-width: 1440px) {
    grid-template-columns: 672px 1fr;
    column-gap: 86px;

    .title {
      font-size: 32px;
    }

    .text {
      font-size: 16px;
    }
  }
`
