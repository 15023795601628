import { type FC } from 'react'
import { type IconProps } from 'website/components/types'

const IconSVGMotor: FC<IconProps> = ({
  fill = '#20C39E',
  height = 18,
  width = 18
}) => {
  return (
    <svg width={width} height={height} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.66536 8.33203H13.332V14.9987H9.16537L7.4987 13.332H5.83203V9.16536M5.83203 3.33203V4.9987H8.33203V6.66536H5.83203L4.16536 8.33203V10.832H2.4987V8.33203H0.832031V14.9987H2.4987V12.4987H4.16536V14.9987H6.66536L8.33203 16.6654H14.9987V13.332H16.6654V15.832H19.1654V7.4987H16.6654V9.9987H14.9987V6.66536H9.9987V4.9987H12.4987V3.33203H5.83203Z'
        fill={fill}
      />
    </svg>
  )
}

export default IconSVGMotor
