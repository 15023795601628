import { type FC } from 'react'
import { cnx } from '@carfluent/common'
import { InfoCardProps, SupportedComponents, TypographyVariant } from 'website/components/types'
import Text from 'website/components/Text'
import { useComponentStyles } from 'website/styles/useComponentStyles'
import { useLayoutStyles } from 'website/styles/useLayoutStyles'

import IconSVG from '../icons'

const titleVariant = { variant: 'h4' as TypographyVariant }
const subtitleVariant = { variant: 'body2' as TypographyVariant }
const textVariant = { variant: 'textInfo' as TypographyVariant }

const InfoCard: FC<InfoCardProps> = ({
  nameInLayout,
  template,
  layout,
  variant,
  className,
  icon,
  title,
  subtitle,
  text
}) => {
  const componentStylesCls = useComponentStyles(SupportedComponents.InfoCard, variant)
  const templateCls = useLayoutStyles({ template, layout })
  const SelectedIcon = IconSVG[icon as keyof typeof IconSVG]

  return (
    <div className={cnx(nameInLayout ?? SupportedComponents.Header, componentStylesCls.root, className)}>
      <div className={cnx(componentStylesCls.content, templateCls)}>
        <SelectedIcon />
        <Text
          nameInLayout='Title'
          value={title}
          config={titleVariant}
        />
        {
          (subtitle != null && subtitle !== '') && (
            <Text
              nameInLayout='Subtitle'
              value={subtitle}
              config={subtitleVariant}
            />
          )
        }
        <Text
          value={text}
          config={textVariant}
        />
      </div>
    </div>
  )
}

export default InfoCard
