import { MAIN_COLOR } from './constants'

export default `
  --mainColor: ${MAIN_COLOR};
  --mainColorHover: hsl(from var(--mainColor) h s l / 0.08);
  --mainColorBackground: hsl(from var(--mainColor) h s l / 0.08);
  --buttonMainColor: var(--mainColor);
  --buttonTextColor: #FFF;
  --buttonTextHoverColor: rgba(247, 248, 250, 0.08);
  --buttonDisabledColor: #F3F3F3;
  --checkboxCheckedFill: var(--mainColor);
  --checkboxUncheckedStroke: #DBDBDB;
  --fadedBackgroundColor: rgba(255, 255, 255, 0.8);
  --headerBackgroundColor: #FFF;
  --headerColor: #000;
  --headerBottomBorderColor: #D7D7D7;
  --mainBackgroundColor: #fafafa;
  --mainOutlinedButtonColor: var(--mainColor);
  --outlineInactive: rgba(0, 0, 0, 0.30);
  --searchBarBackgroundColor: #FFF;
  --textInactiveColor: rgba(0, 0, 0, 0.38);
  --textCaption: rgba(33, 33, 33, 0.80);
  --textBlack: #101010;
  --textWhite: #FFF;
  --vehicleCardBorderHighlightColor: rgba(32, 195, 158, 0.32);
  --vehicleCardBadgeBgColor: #F7F8FA;
  --vehicleCardBadgeColor: rgba(34, 36, 41, 0.80);
  --headerPhoneBgColor: rgba(32, 195, 158, 0.08);
  --footerSectionTitle: rgba(255, 255, 255, 0.56);
  
  /**
   * DD-WARNING: we need to duplicate this from CommonLib
   * because Mui styles are calculated earlier and they do not have these vars!
   */
  --cf-input-border-focus-color: #C99B86;
  --cf-input-error-color: #B00020;
`
