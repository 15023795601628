import { type FC } from 'react'
import { type IconProps } from 'website/components/types'

const IconSVGPersonalData: FC<IconProps> = ({
  fill = 'var(--mainColor)',
  height = 56,
  width = 57
}) => {
  return (
    <svg width={width} height={height} viewBox='0 0 57 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M46.6918 42.7801C52.0746 42.7801 56.4382 38.4378 56.4382 33.0814C56.4382 27.725 52.0746 23.3828 46.6918 23.3828C41.309 23.3828 36.9453 27.725 36.9453 33.0814C36.9453 38.4378 41.309 42.7801 46.6918 42.7801Z' fill='#F3F3F3' />
      <path d='M22.8404 17.5012C27.4543 17.5012 31.1945 13.7793 31.1945 9.18811C31.1945 4.59691 27.4543 0.875 22.8404 0.875C18.2266 0.875 14.4863 4.59691 14.4863 9.18811C14.4863 13.7793 18.2266 17.5012 22.8404 17.5012Z' fill='#F3F3F3' />
      <path d='M14.361 55.1244C22.0508 55.1244 28.2845 48.9213 28.2845 41.2693C28.2845 33.6172 22.0508 27.4141 14.361 27.4141C6.67127 27.4141 0.4375 33.6172 0.4375 41.2693C0.4375 48.9213 6.67127 55.1244 14.361 55.1244Z' fill='#F3F3F3' />
      <path d='M39.8225 50.3444H14.7601C9.37869 50.3444 5.01367 46.0008 5.01367 40.6458V15.7064C5.01367 10.3514 9.37869 6.00781 14.7601 6.00781H39.8225C45.2039 6.00781 49.5689 10.3514 49.5689 15.7064V40.6458C49.5689 46.0008 45.2039 50.3444 39.8225 50.3444Z' fill={fill} fillOpacity={0.8} />
      <path d='M39.1263 47.5734H12.6716C8.43886 47.5734 5.01367 44.165 5.01367 39.953V15.7064C5.01367 10.3514 9.37869 6.00781 14.7601 6.00781H39.1263C43.359 6.00781 46.7842 9.41619 46.7842 13.6282V39.953C46.7842 44.1581 43.3521 47.5734 39.1263 47.5734Z' fill='white' />
      <path d='M39.8212 50.864H14.7588C9.09892 50.864 4.49023 46.278 4.49023 40.6458V15.7065C4.49023 10.0743 9.09892 5.48828 14.7588 5.48828H39.8212C45.4811 5.48828 50.0897 10.0743 50.0897 15.7065V40.6458C50.0897 46.278 45.4811 50.864 39.8212 50.864ZM14.7588 6.52742C9.66978 6.52742 5.5345 10.6493 5.5345 15.7065V40.6458C5.5345 45.7099 9.66978 49.8249 14.7588 49.8249H39.8212C44.9102 49.8249 49.0455 45.7099 49.0455 40.6458V15.7065C49.0455 10.6493 44.9102 6.52742 39.8212 6.52742H14.7588Z' fill='#101010' />
      <path d='M39.1251 45.4934H15.4551V10.8555H39.1251C42.2022 10.8555 44.6945 13.3355 44.6945 16.3975V39.9514C44.6945 43.0134 42.2022 45.4934 39.1251 45.4934Z' fill={fill} fillOpacity={0.8} />
      <path d='M38.4289 43.4152H15.4551V10.8555H37.7327C40.4269 10.8555 42.6059 13.0238 42.6059 15.7048V39.2586C42.6059 41.5516 40.7332 43.4152 38.4289 43.4152Z' fill='white' />
      <path d='M39.1238 46.013H15.4538C15.1683 46.013 14.9316 45.7844 14.9316 45.4935V10.8555C14.9316 10.5715 15.1683 10.3359 15.4538 10.3359H39.1238C42.4863 10.3359 45.2153 13.0585 45.2153 16.3976V39.9514C45.2153 43.2905 42.4793 46.013 39.1238 46.013ZM15.9759 44.9739H39.1238C41.9085 44.9739 44.171 42.7224 44.171 39.9514V16.3976C44.171 13.6265 41.9085 11.3751 39.1238 11.3751H15.9759V44.9739Z' fill='#101010' />
      <path d='M17.5427 21.9396C17.9256 21.9396 18.2389 21.6279 18.2389 21.2469V17.0903C18.2389 16.7093 17.9256 16.3975 17.5427 16.3975H15.4542V10.8555C12.3771 10.8555 9.88477 13.3355 9.88477 16.3975V39.9514C9.88477 43.0134 12.3771 45.4934 15.4542 45.4934V39.9514H17.5427C17.9256 39.9514 18.2389 39.6396 18.2389 39.2586V35.102C18.2389 34.721 17.9256 34.4093 17.5427 34.4093H15.4542V21.9396H17.5427Z' fill='white' />
      <path d='M17.5442 21.9405C17.9271 21.9405 18.2404 21.6288 18.2404 21.2478V17.0912C18.2404 16.7102 17.9271 16.3984 17.5442 16.3984H16.1519V19.5159C16.1519 19.7098 15.9987 19.8622 15.8038 19.8622H13.7153C13.5203 19.8622 13.3672 20.0146 13.3672 20.2086V31.9855C13.3672 32.1795 13.5203 32.3319 13.7153 32.3319H15.4557V21.9405H17.5442Z' fill={fill} fillOpacity={0.8} />
      <path d='M15.8038 37.874H13.7153C13.5203 37.874 13.3672 38.0264 13.3672 38.2203V45.0856C14.0146 45.3488 14.7178 45.4943 15.4557 45.4943V39.9522H17.5442C17.9271 39.9522 18.2404 39.6405 18.2404 39.2595V35.1029C18.2404 34.7219 17.9271 34.4102 17.5442 34.4102H16.1519V37.5276C16.1519 37.7215 15.9918 37.874 15.8038 37.874Z' fill={fill} fillOpacity={0.8} />
      <path d='M15.4548 46.013C12.0923 46.013 9.36328 43.2974 9.36328 39.9514V16.3976C9.36328 13.0585 12.0923 10.3359 15.4548 10.3359C15.7403 10.3359 15.977 10.5715 15.977 10.8555V15.878H17.5433C18.2117 15.878 18.7617 16.4184 18.7617 17.0903V21.2469C18.7617 21.9119 18.2117 22.4592 17.5433 22.4592H15.977V33.8898H17.5433C18.2117 33.8898 18.7617 34.437 18.7617 35.1021V39.2586C18.7617 39.9237 18.2117 40.471 17.5433 40.471H15.977V45.4935C15.977 45.7775 15.7403 46.013 15.4548 46.013ZM14.9327 11.4028C12.3916 11.666 10.4075 13.8067 10.4075 16.3976V39.9514C10.4075 42.5492 12.3916 44.6899 14.9327 44.9462V39.9514C14.9327 39.6674 15.1694 39.4318 15.4548 39.4318H17.5433C17.6408 39.4318 17.7174 39.3556 17.7174 39.2586V35.1021C17.7174 35.0051 17.6408 34.9289 17.5433 34.9289H15.4548C15.1694 34.9289 14.9327 34.6934 14.9327 34.4093V21.9397C14.9327 21.6556 15.1694 21.4201 15.4548 21.4201H17.5433C17.6408 21.4201 17.7174 21.3439 17.7174 21.2469V17.0903C17.7174 16.9934 17.6408 16.9172 17.5433 16.9172H15.4548C15.1694 16.9172 14.9327 16.6816 14.9327 16.3976V11.4028Z' fill='#101010' />
      <path d='M29.3795 36.4895C33.9933 36.4895 37.7336 32.7676 37.7336 28.1764C37.7336 23.5852 33.9933 19.8633 29.3795 19.8633C24.7657 19.8633 21.0254 23.5852 21.0254 28.1764C21.0254 32.7676 24.7657 36.4895 29.3795 36.4895Z' fill='white' />
      <path d='M34.1496 21.3516C35.0894 22.6955 35.6464 24.3304 35.6464 26.097C35.6464 30.69 31.9079 34.4101 27.2922 34.4101C25.517 34.4101 23.881 33.8559 22.5234 32.9206C24.0341 35.0751 26.5404 36.4883 29.3808 36.4883C33.9964 36.4883 37.7349 32.7682 37.7349 28.1752C37.7349 25.3488 36.3147 22.8549 34.1496 21.3516Z' fill={fill} fillOpacity={0.8} />
      <path d='M29.3782 37.0091C24.4841 37.0091 20.502 33.0465 20.502 28.1764C20.502 23.3063 24.4841 19.3438 29.3782 19.3438C34.2723 19.3438 38.2544 23.3063 38.2544 28.1764C38.2544 33.0465 34.2723 37.0091 29.3782 37.0091ZM29.3782 20.3829C25.0619 20.3829 21.5462 23.8813 21.5462 28.1764C21.5462 32.4715 25.0619 35.97 29.3782 35.97C33.6945 35.97 37.2102 32.4715 37.2102 28.1764C37.2102 23.8813 33.6945 20.3829 29.3782 20.3829Z' fill='#101010' />
      <path d='M37.7323 28.6954H21.0241C20.7387 28.6954 20.502 28.4668 20.502 28.1758C20.502 27.8918 20.7387 27.6562 21.0241 27.6562H37.7323C38.0177 27.6562 38.2544 27.8918 38.2544 28.1758C38.2544 28.4599 38.0177 28.6954 37.7323 28.6954Z' fill='#101010' />
      <path d='M25.2011 35.8931C25.1106 35.8931 25.0201 35.8723 24.9435 35.8238C24.6929 35.6783 24.6093 35.3597 24.7555 35.1172L33.1096 20.7217C33.2558 20.4723 33.5691 20.3891 33.8197 20.5277C34.0704 20.6732 34.1539 20.9918 34.0077 21.2412L25.6536 35.6368C25.5561 35.803 25.3821 35.8931 25.2011 35.8931Z' fill='#101010' />
      <path d='M33.5528 35.8953C33.3718 35.8953 33.1978 35.8052 33.1003 35.632L24.7462 21.2365C24.6 20.9871 24.6905 20.6684 24.9342 20.5229C25.1848 20.3775 25.505 20.4675 25.6443 20.7169L33.9984 35.1125C34.1446 35.3619 34.0541 35.6805 33.8104 35.8191C33.7338 35.8745 33.6433 35.8953 33.5528 35.8953Z' fill='#101010' />
      <path d='M29.3782 32.3326C31.6852 32.3326 33.5553 30.4717 33.5553 28.1761C33.5553 25.8805 31.6852 24.0195 29.3782 24.0195C27.0713 24.0195 25.2012 25.8805 25.2012 28.1761C25.2012 30.4717 27.0713 32.3326 29.3782 32.3326Z' fill='white' />
      <path d='M31.549 24.6289C31.9389 25.2593 32.1616 25.9936 32.1616 26.7903C32.1616 29.0833 30.2889 30.9469 27.9846 30.9469C27.1909 30.9469 26.446 30.7183 25.8125 30.3372C26.5435 31.5357 27.8662 32.3324 29.3769 32.3324C31.6813 32.3324 33.554 30.4689 33.554 28.1758C33.554 26.6725 32.7464 25.3563 31.549 24.6289Z' fill={fill} fillOpacity={0.8} />
      <path d='M29.3769 32.8522C26.7871 32.8522 24.6777 30.7532 24.6777 28.1761C24.6777 25.5991 26.7871 23.5 29.3769 23.5C31.9667 23.5 34.0761 25.5991 34.0761 28.1761C34.0761 30.7532 31.9667 32.8522 29.3769 32.8522ZM29.3769 24.5391C27.365 24.5391 25.722 26.174 25.722 28.1761C25.722 30.1851 27.365 31.8131 29.3769 31.8131C31.3889 31.8131 33.0318 30.1851 33.0318 28.1761C33.0318 26.1671 31.3889 24.5391 29.3769 24.5391Z' fill='#101010' />
      <path d='M41.2135 34.2366C40.9281 34.2366 40.6914 34.008 40.6914 33.717V22.6329C40.6914 22.3488 40.9281 22.1133 41.2135 22.1133C41.499 22.1133 41.7357 22.3488 41.7357 22.6329V33.717C41.7357 34.001 41.499 34.2366 41.2135 34.2366Z' fill='#101010' />
      <path d='M36.6039 18.9962H35.6432C35.3578 18.9962 35.1211 18.7606 35.1211 18.4766C35.1211 18.1926 35.3578 17.957 35.6432 17.957H36.6039C36.8894 17.957 37.1261 18.1926 37.1261 18.4766C37.1261 18.7606 36.8963 18.9962 36.6039 18.9962Z' fill='#101010' />
      <path d='M39.8199 18.9962H38.8522C38.5668 18.9962 38.3301 18.7606 38.3301 18.4766C38.3301 18.1926 38.5668 17.957 38.8522 17.957H39.8129C40.0984 17.957 40.3351 18.1926 40.3351 18.4766C40.342 18.7606 40.1053 18.9962 39.8199 18.9962Z' fill='#101010' />
      <path d='M37.7331 21.0772C37.4476 21.0772 37.2109 20.8416 37.2109 20.5576V19.6016C37.2109 19.3176 37.4476 19.082 37.7331 19.082C38.0185 19.082 38.2552 19.3176 38.2552 19.6016V20.5576C38.2552 20.8416 38.0185 21.0772 37.7331 21.0772Z' fill='#101010' />
      <path d='M37.7331 17.8819C37.4476 17.8819 37.2109 17.6463 37.2109 17.3623V16.4063C37.2109 16.1223 37.4476 15.8867 37.7331 15.8867C38.0185 15.8867 38.2552 16.1223 38.2552 16.4063V17.3623C38.2552 17.6463 38.0185 17.8819 37.7331 17.8819Z' fill='#101010' />
      <path d='M22.6801 16.2266H21.7194C21.434 16.2266 21.1973 15.998 21.1973 15.7071C21.1973 15.423 21.434 15.1875 21.7194 15.1875H22.6801C22.9655 15.1875 23.2022 15.423 23.2022 15.7071C23.2022 15.9911 22.9655 16.2266 22.6801 16.2266Z' fill='#101010' />
      <path d='M25.8941 16.2266H24.9264C24.641 16.2266 24.4043 15.998 24.4043 15.7071C24.4043 15.423 24.641 15.1875 24.9264 15.1875H25.8872C26.1726 15.1875 26.4093 15.423 26.4093 15.7071C26.4162 15.9911 26.1796 16.2266 25.8941 16.2266Z' fill='#101010' />
      <path d='M23.8092 18.3037C23.5238 18.3037 23.2871 18.0751 23.2871 17.7842V16.8282C23.2871 16.5441 23.5238 16.3086 23.8092 16.3086C24.0947 16.3086 24.3314 16.5441 24.3314 16.8282V17.7842C24.3314 18.0682 24.0947 18.3037 23.8092 18.3037Z' fill='#101010' />
      <path d='M23.8092 15.1045C23.5238 15.1045 23.2871 14.869 23.2871 14.585V13.6289C23.2871 13.3449 23.5238 13.1094 23.8092 13.1094C24.0947 13.1094 24.3314 13.3449 24.3314 13.6289V14.585C24.3314 14.8759 24.0947 15.1045 23.8092 15.1045Z' fill='#101010' />
      <path d='M31.7309 41.1641H30.7702C30.4847 41.1641 30.248 40.9286 30.248 40.6446C30.248 40.3605 30.4847 40.125 30.7702 40.125H31.7309C32.0163 40.125 32.253 40.3605 32.253 40.6446C32.253 40.9355 32.0233 41.1641 31.7309 41.1641Z' fill='#101010' />
      <path d='M34.9469 41.1641H33.9792C33.6937 41.1641 33.457 40.9286 33.457 40.6446C33.457 40.3605 33.6937 40.125 33.9792 40.125H34.9399C35.2253 40.125 35.462 40.3605 35.462 40.6446C35.469 40.9355 35.2323 41.1641 34.9469 41.1641Z' fill='#101010' />
      <path d='M32.8581 43.2451C32.5726 43.2451 32.3359 43.0096 32.3359 42.7256V41.7696C32.3359 41.4855 32.5726 41.25 32.8581 41.25C33.1435 41.25 33.3802 41.4855 33.3802 41.7696V42.7256C33.3802 43.0165 33.1435 43.2451 32.8581 43.2451Z' fill='#101010' />
      <path d='M32.8581 40.049C32.5726 40.049 32.3359 39.8134 32.3359 39.5294V38.5664C32.3359 38.2824 32.5726 38.0469 32.8581 38.0469C33.1435 38.0469 33.3802 38.2824 33.3802 38.5664V39.5294C33.3802 39.8134 33.1435 40.049 32.8581 40.049Z' fill='#101010' />
    </svg>

  )
}

export default IconSVGPersonalData
