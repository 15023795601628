import { css } from '@emotion/css'
import { Responsive } from 'website/constants'

export default css(`
  display: flex;
  flex-direction: column;
  align-items: center;
  column-gap: 24px;
  row-gap: 32px;
  
  .cf-submit-button { width: 100%; }
  
  .cf-input { width: 100%; }
  
  @media screen and (min-width: ${Responsive.WideMobileScreen}px) {
    display: grid;
    grid-template-columns: minmax(auto, 1019px) 237px;
    grid-column-gap: 24px;
    
    .cf-submit-button {
      min-width: 237px;
    }
  }
`)
