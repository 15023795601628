import { css } from '@emotion/css'

export default css`
  position: fixed;
  bottom: 24px;
  right: 24px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: var(--mainColor);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 3;
  transition: background-color .2s;
  box-shadow: 0 24px 38px 0 rgba(58, 71, 78, 0.10);

  .counter-icon {
    position: absolute;
    right: -10px;
    top: -10px;
    background: #FF1B44;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    width: 24px;
    height: 24px;
    color: #FFF;
    justify-content: center;
    align-items: center;
  }
  
  :hover {
    background: rgba(32, 195, 158, 0.80);
  }
`

export const CHAT_CLASS_NAME = css`
  opacity: 0;
  z-index: -1;
  transition: opacity .3s linear, z-index .3s step-end;
  
  &.is-opened {
    opacity: 1;
    z-index: 1102;
    transition: z-index 0.3s step-start, opacity 0.3s linear;
    
    @media screen and (min-width: 1024px) {
      z-index: 100;
    }
  }
`

export const PREVIEW_MESSAGE_CLASS_NAME = css`
  position: fixed;
  right: 100px;
  bottom: 28px;
  width: calc(100% - 160px);
  max-width: 270px;
  border-radius: 16px 16px 0px 16px;
  border: 1px solid #DCDEE0;
  background: #FFF;
  box-shadow: 0px 8px 16px 5px rgba(0, 0, 0, 0.04);
  padding: 16px 20px;
  z-index: 3;
  display: none;

  @media screen and (min-width: 780px) {
    display: block;
    width: 270px;
  }

  .close-icon {
    position: absolute;
    left: -10px;
    top: -10px;
    background: #F7F8FA;
    border: 1px solid #DCDEE0;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
  }
`
