import { ReactNode, useMemo, type FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { cnx } from '@carfluent/common'

import { BODY_STYLE_MAP, BODY_STYLE_SECONDARY_MAP } from 'website/configs'
import { WebsiteRoutes } from 'website/routing/constants'
import { updateListEntryInQs } from 'website/utils/qsfilters'
import { isTruthy } from 'utils/common'

import { type CarBodyStylePanelProps, SupportedComponents } from '../types'

import CLASS_NAME from './styles'

const CarBodyStylePanel: FC<CarBodyStylePanelProps> = ({
  nameInLayout = SupportedComponents.CarBodyStylePanel,
  title = 'Shop by body style',
  showIcons = true,
  variant = 'default'
}) => {
  const navigate = useNavigate()

  const onBodyStyleChange = (bodyStyleName: string): void => {
    navigate(WebsiteRoutes.Inventory)
    updateListEntryInQs('bodyStyle', bodyStyleName)
  }

  const content = useMemo(() => {
    if (variant === 'secondary') {
      return getSecondaryContent(onBodyStyleChange)
    }

    return getDefaultContent(onBodyStyleChange, title, showIcons)
  }, [onBodyStyleChange, variant])

  return (
    <div className={cnx(nameInLayout, CLASS_NAME[variant])}>
      {content}
    </div>
  )
}

const getDefaultContent = (onBodyStyleChange: (key: string) => void, title: string | null, showIcons: boolean): ReactNode => {
  return (
    <>
      <div className='component-container'>
        {isTruthy(title) && <h3>{title}</h3>}

        <div className='body-style-container'>
          {
            Array.from(BODY_STYLE_MAP.keys()).map((key) => (
              <div
                key={key}
                className='body-style-item'
                onClick={() => onBodyStyleChange(key)}
              >
                <p className='small-text'>{key}</p>
                {showIcons && <img src={BODY_STYLE_MAP.get(key)} alt={key} />}
              </div>
            ))
          }
        </div>
      </div>
    </>
  )
}

const getSecondaryContent = (onBodyStyleChange: (key: string) => void): ReactNode => {
  return (
    <>
      <div className='component-container'>
        {
          Array.from(BODY_STYLE_SECONDARY_MAP.keys()).map((key) => (
            <div
              key={key}
              className='body-style-item'
              onClick={() => onBodyStyleChange(key)}
            >
              <p className='small-text'>{key}</p>
              <img src={BODY_STYLE_SECONDARY_MAP.get(key)} alt={key} />
            </div>
          ))
          }
      </div>
    </>
  )
}

export default CarBodyStylePanel
