import { css } from '@emotion/css'

export default css(`
  display: grid;
  grid-row-gap: 24px;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, auto);
  
  > [class*="dropdown"] {
    margin: 0px;
  }
  
  .cf-submit-button { width: 100%; }
  
  @media screen and (min-width: 769px) {
    display: grid;
    grid-column-gap: 24px;  
    grid-template-columns: repeat(3, minmax(auto, 324px)) 237px;
    grid-template-rows: 1fr;
    
    .cf-submit-button {
      m-width: 237px;
    }
  }
`)
