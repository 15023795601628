import { css } from '@emotion/css'

export default css(`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
  margin: 24px auto 0px;
  width: 100%;
  
  .cf-title {
    margin-bottom: 0px;
  }

  h4 {
    font-weight: 500;
  }
  
  @media (min-width: 680px) {
      width: 600px;
  }
  
  @media (min-width: 768px) {
    margin: 0 auto;
  }

  .additional-note {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #212121CC;
    text-align: center;
  }
  
  .cf-trade-in-car-title {
    display: none;
    
    @media (min-width: 680px) {
      display: block;
    }
  }
  
  .cf-content-wrapper {
    padding: 0px 16px 24px;
    width: 100%;
    border-radius: 12px;
    border-radius: 12px;
    box-sizing: border-box;
              
    @media (min-width: 680px) {
      padding: 40px;
      box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.02), 0px 10px 16px rgba(0, 0, 0, 0.08), 0px 6px 30px rgba(0, 0, 0, 0.03);
    }
    
    .cf-car-info {
      display: flex;
      justify-content: space-between;
      
      img {
        width: 80px;
        
        @media (min-width: 680px) {
         width: 160px;
        }
      }
    }
    
    .cf-car-placeholder {
      width: 120px;
      height: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(250, 250, 250, 1);
      border-radius: 4px;
      
      img {
        width: 102px;
        height: 34px;
      }
    }
    
    .cf-car-name, .cf-car-spec {
      margin-bottom: 8px;  
    }
    
    .cf-price-bar {
      display: flex;
      flex-direction: column;
      row-gap: 40px;
      align-items: center;
      margin-top: 40px;
    }
    
    .search-action-bar {
      margin-top: 40px;

      p {
        text-align: center;
        color: var(--textBlack, #101010);
      }
      
      button {
        margin-bottom: 8px;
        height: 56px;
        max-height: unset;
      }
    }
    
    .cf-estimate-car-wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      row-gap: 40px;

      .Text p {
        text-align: center;
      }
      
      .cf-title {
        display: flex;
        align-items: center;
        flex-direction: column;
        row-gap: 16px;
        margin-top: 40px;
        
        .cf-main-title {
          margin: 0 auto;
          max-width: 230px;
          
          @media (min-width: 480px) {
            max-width: 350px;
          }
        }
      }
      
      .cf-estimate-action-bar {
        width: 100%;
        
        .Button {
          margin-bottom: 8px;
        }
      }
    }
     
    .cf-action-bar {
      display: flex;
      flex-direction: column;
      margin-top: 40px;

      p {
        text-align: center;
      }
      
      .MuiButtonBase-root {
          height: 56px;
          border-radius: 12px;
          background: var(--buttonMainColor);
          color: #fff;
          margin-bottom: 24px;
       }
      
      .cf-outlined-btn {
        .MuiButtonBase-root {
          background: #fff;
          border-color: var(--buttonMainColor);  
          color: var(--mainOutlinedButtonColor);
        }
      }
    }
  }

  hr {
    display: block;
    width: 100%;
    margin-top: -10px;
    border-color: rgba(0, 0, 0, 0.3);
  }

  @media (min-width: 680px) {
    hr {
      display: none;
    }
  }
  
  [class*=helper-text] {
    padding: 0px 16px;
  }
`)
