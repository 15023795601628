import { css } from '@emotion/css'

export default css(`
  &:not(.touch-device) {
    .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-fullWidth {
      &:hover {
        background: rgba(247, 248, 250, 0.08);
      }
    }
  }
  
  .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-fullWidth {
    border-radius: 8px;
    border: 1px solid #416EEB;
    background-color: #1F2229;
    padding: 16px 20px;
    height: 40px;
    
    .MuiButton-label {
      color: #FFF;
      text-align: center;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      text-transform: none;
      letter-spacing: normal;
    }
  }
  
  .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-fullWidth {
    padding: 0;
    justify-content: flex-start;
    
    .MuiButton-label {
      color: #FFF;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: none;
      
      @media (min-width: 1024px) and (max-width: 1439px) {
        font-size: 14px;
      }
    }
  }
`)
