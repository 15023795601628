export default {
  FinancingPage: {
    default: {
      root: `
        .GetPrequalifiedIntro {
          > div {
            background: linear-gradient(263deg, rgba(32, 195, 158, 0.72) 0.71%, rgba(31, 34, 41, 0.00) 100%), #1F2229;
            padding: 0 16px;
            
            @media (min-width: 768px) {
              padding: 0 28px;
            }
            
            @media (min-width: 1024px) {
              padding: 80px 32px 80px 40px;
            }
            
            @media (min-width: 1280px) {
              padding: 80px 94px 80px 148px;
            }
            
            @media (min-width: 1440px) {
              padding: 57px 90px 57px 148px;
            }
          }
          
          .GetPrequalifiedCallToAction {
            .ExtendedTypography1 {
              color: var(--textWhite);
              text-align: center;
              font-feature-settings: 'liga' off, 'clig' off;
              font-family: Montserrat;
              font-size: 32px;
              font-style: normal;
              font-weight: 500;
              line-height: 56px;
              
              @media (min-width: 768px) {
                font-size: 40px;
              }
              
              @media (min-width: 1024px) {
                text-align: left;
              }  
              
              @media (min-width: 1440px) {
                font-size: 48px;
              }
            }
            
            .Text1 p, .Text1 span, .Text2 p, .Text2 span {
              text-align: center;
              color: #FFF;
              font-family: var(--mainFontFamily);
              font-size: 20px;

              @media (min-width: 1024px) {
                text-align: left;
              }  
            }
            
            .Text2 {
              max-width: 475px;
            }
            
            .GetPrequalifiedComponent {
              width: 100%;
              
              .GetPrequalifiedButton {
                min-width: 100%;
                max-width: 100%;
                
                .MuiButton-label {
                  font-weight: 500;
                }
              }
              
              @media (min-width: 768px) {
                max-width: 225px;
              }  
            }
          }
          
          .ExtendedImage {
            @media (min-width: 1024px) {
              height: 360px;
              max-height: 360px;
            }
            
            @media (min-width: 1440px) {
              height: 406px;
              max-height: 406px;
            }
          }
        }
         
        .InfoSection {
          > div {
            padding: 40px 16px;
            background: var(--mainColorBackground);
            
            @media (min-width: 768px) {
              padding: 64px 40px;
            }
            
            @media (min-width: 1024px) {
              padding: 64px 0;
            }
          }
          
          .ExtendedTypography1 {
            color: var(--textBlack);
            line-height: 140%;
            
            @media (min-width: 768px) {
              font-size: 32px;
            }
          }

          .Text .ExtendedTypography {
            font-size: 20px;
            line-height: 150%;
            color: var(--textCaption);
          }
          
          .GetPrequalifiedComponent {
            padding-top: 16px;

            @media (min-width: 472px) {
              padding-top: 0;
              padding-left: 8px;
            }
            
            .GetPrequalifiedButton {
              .MuiButtonBase-root {
                color: var(--mainColor);
                font-size: 20px;
                
                .MuiButton-label {
                  font-weight: 600;
                }
              }
            }
          }
        }

        .List {
          .InfoCard {
            .ExtendedImage {
              width: 72px;
              height: 72px;
            }
          }
        }
      `
    }
  },
  GetPrequalifiedForm: {
    default: {
      root: `
        max-width: 1008px;
        margin: 0 auto;
        padding: 24px 0;
        background: #ffffff;
        
        @media (min-width: 768px) {
          padding: 44px 44px 32px;
        }
        
        .form-container {
          max-width: 600px;
          margin: 0 auto;

          @media (max-width: 768px) {
            padding: 0 16px;
          }
          
          .cf-form-container-personal {
            display: grid;
            justify-content: center;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 12px;
            grid-row-gap: 32px;
            
            .form-field {
              @media (max-width: 680px) {
                grid-column-start: 1;
                grid-column-end: span 2;
              }
              
              &.cf-full-width {
                grid-column-start: 1;
                grid-column-end: span 2;
              }
            }
          }
          
          
          .prequalify-form-reminder-reminder {
            margin: 32px 0;

            a, p {
              color: rgba(33, 33, 33, 0.50);
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0.25px;
            }
          }
          
          .cf-form-head {
            margin-bottom: 16px;

            h5 {
              font-weight: 500;
              font-size: 24px;
              line-height: 24px;
              color: #101010;
            }

            &.cf-personal-info {
              margin-bottom: 32px;
            }
          }
          
          .MuiButtonBase-root {
            max-height: 56px;
            height: 56px;
            border-radius: 12px;
            box-shadow: none;
          }
          
          .Button {
            .MuiButtonBase-root {
              background: var(--mainOutlinedButtonColor);
            }
          }
        }
      `
    }
  },
  GetPrequalifiedSuccess: {
    default: {
      root: `
        .cf-main-block {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-bottom: 16px;
          border-bottom: 1px solid #D9D9D9;
        }
        
        .cf-split-blocks {
          display: flex;
          justify-content: center;
          padding-top: 16px;
          
          .cf-split-block {
            display: flex;
            align-items: center;
            padding: 0 32px;
            
            .cf-image-wrapper {
              margin-right: 10px;
              max-height: 28px;
            }
            
            &:first-child {
              border-right: 1px solid #D9D9D9;
            }
          }
        }
        
        .Button {
          margin: 0px 16px;

          .MuiButtonBase-root {
            max-height: 56px;
            margin-top: 40px;
            height: 56px;
            border-radius: 12px;
            background: var(--mainOutlinedButtonColor);
            
            :hover {
              background: var(--mainOutlinedButtonColor);
            }
          }
        }
      `
    }
  },
  PrequalifiedFormError: {
    default: {
      root: `
      padding: 40px 16px;
      background: #fff;
      margin-bottom: 40px;

      .ButtonGoShopWrapper .MuiButton-root {
        max-height: 56px;
        height: 56px;
        background: var(--mainOutlinedButtonColor);
        
        :hover {
          background: var(--mainOutlinedButtonColor);
        }
      }
      
      .ErrorHead {
        h1 {
          font-weight: 500;
        }
      }
      
      .ErrorSubHead {
        p {
          font-size: 14px;
        }
      }
      `
    }
  },
  GetPrequalifiedFormPage: {
    default: {
      root: `
      .TextGetPrequalifiedFormPageHeader1 {
        h1 {
          font-weight: 500;
        }
      }
      
      .MuiButton-label {
        font-weight: 500;
      }
      
      .ImageBlockItemText, .ImageBlockItemText2 {
        .ExtendedTypography {
          font-weight: 500;
        }
      }
    `
    }
  }
}
