import { APP_CLS_NAME } from '../constants'
import variables from './variables'

export default `
  // TODO: in all theme global styles and in global CommonLib styles
  // make css-vars under :root

  ${variables}

  @keyframes shimmer {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }

  p, span, h1, h2, h3, h4, h5, h6 {
    font-family: Roboto, sans-serif;
  }

  .g-h1-thin {
    && {
      font-family: Roboto, sans-serif;
      font-size: 34px;
      line-height: 36px;
      font-weight: 400;
      text-align: center;

      @media (min-width: 767px) {
        font-size: 48px;
        line-height: 56px;
        font-weight: 400;
      }
    }
  }
  
  .cf-opened .cf-popover-content {
    z-index: var(--popoverZindex);
  }

  .cf-skeleton {
    background: linear-gradient(90deg, #ECECEC 25%, #F3F3F3 50%, #ECECEC 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
  }

  .cf-modal-title {
    @media (max-width: 480px) {
      margin-bottom: 16px;
      border-bottom: 1px solid #C2C2C2;
    }
  }

  .g-checkbox {
    display: flex;
    align-items: center;
    user-select: none;

    .checkmark {
      width: 24px;
      min-width: 24px;
      height: 24px;
      border-radius: 4px;
      border: 1px solid #C2C2C2;
      margin-right: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: background, border-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  
      svg {
        opacity: 0;
        transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
    }

    &.is-checked .checkmark {
      background: var(--mainColor);
      border-color: var(--mainColor);

      svg {
        opacity: 1;
      }
    }
  }

  /*
    DD-NOTE: popover styles are global because they are rendered via Portals,
    so their styles are outside normal flow.
    item-count is also here for now because they are met in DropDown popovers.
  */

  .cf-dropdown-listbox {
    border-radius: 8px;
    min-width: 170px;
  }

  .cf-list-filter-item-count {
    font-size: 14px;
    color: #0000004D;
    margin-left: 8px;
  }

  .cf-list-filter-item-label {
    display: flex;
    align-items: center;

    .cf-checkbox-icon {
      margin-right: 8px;
    }
  }

  .cf-dropdown-option:hover :not(.checkmark),
  .cf-dropdown-option :not(.checkmark) {
    background: unset !important;
  }

  .cf-filter-section.filter {
    .cf-input-container, .cf-select-display-container {
      &.disabled:hover {
        cursor: unset;
        border: 1px solid #DBDBDB !important;
      }
    }
  }

  .g-input-search {
    :hover .cf-start-adornment svg * {
      stroke: #000;
    }
  }

  // INPUT-based components border fixes

  .cf-input-container:not(.with-error):not(.disabled) {
    border-color: transparent;

    :hover {
      border-color: var(--mainColor);
    }
  }

  // For dateTime pickers and for address pickers

  .MuiAutocomplete-endAdornment {
    && {
      top: 50%;
      transform: translateY(-50%);
    }
  }
  
  .MuiFilledInput-underline:after, .MuiFilledInput-underline:before {
    display: none;
  }
  
  .MuiIconButton-root:hover {
    background: transparent;
  }

  .MuiFormLabel-root.MuiInputLabel-root {
    color: rgba(33, 33, 33, 0.8);

    &.Mui-error {
      color: var(--cf-input-error-color);
    }
  }

  .MuiInputBase-root {
    && {
      &.MuiFilledInput-root {
        background: #fafafa;
        border-radius: 12px;
      }
    
      box-sizing: border-box;
      border: 1px solid transparent;

      &:hover {
        border: 1px solid var(--mainColor);
      }
        
      &.Mui-focused {
        border: 1px solid var(--mainColor);
      }
      
      &.Mui-error {
        border: 1px solid var(--cf-input-error-color);
      }
    }
  }

  // For Tradein condition component
  .g-tradein-condition .g-tradein-condition-option {
    &.cf-button-root.cf-button-variant-outlined.cf-button-color-primary {
      border: 1px solid var(--outlineInactive);
    }
    
    &.cf-button-root.cf-button-variant-outlined.cf-button-color-primary:hover {
      border: 2px solid var(--mainColor);
    }
    
    &.cf-active.cf-button-root.cf-button-variant-outlined.cf-button-color-primary {
      border: 2px solid var(--mainColor);
    }
  }
  
  /**
   * We can't use 'important!' because it will redefine buttons inside customer flow.
   * So we need to suppress default styles only by using more specific selectors.
   */
  & .cf-button-root.cf-button-variant-contained.cf-button-color-primary,
  & .cf-button-root.cf-button-variant-contained.cf-button-color-primary:hover,
  & .CarAppraisalForm .cf-button-root.cf-button-variant-contained.cf-button-color-primary,
  & .CarAppraisalForm .cf-button-root.cf-button-variant-contained.cf-button-color-primary:hover,
  & .TradeInDetails .cf-button-root.cf-button-variant-contained.cf-button-color-primary,
  & .TradeInDetails .cf-button-root.cf-button-variant-contained.cf-button-color-primary:hover,
  & .VehicleViewContent .cf-button-root.cf-button-variant-contained.cf-button-color-primary,
  & .VehicleViewContent .cf-button-root.cf-button-variant-contained.cf-button-color-primary:hover,
  & .ZipCodeLocation .cf-button-root.cf-button-variant-contained.cf-button-color-primary,
  & .ZipCodeLocation .cf-button-root.cf-button-variant-contained.cf-button-color-primary:hover,
  & .cf-login-flow-modal .cf-button-root.cf-button-variant-contained.cf-button-color-primary,
  & .cf-login-flow-modal .cf-button-root.cf-button-variant-contained.cf-button-color-primary:hover,
  & .change-location-popover .cf-button-root.cf-button-variant-contained.cf-button-color-primary,
  & .change-location-popover .cf-button-root.cf-button-variant-contained.cf-button-color-primary:hover,
  & .cf-filters-drawer-footer-btn .cf-button-root.cf-button-variant-contained.cf-button-color-primary,
  & .cf-filters-drawer-footer-btn .cf-button-root.cf-button-variant-contained.cf-button-color-primary:hover,
  & .cf-thank-for-your-interest-dialog .cf-button-root.cf-button-variant-contained.cf-button-color-primary,
  & .cf-thank-for-your-interest-dialog .cf-button-root.cf-button-variant-contained.cf-button-color-primary:hover {
    background-color: var(--mainColor);
    
    .cf-button-content {
      color: var(--textWhite);
    }
    
    &.cf-button-disabled {
      opacity: 0.4;
      background: var(--mainColor);
      
      .cf-button-content {
        color: var(--textWhite);
      }
    }
  }
  
  &.cf-button-root.cf-button-variant-outlined.cf-button-color-primary {
    &:hover {
      background: #F7F8FA;
    }
  }
  
  /**
   * Buttons inside customer flow should not be affected
   */
  .g-carfluent-purchase-flow {
    .cf-button-variant-contained.cf-button-color-primary {
      background-color: var(--cf-button-color-primary);
      
      .cf-button-content {
        color: #fff;
      }
      
      &.cf-button-disabled,
      &.cf-button-disabled:hover {
        opacity: 1;
        background-color: var(--cf-button-disabled-background);
      }
    }
  }

  // Overrides for purchase flow

  .${APP_CLS_NAME} {
    && {
      // INPUT-based components border fixes

      .cf-input-container:not(.with-error):not(.disabled) {
        &:hover {
          border-color: var(--cf-input-border-focus-color);
        }
      }

      // For dateTime pickers and for address pickers

      .MuiInputBase-root {
        &:hover {
          border: 1px solid var(--cf-input-border-focus-color);
        }
          
        &.Mui-focused {
          border: 1px solid var(--cf-input-border-focus-color);
        }

        &.Mui-error {
          border: 1px solid var(--cf-input-error-color);
        }
      }

      // For Tradein condition component
      .g-tradein-condition .g-tradein-condition-option {
        &.cf-button-root.cf-button-variant-outlined.cf-button-color-primary:hover {
          border: 2px solid var(--cf-input-border-focus-color);
        }
        
        &.cf-active.cf-button-root.cf-button-variant-outlined.cf-button-color-primary {
          border: 2px solid var(--cf-input-border-focus-color);
        }
      }
    }
  }
`
