export default {
  VehicleViewPage: {
    default: {
      content: `
        .RecommendedVehicles {
          padding: 40px 0;
          background: #FAFAFA;
          
          @media (min-width: 768px) {
            padding: 40px var(--pageOffset);
            
            .component-container > .Text {
              margin: 0px var(--pageOffset);
            }
          }
        }
      `
    }
  },
  VehicleViewContent: {
    default: {
      root: `
        background: white;
        white-space: pre-wrap;
        
        strong {
          font-weight: bold;
        }
        
        .no-image-wrapper {
          background: #F6F9F8;
          border-radius: 8px;
          img {
            width: 120px;
          }
        }
        
        .VehicleDealershipInfo {
          margin-top: 24px;
        }
        
        /* AZ-TODO: check, if needed */
        .VehicleDescriptionLabel {
          margin-top: 24px;
          margin-bottom: 16px;
          
          @media screen and (min-width: 1200px) {
            margin-top: 32px;
          }
        }

        .InfoLinkBlock {
          display: flex!important;
          flex-direction: column;
          row-gap: 16px;
          
          @media screen and (min-width: 1024px) {
            margin-top: 12px;
          }

          @media screen and (min-width: 768px) {
            display: grid!important;
            grid-template-columns: 1fr 1fr!important;
            column-gap: 16px!important;
          }
        }
        
        .IncludedWithCarAds {
          margin-top: 24px;

          @media screen and (min-width: 1024px) {
            margin-top: 32px;
          }
        }
        
        .TestDriveBlock {
          border-radius: 12px;
          background: var(--mainColorBackground);
          padding: 24px;
          position: relative;
          margin-top: 24px;
          
          @media screen and (min-width: 1200px) {
            margin-top: 32px;
          }
          
          .test-drive-title {
            color: #101010;
            font-family: Roboto;
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.15px;
            margin-bottom: 4px;
          }
          
          .test-drive-content {
            color: rgba(33, 33, 33, 0.50);
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.5px;
            margin-bottom: 16px;
          }
          
          .test-drive-button {
            .MuiButtonBase-root {
              box-shadow: none;
              border-radius: 12px;
              background: var(--mainColor);
              color: #ffffff;
              max-width: 213px;
              
              .MuiButton-label {
                font-weight: 500;
                line-height: 24px;
                display: flex;
                align-items: center;
              }
            }
          }
          
          img {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
            display: none;
          }
          
          @media screen and (min-width: 768px) {
            padding-right: 110px;
            img {
              display: block;
            }
          }
        }
        
        .IncludedWithCarAdsContent {
          .Ad1, .Ad2,.Ad3 {
            > div {
              border-radius: 8px;
              background: var(--mainColorBackground);

              p {
                letter-spacing: -0.24px;
              }
            }
          }
        }

        .VehicleDescriptionContent {
          @media (min-width: 1440px) {
            margin-bottom: 0;
          }
          
          .show-more-less-block {
            justify-content: flex-start;
            
            button {
              color: #212121;
              font-size: 18px;
              font-style: normal;
              font-family: Roboto;
              font-weight: 700;
              line-height: 24px;
              margin-top: 8px;
              text-underline-position: under;
            }
          }

          > div {
            @media (max-width: 1440px) {
              word-break: break-word;
            }
          }
        }
        
        .SalePrice {
          > div {
            p:last-child {
              border-left: 1px solid rgba(33, 33, 33, 0.5);
              padding-left: 5px;
              color: rgba(33, 33, 33, 0.5);
              line-height: 20px;
            }
          }
        }
        
        .VehicleSpecsOverview {
          margin-top: 24px;
          
          @media screen and (min-width: 1200px) {
            margin-top: 32px;
          }
          
          .Cylinders > div {
              border-bottom: none;
          }
          
          @media screen and (min-width: 768px) {
            .BodyType > div {
                border-bottom: none;
            }
          }
          
          .Title h2 {
            font-size: 20px;
          }
        }
        
        .FeatureOptionListTitle h2 {
          font-size: 20px;
        }
        
        .VehicleDescriptionLabel h2 {
          font-size: 20px;
        }
      `
    }
  }
}
