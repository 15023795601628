export default {
  TradePage: {
    default: {
      root: `
        .TradeIntro > div {
          padding: 32px 16px;
          
          @media (min-width: 768px) {
            padding: 40px;
          }
          
          @media (min-width: 1024px) {
            padding: 72px 40px;
          }
          
          .Text1 {
            .ExtendedTypography {
              color: var(--textWhite);
              font-family: Montserrat;
              font-size: 32px;
              font-style: normal;
              font-weight: 500;
              line-height: 120%;
              
              @media (min-width: 768px) {
                font-size: 40px;
              }
              
              @media (min-width: 1024px) {
                line-height: 56px;
              }
              
              @media (min-width: 1440px) {
                font-size: 48px;
              }
            }
          }
          
          .Text2 {
            .ExtendedTypography {
              color: var(--textWhite);
              text-align: center;
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0.15px;
              
              @media (min-width: 1024px) {
                font-size: 20px;
                line-height: 150%;
              }
            }
          }
          
          .Filler {
            color: var(--textWhite);
          }
        }
        
        .HowItWorks {
          > div {
            @media (min-width: 768px) {
              padding: 40px;
            }
            
            @media (min-width: 1024px) {
              .List > div {
                margin-left: 0;
              }
            }
            
            @media (min-width: 1280px) {
              padding: 64px 40px;
            }
          }
            
          .List {
            .InfoCard {
              .ExtendedImage {
                width: 72px;
                height: 72px;
              }
            }
          }
        }
        
        .PromoSection {
          background: var(--mainColorBackground);
          padding: 40px 16px;
          
          .Text1 h3 {
            color: var(--textBlack);
          }
          
          .Text2, .Text3, .Text4 {
            p {
              font-size: 20px;
              color: var(--textCaption);
            }
          }
          
          .Text2, .Text4 {
            padding-top: 16px;
          }
          
          @media (min-width: 768px) {
            padding: 64px 40px; 
            
            .Text3 {
              justify-self: end;
              padding-right: 8px;
            }
            
            .Text4 {
              padding-top: 0;
              justify-self: start;
            }
          }
        }
      `
    }
  }
}
