import type { FC } from 'react'

import type { IconProps } from 'website/components/types'

const ArrowRight: FC<IconProps> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g opacity='0.56'>
        <path d='M6.76562 22.4767L17.2384 12.004L6.76562 1.53125' stroke='#222429' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
      </g>
    </svg>
  )
}

export default ArrowRight
