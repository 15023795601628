const commonRootStyles = `
  width: calc(100% - 32px);
  margin: 0 16px;
  
  @media (min-width: 768px) {
    width: var(--pageBlockWidth);
    margin: 0 var(--pageOffset);
  }
`

const commonContentStyles = `
  display: grid;
  grid-row-gap: 16px;
  grid-column-gap: 8px;
  grid-template-columns: 1fr;

  .DealerInfoFragment {
    .phone-block {
      margin-bottom: 16px;
    }

    span {
      font-weight: 500;
    }
  }

  .Map {
    height: 224px;
    margin: 0px -16px;
    
    > div {
      border-radius: 0;
    }
  }
  
  .dealer-info-header {
    margin-bottom: 8px;
  }

  .DealerInfoSwitcher {
    .dealer-info-switcher-item {
      border: 1px solid transparent;

      &:hover {
        background: rgba(250, 250, 250, 1);

        svg path {
          fill: rgba(32, 195, 158, 1);
        }
      }
    }

    .active {
      border: 1px solid rgba(32, 195, 158, 1);

      svg path {
        fill: rgba(32, 195, 158, 1);
      }
    }
  }
  
  @media (min-width: 768px) {
    .Map {
      height: 411px;
      margin: 0px;
    }
  }
  
  @media (min-width: 1280px) {
    .Map {
      height: 400px;
    }
  }
`

export default {
  DealerInfo: {
    default: {
      root: `${commonRootStyles}`,
      content: `
        ${commonContentStyles}
        grid-template-columns: 1fr;
        grid-template-areas: 
          'Title'
          'DealerInfoFragment'
          'Schedule'
          'Map';

        @media (min-width: 1100px) {
          grid-row-gap: 24px;
          grid-template-columns: 1fr minmax(670px, 1fr);
          grid-template-areas:
            'Title Map'
            'DealerInfoFragment Map'
            'Schedule Map'
            '. Map';

          .Map > div {
            border-radius: 12px;
          }
        }
      `
    },
    multiDealership: {
      root: `${commonRootStyles}`,
      content: `
        ${commonContentStyles}
        grid-template-rows: min-content auto auto auto auto;
        grid-template-areas:
          'Title'
          'DealerInfoSwitcher'
          'DealerInfoFragment'
          'Schedule'
          'Map';

        .DealerInfoSwitcher {
          width: 100%;
          display: flex;
          flex-direction: column;

          .cf-select-display-container {
            border: 1px solid #DBDBDB;

            :hover {
              border: 1px solid var(--mainColor);
            }
          }

          .dealer-info-switcher-item {
            border-radius: 12px;
            height: 40px;
            padding: 8px 16px;
            margin-bottom: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;

            > span {
              font-weight: 500;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            svg {
              display: none;
              min-width: 24px;
            }

            &.active, :hover {
              background: #fff;
              cursor: pointer;

              svg {
                display: block;
              }
            }
            
            :hover:not(.active) {
              background: #FAFAFA;
            }
          }
        }

        @media (min-width: 768px) {
          grid-template-rows: min-content auto auto auto;
          grid-template-columns: 1fr 2fr;
          grid-column-gap: 24px;
          grid-template-areas:
            'Title               Title'
            'DealerInfoSwitcher  DealerInfoFragment'
            'DealerInfoSwitcher  Schedule'
            'Map                 Map';
        }

        @media (min-width: 1200px) {
          grid-row-gap: 24px;
          grid-template-columns: minmax(220px, 1fr) 1.5fr 2.5fr;
          grid-template-areas:
            'Title               Title               Map'
            'DealerInfoSwitcher  DealerInfoFragment  Map'
            'DealerInfoSwitcher  Schedule            Map'
            '.                   .                   Map';

          .Map > div {
            border-radius: 12px;
          }
        }
      `
    }
  }
}
