import { css } from '@emotion/css'

interface StylesProps {
  isReceived: boolean
}

enum FlexMessageLayout {
  Received = 'flex-start',
  Sent = 'flex-end'
}

enum BackgroundMessages {
  Received = 'var(--mainColorBackground)',
  Sent = '#FFF'
}

enum ColorMessages {
  Received = '#222429',
  Sent = '#222429'
}

export const createUseStyles = ({ isReceived }: StylesProps): string => {
  const justifyContent = isReceived ? FlexMessageLayout.Received : FlexMessageLayout.Sent
  const background = isReceived ? BackgroundMessages.Received : BackgroundMessages.Sent
  const color = isReceived ? ColorMessages.Received : ColorMessages.Sent

  return css`
    display: flex;
    justify-content: ${justifyContent};
    margin-bottom: 16px;
    width: 100%;
    min-height: min-content;
    
    .message-content {
      padding: 16px;
      width: max-content;
      background: ${background};
      border-radius: 12px 12px ${isReceived ? '12px 0px' : '0px 12px'};
      border: ${isReceived ? 'none' : '1px solid #DCDEE0'};
      
      .message {
        position: relative;
        word-break: break-word;

        span {
          font-size: 16px;
          line-height: 24px;
          text-align: left;
          color: ${color};
          font-weight: 400;
        }
        
        a {
          word-wrap: break-word;
          color: rgba(69, 143, 255, 1);
        }

        &.message-sending { min-height: 48px; }
        
        .sending-note {
          position: absolute;
          bottom: 0;
          right: 8px;
          color: white;
          font-size: 10px;
          line-height: 24px;
          color: rgba(255, 255, 255, .7);
        }
      }
    }
  `
}
