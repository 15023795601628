import { type FC } from 'react'
import { cnx } from '@carfluent/common'
import { DealerInfoFragmentProps } from 'website/components/types'
import SvgIcon from 'website/components/_base/SvgIcon'
import formatString from 'website/utils/formatString'
import { fullAddressShort } from 'website/utils/googleMap'
import { isStringEmpty } from 'website/utils/isStringEmpty'
import CLASS_NAME from './styles'
import { createGoogleMapsLink, createPhoneLink } from 'website/utils/links'

/**
 * DD-TODO: maybe simplify configs to just have icon type.
 * icon styles may be overriden in theme.
 */

const DealerInfoFragment: FC<DealerInfoFragmentProps> = ({
  addressData,
  addressConfig,
  className,
  phone,
  phoneConfig,
  title
}) => {
  const phoneFormatted = formatString(phone, phoneConfig?.format)
  const address = fullAddressShort(addressData)

  return (
    <div className={cnx('DealerInfoFragment', CLASS_NAME, className)}>
      {(title != null) && (
        <h3 className='title'>{title}</h3>
      )}

      {!isStringEmpty(phoneFormatted) && (
        <a
          href={createPhoneLink(phoneFormatted)}
          className='phone-block'
          target='_blank'
          rel='noopener noreferrer'
        >
          {(phoneConfig?.label != null) && (
            <span className='phone-label'>{phoneConfig?.label} </span>
          )}

          {(phoneConfig?.icon != null) && (
            <SvgIcon {...phoneConfig.icon} />
          )}

          <span className='phone-value'>{phoneFormatted}</span>
        </a>
      )}

      {!isStringEmpty(address) && (
        <a
          className='address-block'
          href={createGoogleMapsLink(address)}
          target='_blank'
          rel='noopener noreferrer'
        >
          {(addressConfig?.label != null) && (
            <span className='address-label'>{addressConfig.label}</span>
          )}

          {(addressConfig?.icon != null) && (
            <SvgIcon {...addressConfig.icon} />
          )}

          <span className='address-value'>{address}</span>
        </a>
      )}
    </div>
  )
}

export default DealerInfoFragment
