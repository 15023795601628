export default {
  InventoryPage: {
    default: {
      root: `
        & > div:first-child {
          @media (min-width: 1072px) {
            padding: 0 var(--pageOffset);
          }
        }
        
        .PageTitle {
          background: #FFF;
          padding: 16px;
          
          @media (min-width: 768px) {
            padding: 16px 40px;
          }
          
          @media (min-width: 1072px) {
            background: unset;
            padding: 16px 0 4px;
          }
          
          .ExtendedTypography {
            color: var(--textBlack);
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 140%;
            
            @media (min-width: 768px) {
              font-size: 32px;
            }
          }
        }
        
        .FiltersTopBar {
          background: #fff;
          border-radius: 12px;
          padding: 0 16px 16px;
          
          @media (min-width: 768px) {
            padding: 0px 40px 16px;
          }
          
          @media (min-width: 1072px) {
            padding: 8px;
          }
          
          .g-input-search {
            .cf-input-container:not(.with-error):not(.disabled) {
              border-color: #DBDBDB;
              
              :hover {
                border-color: var(--mainColor);
              }
            }
          }
        }
        
        .VehicleInfiniteScroll {
          padding: 16px 16px 48px;
          
          @media (min-width: 768px) {
            padding: 16px 40px 48px;
          }
          
          @media (min-width: 1072px) {
            padding: 0;
          }
          
          .infinite-scroll-component {
            overflow: hidden !important;
          
            .list-with-content {
              margin-bottom: 0;
            }
          }
        }

        @media (max-width: 700px) {
          .VehiclesFilterChips {
            padding: 8px 16px;
          }
        }

        @media (max-width: 1072px) {
          .VehiclesFilterChips {
            padding: 16px 40px;
          }
        }
      `
    }
  }
}
