import { type FC } from 'react'
import { type IconProps } from 'website/components/types'

const IconSVGCheckEllipse: FC<IconProps> = ({
  fill = 'var(--mainColor)',
  height = 32,
  width = 33
}) => {
  return (
    <svg width={width} height={height} viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M32.3074 15.884C32.3074 24.6095 25.2347 31.6918 16.4996 31.6918C7.77408 31.6918 0.691803 24.6095 0.691803 15.884C0.691803 7.14884 7.77408 0.0761719 16.4996 0.0761719C25.2347 0.0761719 32.3074 7.14884 32.3074 15.884Z' fill='white' />
      <path d='M16.5 32C7.67838 32 0.5 24.8216 0.5 16C0.5 7.17838 7.67838 0 16.5 0C17.9222 0 19.3348 0.182583 20.6994 0.557357L20.6033 0.932132C19.2772 0.576577 17.8934 0.393994 16.5096 0.393994C7.88979 0.384384 0.884384 7.38979 0.884384 16C0.884384 24.6102 7.88979 31.6156 16.5 31.6156C25.1102 31.6156 32.1156 24.6102 32.1156 16C32.1156 14.0108 31.7505 12.0697 31.0201 10.2342L31.3757 10.0901C32.1252 11.964 32.5 13.9532 32.5 15.9904C32.5 24.8216 25.3216 32 16.5 32Z' fill='black' />
      <path d='M13.0788 20.8432L8.53341 16.2978L7.01509 17.8161L13.0691 23.8702L26.0613 10.8972L24.543 9.37891L13.0788 20.8432Z' fill={fill} />
    </svg>
  )
}

export default IconSVGCheckEllipse
