import { type CssTheme } from 'website/components/types'

import TypographyTheme from './base/Typography'
import ButtonTheme from './base/Button'
import DealerInfoThemes from './DealerInfo'
import FooterInfoThemes from './FooterInfo'
import ScheduleThemes from './Schedule'
import SocialsThemes from './Socials'
import DealerLocationsPopoverThemes from './DealerLocationsPopover'
import VehiclesFilterThemes from './VehiclesFilter'
import VehiclesSortingThemes from './VehiclesSorting'
import VehiclesFilterChipsThemes from './VehiclesFilterChips'
import VehicleCardThemes from './VehicleCard'
import VehicleCardSkeletonThemes from './VehicleCardSkeleton'
import InventoryPageThemes from './InventoryPage'
import VehicleBriefInfo from './VehicleBriefInfo'
import Header from './Header'
import PrequalifiedFormSSNIncome from './PreqialifiedFormSSNIcome'
import InfoLink from './InfoLink'
import UnsubscribePage from './UnsubscribePage'
import UnsubscribedPage from './UnsubscribedPage'
import DealershipLocationInfo from './DealershipLocationInfo'
import DealershipLocations from './DealershipLocations'
import DealerLocationsMenu from './DealerLocationsMenu'
import HomePage from './HomePage'
import VehicleViewPage from './VehicleViewPage'
import TradePage from './TradePage'
import FinancingPage from './FinancingPage'

/**
 * DD-TODO:
 * - split theme file into multiple files
 * - add global common style presets as 'horizontallyScrollable' and 'defaultButtonTextConfig'
 * and reuse it where those are duplicated
 */

const horizontallyScrollable = `
  overflow-x: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

/**
 * DD-NOTE: as we migrate from JSS style engine and yet do not migrate to MUI v.5
 * it is simpler just to import theme directly where we need it.
 */
const cssTheme: CssTheme = {
  typography: TypographyTheme,

  link: {
    'text-decoration': 'none'
  },

  ...ButtonTheme,
  ...VehicleCardThemes,
  ...DealerInfoThemes,
  ...FooterInfoThemes,
  ...ScheduleThemes,
  ...SocialsThemes,
  ...DealerLocationsPopoverThemes,
  ...VehiclesFilterThemes,
  ...VehiclesSortingThemes,
  ...VehiclesFilterChipsThemes,
  ...InventoryPageThemes,
  ...VehicleCardSkeletonThemes,
  ...VehicleBriefInfo,
  ...Header,
  ...PrequalifiedFormSSNIncome,
  ...InfoLink,
  ...UnsubscribePage,
  ...UnsubscribedPage,
  ...DealershipLocationInfo,
  ...DealershipLocations,
  ...DealerLocationsMenu,
  ...HomePage,
  ...VehicleViewPage,
  ...TradePage,
  ...FinancingPage,

  // ============ COMPONENTS ============= /
  GeneralLayout: {
    default: {
      content: `
        background-color: var(--mainBackgroundColor);
        font-family: Roboto, sans-serif;

        p {
          margin: 0px;
        }

        h1 {
          ${TypographyTheme.h1}
        }
        
        h3 {
          ${TypographyTheme.h3}
        }

        p.small-text {
          ${TypographyTheme.default}
          ${TypographyTheme.smallText}
        }

        .MuiTypography-h1 {
          font-family: Roboto, sans-serif;
          font-size: 24px;
          font-weight: 700;
          line-height: 1.5;

          @media (min-width: 376px) {
            font-size: 32px;
          }

          @media (min-width: 767px) {
            font-size: 44px;
          }
        }

        .MuiTypography-h2 {
          font-family: Roboto, sans-serif;
          font-size: 18px;
          font-weight: 700;
          line-height: 1.5;

          @media (min-width: 376px) {
            font-size: 20px;
          }
          
          @media (min-width: 767px) {
            font-size: 24px;
          }
        }

        .MuiTypography-subtitile1 {
          font-family: Roboto, sans-serif;
          font-size: 16px;
          font-weight: 700;
          line-height: 1.5;

          @media (min-width: 767px) {
            font-size: 18px;
          }
        }
      `
    }
  },
  FiltersDrawerToggler: {
    default: {
      root: `
        &.cf-button-root.cf-button-variant-text {
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;

          .cf-button-start-adornment {
            padding-right: 4px;
            height: 20px;

            * {
              width: unset;
            }
          }

          &, :hover {
            background-color: transparent;
          }

          .cf-button-content {
            font-size: 16px;
            font-weight: 500;
          }
        }
      `
    }
  },
  CarAppraisalForm: {
    default: {
      root: `
        background: #fff;
        padding: 40px;
        width: 100%;
        box-sizing: border-box;
        border-radius: 12px;
        height: min-content;
      `,
      content: `
        display: grid;
        justify-items: center;
        grid-template-rows: 36px 32px auto;
        grid-template-areas:
          "FormControls"
          "."
          "Form"
        ;

        .form-controls > div {
          border-radius: 0px;
          border-bottom: 4px solid #F3F3F3;

          &.active, &:hover {
            border-bottom: 4px solid var(--mainColor);

            & button {
              color: var(--mainColor);
            }
          }
        }
      `
    }
  },

  DrawerMenu: {
    default: {
      root: `
        color: #000;
      `
    }
  },
  HowItWorks: {
    default: {
      root: `
        padding: 0;
        
        > div {
          padding: 40px 16px;
          
          @media (min-width: 768px) {
            padding: 64px 40px;
          }
        }
        
        .Text1 .ExtendedTypography {
          color: var(--textBlack);
        }
        
        .Text2 .ExtendedTypography {
          color: var(--textCaption);
          font-size: 20px;
          line-height: 150%;
        }
        
        .List {
          > div {
            gap: 16px;
          }
          
          .ListItem {
            margin: 0;
          }
        }
      `
    }
  },
  InfoCard: {
    default: {
      root: `
        display: flex;
        box-sizing: border-box;
        min-height: 276px;
        border: 1px solid #ECF1EF;
        border-radius: 20px;
        padding: 32px;
        max-width: 100%;

        @media (min-width: 768px) {
          height: 310px;
          max-width: 336px;
        }

        @media (min-width: 1024px) {
          height: 284px;
          max-width: 304px;
        }

        @media (min-width: 1280px) {
          height: 236px;
          max-width: 389px;
        }
        
        @media (min-width: 1440px) {
          height: 254px;
          max-width: 441px;
        }
      `,
      content: `
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        
        .Title {
          margin-top: 8px;
        }
        
        .Title, .Subtitle, .Text {
          text-align: center;
        }
        
        .Title .ExtendedTypography {
          color: var(--textBlack);
          font-family: Montserrat;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 33.6px */
        }
        
        .Text .ExtendedTypography {
          color: #333;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
        }
        
        @media (min-width: 1024px) {
          gap: 8px;
          
          .Title {
            margin-top: 16px;
          }
          
          .Title .ExtendedTypography {
            font-size: 20px;
          }
          
          .Text .ExtendedTypography {
            font-size: 14px;
            line-height: 140%;
          }
        }
        
        @media (min-width: 1440px) {
          gap: 16px;
          
          .Title {
            margin-top: 8px;
          }
          
          .Title .ExtendedTypography {
            font-size: 24px;
          }
          
          .Text .ExtendedTypography {
            font-size: 16px;
          }
        }
      `
    }
  },

  RecommendedVehicles: {
    default: {
      root: `
        overflow-x: hidden;
        z-index: 2;

        .cf-loader {
          grid-column: 1 / 5;
          min-height: 500px;
        }

        &.vehicle-details-view {
          padding-top: 32px;
        }

        .component-container {
          display: grid;
          grid-gap: 16px;
          position: relative;
          grid-template-columns: 1fr;
          grid-template-areas: 
            "Text"
            "RecommendedVehiclesList"
            "Button";

          .Button {
            margin: 0 auto;
            min-width: 90px;
            
            button {
              max-height: 32px;
              min-height: 32px;
              padding: 6px 8px;
            }
          }
          
          > .Text {
            margin: 0px 16px;
            
            @media (min-width: 768px) {
              margin-left: var(--pageOffset);
            }
          }
          
          @media (min-width: 768px) {
            grid-template-areas: 
              "Text                      Button                 "
              "RecommendedVehiclesList   RecommendedVehiclesList";
            grid-gap: 24px;
            
            .Button {
              margin-right: var(--pageOffset);
            }
          }

          @media (min-width: 1200px) {
            grid-gap: 32px;

            .cf-list-scroll-horz-btn {
              display: flex;

              &.right {
                margin-right: var(--pageOffset);
              }

              &.left {
                margin-left: var(--pageOffset);
              }
            }
          }
        }
      `,
      content: `
        grid-area: RecommendedVehiclesList;
        position: relative;
        padding: 0 16px;

        ${horizontallyScrollable}

        grid-column: 1 / 3;
        display: grid;
        grid-template-columns: repeat(4, 328px);
        grid-column-gap: 16px;
        align-items: center;

        @media (min-width: 768px) {
          padding: 0px var(--pageOffset);
        }
      `
    },

    vehicleInfo: {
      root: `
        width:100%;
        background: var(--mainBackgroundColor);
        overflow: hidden;
        margin-left: min(-16px, calc(-1 * (100vw - 343px) / 2));
        margin-right: min(-16px, calc(-1 * (100vw - 343px) / 2));

        @media (min-width: 768px) {
          margin-left: min(-40px, calc(-1 * (100vw - 1360px) / 2));
          margin-right: min(-40px, calc(-1 * (100vw - 1360px) / 2));
        }
      `,
      content: horizontallyScrollable
    }
  },

  NotFound: {
    default: {
      root: `
        & .Button.back-btn {
          min-width: 290px;
        }
      `
    }
  },

  LazyImage: {
    default: {
      root: `
        flex: 1;
        max-width: 100%;
        max-height: 100%;
        position: relative;

        &.Placeholder {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 24px;

          & img {
            max-height: 72px;
            max-width: 216px;
          }
        }

        & > [class*=Skeleton] {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          transform: unset;
        }
      `
    }
  },

  VehiclesSearch: {
    default: {
      content: `
        .cf-input-container {
          border: 1px solid #DBDBDB;
          background: #fff;
          border-radius: 12px;
          color: rgba(33, 33, 33, 0.8);
        }
      `
    },
    medium: {
      content: `
        & .MuiTextField-root {
          max-height: 36px;
          min-height: 36px;
        }
      `
    }
  },

  AboutUsPromos: {
    default: {
      root: `
        background-color: var(--mainBackgroundColor);
      `
    }
  },

  AboutUsPage: {
    default: {
      root: `
        .AboutUsPromos, .DealerInfo {
          background-color: #fff;
        }
      `
    }
  },

  Footer: {
    default: {
      root: `
        z-index:2;
        background-color: #fff;
        
        @media(min-width: 768px) {
          .DealerInfoFragment {
            display: flex;
            align-items: center;
            
            a.phone-block {
              margin: 0;
              margin-right: 16px;
            }
          }
        }
        
        @media (min-width: 1280px) {
          .FooterNavigation > div {
            justify-content: end;
          }
        }
        
        .Text, .ListItem {
          .ExtendedTypography {
            font-size: 16px;
          }
        }
        
        .ListItem .ExtendedTypography {
          color: rgba(0, 0, 0, 0.80);
        }
      `
    }
  },

  GalleryVehicleView: {
    default: {
      root: `
        height: 100%;
      `,
      content: `
        display: flex;
        flex-direction: column;
        margin: 0 auto;
      `
    }
  },

  GetPrequalifiedComponent: {
    default: {
      root: `
        &.financing-get-prequalified {
            .MuiButtonBase-root {
              background: var(--mainOutlinedButtonColor);
              max-height: 56px;
              height: 56px;

              :hover {
                background: var(--mainOutlinedButtonColor);
              }
            }
        }

        .GetPrequalifiedButton {
          max-width: 225px;
          
          button {
            font-weight: 400;
          }
        }
      `
    }
  },

  TradeSellIntro: {
    default: {
      content: `
        & .Text1 p, & .Text1 span, & .Text2 p, & .Text2 span {
          text-align: center;

          @media (min-width: 1200px) {
            text-align: left;
          }
        }
      `
    }
  },

  RequestHelpOrDrive: {
    default: {
      root: `
        .RequestHelpOrDriveLink {
          display: flex;
          align-items: center;
          padding: 12px;
          box-sizing: border-box;
          border: 1px solid rgba(0, 0, 0, 0.12);
          border-radius: 12px;

          & .MuiButton-root {
            min-width: 40px;
          }

          & .MuiButton-label {
            padding-left: 8px;
            font-weight: 500;
          }
        }
      `
    }
  },

  VehicleServiceRequestModalForm: {
    default: {
      root: `
      --form-row-gap: 28px;

      & {
        padding-top: 32px;
        display: grid;
        justify-content: center;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 12px;
        grid-row-gap: var(--form-row-gap);

        @media (max-width: 680px) {
          padding-top: 0;
        }

        & .form-field {
          @media (max-width: 680px) {
            grid-column-start: 1;
            grid-column-end: span 2;
          }
        }

        & .form-field.Comments {
          grid-column-start: 1;
          grid-column-end: span 2;

          & .MuiFormControl-root {
            max-height: 102px;
          }

          & .MuiInputBase-root.MuiFilledInput-root {
            min-height: unset;
            max-height: unset;
            background: #fafafa;
          }
        }

        & .disclosure-block {
          grid-column-start: 1;
          grid-column-end: span 2;
          
          a, p {
            color: rgba(33, 33, 33, 0.50);
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.25px;
          }
        }
        
        & .form-button {
          margin-top: calc(48px - var(--form-row-gap));

          @media (max-width: 680px) {
            margin-top: calc(66px - var(--form-row-gap));
          }
          
          @media (max-width: 480px) {
            margin-top: 0;
            margin-bottom: var(--form-row-gap);
          }

          & .MuiButtonBase-root {
            height: 56px;
            min-height: 56px;
            border-radius: 12px;
            box-shadow: none;
          }
        }
      }
    `
    }
  }
}

export default cssTheme
