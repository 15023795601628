import dotsGrid from 'website/assets/dots-grid.svg'
import waves from 'website/assets/waves.svg'

export default {
  HomePage: {
    default: {
      root: `
        --mainBackgroundColor: #fff;

        background: var(--mainBackgroundColor);
        
        .CarBodyStylePanel {
          padding: 0 16px;
          
          @media (min-width: 768px) {
            padding: 0 40px;
          }
          
          @media (min-width: 1440px) {
            padding: 0px var(--pageOffset);
          }
        }

        .TradeSellBlock {
          border-radius: 12px;
          background-color: var(--mainColorHover);
          
          max-height: 550px;
          width: calc(100% - 32px);
          margin: 0 16px;
          
          > div {
            align-items: center;
            padding: 24px 24px 0;
            
            .Button {
              // width: 295px;
              width: 100%;
            }

            .ExtendedImage {
              height: 100%;
              min-height: 268px;
              
              > div {
                background-size: contain !important;
                background-position: center bottom !important;
              }
            }
            
            .TitleWithDescriptions > div {
              text-align: center;
              justify-items: center;
            }
          }
          
          @media (min-width: 768px) {
            height: 581px;
            margin: 0 var(--pageOffset);
            width: var(--pageBlockWidth);
            
            > div {
              padding: 42px 55px 0;
              
              .Button {
                width: 216px;
              }
            } 
          }

          @media (min-width: 1024px) {
            height: 368px;
            
            > div {
              padding: 32px 40px 0;
            
              .TitleWithDescriptions > div {
                text-align: left;
                justify-items: flex-start;
              }
              
              .Text1 h3 {
                line-height: 140%;
              }
              
              .Text2 {
                letter-spacing: 0.15px;
              }
            }
          }
          
          @media (min-width: 1280px) {
            > div {
              padding: 32px 101px 0 86px;
            }
          }
          
          @media (min-width: 1440px) {
            > div {
              padding: 32px 162px 0 184px;
            }
          }
        }

        .CarsUnderPrice {
          margin: 0 var(--pageOffset);
          width: var(--pageBlockWidth);

          .carCategoryCard {
            cursor: pointer;
            color: white;
            padding: 16px;
            height: 188px; /* 220 - 32 */
            display: flex;
            align-items: end;
            position: relative;
            flex-direction: row;
            border-radius: 12px;
            overflow: hidden;
            background-size: cover;
            background-position: center;
            justify-content: space-between;

            &:before {
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.70) 100%);
            }

            &:hover{
              &:before {
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.85) 100%);
              }

              .category-arrow-icon {
                background: rgba(255, 255, 255, 1);

                path {
                  fill: rgba(32, 195, 158, 1);
                }
              }
            }

            .category-text {
              font-size: 24px;
              line-height: 33px;
              font-family: Montserrat;

              h3 {
                font-size: 24px;
                font-weight: 500;
              }

              p {
                font-size: 24px;
                font-weight: 700;
              }
            }

            .category-text,
            .category-arrow-icon {
              z-index: 1;
              position: relative;
            }

            .category-arrow-icon {
              width: 40px;
              height: 40px;
              cursor: pointer;
              border-radius: 50%;
              align-items: center;
              display: inline-flex;
              justify-content: center;
              border: 1px solid rgba(255, 255, 255, 1);
            }
          }

          @media (max-width: 375px) {
            margin: 0 16px;
            width: calc(100% - 32px);
          }
        }

        .AboutUsPromos {
          > div {
            padding: 0 var(--pageOffset);

            @media (max-width: 375px) {
              padding: 0 16px;
            }
          }

          .Block1 {
            overflow: hidden;
            position: relative;

            .Button {
              width: 216px;
            }

            .ExtendedImage {
              width: 328px;
              height: 463px;
              margin: 48px 0;
            }

            &:before {
              content: '';
              top: 0;
              left: 0;
              width: 9%;
              height: 24%;
              position: absolute;
              background-size: cover;
              background-position: right;
              background-image: url(${dotsGrid});

              @media (max-width: 690px) {
                display: none
              }
            }

            &:after {
              content: '';
              right: 0;
              bottom: 27px;
              width: 10%;
              height: 119px;
              position: absolute;
              background-image: url(${waves});

              @media (max-width: 690px) {
                display: none
              }
            }

            .Descriptions {
              @media (max-width: 690px) {
                padding: 24px;
              }

              > div {
                display: flex;
                flex-direction: column;
                justify-content: center;
  
                @media (max-width: 899px) {
                  width: 480px;
                  padding: 56px 0;
                }
  
                @media (max-width: 690px) {
                  width: auto;
                  padding: 0;
                  text-align: center;
                  align-items: center;
                }
              }

              button {
                box-shadow: none;
              }
            }
          }

          .PopularCarsPanel,
          .Block2 {
            @media (max-width: 375px) {
              display: none
            }
          }

          .PopularCarsPanel {
            .makes-item:hover {
              background-color: var(--mainColorHover);
            }
            
            .makes-title {
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              font-family: Roboto;
              letter-spacing: 0.4px;
              text-align: center;
            }
          }
        }

        .popular-cars-section-header {
          margin-bottom: 32px;
          font-family: Montserrat;
        }

        .makes-container {
          display: grid;
          gap: 16px 24px;
          cursor: pointer;
          grid-template-columns: repeat(5, 1fr);

          @media (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
          }

          .makes-item {
            padding: 8px;
            display: flex;
            align-items: center;
            border-radius: 12px;
            flex-direction: column;

            img {
              margin-bottom: 4px;
            }

            &:hover {
              background: rgba(250, 250, 250, 1);
            }
          }
        }
      `
    }
  }
}
