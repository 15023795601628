import type { FC } from 'react'
import { cnx, formatCurrency, formatNumber, Loader } from '@carfluent/common'

import LazyImage from 'website/components/LazyImage'

import SvgIcon from 'website/components/_base/SvgIcon'
import { VehicleCardProps, SupportedComponents } from 'website/components/types'
import { useComponentStyles } from 'website/styles/useComponentStyles'
import useVehicleCard from './hook'
import IconSVG from 'website/components/icons'

const MILES_FORMAT_CONFIG = { mantissa: 0 }

const VehicleCard: FC<VehicleCardProps> = ({
  emptyImage,
  isMonthlyPaymentLoading = false,
  vehicle,
  variant,
  ...hookProps
}) => {
  const componentStylesCls = useComponentStyles(SupportedComponents.VehicleCard, variant)
  const {
    engineStr,
    isDisabledLink,
    isPlaceholderImg,
    imgSrc,
    onCardClick,
    paymentInfo,
    price,
    tooltip
  } = useVehicleCard({
    ...hookProps,
    emptyImage,
    vehicle
  })

  if (vehicle == null) {
    return null
  }

  return (
    <div className={componentStylesCls.root}>
      <div
        className={cnx(
          'card-wrapper',
          isDisabledLink && 'card-disabled',
          isPlaceholderImg && 'card-placeholder'
        )}
        onClick={onCardClick}
      >
        <div className='card-img-wrapper'>
          <LazyImage src={imgSrc} lazyLoad={false} />

          {isPlaceholderImg && (
            <div className='empty-img-subtitle'>
              Preparing for a close up <br />

              <span>Photos coming soon</span>
            </div>
          )}

          {(vehicle.dealershipCity != null || vehicle.distanceToDealership != null) && (
            <div className='card-distance-info'>
              <SvgIcon type='map' />
              <span>{vehicle.dealershipCity ?? ''}</span>

              {(vehicle.distanceToDealership != null) && (
                <span>&nbsp;({vehicle.distanceToDealership.toFixed(1)} mi away)</span>
              )}
            </div>
          )}
        </div>

        <div className='content-wrapper'>
          <div className='car-name-block'>
            <p className='car-name'>{vehicle.year} {vehicle.make} {vehicle.model}</p>
            <p className='car-trim'>{vehicle.trim ?? '-'}</p>
          </div>

          <div className='car-info-miles car-info-badge'>
            <IconSVG.Tachometer/>
            <p>{formatNumber(vehicle.mileage ?? 0, MILES_FORMAT_CONFIG)} mi</p>
          </div>

          <div className='car-info-engine car-info-badge'>
            <IconSVG.Motor />
            <p>{engineStr}</p>
          </div>

          <div className='price-info'>
            <p>{price}</p>

            <div className={cnx('price-details', isMonthlyPaymentLoading && 'is-loading')}>
              {isMonthlyPaymentLoading && (
                <Loader color='dark' />
              )}

              {(paymentInfo != null) && !isMonthlyPaymentLoading && (
                <>
                  <p className='price-per-month'>
                    Est. {formatCurrency(paymentInfo.monthlyPayment)}/mo

                    {tooltip != null && (
                      <div className='prequalify-tooltip'>
                        {tooltip}
                      </div>
                    )}
                  </p>

                  <p className='cash-down'>
                    Cash down {formatCurrency(paymentInfo.cashDown)}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VehicleCard
