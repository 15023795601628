import { type FC } from 'react'
import { cnx } from '@carfluent/common'

import { type ExtendedIconSVGProps, SupportedComponents } from 'website/components/types'

import IconSVG from '../icons'

const ExtendedIconSVG: FC<ExtendedIconSVGProps> = ({
  className,
  nameInLayout,
  componentProps
}) => {
  const SelectedIcon = IconSVG[componentProps.iconName as keyof typeof IconSVG]

  return (
    <div className={cnx(nameInLayout ?? SupportedComponents.ExtendedImage, className)}>
      <SelectedIcon />
    </div>
  )
}

export default ExtendedIconSVG
