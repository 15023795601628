import { type BusinessHours } from 'website/api/schedule.types'

interface OpeningHoursSpecification {
  '@type': 'OpeningHoursSpecification'
  dayOfWeek: string
  opens: string | null
  closes: string | null
}

// Helper function to format 12-hour time into 24-hour time (e.g., "09:00 AM" -> "09:00")
const formatTime = (time: string | null): string | null => {
  if (time === null || time.trim() === '') return null

  const [hours, minutes] = time.split(':')
  const period = time.slice(-2)
  let formattedHours = parseInt(hours, 10)

  if (period === 'PM' && formattedHours !== 12) {
    formattedHours += 12
  } else if (period === 'AM' && formattedHours === 12) {
    formattedHours = 0
  }

  return `${String(formattedHours).padStart(2, '0')}:${minutes.slice(0, 2)}`
}

// Convert the data into an openingHoursSpecification array
export const generateOpeningHoursSpecification = (businessHours: BusinessHours): OpeningHoursSpecification[] => {
  const days = [
    { dayOfWeek: 'Monday', open: businessHours.mondayOpeningTime, close: businessHours.mondayClosingTime },
    { dayOfWeek: 'Tuesday', open: businessHours.tuesdayOpeningTime, close: businessHours.tuesdayClosingTime },
    { dayOfWeek: 'Wednesday', open: businessHours.wednesdayOpeningTime, close: businessHours.wednesdayClosingTime },
    { dayOfWeek: 'Thursday', open: businessHours.thursdayOpeningTime, close: businessHours.thursdayClosingTime },
    { dayOfWeek: 'Friday', open: businessHours.fridayOpeningTime, close: businessHours.fridayClosingTime },
    { dayOfWeek: 'Saturday', open: businessHours.saturdayOpeningTime, close: businessHours.saturdayClosingTime },
    { dayOfWeek: 'Sunday', open: businessHours.sundayOpeningTime, close: businessHours.sundayClosingTime }
  ]

  // Generate the final openingHoursSpecification array
  return days
    .filter(({ open, close }) => (open !== null && open.trim() !== '') && (close !== null && close.trim() !== ''))
    .map(({ dayOfWeek, open, close }) => ({
      '@type': 'OpeningHoursSpecification',
      dayOfWeek,
      opens: formatTime(open),
      closes: formatTime(close)
    }))
}
