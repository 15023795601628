import { type FC, type ReactNode, useCallback } from 'react'
import { Button, cnx } from '@carfluent/common'
import { useNavigate } from 'react-router-dom'

import MakeModelYearForm from 'website/components/MakeModelYearForm'

import SharedStateHook, { StoreBranches } from 'website/store'
import { FormValues } from 'website/components/MakeModelYearForm/hook/validator'
import { TradeInSubRoutes } from 'website/routing/constants'
import translate from 'website/utils/translate'

import { configureStoreData } from './config'
import CLASS_NAME from './styles'

const useSharedState = SharedStateHook<Store.TradeInVehicleState>(StoreBranches.TradeInVehicle)

export interface MakeModelProps {
  states: {
    tradeInVehicle: string
  }
}

const MakeModel: FC<MakeModelProps> = (props) => {
  const navigate = useNavigate()
  const [tradeInVehicle, setTradeInVehicle] = useSharedState(props.states.tradeInVehicle)
  const onSubmit = useCallback((values: FormValues) => {
    setTradeInVehicle({
      ...tradeInVehicle,
      ...configureStoreData(values),
      vin: '',
      lastUpdateTs: Date.now()
    })
    navigate(TradeInSubRoutes.Car)
  }, [tradeInVehicle, navigate])

  const renderSubmitButton = (onSubmit: FormSubmit, isValid: boolean): ReactNode => {
    return (
      <Button
        isDisabled={!isValid}
        className='cf-submit-button'
        onClick={onSubmit}
      >
        {translate('GET MY OFFER')}
      </Button>
    )
  }

  return (
    <div className={cnx(CLASS_NAME, 'make-model-year-form')}>
      <MakeModelYearForm
        onSubmit={onSubmit}
        renderSubmitButton={renderSubmitButton}
      />
    </div>
  )
}

export default MakeModel
