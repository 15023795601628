import { useRef, type FC } from 'react'

import ScrollHorizontalButtons from '../RecommendedVehicles/components/ScrollHorizontalButtons'

import type { CarUnderPriceListProps } from '../types'
import CarCategoryCard from '../CarCategoryCard'

import CLASS_NAME from './styles'
import { useComponentStyles } from 'website/styles/useComponentStyles'
import { cnx } from '@carfluent/common'

const ARROW_BREAKPOINTS: [number, number] = [0, 1024]
const SCROLL_STEP = 300

const CarUnderPriceList: FC<CarUnderPriceListProps> = ({ nameInLayout, variant, items }) => {
  const componentStylesCls = useComponentStyles(nameInLayout, variant)

  const refList = useRef<HTMLDivElement>(null)
  return (
    <div className={cnx(CLASS_NAME, componentStylesCls.root, nameInLayout)}>
      <div className={componentStylesCls.content}>
        <div className='title-wrapper'>
          <p className='title'>Get More for Less</p>

          <ScrollHorizontalButtons
            isVisible
            refList={refList}
            scrollStep={SCROLL_STEP}
            type='secondary'
            breakpoints={ARROW_BREAKPOINTS}
          />
        </div>

        <div className='list-wrapper' ref={refList}>
          {items.map((item, i) => (
            <div key={i}>
              <CarCategoryCard {...item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CarUnderPriceList
