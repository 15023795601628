import { useState, type FC } from 'react'
import { cnx } from '@carfluent/common'
import { useNavigate } from 'react-router-dom'

import SharedStateHook, { StoreBranches, defaultInstance } from 'website/store'
import { type DealerLocationsMenuProps, SupportedComponents } from 'website/components/types'

import ArrowDownIcon from '../_base/SvgIcon/ArrowDownIcon'
import { useComponentStyles } from 'website/styles/useComponentStyles'

const COMPONENT_NAME = SupportedComponents.DealerLocationsMenu
const useSharedState = SharedStateHook<Store.Dealership>(StoreBranches.Dealership)

const DealerLocationsMenu: FC<DealerLocationsMenuProps> = ({
  onClick,
  variant,
  config,
  isFooterContainer = false,
  isHeaderContainer = false,
  shouldUseListView = false
}) => {
  const [dealerInfo] = useSharedState(defaultInstance(StoreBranches.Dealership))
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const navigate = useNavigate()
  const componentStyles = useComponentStyles(COMPONENT_NAME, variant)
  const hasMultipleLocations = dealerInfo.dealerships.length > 1

  const handleDealerClick = (to: string, event: React.MouseEvent): void => {
    event.stopPropagation()
    onClick?.()
    navigate(to)
    setDropdownVisible(false)
  }

  const toggleDropdown = (visible: boolean): void => {
    setDropdownVisible(visible)
  }

  if (isFooterContainer && dealerInfo.dealerships.length <= 1) {
    return null
  }

  return (
    (hasMultipleLocations || shouldUseListView)
      ? (
        <div
          className={componentStyles.content}
          onClick={(event) => handleDealerClick('/locations', event)}
          onMouseEnter={isHeaderContainer ? () => toggleDropdown(true) : undefined}
          onMouseLeave={isHeaderContainer ? () => toggleDropdown(false) : undefined}
        >
          <span style={{ color: config?.color, fontWeight: config?.fontWeight }} className='locations-link'>
            Location{hasMultipleLocations ? 's' : ''}

            {isHeaderContainer && (
              <ArrowDownIcon
                className={cnx('arrow-icon', (isDropdownVisible) && 'rotated')}
                fill={config?.color ?? '#101010'}
              />
            )}
          </span>

          <ul className={cnx('dropdown-menu', isDropdownVisible && 'visible', isFooterContainer && 'hidden')}>
            {dealerInfo.dealerships.map((dealer) => (
              <li key={dealer.id} onClick={(event) => handleDealerClick(`/locations/${dealer.id}`, event)}>
                {dealer.dealerName}
              </li>
            ))}
          </ul>
        </div>
        )
      : (
        <div
          className={cnx(componentStyles.content, 'location-item-container', 'single-location')}
          onClick={(event) => handleDealerClick(`/locations/${dealerInfo.dealerships[0].id}`, event)}
          onMouseEnter={isHeaderContainer ? () => toggleDropdown(true) : undefined}
          onMouseLeave={isHeaderContainer ? () => toggleDropdown(false) : undefined}
        >
          <span style={{ color: config?.color, fontWeight: config?.fontWeight }}>
            Location
          </span>
        </div>
        )
  )
}

export default DealerLocationsMenu
