import { css } from '@emotion/css'

export default css(`
  margin-top: 32px;
  
  .sub-option-checkbox {
    margin-bottom: 20px;
    
    :last-child {
      margin-bottom: 0;
    }
  }
`)