export default {
  InfoLink: {
    default: {
      root: `
        --backgroundColor: hsl(from var(--mainColor) h s l / 0.1);
        
        border-radius: 12px;
        background: var(--backgroundColor);
        padding: 16px;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        :hover {
          .link-handler-wrapper {
            .link-handler {
              path {
                fill: #fff;
              }
  
              .hover-rect {
                fill: var(--mainColor);
              }
            }
          }
        }

        .carfax-icon {
          margin-bottom: 12px;

          img, svg {
            width: 100px;
            height: auto;
          }
        }

        .link-title {
          font-family: Roboto;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.4px;
          margin-bottom: 4px;
        }
        
        .link-description {
          font-family: Roboto;
          font-size: 14px;
          line-height: 20px;
          color: rgba(33, 33, 33, 0.50);
          letter-spacing: 0.25px;
          margin-bottom: 8px;
        }
        
        .link-handler-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: auto;
          
          .link-action-title {
            color: var(--mainColor);
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
          }
          
          .link-handler {
            cursor: pointer;
            .rect-border {
              stroke: rgba(32, 195, 158, 0.10);
            }

            .hover-rect {
              fill: transparent;
              fill-opacity: 1;
            }
            
            path {
              fill: var(--mainColor);
            }
          }
        }
      `
    }
  }
}
