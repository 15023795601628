import { css } from '@emotion/css'

export default css(`
  .cf-action-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    
    .cf-continue-btn {
      width: 100%;
      margin-bottom: 40px;
    }
  
    .cf-back-btn {
      width: 65px;
      margin: 0 auto;
      text-decoration: underline;
      
      .cf-button-content {
        font-weight: 700;
      }
    }
  }
`)
