export default `
  --pageOffset: max(40px, calc((100vw - 1360px) / 2));
  --pageBlockWidth: calc(100% - var(--pageOffset) * 2);

  --headerMobileHeight: 50px;
  --headerTabletHeight: 60px;
  --headerDesktopHeight: 72px;
  --titleMainColor: #101010;
  --menuIconColor: #000;
  --galleryThumbnailHeight: 66px;
  --galleryThumbnailGap: 16px;
  --galleryDotSize: 10px;
  --galleryDotGap: 6px;
  --galleryArrowSize: 32px;
  --galleryHeaderHeight: 72px;
  --galleryNormalViewImgMaxHeight: 560px;
  --galleryNormalViewImgMaxHeightMobile: 250px;
  --galleryNormalViewImgMinHeight: 250px;
  --galleryNormalViewImgMediumHeight: 426px;
  --filtersTransitionDuration: 300;
  --popoverZindex: 1301;
`
