import { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import Picker from '@emoji-mart/react'
import data from '@emoji-mart/data'
import { cnx, type PopoverPropsV2, PopoverV2 } from '@carfluent/common'

import type { IconProps } from 'website/components/types'
import IconSVG from '../icons'

import CLASS_NAME, { POPOVER_CLASS_NAME } from './styles'

export interface EmojiData {
  native: string
}

export interface EmojiPickerProps {
  className?: string
  isDisabled?: boolean
  iconProps?: IconProps
  isButtonShown?: boolean
  onEmojiSelect: (emoji: string) => void
  popoverOptions?: PopoverPropsV2['options']
  rootClassName?: string
}

export interface EmojiPickerRef {
  openPopover: (anchorEl?: HTMLElement) => void
}

const POPOVER_OPTIONS = { modifiers: [{ name: 'offset', options: { offset: [120, -8] } }] }

const EmojiPicker = forwardRef<EmojiPickerRef, EmojiPickerProps>(({
  iconProps,
  onEmojiSelect,
  className = '',
  isDisabled = false,
  isButtonShown = true,
  popoverOptions = POPOVER_OPTIONS,
  rootClassName
}, ref) => {
  const [isOpen, setIsOpen] = useState(false)
  const refAnchorEl = useRef<HTMLSpanElement | null>(null)

  const onEmojiClick = (emoji: EmojiData): void => {
    onEmojiSelect(emoji.native)

    setIsOpen(false)
  }

  const togglePicker = (): void => {
    if (!isDisabled) { setIsOpen((prevOpen) => !prevOpen) }
  }

  useImperativeHandle(ref, () => ({
    openPopover: (anchorEl?: HTMLElement) => {
      if (anchorEl != null) {
        refAnchorEl.current = anchorEl
      }

      togglePicker()
    }
  }))

  return (
    <div className={cnx(CLASS_NAME, rootClassName)}>
      {
        isButtonShown && (
          <div className='emoji-wrapper'>
            <span ref={refAnchorEl} className={cnx(className, 'emoji-toggle', isDisabled && 'is-disabled')} onClick={togglePicker}>
              <IconSVG.SmileEmoji {...iconProps} fill={iconProps?.fill ?? '#212121'} />
            </span>
          </div>
        )
      }

      <PopoverV2
        isOpen={isOpen}
        options={popoverOptions}
        onClose={togglePicker}
        anchorEl={refAnchorEl}
        className={POPOVER_CLASS_NAME}
      >
        <Picker
          data={data}
          theme='light'
          onEmojiSelect={onEmojiClick}
        />
      </PopoverV2>
    </div>
  )
})

export default EmojiPicker
