import { type FC } from 'react'
import { type IconProps } from 'website/components/types'

const IconSVGNoImpactImage: FC<IconProps> = ({
  fill = 'var(--mainColor)',
  height = 55,
  width = 56
}) => {
  return (
    <svg width={width} height={height} viewBox='0 0 56 55' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M9.64917 45.612C14.9782 45.612 19.2983 41.2558 19.2983 35.8822C19.2983 30.5085 14.9782 26.1523 9.64917 26.1523C4.32008 26.1523 0 30.5085 0 35.8822C0 41.2558 4.32008 45.612 9.64917 45.612Z' fill='#F3F3F3' />
      <path d='M30.3469 17.4297C34.9147 17.4297 38.6176 13.6958 38.6176 9.08984C38.6176 4.48387 34.9147 0.75 30.3469 0.75C25.7791 0.75 22.0762 4.48387 22.0762 9.08984C22.0762 13.6958 25.7791 17.4297 30.3469 17.4297Z' fill='#F3F3F3' />
      <path d='M42.2142 54.1237C49.8272 54.1237 55.9987 47.9006 55.9987 40.224C55.9987 32.5473 49.8272 26.3242 42.2142 26.3242C34.6012 26.3242 28.4297 32.5473 28.4297 40.224C28.4297 47.9006 34.6012 54.1237 42.2142 54.1237Z' fill='#F3F3F3' />
      <path d='M51.8641 28.3789H36.7012C36.7012 30.2971 38.245 31.8538 40.1473 31.8538H48.418C50.3272 31.8538 51.8641 30.2971 51.8641 28.3789Z' fill={fill} fillOpacity={0.8} />
      <path d='M47.7288 28.3789H36.7012C36.7012 30.2971 38.245 31.8538 40.1473 31.8538H44.2827C46.1918 31.8538 47.7288 30.2971 47.7288 28.3789Z' fill='white' />
      <path d='M48.4193 32.3729H40.1486C37.9637 32.3729 36.1855 30.5798 36.1855 28.3767C36.1855 28.0918 36.4199 27.8555 36.7025 27.8555H51.8654C52.148 27.8555 52.3823 28.0918 52.3823 28.3767C52.3823 30.5798 50.6041 32.3729 48.4193 32.3729ZM37.2676 28.8979C37.5157 30.281 38.715 31.3304 40.1486 31.3304H48.4193C49.8598 31.3304 51.059 30.281 51.3003 28.8979H37.2676Z' fill='#101010' />
      <path d='M22.9149 28.3789H7.75195C7.75195 30.2971 9.29582 31.8538 11.1981 31.8538H19.4688C21.3779 31.8538 22.9149 30.2971 22.9149 28.3789Z' fill={fill} fillOpacity={0.8} />
      <path d='M18.7796 28.3789H7.75195C7.75195 30.2971 9.29582 31.8538 11.1981 31.8538H15.3334C17.2357 31.8538 18.7796 30.2971 18.7796 28.3789Z' fill='white' />
      <path d='M19.4701 32.3729H11.1994C9.01453 32.3729 7.23633 30.5798 7.23633 28.3767C7.23633 28.0918 7.47066 27.8555 7.75325 27.8555H22.9162C23.1988 27.8555 23.4331 28.0918 23.4331 28.3767C23.4331 30.5798 21.6549 32.3729 19.4701 32.3729ZM8.3184 28.8979C8.56652 30.281 9.76578 31.3304 11.1994 31.3304H19.4701C20.9106 31.3304 22.1098 30.281 22.351 28.8979H8.3184Z' fill='#101010' />
      <path d='M50.4849 28.9003C50.2712 28.9003 50.0713 28.7613 49.9955 28.5459L44.2819 11.901L38.5682 28.5459C38.4717 28.8169 38.1822 28.9629 37.9134 28.8656C37.6446 28.7683 37.4999 28.4764 37.5964 28.1984L43.7994 10.1287C43.8683 9.92025 44.0682 9.78125 44.2887 9.78125C44.5093 9.78125 44.7023 9.92025 44.7781 10.1287L50.9811 28.1984C51.0707 28.4694 50.9329 28.7683 50.6641 28.8656C50.602 28.8934 50.5469 28.9003 50.4849 28.9003Z' fill='#101010' />
      <path d='M21.5376 28.9003C21.324 28.9003 21.1241 28.7613 21.0483 28.5459L15.3346 11.901L9.62091 28.5459C9.52442 28.8169 9.23494 28.9629 8.96614 28.8656C8.69734 28.7683 8.55261 28.4764 8.6491 28.1984L14.8521 10.1287C14.9211 9.92025 15.1209 9.78125 15.3415 9.78125C15.562 9.78125 15.755 9.92025 15.8308 10.1287L22.0339 28.1984C22.1235 28.4694 21.9856 28.7683 21.7168 28.8656C21.6548 28.8934 21.5997 28.9003 21.5376 28.9003Z' fill='#101010' />
      <path d='M43.9383 46.4492H15.6801C14.729 46.4492 13.957 47.2276 13.957 48.1867C13.957 49.1458 14.729 49.9242 15.6801 49.9242H43.9383C44.8895 49.9242 45.6614 49.1458 45.6614 48.1867C45.6614 47.2276 44.8895 46.4492 43.9383 46.4492Z' fill={fill} fillOpacity={0.8} />
      <path d='M39.1138 46.4492H15.6801C14.729 46.4492 13.957 47.2276 13.957 48.1867C13.957 49.1458 14.729 49.9242 15.6801 49.9242H39.1138C40.0649 49.9242 40.8368 49.1458 40.8368 48.1867C40.8368 47.2276 40.0649 46.4492 39.1138 46.4492Z' fill='white' />
      <path d='M43.9377 50.4432H15.6794C14.4457 50.4432 13.4395 49.4285 13.4395 48.1845C13.4395 46.9405 14.4457 45.9258 15.6794 45.9258H43.9377C45.1714 45.9258 46.1777 46.9405 46.1777 48.1845C46.1777 49.4285 45.1783 50.4432 43.9377 50.4432ZM15.6794 46.9683C15.0178 46.9683 14.4733 47.5173 14.4733 48.1845C14.4733 48.8517 15.0178 49.4007 15.6794 49.4007H43.9377C44.5993 49.4007 45.1438 48.8517 45.1438 48.1845C45.1438 47.5173 44.5993 46.9683 43.9377 46.9683H15.6794Z' fill='#101010' />
      <path d='M40.8433 46.4492C30.7324 32.3827 31.8834 13.0898 31.8834 13.0898H27.7412C27.7412 13.0898 28.8991 32.3827 18.7812 46.4492H40.8433Z' fill={fill} fillOpacity={0.8} />
      <path d='M34.6412 46.4492C29.2446 29.7695 30.5128 13.0898 30.5128 13.0898H27.749C27.749 13.0898 28.9069 32.3827 18.7891 46.4492H34.6412Z' fill='white' />
      <path d='M40.8445 46.9674H18.7893C18.5963 46.9674 18.4172 46.8562 18.3276 46.6824C18.238 46.5087 18.2586 46.3002 18.3689 46.1404C28.2524 32.3935 27.2392 13.3092 27.2255 13.1146C27.2186 12.9686 27.2668 12.8296 27.3633 12.7254C27.4598 12.6211 27.5976 12.5586 27.7424 12.5586H31.8846C32.0293 12.5586 32.1603 12.6211 32.2637 12.7254C32.3671 12.8296 32.4084 12.9686 32.4015 13.1146C32.3877 13.3092 31.3746 32.3866 41.2581 46.1404C41.3752 46.3002 41.389 46.5087 41.2994 46.6824C41.2167 46.8562 41.0375 46.9674 40.8445 46.9674ZM19.7818 45.9249H39.8452C31.2712 33.297 31.2643 16.8119 31.347 13.608H28.2731C28.3627 16.8119 28.3558 33.297 19.7818 45.9249Z' fill='#101010' />
      <path d='M44.2827 10.8276H15.3353C15.0527 10.8276 14.8184 10.5913 14.8184 10.3064C14.8184 10.0215 15.0527 9.78516 15.3353 9.78516H44.2827C44.5653 9.78516 44.7997 10.0215 44.7997 10.3064C44.7997 10.5913 44.5722 10.8276 44.2827 10.8276Z' fill='#101010' />
      <path d='M29.809 15.1712C32.4735 15.1712 34.6335 12.9931 34.6335 10.3063C34.6335 7.6195 32.4735 5.44141 29.809 5.44141C27.1444 5.44141 24.9844 7.6195 24.9844 10.3063C24.9844 12.9931 27.1444 15.1712 29.809 15.1712Z' fill='white' />
      <path d='M33.0018 6.66016C33.1672 7.1536 33.2568 7.67484 33.2568 8.21693C33.2568 10.9065 31.0996 13.0818 28.4323 13.0818C27.2054 13.0818 26.0958 12.6162 25.248 11.8587C25.8959 13.7768 27.6879 15.1668 29.8107 15.1668C32.478 15.1668 34.6353 12.9915 34.6353 10.3019C34.6353 8.85631 34.0012 7.55669 33.0018 6.66016Z' fill={fill} fillOpacity={0.8} />
      <path d='M29.8083 15.6942C26.8653 15.6942 24.4668 13.2756 24.4668 10.308C24.4668 7.34043 26.8653 4.92188 29.8083 4.92188C32.7513 4.92188 35.1498 7.34043 35.1498 10.308C35.1498 13.2756 32.7582 15.6942 29.8083 15.6942ZM29.8083 5.96436C27.4305 5.96436 25.5006 7.91032 25.5006 10.308C25.5006 12.7057 27.4305 14.6517 29.8083 14.6517C32.1861 14.6517 34.116 12.7057 34.116 10.308C34.116 7.91032 32.1861 5.96436 29.8083 5.96436Z' fill='#101010' />
      <path d='M29.8064 11.0033C30.1871 11.0033 30.4956 10.6921 30.4956 10.3083C30.4956 9.92444 30.1871 9.61328 29.8064 9.61328C29.4258 9.61328 29.1172 9.92444 29.1172 10.3083C29.1172 10.6921 29.4258 11.0033 29.8064 11.0033Z' fill='#101010' />
      <path d='M15.3353 12.3926C16.4772 12.3926 17.4029 11.4591 17.4029 10.3076C17.4029 9.15612 16.4772 8.22266 15.3353 8.22266C14.1933 8.22266 13.2676 9.15612 13.2676 10.3076C13.2676 11.4591 14.1933 12.3926 15.3353 12.3926Z' fill='white' />
      <path d='M15.5062 8.23828C15.8301 8.60662 16.03 9.08617 16.03 9.61436C16.03 10.768 15.1064 11.6993 13.9623 11.6993C13.9071 11.6993 13.852 11.6854 13.7969 11.6785C14.1759 12.1094 14.7204 12.3943 15.3407 12.3943C16.4848 12.3943 17.4084 11.463 17.4084 10.3093C17.4015 9.21127 16.5676 8.32863 15.5062 8.23828Z' fill={fill} fillOpacity={0.8} />
      <path d='M15.3346 12.9155C13.9079 12.9155 12.75 11.7479 12.75 10.3093C12.75 8.8707 13.9079 7.70312 15.3346 7.70312C16.7613 7.70312 17.9192 8.8707 17.9192 10.3093C17.9192 11.7479 16.7613 12.9155 15.3346 12.9155ZM15.3346 8.74561C14.48 8.74561 13.7838 9.44754 13.7838 10.3093C13.7838 11.1711 14.48 11.873 15.3346 11.873C16.1892 11.873 16.8854 11.1711 16.8854 10.3093C16.8854 9.44754 16.1892 8.74561 15.3346 8.74561Z' fill='#101010' />
      <path d='M44.2825 12.3926C45.4245 12.3926 46.3502 11.4591 46.3502 10.3076C46.3502 9.15612 45.4245 8.22266 44.2825 8.22266C43.1406 8.22266 42.2148 9.15612 42.2148 10.3076C42.2148 11.4591 43.1406 12.3926 44.2825 12.3926Z' fill='white' />
      <path d='M44.4534 8.23828C44.7774 8.60662 44.9772 9.08617 44.9772 9.61436C44.9772 10.768 44.0537 11.6993 42.9095 11.6993C42.8544 11.6993 42.7993 11.6854 42.7441 11.6785C43.1232 12.1094 43.6746 12.3943 44.288 12.3943C45.4321 12.3943 46.3557 11.463 46.3557 10.3093C46.3488 9.21127 45.5148 8.32863 44.4534 8.23828Z' fill={fill} fillOpacity={0.8} />
      <path d='M44.2819 12.9155C42.8552 12.9155 41.6973 11.7479 41.6973 10.3093C41.6973 8.8707 42.8552 7.70312 44.2819 7.70312C45.7086 7.70312 46.8665 8.8707 46.8665 10.3093C46.8665 11.7479 45.7086 12.9155 44.2819 12.9155ZM44.2819 8.74561C43.4272 8.74561 42.7311 9.44754 42.7311 10.3093C42.7311 11.1711 43.4272 11.873 44.2819 11.873C45.1365 11.873 45.8326 11.1711 45.8326 10.3093C45.8326 9.44754 45.1365 8.74561 44.2819 8.74561Z' fill='#101010' />
      <path d='M10.5093 43.5299C11.2706 43.5299 11.8878 42.9076 11.8878 42.14C11.8878 41.3723 11.2706 40.75 10.5093 40.75C9.74801 40.75 9.13086 41.3723 9.13086 42.14C9.13086 42.9076 9.74801 43.5299 10.5093 43.5299Z' fill='white' />
      <path d='M10.5087 44.049C9.46103 44.049 8.61328 43.1942 8.61328 42.1378C8.61328 41.0814 9.46103 40.2266 10.5087 40.2266C11.5563 40.2266 12.404 41.0814 12.404 42.1378C12.404 43.1942 11.5563 44.049 10.5087 44.049ZM10.5087 41.269C10.0331 41.269 9.64712 41.6582 9.64712 42.1378C9.64712 42.6173 10.0331 43.0065 10.5087 43.0065C10.9842 43.0065 11.3702 42.6173 11.3702 42.1378C11.3702 41.6582 10.9842 41.269 10.5087 41.269Z' fill='#101010' />
      <path d='M15.3355 37.9674C16.0968 37.9674 16.7139 37.3451 16.7139 36.5775C16.7139 35.8098 16.0968 35.1875 15.3355 35.1875C14.5742 35.1875 13.957 35.8098 13.957 36.5775C13.957 37.3451 14.5742 37.9674 15.3355 37.9674Z' fill='white' />
      <path d='M15.3348 38.4904C14.2872 38.4904 13.4395 37.6356 13.4395 36.5792C13.4395 35.5228 14.2872 34.668 15.3348 34.668C16.3824 34.668 17.2302 35.5228 17.2302 36.5792C17.2302 37.6356 16.3824 38.4904 15.3348 38.4904ZM15.3348 35.7104C14.8593 35.7104 14.4733 36.0996 14.4733 36.5792C14.4733 37.0587 14.8593 37.4479 15.3348 37.4479C15.8104 37.4479 16.1964 37.0587 16.1964 36.5792C16.1964 36.0996 15.8104 35.7104 15.3348 35.7104Z' fill='#101010' />
      <path d='M21.5445 18.5104C22.3058 18.5104 22.9229 17.8881 22.9229 17.1204C22.9229 16.3528 22.3058 15.7305 21.5445 15.7305C20.7832 15.7305 20.166 16.3528 20.166 17.1204C20.166 17.8881 20.7832 18.5104 21.5445 18.5104Z' fill='white' />
      <path d='M21.5438 19.0295C20.4962 19.0295 19.6484 18.1746 19.6484 17.1182C19.6484 16.0619 20.4962 15.207 21.5438 15.207C22.5914 15.207 23.4392 16.0619 23.4392 17.1182C23.4392 18.1746 22.5845 19.0295 21.5438 19.0295ZM21.5438 16.2495C21.0682 16.2495 20.6823 16.6387 20.6823 17.1182C20.6823 17.5978 21.0682 17.987 21.5438 17.987C22.0194 17.987 22.4053 17.5978 22.4053 17.1182C22.4053 16.6387 22.0194 16.2495 21.5438 16.2495Z' fill='#101010' />
      <path d='M44.9781 24.7643C45.7394 24.7643 46.3565 24.142 46.3565 23.3743C46.3565 22.6067 45.7394 21.9844 44.9781 21.9844C44.2168 21.9844 43.5996 22.6067 43.5996 23.3743C43.5996 24.142 44.2168 24.7643 44.9781 24.7643Z' fill='white' />
      <path d='M44.9774 25.2834C43.9298 25.2834 43.082 24.4285 43.082 23.3722C43.082 22.3158 43.9298 21.4609 44.9774 21.4609C46.025 21.4609 46.8728 22.3158 46.8728 23.3722C46.8728 24.4285 46.025 25.2834 44.9774 25.2834ZM44.9774 22.5034C44.5018 22.5034 44.1159 22.8926 44.1159 23.3722C44.1159 23.8517 44.5018 24.2409 44.9774 24.2409C45.453 24.2409 45.8389 23.8517 45.8389 23.3722C45.8389 22.8926 45.453 22.5034 44.9774 22.5034Z' fill='#101010' />
      <path d='M42.9097 40.0534C43.671 40.0534 44.2882 39.4311 44.2882 38.6634C44.2882 37.8957 43.671 37.2734 42.9097 37.2734C42.1484 37.2734 41.5312 37.8957 41.5312 38.6634C41.5312 39.4311 42.1484 40.0534 42.9097 40.0534Z' fill='white' />
      <path d='M42.911 40.5763C41.8634 40.5763 41.0156 39.7215 41.0156 38.6651C41.0156 37.6087 41.8634 36.7539 42.911 36.7539C43.9586 36.7539 44.8064 37.6087 44.8064 38.6651C44.8064 39.7215 43.9586 40.5763 42.911 40.5763ZM42.911 37.7964C42.4354 37.7964 42.0495 38.1856 42.0495 38.6651C42.0495 39.1447 42.4354 39.5339 42.911 39.5339C43.3866 39.5339 43.7725 39.1447 43.7725 38.6651C43.7725 38.1856 43.3866 37.7964 42.911 37.7964Z' fill='#101010' />
      <path d='M50.4917 43.5299C51.253 43.5299 51.8702 42.9076 51.8702 42.14C51.8702 41.3723 51.253 40.75 50.4917 40.75C49.7304 40.75 49.1133 41.3723 49.1133 42.14C49.1133 42.9076 49.7304 43.5299 50.4917 43.5299Z' fill='white' />
      <path d='M50.493 44.049C49.4454 44.049 48.5977 43.1942 48.5977 42.1378C48.5977 41.0814 49.4454 40.2266 50.493 40.2266C51.5406 40.2266 52.3884 41.0814 52.3884 42.1378C52.3884 43.1942 51.5406 44.049 50.493 44.049ZM50.493 41.269C50.0175 41.269 49.6315 41.6582 49.6315 42.1378C49.6315 42.6173 50.0175 43.0065 50.493 43.0065C50.9686 43.0065 51.3546 42.6173 51.3546 42.1378C51.3546 41.6582 50.9686 41.269 50.493 41.269Z' fill='#101010' />
    </svg>
  )
}

export default IconSVGNoImpactImage
