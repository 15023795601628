import { css } from '@emotion/css'

export const ARROW_OFFSET = 16
export const ARROW_CLS = 'cf-list-scroll-horz-btn'

export const SECONDARY_CLASS_NAME = css(`
  display: flex;
  gap: 12px;

  .${ARROW_CLS} {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    margin: 0!important;
    opacity: 0;
    pointer-events: none;

    :hover {
      background: linear-gradient(0deg, rgba(34, 36, 41, 0.08) 0%, rgba(34, 36, 41, 0.08) 100%), #EBECF0;
    }

    svg {
      path {
       stroke: #222429;
       fill: #222429;
       fill-opacity: 1;
      }
    }

    &.visible {
      pointer-events: all;
      opacity: 1;
    }

    &.left {
      svg {
        transform: rotate(180deg);
      }
    }
  }
`)

export default css(`
  &.cf-slider-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 100vw;
    pointer-events: none;
    z-index: 2;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-areas: "left . right";
    align-items: center;

    .${ARROW_CLS} {
      background: #fff;
      box-shadow: 0px 0px 8px 5px #00000005;
      pointer-events: none;
      opacity: 0;

      padding: 0px;
      width: 44px;
      height: 44px;

      &.right {
        grid-area: right;
        margin-right: ${ARROW_OFFSET}px;
      }

      &.left {
        grid-area: left;
        margin-left: ${ARROW_OFFSET}px;

        svg {
          transform: rotate(180deg);
        }
      }

      &.visible {
        pointer-events: all;
        opacity: 1;
      }
    }
  }
`)
