import { css } from '@emotion/css'

export const createStyleClass = (minWidth: string): string => {
  return css(`
    & {
      .cf-header-drawer-icon {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        display: flex;
        align-items: flex-start;

        &:hover {
          cursor: pointer;
        }
      }

      .cf-header-drawer-close-bar {
        grid-area: CloseBar;
        display: flex;
        align-items: center;
        padding: 25px 30px;

        & img:hover {
          cursor: pointer;
        }
      }

      .cf-header-drawer-menu {
        position: fixed;
        width: 100vw;
        z-index: 101;
        top: 0;
        transition: all 225ms cubic-bezier(0.4, 0, 0.2, 1);
        background-color: #fff;
        height: 100vh;

        @media (min-width: 429px) {
          width: min(100vw, ${minWidth});
        }
      }
    }
  `)
}
