import { type FC } from 'react'
import { type IconProps } from 'website/components/types'

const IconSVGGetPaid: FC<IconProps> = ({
  fill = 'var(--mainColor)',
  height = 72,
  width = 72
}) => {
  return (
    <svg width={width} height={height} viewBox='0 0 72 72' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_9033_57865)'>
        <path d='M19.5811 26.7451C26.811 26.7451 32.672 20.807 32.672 13.4819C32.672 6.15686 26.811 0.21875 19.5811 0.21875C12.3513 0.21875 6.49023 6.15686 6.49023 13.4819C6.49023 20.807 12.3513 26.7451 19.5811 26.7451Z' fill='#F7F8FA' />
        <path d='M60.3126 55.231C66.5096 55.231 71.5334 50.1412 71.5334 43.8626C71.5334 37.584 66.5096 32.4941 60.3126 32.4941C54.1155 32.4941 49.0918 37.584 49.0918 43.8626C49.0918 50.1412 54.1155 55.231 60.3126 55.231Z' fill='#F7F8FA' />
        <path d='M19.2638 71.3361C29.5923 71.3361 37.9651 62.8531 37.9651 52.3888C37.9651 41.9245 29.5923 33.4414 19.2638 33.4414C8.93536 33.4414 0.5625 41.9245 0.5625 52.3888C0.5625 62.8531 8.93536 71.3361 19.2638 71.3361Z' fill='#F7F8FA' />
        <path d='M63.8 51.0055C63.5662 52.5592 62.5283 53.8666 61.0696 54.435L49.3346 58.9824C46.9783 59.8918 44.3601 58.5561 43.6775 56.1024C43.1819 54.3024 44.1823 52.4266 45.9403 51.8487L48.2592 51.1003C54.786 49.6982 58.6478 47.2161 61.0791 47.1876C63.3325 47.1687 64.2395 49.1392 63.8 51.0055Z' fill='white' />
        <path d='M47.8118 59.9891C47.0451 59.9891 46.269 59.8092 45.5677 59.4492C44.3147 58.8144 43.389 57.668 43.0056 56.3038C42.3978 54.1248 43.5947 51.8702 45.7266 51.1881L48.0456 50.4397C51.4773 49.6913 54.1235 48.6776 56.2555 47.8723C58.2285 47.1144 59.79 46.5176 61.0711 46.4986C62.2025 46.4797 63.1843 46.9249 63.8295 47.7302C64.5308 48.6207 64.7739 49.8997 64.484 51.1597C64.2035 52.9502 62.9973 54.466 61.3142 55.1196L49.5791 59.667C49.0181 59.8755 48.4196 59.9891 47.8118 59.9891ZM61.1365 47.9007C61.1272 47.9007 61.1085 47.9007 61.0991 47.9007C60.0705 47.9102 58.6025 48.4692 56.7604 49.1797C54.5911 50.0134 51.8887 51.046 48.4103 51.7944L46.1568 52.5239C44.7448 52.9786 43.9594 54.466 44.3614 55.906C44.6326 56.9007 45.2872 57.706 46.2035 58.1702C47.1199 58.6344 48.1391 58.6818 49.0929 58.3218L60.8279 53.7744C62.0435 53.3007 62.9131 52.2018 63.1095 50.9039C63.1095 50.8849 63.1189 50.866 63.1189 50.847C63.3152 49.9944 63.175 49.1513 62.7355 48.6018C62.3708 48.1376 61.8378 47.9007 61.1365 47.9007Z' fill='#222429' />
        <path d='M66.3537 50.9491C64.549 49.4239 59.5464 54.0944 46.5397 56.5765C48.2508 55.9228 48.868 54.9564 48.9241 53.9806C49.214 49.6133 45.483 49.8123 45.483 49.8123C26.3049 50.0397 40.8919 45.4259 14.8223 45.3027V62.3365C14.8223 62.3365 43.5381 72.2175 65.2784 55.2691C65.2784 55.2691 68.7194 52.9386 66.3537 50.9491Z' fill='white' />
        <path d='M46.5391 56.5782C48.2409 56.2561 49.8024 55.8867 51.2331 55.5077C50.1391 55.4982 49.1666 55.4793 48.3063 55.4414C47.9417 55.8677 47.3713 56.2656 46.5391 56.5782Z' fill='white' />
        <path d='M66.3525 50.9503C64.7816 49.624 60.7888 52.9777 51.2324 55.5072C49.7924 55.8861 48.2403 56.2556 46.5385 56.5777C47.3707 56.2556 47.9317 55.8672 48.3057 55.4409C45.1359 55.2987 43.4528 54.9293 42.2839 54.4651C33.887 51.1209 24.8543 55.9524 22.7598 64.223C30.7733 65.5872 43.6958 66.3356 56.0293 60.8124C59.1805 59.4009 62.2943 57.6009 65.2772 55.2703C65.2772 55.2703 68.7182 52.9398 66.3525 50.9503Z' fill={fill} fillOpacity='0.48' />
        <path d='M34.223 65.8698C23.1332 65.8698 14.755 63.0655 14.596 63.0086C14.3155 62.9139 14.1191 62.6393 14.1191 62.3361V45.3023C14.1191 45.1128 14.1939 44.9329 14.3249 44.8002C14.4558 44.6676 14.6615 44.6202 14.8204 44.5918C28.9493 44.6581 31.3617 46.0698 33.4937 47.3108C35.2329 48.3245 36.7477 49.2056 45.4719 49.1014C45.5186 49.1014 47.2765 49.0161 48.5015 50.2856C49.3524 51.1572 49.7264 52.4172 49.6142 54.0277C49.5862 54.4066 49.4926 54.8329 49.2682 55.2593C54.57 54.0087 58.3197 52.4456 60.9378 51.3561C63.9581 50.0961 65.6132 49.4045 66.782 50.3898C67.7264 51.1856 67.8854 52.0381 67.8573 52.616C67.7638 54.3592 65.8656 55.7045 65.6412 55.8561C55.5519 63.7477 43.8636 65.8698 34.223 65.8698ZM15.5217 61.815C19.3368 62.9803 45.1726 70.0477 64.8558 54.7003C65.2952 54.3972 66.4454 53.4214 66.4828 52.5403C66.4921 52.4077 66.5108 51.9908 65.9124 51.4887C65.3981 51.0529 63.7617 51.735 61.4989 52.6729C58.3851 53.9708 53.663 55.9319 46.6781 57.2772C46.3228 57.3435 45.9675 57.1256 45.8739 56.7656C45.7804 56.415 45.9581 56.0361 46.3041 55.9129C47.529 55.4393 48.1836 54.776 48.2397 53.9328C48.3145 52.7581 48.0807 51.8677 47.529 51.2898C46.7529 50.475 45.5373 50.5224 45.528 50.5224C36.3924 50.6266 34.728 49.6603 32.8111 48.5424C30.8662 47.4056 28.6688 46.1265 15.5311 46.0128V61.815H15.5217Z' fill='#222429' />
        <path d='M40.0121 58.0735C37.6463 58.0735 34.916 57.8651 31.8396 57.4577C31.4562 57.4103 31.1851 57.0503 31.2318 56.6619C31.2786 56.2735 31.6339 55.9988 32.0173 56.0556C40.0121 57.1261 44.2105 56.5672 46.3238 55.904C46.6884 55.7903 47.0905 55.9988 47.2027 56.3777C47.3149 56.7567 47.1092 57.1546 46.7352 57.2682C44.996 57.8082 42.7425 58.0735 40.0121 58.0735Z' fill='#222429' />
        <path d='M14.8204 45.3125H7.33984V62.3651H14.8204V45.3125Z' fill='white' />
        <path d='M14.8223 62.3667H11.082V52.893C11.082 50.7993 12.7558 49.1035 14.8223 49.1035V62.3667Z' fill='white' />
        <path d='M14.8186 63.0772H7.33802C6.95464 63.0772 6.63672 62.7551 6.63672 62.3667V45.314C6.63672 44.9256 6.95464 44.6035 7.33802 44.6035H14.8186C15.2019 44.6035 15.5199 44.9256 15.5199 45.314V62.3667C15.5199 62.7551 15.2019 63.0772 14.8186 63.0772ZM8.03932 61.6561H14.1173V46.0246H8.03932V61.6561Z' fill='#222429' />
        <path d='M11.0815 59.5236C11.598 59.5236 12.0166 59.0994 12.0166 58.5763C12.0166 58.053 11.598 57.6289 11.0815 57.6289C10.5651 57.6289 10.1465 58.053 10.1465 58.5763C10.1465 59.0994 10.5651 59.5236 11.0815 59.5236Z' fill='#222429' />
        <path d='M42.8742 46.2624C54.2354 46.2624 63.4456 36.931 63.4456 25.4203C63.4456 13.9095 54.2354 4.57812 42.8742 4.57812C31.5128 4.57812 22.3027 13.9095 22.3027 25.4203C22.3027 36.931 31.5128 46.2624 42.8742 46.2624Z' fill='white' />
        <path d='M63.4475 25.4194C63.4475 21.611 62.4376 18.0394 60.6703 14.9699C57.6407 13.1889 54.1155 12.1562 50.3566 12.1562C38.9955 12.1562 29.7852 21.4878 29.7852 32.9984C29.7852 36.8068 30.795 40.3784 32.5623 43.4478C35.5919 45.2289 39.1171 46.2615 42.8761 46.2615C54.2371 46.2615 63.4475 36.9299 63.4475 25.4194Z' fill={fill} fillOpacity='0.48' />
        <path d='M42.8723 46.9686C31.1466 46.9686 21.5996 37.2959 21.5996 25.416C21.5996 13.536 31.1466 3.86328 42.8723 3.86328C54.598 3.86328 64.1451 13.536 64.1451 25.416C64.1451 37.2959 54.6074 46.9686 42.8723 46.9686ZM42.8723 5.28433C31.9134 5.28433 23.0022 14.3128 23.0022 25.416C23.0022 36.5191 31.9134 45.5475 42.8723 45.5475C53.8313 45.5475 62.7425 36.5191 62.7425 25.416C62.7425 14.3128 53.8313 5.28433 42.8723 5.28433Z' fill='#222429' />
        <path d='M66.786 21.6293C66.4588 21.6293 66.1596 21.3924 66.0942 21.0514C65.2807 16.5703 63.2235 12.4398 60.1378 9.09563C59.876 8.81142 59.8853 8.35669 60.1751 8.09142C60.4556 7.82616 60.9046 7.83564 61.1664 8.12932C64.4298 11.6724 66.6178 16.0588 67.4781 20.8051C67.5435 21.1935 67.3004 21.563 66.917 21.6293C66.8703 21.6293 66.8234 21.6293 66.786 21.6293Z' fill='#222429' />
        <path d='M57.8361 6.70759C57.6865 6.70759 57.5369 6.66023 57.406 6.55602C56.7889 6.06338 56.1343 5.59917 55.4704 5.17286C55.1432 4.96444 55.0497 4.51917 55.2554 4.18759C55.4611 3.85602 55.8912 3.76128 56.2185 3.9697C56.9198 4.42444 57.6117 4.91707 58.2663 5.42865C58.5749 5.67496 58.6216 6.12023 58.3878 6.42338C58.2476 6.61286 58.0418 6.70759 57.8361 6.70759Z' fill='#222429' />
        <path d='M42.8751 40.5736C51.1378 40.5736 57.8361 33.7871 57.8361 25.4157C57.8361 17.0442 51.1378 10.2578 42.8751 10.2578C34.6123 10.2578 27.9141 17.0442 27.9141 25.4157C27.9141 33.7871 34.6123 40.5736 42.8751 40.5736Z' fill='white' />
        <path d='M57.8345 25.4168C57.8345 23.1905 57.3483 21.0874 56.5068 19.1832C54.6273 18.321 52.5514 17.8379 50.3634 17.8379C42.0974 17.8379 35.4023 24.6211 35.4023 32.9958C35.4023 35.2221 35.8886 37.3252 36.7301 39.2199C38.6096 40.082 40.6855 40.5747 42.8735 40.5747C51.1395 40.5747 57.8345 33.7821 57.8345 25.4168Z' fill={fill} fillOpacity='0.48' />
        <path d='M42.8733 41.2857C34.2333 41.2857 27.2109 34.1615 27.2109 25.4172C27.2109 16.6636 34.2333 9.54883 42.8733 9.54883C51.5133 9.54883 58.5356 16.6636 58.5356 25.4172C58.5356 34.1615 51.5133 41.2857 42.8733 41.2857ZM42.8733 10.9699C35.0094 10.9699 28.6135 17.4499 28.6135 25.4172C28.6135 33.3846 35.0094 39.8646 42.8733 39.8646C50.7372 39.8646 57.133 33.3846 57.133 25.4172C57.133 17.4499 50.7372 10.9699 42.8733 10.9699Z' fill='#222429' />
        <path d='M52.9626 31.1044C52.8597 31.1044 52.7569 31.076 52.654 31.0286C52.308 30.8581 52.1584 30.4318 52.3267 30.0812C53.028 28.6223 53.3834 27.0496 53.3834 25.4107C53.3834 23.7718 53.028 22.2086 52.3267 20.7496C52.1584 20.3991 52.2987 19.9728 52.654 19.8023C53 19.6318 53.4208 19.7739 53.5891 20.1339C54.3839 21.7918 54.786 23.5633 54.786 25.4202C54.786 27.277 54.3839 29.0581 53.5797 30.7065C53.4769 30.9623 53.2244 31.1044 52.9626 31.1044Z' fill='#222429' />
        <path d='M32.7824 31.1044C32.5205 31.1044 32.2774 30.9528 32.1559 30.7065C31.3611 29.0486 30.959 27.277 30.959 25.4202C30.959 23.5633 31.3611 21.7823 32.1652 20.1339C32.3335 19.7739 32.7543 19.6318 33.1003 19.8023C33.4463 19.9728 33.5959 20.3991 33.4276 20.7496C32.7263 22.2086 32.3709 23.7812 32.3709 25.4202C32.3709 27.0591 32.7263 28.6223 33.4276 30.0812C33.5959 30.4318 33.4556 30.8581 33.1003 31.0286C32.9881 31.0854 32.8852 31.1044 32.7824 31.1044Z' fill='#222429' />
        <path d='M25.1075 26.1281C25.4949 26.1281 25.8088 25.81 25.8088 25.4176C25.8088 25.0251 25.4949 24.707 25.1075 24.707C24.7202 24.707 24.4062 25.0251 24.4062 25.4176C24.4062 25.81 24.7202 26.1281 25.1075 26.1281Z' fill='#222429' />
        <path d='M60.6349 26.1281C61.0222 26.1281 61.3362 25.81 61.3362 25.4176C61.3362 25.0251 61.0222 24.707 60.6349 24.707C60.2476 24.707 59.9336 25.0251 59.9336 25.4176C59.9336 25.81 60.2476 26.1281 60.6349 26.1281Z' fill='#222429' />
        <path d='M42.8751 44.1281C43.2624 44.1281 43.5764 43.81 43.5764 43.4176C43.5764 43.0251 43.2624 42.707 42.8751 42.707C42.4878 42.707 42.1738 43.0251 42.1738 43.4176C42.1738 43.81 42.4878 44.1281 42.8751 44.1281Z' fill='#222429' />
        <path d='M42.8751 8.11832C43.2624 8.11832 43.5764 7.8002 43.5764 7.40779C43.5764 7.01537 43.2624 6.69727 42.8751 6.69727C42.4878 6.69727 42.1738 7.01537 42.1738 7.40779C42.1738 7.8002 42.4878 8.11832 42.8751 8.11832Z' fill='#222429' />
        <path d='M54.9409 37.6396C55.212 37.3649 55.6609 37.3649 55.932 37.6396C56.2032 37.9144 56.2032 38.369 55.932 38.6438C55.6609 38.9185 55.212 38.9185 54.9409 38.6438C54.6603 38.369 54.6603 37.9144 54.9409 37.6396Z' fill='#222429' />
        <path d='M29.8128 12.1846C30.0839 11.9098 30.5328 11.9098 30.8039 12.1846C31.0751 12.4593 31.0751 12.914 30.8039 13.1888C30.5328 13.4635 30.0839 13.4635 29.8128 13.1888C29.5416 12.914 29.5416 12.4593 29.8128 12.1846Z' fill='#222429' />
        <path d='M54.9495 13.1888C54.6783 12.914 54.6783 12.4593 54.9495 12.1846C55.2206 11.9098 55.6695 11.9098 55.9406 12.1846C56.2118 12.4593 56.2118 12.914 55.9406 13.1888C55.6601 13.4635 55.2206 13.4635 54.9495 13.1888Z' fill='#222429' />
        <path d='M29.8128 38.6438C29.5416 38.369 29.5416 37.9144 29.8128 37.6396C30.0839 37.3649 30.5328 37.3649 30.8039 37.6396C31.0751 37.9144 31.0751 38.369 30.8039 38.6438C30.5328 38.928 30.0933 38.928 29.8128 38.6438Z' fill='#222429' />
        <path d='M58.6333 32.0302C58.7829 31.6702 59.1944 31.4997 59.5497 31.6418C59.905 31.7934 60.0733 32.2102 59.9331 32.5702C59.7835 32.9302 59.372 33.1007 59.0167 32.9586C58.6614 32.8071 58.4931 32.3902 58.6333 32.0302Z' fill='#222429' />
        <path d='M25.8091 18.2548C25.9587 17.8948 26.3701 17.7243 26.7255 17.8664C27.0808 18.018 27.2491 18.4348 27.1088 18.7948C26.9592 19.1548 26.5478 19.3254 26.1925 19.1738C25.8371 19.0317 25.6595 18.6148 25.8091 18.2548Z' fill='#222429' />
        <path d='M49.4132 9.43499C49.0579 9.28341 48.8896 8.86657 49.0298 8.50657C49.1794 8.14657 49.5909 7.97605 49.9462 8.12763C50.3015 8.27921 50.4698 8.69604 50.3296 9.05604C50.1799 9.41604 49.7685 9.58655 49.4132 9.43499Z' fill='#222429' />
        <path d='M35.7999 42.6969C35.4446 42.5453 35.2763 42.1285 35.4165 41.7685C35.5661 41.4085 35.9776 41.238 36.3329 41.3801C36.6882 41.5317 36.8565 41.9485 36.7163 42.3085C36.5667 42.678 36.1646 42.8485 35.7999 42.6969Z' fill='#222429' />
        <path d='M58.6385 18.7942C58.4889 18.4342 58.6572 18.0173 59.0219 17.8658C59.3772 17.7142 59.7886 17.8847 59.9382 18.2542C60.0878 18.6142 59.9195 19.031 59.5549 19.1826C59.1995 19.3342 58.7881 19.1542 58.6385 18.7942Z' fill='#222429' />
        <path d='M25.8103 32.5676C25.6607 32.2076 25.8291 31.7908 26.1938 31.6392C26.5491 31.4876 26.9605 31.6582 27.1101 32.0276C27.2597 32.3876 27.0915 32.8045 26.7268 32.956C26.3715 33.1076 25.96 32.9371 25.8103 32.5676Z' fill='#222429' />
        <path d='M36.3459 9.43666C35.9905 9.5882 35.5791 9.41771 35.4295 9.04824C35.2799 8.68824 35.4482 8.2714 35.8129 8.11982C36.1682 7.96824 36.5796 8.13877 36.7292 8.49877C36.8695 8.86824 36.7012 9.28508 36.3459 9.43666Z' fill='#222429' />
        <path d='M49.9435 42.6982C49.5882 42.8498 49.1768 42.6793 49.0272 42.3098C48.8776 41.9498 49.0459 41.533 49.4105 41.3814C49.7659 41.2298 50.1773 41.4003 50.3269 41.7698C50.4672 42.1393 50.2988 42.5561 49.9435 42.6982Z' fill='#222429' />
        <path d='M42.8835 35.1281C40.2747 35.1281 37.9744 33.8302 37.0207 31.8312C36.8524 31.4712 37.002 31.0544 37.348 30.8839C37.6939 30.7133 38.1147 30.8649 38.283 31.2155C38.9937 32.7028 40.8451 33.707 42.8835 33.707C45.5859 33.707 47.7833 32.0112 47.7833 29.9176C47.7833 27.4733 46.0347 26.1281 42.8648 26.1281C38.9189 26.1281 36.5625 24.1765 36.5625 20.9176C36.5625 18.047 39.3864 15.707 42.8648 15.707C45.4737 15.707 47.7739 17.0049 48.7277 19.0039C48.896 19.3544 48.7464 19.7807 48.4004 19.9512C48.0544 20.1218 47.6337 19.9702 47.4654 19.6197C46.7547 18.1323 44.9033 17.1281 42.8648 17.1281C40.1625 17.1281 37.9651 18.8239 37.9651 20.9176C37.9651 23.3618 39.7043 24.707 42.8648 24.707C46.8202 24.707 49.1859 26.6491 49.1859 29.9176C49.1859 32.7881 46.362 35.1281 42.8835 35.1281Z' fill='#222429' />
        <path d='M42.8615 37.9725C42.4781 37.9725 42.1602 37.6503 42.1602 37.2619V13.5777C42.1602 13.1893 42.4781 12.8672 42.8615 12.8672C43.2448 12.8672 43.5628 13.1893 43.5628 13.5777V37.2619C43.5628 37.6503 43.2542 37.9725 42.8615 37.9725Z' fill='#222429' />
        <path d='M18.5615 39.6215C19.5943 39.6215 20.4317 38.7732 20.4317 37.7268C20.4317 36.6804 19.5943 35.832 18.5615 35.832C17.5287 35.832 16.6914 36.6804 16.6914 37.7268C16.6914 38.7732 17.5287 39.6215 18.5615 39.6215Z' fill='white' />
        <path d='M18.5597 40.3316C17.1384 40.3316 15.9883 39.1664 15.9883 37.7264C15.9883 36.2864 17.1384 35.1211 18.5597 35.1211C19.981 35.1211 21.1311 36.2864 21.1311 37.7264C21.1311 39.1664 19.981 40.3316 18.5597 40.3316ZM18.5597 36.5421C17.9145 36.5421 17.3909 37.0727 17.3909 37.7264C17.3909 38.38 17.9145 38.9106 18.5597 38.9106C19.2049 38.9106 19.7285 38.38 19.7285 37.7264C19.7285 37.0727 19.2049 36.5421 18.5597 36.5421Z' fill='#222429' />
        <path d='M65.3193 43.4184C66.3522 43.4184 67.1895 42.57 67.1895 41.5236C67.1895 40.4772 66.3522 39.6289 65.3193 39.6289C64.2865 39.6289 63.4492 40.4772 63.4492 41.5236C63.4492 42.57 64.2865 43.4184 65.3193 43.4184Z' fill='white' />
        <path d='M65.3253 44.1285C63.904 44.1285 62.7539 42.9632 62.7539 41.5232C62.7539 40.0832 63.904 38.918 65.3253 38.918C66.7466 38.918 67.8968 40.0832 67.8968 41.5232C67.8968 42.9538 66.7373 44.1285 65.3253 44.1285ZM65.3253 40.339C64.6801 40.339 64.1565 40.8695 64.1565 41.5232C64.1565 42.1769 64.6801 42.7074 65.3253 42.7074C65.9705 42.7074 66.4942 42.1769 66.4942 41.5232C66.4942 40.8695 65.9705 40.339 65.3253 40.339Z' fill='#222429' />
        <path d='M9.20997 32.0414C10.2428 32.0414 11.0801 31.1932 11.0801 30.1467C11.0801 29.1003 10.2428 28.252 9.20997 28.252C8.17713 28.252 7.33984 29.1003 7.33984 30.1467C7.33984 31.1932 8.17713 32.0414 9.20997 32.0414Z' fill='white' />
        <path d='M9.20815 32.7515C7.78685 32.7515 6.63672 31.5863 6.63672 30.1463C6.63672 28.7063 7.78685 27.541 9.20815 27.541C10.6295 27.541 11.7796 28.7063 11.7796 30.1463C11.7796 31.5863 10.6295 32.7515 9.20815 32.7515ZM9.20815 28.9621C8.56295 28.9621 8.03932 29.4926 8.03932 30.1463C8.03932 30.8 8.56295 31.3305 9.20815 31.3305C9.85336 31.3305 10.377 30.8 10.377 30.1463C10.377 29.4926 9.85336 28.9621 9.20815 28.9621Z' fill='#222429' />
        <path d='M16.6924 21.6215C17.7252 21.6215 18.5625 20.7732 18.5625 19.7268C18.5625 18.6804 17.7252 17.832 16.6924 17.832C15.6595 17.832 14.8223 18.6804 14.8223 19.7268C14.8223 20.7732 15.6595 21.6215 16.6924 21.6215Z' fill='white' />
        <path d='M16.6906 22.3316C15.2693 22.3316 14.1191 21.1664 14.1191 19.7264C14.1191 18.2864 15.2693 17.1211 16.6906 17.1211C18.1119 17.1211 19.262 18.2864 19.262 19.7264C19.262 21.1664 18.1119 22.3316 16.6906 22.3316ZM16.6906 18.5421C16.0454 18.5421 15.5217 19.0727 15.5217 19.7264C15.5217 20.38 16.0454 20.9106 16.6906 20.9106C17.3358 20.9106 17.8594 20.38 17.8594 19.7264C17.8594 19.0727 17.3358 18.5421 16.6906 18.5421Z' fill='#222429' />
        <path d='M12.0166 11.1996C13.0494 11.1996 13.8867 10.3513 13.8867 9.30489C13.8867 8.25846 13.0494 7.41016 12.0166 7.41016C10.9837 7.41016 10.1465 8.25846 10.1465 9.30489C10.1465 10.3513 10.9837 11.1996 12.0166 11.1996Z' fill='white' />
        <path d='M12.0148 11.9077C10.5935 11.9077 9.44336 10.7425 9.44336 9.30253C9.44336 7.86253 10.5935 6.69727 12.0148 6.69727C13.4361 6.69727 14.5862 7.86253 14.5862 9.30253C14.5862 10.7425 13.4361 11.9077 12.0148 11.9077ZM12.0148 8.11832C11.3696 8.11832 10.846 8.64884 10.846 9.30253C10.846 9.95617 11.3696 10.4867 12.0148 10.4867C12.66 10.4867 13.1836 9.95617 13.1836 9.30253C13.1836 8.64884 12.66 8.11832 12.0148 8.11832Z' fill='#222429' />
        <path d='M24.1729 8.35588C25.2056 8.35588 26.043 7.50758 26.043 6.46114C26.043 5.41471 25.2056 4.56641 24.1729 4.56641C23.14 4.56641 22.3027 5.41471 22.3027 6.46114C22.3027 7.50758 23.14 8.35588 24.1729 8.35588Z' fill='white' />
        <path d='M24.171 9.06404C22.7497 9.06404 21.5996 7.89878 21.5996 6.45878C21.5996 5.01878 22.7497 3.85352 24.171 3.85352C25.5923 3.85352 26.7425 5.01878 26.7425 6.45878C26.7425 7.89878 25.5923 9.06404 24.171 9.06404ZM24.171 5.27457C23.5258 5.27457 23.0022 5.80509 23.0022 6.45878C23.0022 7.11246 23.5258 7.64299 24.171 7.64299C24.8162 7.64299 25.3399 7.11246 25.3399 6.45878C25.3399 5.80509 24.8162 5.27457 24.171 5.27457Z' fill='#222429' />
      </g>
      <defs>
        <clipPath id='clip0_9033_57865'>
          <rect width={width} height={height} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconSVGGetPaid
