import { css } from '@emotion/css'
import { Responsive } from 'website/constants'

export default css(`
  > div {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 16px;

    .ExtendedImage {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .Text1 {
      margin-bottom: 8px;
      min-height: 36px;
      text-align: center;

      @media (min-width: ${Responsive.WideMobileScreen}px) {
        margin-top: -120px;
      }
    }

    .Text2 {
      min-height: 60px;
      margin-bottom: 24px;
      text-align: center;
      &.subtitle-for-banner {
        p {
          font-family: Montserrat;
          font-size: 32px;
          font-weight: 700;
          text-transform: uppercase;

          @media (min-width: 767px) {
            font-size: 40px;
            line-height: 1.5;
          }
        }
      }
    }

    @media (max-width: ${Responsive.WideMobileScreen}px) {
      margin-top: 0px;

      .SearchBar, .SearchBarRounded {
        width: 100%;
        max-width: 600px;
      }
    }
  }
`)
