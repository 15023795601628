import { type FC } from 'react'
import { type IconProps } from 'website/components/types'

const IconSVGSuccessFinancingForm: FC<IconProps> = ({
  fill = 'var(--mainColor)',
  height = 79,
  width = 80
}) => {
  return (
    <svg width={width} height={height} viewBox='0 0 80 79' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M35.1781 78.5515C43.1195 78.5515 49.5573 72.1137 49.5573 64.1722C49.5573 56.2308 43.1195 49.793 35.1781 49.793C27.2366 49.793 20.7988 56.2308 20.7988 64.1722C20.7988 72.1137 27.2366 78.5515 35.1781 78.5515Z' fill='#F3F3F3' />
      <path d='M12.3251 43.2283C19.132 43.2283 24.6501 37.7101 24.6501 30.9032C24.6501 24.0962 19.132 18.5781 12.3251 18.5781C5.51812 18.5781 0 24.0962 0 30.9032C0 37.7101 5.51812 43.2283 12.3251 43.2283Z' fill='#F3F3F3' />
      <path d='M59.458 41.0836C70.803 41.0836 79.9998 31.8867 79.9998 20.5418C79.9998 9.19687 70.803 0 59.458 0C48.1131 0 38.9163 9.19687 38.9163 20.5418C38.9163 31.8867 48.1131 41.0836 59.458 41.0836Z' fill='#F3F3F3' />
      <path d='M69.3994 59.2929C67.643 57.0231 66.5441 54.3218 66.5851 51.487C66.883 28.1516 47.3581 19.4727 47.3581 19.4727C43.4448 23.2626 33.0712 23.2626 29.158 19.4727C29.158 19.4727 9.64334 28.1516 9.93092 51.487C9.96173 54.3218 8.87297 57.0231 7.11664 59.2929C6.17172 60.5152 5.38086 62.2407 5.38086 64.6132C5.38086 66.575 8.89356 66.3798 12.0467 65.8047C15.3231 65.209 18.7023 65.5171 21.8657 66.5545C25.707 67.8075 31.5306 69.1838 38.2477 68.9989C44.9752 69.1838 50.7885 67.8075 54.6298 66.5545C57.7932 65.5171 61.1724 65.209 64.4488 65.8047C67.6019 66.3798 71.1146 66.575 71.1146 64.6132C71.1351 62.2407 70.3443 60.5152 69.3994 59.2929Z' fill='white' />
      <path d='M69.3994 59.2929C67.643 57.0231 66.5441 54.3218 66.5851 51.487C66.883 28.1516 47.3581 19.4727 47.3581 19.4727C46.218 20.5819 44.513 21.3523 42.5924 21.8145C47.5737 25.3887 55.5542 33.287 55.3693 47.2349C55.3385 49.6999 56.2835 52.0417 57.8138 54.0034C58.6355 55.0613 59.3133 56.5609 59.3133 58.615C59.3133 60.3097 56.2731 60.1454 53.5308 59.6421C50.6858 59.1286 47.7586 59.3956 45.0163 60.2892C41.6782 61.3779 36.6352 62.5694 30.8013 62.4153C24.9675 62.5694 19.9245 61.3882 16.5967 60.2892C13.8544 59.3956 10.9271 59.1286 8.08211 59.6421C7.63019 59.7243 7.16805 59.7962 6.71613 59.8578C5.95608 61.0287 5.38086 62.5796 5.38086 64.6132C5.38086 66.575 8.89356 66.3798 12.0467 65.8047C15.3231 65.209 18.7023 65.5171 21.8657 66.5545C25.707 67.8075 31.5306 69.1838 38.2477 68.9989C44.9752 69.1838 50.7885 67.8075 54.6298 66.5545C57.7932 65.5171 61.1724 65.209 64.4488 65.8047C67.6019 66.3798 71.1146 66.575 71.1146 64.6132C71.1351 62.2407 70.3443 60.5152 69.3994 59.2929Z' fill={fill} fillOpacity={0.8} />
      <path d='M39.4697 69.78C39.0691 69.78 38.6583 69.78 38.2475 69.7594C31.3762 69.9443 25.4294 68.5167 21.6395 67.2739C18.5068 66.2468 15.2407 66.0003 12.2005 66.5549C8.66732 67.202 6.42829 67.0787 5.36012 66.1852C4.88765 65.7846 4.63086 65.2402 4.63086 64.6137C4.63086 62.3952 5.26768 60.4438 6.52073 58.8209C8.28733 56.5305 9.21166 54.0039 9.17058 51.4875C8.87272 27.9158 28.6545 18.8466 28.8496 18.7645C29.1372 18.6412 29.4762 18.6926 29.7021 18.9185C33.3072 22.4106 43.2289 22.4106 46.8237 18.9185C47.0497 18.7028 47.3886 18.6412 47.6762 18.7645C47.8714 18.8569 67.6531 27.9158 67.3552 51.4875C67.3244 53.9936 68.2385 56.5305 70.0051 58.8209C71.2582 60.4438 71.8949 62.3952 71.8949 64.6137C71.8949 65.2505 71.6382 65.7949 71.1657 66.1852C70.0976 67.0787 67.8585 67.202 64.3253 66.5549C61.2748 66.0003 58.019 66.2571 54.8863 67.2739C51.3326 68.4448 45.8479 69.78 39.4697 69.78ZM38.2475 68.2291C44.9852 68.4139 50.7472 67.0171 54.4139 65.8257C57.793 64.7164 61.3159 64.4494 64.6129 65.0554C68.8342 65.8257 69.9538 65.2197 70.19 65.0143C70.2927 64.9321 70.3646 64.8294 70.3646 64.624C70.3646 62.7547 69.8408 61.1216 68.7932 59.7761C66.8109 57.2084 65.7838 54.3428 65.8146 51.4875C66.0714 30.8636 50.3466 21.8663 47.4913 20.4078C43.1467 24.0129 33.3791 24.0129 29.0448 20.4078C26.1895 21.8765 10.4647 30.8944 10.7215 51.4875C10.7626 54.3531 9.72525 57.2187 7.74297 59.7761C6.70561 61.1216 6.17149 62.7547 6.17149 64.624C6.17149 64.8294 6.24337 64.9321 6.34608 65.0143C6.59258 65.2197 7.71217 65.8154 11.9232 65.0554C15.2202 64.4596 18.7431 64.7267 22.1222 65.8257C25.7889 67.0171 31.5714 68.4139 38.2475 68.2291Z' fill='#101010' />
      <path d='M47.3588 19.4707L52.0731 9.05606C53.018 6.98134 51.0049 4.79363 48.7761 5.45097L42.2335 7.37162C39.6453 8.13166 36.8824 8.13166 34.2942 7.37162L27.7516 5.45097C25.5126 4.79363 23.5097 6.98134 24.4546 9.05606L29.169 19.4707C33.0822 23.2607 43.4456 23.2607 47.3588 19.4707Z' fill='white' />
      <path d='M50.624 5.54688C50.5829 5.69067 50.5418 5.83447 50.4699 5.97827L46.6903 14.3182C45.807 16.2594 44.1636 17.759 42.1403 18.4163C37.5389 19.9159 31.0477 19.3407 27.8945 16.6806L29.1578 19.4742C33.071 23.2642 43.4447 23.2642 47.3579 19.4742L52.0722 9.05953C52.7398 7.60107 51.9387 6.08096 50.624 5.54688Z' fill={fill} fillOpacity={0.8} />
      <path d='M38.2671 23.0983C34.5079 23.0983 30.7488 22.0712 28.633 20.0273C28.5611 19.9554 28.5098 19.8835 28.4687 19.7911L23.7543 9.37637C23.2099 8.16441 23.4051 6.78811 24.2884 5.78156C25.2025 4.72366 26.6199 4.32308 27.9756 4.71338L34.5182 6.63403C36.973 7.35299 39.5715 7.35299 42.0263 6.63403L48.5688 4.71338C49.9246 4.31281 51.3419 4.72366 52.256 5.78156C53.1291 6.78811 53.3345 8.16441 52.7901 9.37637L48.0758 19.7911C48.0347 19.8835 47.9834 19.9554 47.9115 20.0273C45.7854 22.0712 42.0262 23.0983 38.2671 23.0983ZM29.8039 19.0208C33.45 22.3793 43.0738 22.3793 46.72 19.0208L51.3728 8.74986C51.6706 8.08225 51.5679 7.353 51.0851 6.79837C50.6846 6.32591 49.9143 5.92536 48.9899 6.20267L42.4473 8.12332C39.705 8.92445 36.8189 8.92445 34.0765 8.12332L27.534 6.20267C26.6096 5.93563 25.8496 6.33618 25.4387 6.79837C24.956 7.353 24.843 8.08225 25.1511 8.74986L29.8039 19.0208Z' fill='#101010' />
      <path d='M34.8507 22.7918C34.4912 22.7918 34.1626 22.535 34.0907 22.1653L32.2625 12.7777C32.1803 12.3566 32.4576 11.956 32.8684 11.8738C33.2895 11.7917 33.6901 12.069 33.7723 12.4798L35.6005 21.8674C35.6827 22.2885 35.4054 22.6891 34.9945 22.7713C34.9432 22.7815 34.9021 22.7918 34.8507 22.7918Z' fill='#101010' />
      <path d='M41.6798 22.7888C41.6079 22.7888 41.5463 22.7786 41.4744 22.758C41.0635 22.645 40.8273 22.224 40.9403 21.8131L43.7031 11.8709C43.8161 11.46 44.2373 11.2238 44.6481 11.3368C45.0589 11.4498 45.2951 11.8709 45.1821 12.2817L42.4193 22.224C42.3268 22.5629 42.0187 22.7888 41.6798 22.7888Z' fill='#101010' />
      <path d='M43.3941 65.9297H26.9607C26.3958 65.9297 25.9336 66.3919 25.9336 66.9568V70.038C25.9336 70.6029 26.3958 71.0651 26.9607 71.0651H43.3941C43.959 71.0651 44.4212 70.6029 44.4212 70.038V66.9568C44.4212 66.3919 43.9693 65.9297 43.3941 65.9297Z' fill='white' />
      <path d='M43.3949 71.8323H26.9615C25.9755 71.8323 25.1641 71.0312 25.1641 70.0349V66.9537C25.1641 65.9677 25.9755 65.1562 26.9615 65.1562H43.3949C44.3809 65.1562 45.1923 65.9677 45.1923 66.9537V70.0349C45.1923 71.0312 44.3912 71.8323 43.3949 71.8323ZM26.9615 66.6969C26.8177 66.6969 26.7047 66.8099 26.7047 66.9537V70.0349C26.7047 70.1787 26.8177 70.2917 26.9615 70.2917H43.3949C43.5387 70.2917 43.6517 70.1787 43.6517 70.0349V66.9537C43.6517 66.8099 43.5387 66.6969 43.3949 66.6969H26.9615Z' fill='#101010' />
      <path d='M31.5828 71.8323C31.1617 71.8323 30.8125 71.4934 30.8125 71.062V65.9266C30.8125 65.5055 31.1617 65.1562 31.5828 65.1562C32.0039 65.1562 32.3531 65.5055 32.3531 65.9266V71.062C32.3531 71.4934 32.0142 71.8323 31.5828 71.8323Z' fill='#101010' />
      <path d='M38.7723 71.8323C38.3512 71.8323 38.002 71.4934 38.002 71.062V65.9266C38.002 65.5055 38.3512 65.1562 38.7723 65.1562C39.1934 65.1562 39.5426 65.5055 39.5426 65.9266V71.062C39.5426 71.4934 39.2036 71.8323 38.7723 71.8323Z' fill='#101010' />
      <path d='M44.4215 60.793H27.988C27.4231 60.793 26.9609 61.2552 26.9609 61.8201V64.9013C26.9609 65.4662 27.4231 65.9284 27.988 65.9284H44.4215C44.9864 65.9284 45.4485 65.4662 45.4485 64.9013V61.8201C45.4485 61.2552 44.9966 60.793 44.4215 60.793Z' fill='white' />
      <path d='M44.4203 66.6995H27.9869C27.0009 66.6995 26.1895 65.8984 26.1895 64.9021V61.8208C26.1895 60.8348 27.0009 60.0234 27.9869 60.0234H44.4203C45.4063 60.0234 46.2177 60.8348 46.2177 61.8208V64.9021C46.2177 65.8984 45.4166 66.6995 44.4203 66.6995ZM27.9869 61.5641C27.8431 61.5641 27.7301 61.6771 27.7301 61.8208V64.9021C27.7301 65.0459 27.8431 65.1589 27.9869 65.1589H44.4203C44.5641 65.1589 44.6771 65.0459 44.6771 64.9021V61.8208C44.6771 61.6771 44.5641 61.5641 44.4203 61.5641H27.9869Z' fill='#101010' />
      <path d='M32.6102 66.6995C32.1891 66.6995 31.8398 66.3606 31.8398 65.9292V60.7938C31.8398 60.3726 32.1891 60.0234 32.6102 60.0234C33.0313 60.0234 33.3805 60.3726 33.3805 60.7938V65.9292C33.3805 66.3606 33.0415 66.6995 32.6102 66.6995Z' fill='#101010' />
      <path d='M39.7996 66.6995C39.3785 66.6995 39.0293 66.3606 39.0293 65.9292V60.7938C39.0293 60.3726 39.3785 60.0234 39.7996 60.0234C40.2207 60.0234 40.5699 60.3726 40.5699 60.7938V65.9292C40.5699 66.3606 40.231 66.6995 39.7996 66.6995Z' fill='#101010' />
      <path d='M42.3687 55.6562H25.9353C25.3704 55.6562 24.9082 56.1184 24.9082 56.6833V59.7646C24.9082 60.3295 25.3704 60.7917 25.9353 60.7917H42.3687C42.9336 60.7917 43.3958 60.3295 43.3958 59.7646V56.6833C43.3958 56.1184 42.9439 55.6562 42.3687 55.6562Z' fill='white' />
      <path d='M42.3676 61.5628H25.9341C24.9481 61.5628 24.1367 60.7617 24.1367 59.7654V56.6841C24.1367 55.6981 24.9481 54.8867 25.9341 54.8867H42.3676C43.3536 54.8867 44.165 55.6981 44.165 56.6841V59.7654C44.165 60.7617 43.3638 61.5628 42.3676 61.5628ZM25.9341 56.4274C25.7903 56.4274 25.6774 56.5403 25.6774 56.6841V59.7654C25.6774 59.9092 25.7903 60.0222 25.9341 60.0222H42.3676C42.5113 60.0222 42.6243 59.9092 42.6243 59.7654V56.6841C42.6243 56.5403 42.5113 56.4274 42.3676 56.4274H25.9341Z' fill='#101010' />
      <path d='M29.5301 61.5628C29.109 61.5628 28.7598 61.2239 28.7598 60.7925V55.657C28.7598 55.2359 29.109 54.8867 29.5301 54.8867C29.9512 54.8867 30.3004 55.2359 30.3004 55.657V60.7925C30.3004 61.2239 29.9615 61.5628 29.5301 61.5628Z' fill='#101010' />
      <path d='M36.7195 61.5628C36.2984 61.5628 35.9492 61.2239 35.9492 60.7925V55.657C35.9492 55.2359 36.2984 54.8867 36.7195 54.8867C37.1406 54.8867 37.4899 55.2359 37.4899 55.657V60.7925C37.4899 61.2239 37.1509 61.5628 36.7195 61.5628Z' fill='#101010' />
      <path d='M42.3687 50.5234H25.9353C25.3704 50.5234 24.9082 50.9856 24.9082 51.5505V54.6318C24.9082 55.1967 25.3704 55.6589 25.9353 55.6589H42.3687C42.9336 55.6589 43.3958 55.1967 43.3958 54.6318V51.5505C43.3958 50.9856 42.9439 50.5234 42.3687 50.5234Z' fill='white' />
      <path d='M42.3676 56.4261H25.9341C24.9481 56.4261 24.1367 55.625 24.1367 54.6287V51.5474C24.1367 50.5614 24.9481 49.75 25.9341 49.75H42.3676C43.3536 49.75 44.165 50.5614 44.165 51.5474V54.6287C44.165 55.625 43.3638 56.4261 42.3676 56.4261ZM25.9341 51.2906C25.7903 51.2906 25.6774 51.4036 25.6774 51.5474V54.6287C25.6774 54.7725 25.7903 54.8854 25.9341 54.8854H42.3676C42.5113 54.8854 42.6243 54.7725 42.6243 54.6287V51.5474C42.6243 51.4036 42.5113 51.2906 42.3676 51.2906H25.9341Z' fill='#101010' />
      <path d='M31.5828 56.4261C31.1617 56.4261 30.8125 56.0871 30.8125 55.6558V50.5203C30.8125 50.0992 31.1617 49.75 31.5828 49.75C32.0039 49.75 32.3531 50.0992 32.3531 50.5203V55.6558C32.3531 56.0871 32.0142 56.4261 31.5828 56.4261Z' fill='#101010' />
      <path d='M38.7723 56.4261C38.3512 56.4261 38.002 56.0871 38.002 55.6558V50.5203C38.002 50.0992 38.3512 49.75 38.7723 49.75C39.1934 49.75 39.5426 50.0992 39.5426 50.5203V55.6558C39.5426 56.0871 39.2036 56.4261 38.7723 56.4261Z' fill='#101010' />
      <path d='M69.0816 65.9297H52.6482C52.0833 65.9297 51.6211 66.3919 51.6211 66.9568V70.038C51.6211 70.6029 52.0833 71.0651 52.6482 71.0651H69.0816C69.6465 71.0651 70.1087 70.6029 70.1087 70.038V66.9568C70.1087 66.3919 69.6465 65.9297 69.0816 65.9297Z' fill='white' />
      <path d='M69.0804 71.8323H52.647C51.661 71.8323 50.8496 71.0312 50.8496 70.0349V66.9537C50.8496 65.9677 51.661 65.1562 52.647 65.1562H69.0804C70.0665 65.1562 70.8779 65.9677 70.8779 66.9537V70.0349C70.8779 71.0312 70.0665 71.8323 69.0804 71.8323ZM52.647 66.6969C52.5032 66.6969 52.3902 66.8099 52.3902 66.9537V70.0349C52.3902 70.1787 52.5032 70.2917 52.647 70.2917H69.0804C69.2242 70.2917 69.3372 70.1787 69.3372 70.0349V66.9537C69.3372 66.8099 69.2242 66.6969 69.0804 66.6969H52.647Z' fill='#101010' />
      <path d='M57.2703 71.8323C56.8492 71.8323 56.5 71.4934 56.5 71.062V65.9266C56.5 65.5055 56.8492 65.1562 57.2703 65.1562C57.6914 65.1562 58.0406 65.5055 58.0406 65.9266V71.062C58.0406 71.4934 57.6914 71.8323 57.2703 71.8323Z' fill='#101010' />
      <path d='M64.4598 71.8323C64.0387 71.8323 63.6895 71.4934 63.6895 71.062V65.9266C63.6895 65.5055 64.0387 65.1562 64.4598 65.1562C64.8809 65.1562 65.2301 65.5055 65.2301 65.9266V71.062C65.2301 71.4934 64.8809 71.8323 64.4598 71.8323Z' fill='#101010' />
      <path d='M70.109 60.793H53.6755C53.1106 60.793 52.6484 61.2552 52.6484 61.8201V64.9013C52.6484 65.4662 53.1106 65.9284 53.6755 65.9284H70.109C70.6739 65.9284 71.136 65.4662 71.136 64.9013V61.8201C71.136 61.2552 70.6739 60.793 70.109 60.793Z' fill='white' />
      <path d='M70.1078 66.6995H53.6744C52.6884 66.6995 51.877 65.8984 51.877 64.9021V61.8208C51.877 60.8348 52.6884 60.0234 53.6744 60.0234H70.1078C71.0938 60.0234 71.9052 60.8348 71.9052 61.8208V64.9021C71.9052 65.8984 71.0938 66.6995 70.1078 66.6995ZM53.6744 61.5641C53.5306 61.5641 53.4176 61.6771 53.4176 61.8208V64.9021C53.4176 65.0459 53.5306 65.1589 53.6744 65.1589H70.1078C70.2516 65.1589 70.3646 65.0459 70.3646 64.9021V61.8208C70.3646 61.6771 70.2516 61.5641 70.1078 61.5641H53.6744Z' fill='#101010' />
      <path d='M58.2977 66.6995C57.8766 66.6995 57.5273 66.3606 57.5273 65.9292V60.7938C57.5273 60.3726 57.8766 60.0234 58.2977 60.0234C58.7188 60.0234 59.068 60.3726 59.068 60.7938V65.9292C59.068 66.3606 58.7188 66.6995 58.2977 66.6995Z' fill='#101010' />
      <path d='M65.4871 66.6995C65.066 66.6995 64.7168 66.3606 64.7168 65.9292V60.7938C64.7168 60.3726 65.066 60.0234 65.4871 60.0234C65.9082 60.0234 66.2574 60.3726 66.2574 60.7938V65.9292C66.2574 66.3606 65.9082 66.6995 65.4871 66.6995Z' fill='#101010' />
      <path d='M69.0816 55.6562H52.6482C52.0833 55.6562 51.6211 56.1184 51.6211 56.6833V59.7646C51.6211 60.3295 52.0833 60.7917 52.6482 60.7917H69.0816C69.6465 60.7917 70.1087 60.3295 70.1087 59.7646V56.6833C70.1087 56.1184 69.6465 55.6562 69.0816 55.6562Z' fill='white' />
      <path d='M69.0804 61.5628H52.647C51.661 61.5628 50.8496 60.7617 50.8496 59.7654V56.6841C50.8496 55.6981 51.661 54.8867 52.647 54.8867H69.0804C70.0665 54.8867 70.8779 55.6981 70.8779 56.6841V59.7654C70.8779 60.7617 70.0665 61.5628 69.0804 61.5628ZM52.647 56.4274C52.5032 56.4274 52.3902 56.5403 52.3902 56.6841V59.7654C52.3902 59.9092 52.5032 60.0222 52.647 60.0222H69.0804C69.2242 60.0222 69.3372 59.9092 69.3372 59.7654V56.6841C69.3372 56.5403 69.2242 56.4274 69.0804 56.4274H52.647Z' fill='#101010' />
      <path d='M56.243 61.5628C55.8219 61.5628 55.4727 61.2239 55.4727 60.7925V55.657C55.4727 55.2359 55.8219 54.8867 56.243 54.8867C56.6641 54.8867 57.0133 55.2359 57.0133 55.657V60.7925C57.0133 61.2239 56.6641 61.5628 56.243 61.5628Z' fill='#101010' />
      <path d='M63.4324 61.5628C63.0113 61.5628 62.6621 61.2239 62.6621 60.7925V55.657C62.6621 55.2359 63.0113 54.8867 63.4324 54.8867C63.8535 54.8867 64.2027 55.2359 64.2027 55.657V60.7925C64.2027 61.2239 63.8535 61.5628 63.4324 61.5628Z' fill='#101010' />
      <path d='M68.0543 50.5234H51.6208C51.0559 50.5234 50.5938 50.9856 50.5938 51.5505V54.6318C50.5938 55.1967 51.0559 55.6589 51.6208 55.6589H68.0543C68.6192 55.6589 69.0814 55.1967 69.0814 54.6318V51.5505C69.0814 50.9856 68.6192 50.5234 68.0543 50.5234Z' fill='white' />
      <path d='M68.0551 56.4261H51.6216C50.6356 56.4261 49.8242 55.625 49.8242 54.6287V51.5474C49.8242 50.5614 50.6356 49.75 51.6216 49.75H68.0551C69.0411 49.75 69.8525 50.5614 69.8525 51.5474V54.6287C69.8525 55.625 69.0411 56.4261 68.0551 56.4261ZM51.6216 51.2906C51.4778 51.2906 51.3649 51.4036 51.3649 51.5474V54.6287C51.3649 54.7725 51.4778 54.8854 51.6216 54.8854H68.0551C68.1988 54.8854 68.3118 54.7725 68.3118 54.6287V51.5474C68.3118 51.4036 68.1988 51.2906 68.0551 51.2906H51.6216Z' fill='#101010' />
      <path d='M57.2703 56.4261C56.8492 56.4261 56.5 56.0871 56.5 55.6558V50.5203C56.5 50.0992 56.8492 49.75 57.2703 49.75C57.6914 49.75 58.0406 50.0992 58.0406 50.5203V55.6558C58.0406 56.0871 57.6914 56.4261 57.2703 56.4261Z' fill='#101010' />
      <path d='M64.4598 56.4261C64.0387 56.4261 63.6895 56.0871 63.6895 55.6558V50.5203C63.6895 50.0992 64.0387 49.75 64.4598 49.75C64.8809 49.75 65.2301 50.0992 65.2301 50.5203V55.6558C65.2301 56.0871 64.8809 56.4261 64.4598 56.4261Z' fill='#101010' />
      <path d='M70.109 45.3867H53.6755C53.1106 45.3867 52.6484 45.8489 52.6484 46.4138V49.4951C52.6484 50.06 53.1106 50.5222 53.6755 50.5222H70.109C70.6739 50.5222 71.136 50.06 71.136 49.4951V46.4138C71.136 45.8489 70.6739 45.3867 70.109 45.3867Z' fill='white' />
      <path d='M70.1078 51.2933H53.6744C52.6884 51.2933 51.877 50.4819 51.877 49.4959V46.4146C51.877 45.4183 52.6884 44.6172 53.6744 44.6172H70.1078C71.0938 44.6172 71.9052 45.4183 71.9052 46.4146V49.4959C71.9052 50.4921 71.0938 51.2933 70.1078 51.2933ZM53.6744 46.1578C53.5306 46.1578 53.4176 46.2708 53.4176 46.4146V49.4959C53.4176 49.6397 53.5306 49.7526 53.6744 49.7526H70.1078C70.2516 49.7526 70.3646 49.6397 70.3646 49.4959V46.4146C70.3646 46.2708 70.2516 46.1578 70.1078 46.1578H53.6744Z' fill='#101010' />
      <path d='M58.2977 51.2933C57.8766 51.2933 57.5273 50.9441 57.5273 50.523V45.3875C57.5273 44.9561 57.8766 44.6172 58.2977 44.6172C58.7188 44.6172 59.068 44.9561 59.068 45.3875V50.523C59.068 50.9543 58.7188 51.2933 58.2977 51.2933Z' fill='#101010' />
      <path d='M65.4871 51.2933C65.066 51.2933 64.7168 50.9441 64.7168 50.523V45.3875C64.7168 44.9561 65.066 44.6172 65.4871 44.6172C65.9082 44.6172 66.2574 44.9561 66.2574 45.3875V50.523C66.2574 50.9543 65.9082 51.2933 65.4871 51.2933Z' fill='#101010' />
      <path d='M69.0816 40.25H52.6482C52.0833 40.25 51.6211 40.7122 51.6211 41.2771V44.3584C51.6211 44.9233 52.0833 45.3854 52.6482 45.3854H69.0816C69.6465 45.3854 70.1087 44.9233 70.1087 44.3584V41.2771C70.1087 40.7122 69.6465 40.25 69.0816 40.25Z' fill='white' />
      <path d='M69.0804 46.1565H52.647C51.661 46.1565 50.8496 45.3451 50.8496 44.3591V41.2779C50.8496 40.2816 51.661 39.4805 52.647 39.4805H69.0804C70.0665 39.4805 70.8779 40.2816 70.8779 41.2779V44.3591C70.8779 45.3554 70.0665 46.1565 69.0804 46.1565ZM52.647 41.0211C52.5032 41.0211 52.3902 41.1341 52.3902 41.2779V44.3591C52.3902 44.5029 52.5032 44.6159 52.647 44.6159H69.0804C69.2242 44.6159 69.3372 44.5029 69.3372 44.3591V41.2779C69.3372 41.1341 69.2242 41.0211 69.0804 41.0211H52.647Z' fill='#101010' />
      <path d='M57.2703 46.1565C56.8492 46.1565 56.5 45.8073 56.5 45.3862V40.2508C56.5 39.8194 56.8492 39.4805 57.2703 39.4805C57.6914 39.4805 58.0406 39.8194 58.0406 40.2508V45.3862C58.0406 45.8073 57.6914 46.1565 57.2703 46.1565Z' fill='#101010' />
      <path d='M64.4598 46.1565C64.0387 46.1565 63.6895 45.8073 63.6895 45.3862V40.2508C63.6895 39.8194 64.0387 39.4805 64.4598 39.4805C64.8809 39.4805 65.2301 39.8194 65.2301 40.2508V45.3862C65.2301 45.8073 64.8809 46.1565 64.4598 46.1565Z' fill='#101010' />
      <path d='M34.1518 50.5223C39.8242 50.5223 44.4226 45.9238 44.4226 40.2514C44.4226 34.5789 39.8242 29.9805 34.1518 29.9805C28.4793 29.9805 23.8809 34.5789 23.8809 40.2514C23.8809 45.9238 28.4793 50.5223 34.1518 50.5223Z' fill='white' />
      <path d='M34.1492 50.5197C39.8187 50.5197 44.4201 45.9183 44.4201 40.2488C44.4201 36.7567 42.674 33.6754 40.0139 31.8164C41.1745 33.4803 41.8523 35.4934 41.8523 37.6811C41.8523 43.3506 37.251 47.952 31.5814 47.952C29.404 47.952 27.3807 47.2638 25.7168 46.1135C27.5758 48.7736 30.6571 50.5197 34.1492 50.5197Z' fill={fill} fillOpacity={0.8} />
      <path d='M34.1506 51.2934C28.0599 51.2934 23.1094 46.3428 23.1094 40.2521C23.1094 34.1615 28.0599 29.2109 34.1506 29.2109C40.2412 29.2109 45.1918 34.1615 45.1918 40.2521C45.1918 46.3428 40.2412 51.2934 34.1506 51.2934ZM34.1506 30.7516C28.9124 30.7516 24.65 35.014 24.65 40.2521C24.65 45.4903 28.9124 49.7527 34.1506 49.7527C39.3887 49.7527 43.6512 45.4903 43.6512 40.2521C43.6512 35.014 39.3887 30.7516 34.1506 30.7516Z' fill='#101010' />
      <path d='M41.4335 40.2509C41.4335 40.7234 41.0535 41.1034 40.581 41.1034C40.1086 41.1034 39.7285 40.7234 39.7285 40.2509C39.7285 39.7784 40.1086 39.3984 40.581 39.3984C41.0432 39.3984 41.4335 39.7784 41.4335 40.2509Z' fill='#101010' />
      <path d='M28.5937 40.2509C28.5937 40.7234 28.2136 41.1034 27.7412 41.1034C27.2687 41.1034 26.8887 40.7234 26.8887 40.2509C26.8887 39.7784 27.2687 39.3984 27.7412 39.3984C28.2034 39.3984 28.5937 39.7784 28.5937 40.2509Z' fill='#101010' />
      <path d='M34.162 46.4551C32.6008 46.4551 31.2245 45.5821 30.6596 44.2366C30.4953 43.8463 30.6801 43.3944 31.0704 43.2301C31.4607 43.0657 31.9127 43.2506 32.077 43.6409C32.3954 44.401 33.2376 44.9145 34.162 44.9145C35.3842 44.9145 36.3805 44.0415 36.3805 42.963C36.3805 41.6894 35.6102 41.0115 34.1517 41.0115C31.3786 41.0115 30.3926 39.2141 30.3926 37.5194C30.3926 35.5988 32.077 34.0273 34.1517 34.0273C35.7129 34.0273 37.0892 34.9004 37.6541 36.2459C37.8184 36.6362 37.6335 37.0881 37.2432 37.2524C36.853 37.4168 36.4011 37.2319 36.2367 36.8416C35.9183 36.0815 35.0761 35.568 34.1517 35.568C32.9295 35.568 31.9332 36.441 31.9332 37.5194C31.9332 38.8136 32.683 39.4709 34.1517 39.4709C36.9351 39.4709 37.9212 41.2683 37.9212 42.963C37.9212 44.894 36.2367 46.4551 34.162 46.4551Z' fill='#101010' />
      <path d='M34.1492 35.5885C33.7281 35.5885 33.3789 35.2495 33.3789 34.8182V33.3289C33.3789 32.9078 33.7281 32.5586 34.1492 32.5586C34.5703 32.5586 34.9195 32.9078 34.9195 33.3289V34.8182C34.9195 35.2495 34.5703 35.5885 34.1492 35.5885Z' fill='#101010' />
      <path d='M34.1492 47.9542C33.7281 47.9542 33.3789 47.605 33.3789 47.1839V45.6844C33.3789 45.253 33.7281 44.9141 34.1492 44.9141C34.5703 44.9141 34.9195 45.253 34.9195 45.6844V47.1839C34.9195 47.605 34.5703 47.9542 34.1492 47.9542Z' fill='#101010' />
      <path d='M60.8646 40.2488C66.5371 40.2488 71.1355 35.6504 71.1355 29.9779C71.1355 24.3055 66.5371 19.707 60.8646 19.707C55.1922 19.707 50.5938 24.3055 50.5938 29.9779C50.5938 35.6504 55.1922 40.2488 60.8646 40.2488Z' fill='white' />
      <path d='M60.8641 40.2502C66.5336 40.2502 71.135 35.6488 71.135 29.9793C71.135 26.4872 69.3889 23.4059 66.7288 21.5469C67.8894 23.2108 68.5672 25.2238 68.5672 27.4115C68.5672 33.0811 63.9659 37.6824 58.2963 37.6824C56.1189 37.6824 54.0955 36.9943 52.4316 35.8439C54.2907 38.5041 57.372 40.2502 60.8641 40.2502Z' fill={fill} fillOpacity={0.8} />
      <path d='M60.8654 41.0199C54.7748 41.0199 49.8242 36.0694 49.8242 29.9787C49.8242 23.8881 54.7748 18.9375 60.8654 18.9375C66.9561 18.9375 71.9066 23.8881 71.9066 29.9787C71.9066 36.0694 66.9458 41.0199 60.8654 41.0199ZM60.8654 20.4781C55.6273 20.4781 51.3649 24.7406 51.3649 29.9787C51.3649 35.2169 55.6273 39.4793 60.8654 39.4793C66.1036 39.4793 70.366 35.2169 70.366 29.9787C70.366 24.7406 66.1036 20.4781 60.8654 20.4781Z' fill='#101010' />
      <path d='M68.1366 29.9814C68.1366 30.4538 67.7566 30.8339 67.2841 30.8339C66.8117 30.8339 66.4316 30.4538 66.4316 29.9814C66.4316 29.5089 66.8117 29.1289 67.2841 29.1289C67.7566 29.1289 68.1366 29.5089 68.1366 29.9814Z' fill='#101010' />
      <path d='M55.2968 29.9814C55.2968 30.4538 54.9168 30.8339 54.4443 30.8339C53.9718 30.8339 53.5918 30.4538 53.5918 29.9814C53.5918 29.5089 53.9718 29.1289 54.4443 29.1289C54.9168 29.1289 55.2968 29.5089 55.2968 29.9814Z' fill='#101010' />
      <path d='M60.8651 36.1817C59.3039 36.1817 57.9277 35.3087 57.3628 33.9632C57.1984 33.5729 57.3833 33.121 57.7736 32.9566C58.1638 32.7923 58.6158 32.9772 58.7801 33.3675C59.0985 34.1275 59.9407 34.6411 60.8651 34.6411C62.0873 34.6411 63.0837 33.768 63.0837 32.6896C63.0837 31.416 62.3133 30.7381 60.8548 30.7381C58.0817 30.7381 57.0957 28.9407 57.0957 27.246C57.0957 25.3253 58.7801 23.7539 60.8548 23.7539C62.416 23.7539 63.7923 24.6269 64.3572 25.9724C64.5216 26.3627 64.3367 26.8146 63.9464 26.979C63.5561 27.1433 63.1042 26.9584 62.9398 26.5681C62.6214 25.8081 61.7792 25.2945 60.8548 25.2945C59.6326 25.2945 58.6363 26.1676 58.6363 27.246C58.6363 28.5401 59.3861 29.1975 60.8548 29.1975C63.6382 29.1975 64.6243 30.9949 64.6243 32.6896C64.6243 34.6205 62.9398 36.1817 60.8651 36.1817Z' fill='#101010' />
      <path d='M60.8563 25.3151C60.4351 25.3151 60.0859 24.9761 60.0859 24.5447V23.0555C60.0859 22.6241 60.4351 22.2852 60.8563 22.2852C61.2774 22.2852 61.6266 22.6241 61.6266 23.0555V24.5447C61.6266 24.9761 61.2774 25.3151 60.8563 25.3151Z' fill='#101010' />
      <path d='M60.8563 37.6847C60.4351 37.6847 60.0859 37.3355 60.0859 36.9144V35.4148C60.0859 34.9835 60.4351 34.6445 60.8563 34.6445C61.2774 34.6445 61.6266 34.9835 61.6266 35.4148V36.9144C61.6266 37.3355 61.2774 37.6847 60.8563 37.6847Z' fill='#101010' />
      <path d='M6.81856 26.6539H5.40118C4.98007 26.6539 4.63086 26.3047 4.63086 25.8836C4.63086 25.4625 4.98007 25.1133 5.40118 25.1133H6.81856C7.23967 25.1133 7.58888 25.4625 7.58888 25.8836C7.58888 26.3047 7.24994 26.6539 6.81856 26.6539Z' fill='#101010' />
      <path d='M11.5627 26.6539H10.1453C9.72421 26.6539 9.375 26.3047 9.375 25.8836C9.375 25.4625 9.72421 25.1133 10.1453 25.1133H11.5627C11.9838 25.1133 12.333 25.4625 12.333 25.8836C12.333 26.3047 11.9838 26.6539 11.5627 26.6539Z' fill='#101010' />
      <path d='M8.48321 29.7339C8.0621 29.7339 7.71289 29.3847 7.71289 28.9636V27.5359C7.71289 27.1148 8.0621 26.7656 8.48321 26.7656C8.90431 26.7656 9.25352 27.1148 9.25352 27.5359V28.9636C9.25352 29.3847 8.90431 29.7339 8.48321 29.7339Z' fill='#101010' />
      <path d='M8.48321 24.9893C8.0621 24.9893 7.71289 24.6401 7.71289 24.219V22.8016C7.71289 22.3805 8.0621 22.0312 8.48321 22.0312C8.90431 22.0312 9.25352 22.3805 9.25352 22.8016V24.219C9.25352 24.6401 8.90431 24.9893 8.48321 24.9893Z' fill='#101010' />
      <path d='M16.0647 52.3297H14.6473C14.2262 52.3297 13.877 51.9805 13.877 51.5594C13.877 51.1383 14.2262 50.7891 14.6473 50.7891H16.0647C16.4858 50.7891 16.835 51.1383 16.835 51.5594C16.835 51.9805 16.496 52.3297 16.0647 52.3297Z' fill='#101010' />
      <path d='M20.8088 52.3297H19.3914C18.9703 52.3297 18.6211 51.9805 18.6211 51.5594C18.6211 51.1383 18.9703 50.7891 19.3914 50.7891H20.8088C21.2299 50.7891 21.5791 51.1383 21.5791 51.5594C21.5791 51.9805 21.2299 52.3297 20.8088 52.3297Z' fill='#101010' />
      <path d='M17.7273 55.4111C17.3062 55.4111 16.957 55.0619 16.957 54.6408V53.2234C16.957 52.8023 17.3062 52.4531 17.7273 52.4531C18.1485 52.4531 18.4977 52.8023 18.4977 53.2234V54.6408C18.4977 55.0619 18.1485 55.4111 17.7273 55.4111Z' fill='#101010' />
      <path d='M17.7273 50.6651C17.3062 50.6651 16.957 50.3158 16.957 49.8947V48.4773C16.957 48.0562 17.3062 47.707 17.7273 47.707C18.1485 47.707 18.4977 48.0562 18.4977 48.4773V49.8947C18.4977 50.3261 18.1485 50.6651 17.7273 50.6651Z' fill='#101010' />
      <path d='M14.008 9.18126H12.5906C12.1695 9.18126 11.8203 8.84232 11.8203 8.41094C11.8203 7.98984 12.1695 7.64062 12.5906 7.64062H14.008C14.4291 7.64062 14.7783 7.98984 14.7783 8.41094C14.7783 8.84232 14.4394 9.18126 14.008 9.18126Z' fill='#101010' />
      <path d='M18.7522 9.18126H17.3348C16.9137 9.18126 16.5645 8.84232 16.5645 8.41094C16.5645 7.98984 16.9137 7.64062 17.3348 7.64062H18.7522C19.1733 7.64062 19.5225 7.98984 19.5225 8.41094C19.5225 8.84232 19.1733 9.18126 18.7522 9.18126Z' fill='#101010' />
      <path d='M15.6727 12.2627C15.2516 12.2627 14.9023 11.9238 14.9023 11.4924V10.075C14.9023 9.6539 15.2516 9.30469 15.6727 9.30469C16.0938 9.30469 16.443 9.6539 16.443 10.075V11.4924C16.443 11.9238 16.0938 12.2627 15.6727 12.2627Z' fill='#101010' />
      <path d='M15.6727 7.52833C15.2516 7.52833 14.9023 7.17912 14.9023 6.75802V5.34063C14.9023 4.91952 15.2516 4.57031 15.6727 4.57031C16.0938 4.57031 16.443 4.91952 16.443 5.34063V6.75802C16.443 7.17912 16.0938 7.52833 15.6727 7.52833Z' fill='#101010' />
      <path d='M62.2912 13.2906H60.8738C60.4527 13.2906 60.1035 12.9517 60.1035 12.5203C60.1035 12.0992 60.4527 11.75 60.8738 11.75H62.2912C62.7123 11.75 63.0615 12.0992 63.0615 12.5203C63.0615 12.9517 62.7123 13.2906 62.2912 13.2906Z' fill='#101010' />
      <path d='M67.0256 13.2906H65.6082C65.1871 13.2906 64.8379 12.9517 64.8379 12.5203C64.8379 12.0992 65.1871 11.75 65.6082 11.75H67.0256C67.4467 11.75 67.7959 12.0992 67.7959 12.5203C67.7959 12.9517 67.457 13.2906 67.0256 13.2906Z' fill='#101010' />
      <path d='M63.9461 16.3721C63.525 16.3721 63.1758 16.0331 63.1758 15.6018V14.1844C63.1758 13.7633 63.525 13.4141 63.9461 13.4141C64.3672 13.4141 64.7164 13.7633 64.7164 14.1844V15.6018C64.7164 16.0331 64.3775 16.3721 63.9461 16.3721Z' fill='#101010' />
      <path d='M63.9461 11.6377C63.525 11.6377 63.1758 11.2885 63.1758 10.8674V9.45C63.1758 9.0289 63.525 8.67969 63.9461 8.67969C64.3672 8.67969 64.7164 9.0289 64.7164 9.45V10.8674C64.7164 11.2885 64.3775 11.6377 63.9461 11.6377Z' fill='#101010' />
    </svg>
  )
}

export default IconSVGSuccessFinancingForm
