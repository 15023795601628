import { vehicleCardStyles } from './VehicleCard'

export default {
  VehicleCardSkeleton: {
    default: {
      root: `
        &.vehicle-card-skeleton {
          ${vehicleCardStyles}
          box-sizing: border-box;
          display: flex;
          flex-direction: column;

          [class*="block"] {
            border-radius: 12px;
          }

          .text-block {
            height: 16px;
          }

          .img-block {
            height: 165px;
            margin-bottom: 16px;
          }

          .content-block {
            margin-bottom: 48px;

            .text-block {
              margin-bottom: 16px;
            }

            .text-block:first-child {
              width: 100%;
            }

            .text-block:last-child {
              width: 50%;
            }
          }

          .footer-block {
            display: flex;
            justify-content: space-between;

            .text-block:first-child {
              width: 87px;
            }

            .text-block:last-child {
              width: 119px;
            }
          }
        }
      `
    }
  }
}
