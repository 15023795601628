import { css } from '@emotion/css'

export default css`
  .Input {
    .cf-input-container {
      border-radius: 48px;

      .cf-end-adornment {
        padding: 3px 2px 0;
        margin-right: -15px;
        height: min-content;
      }

      .adornment-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        cursor: pointer;
        background: var(--mainColor);
        border-radius: 50%;

        svg {
          circle {
            stroke: #fff;
          }

          path {
            stroke: #fff;
          }
        }
      }
    }
  }
`
