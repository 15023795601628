import { useEffect, useState, type FC } from 'react'
import { cnx, IconButton } from '@carfluent/common'

import Button from 'website/components/Button'

import type { DescriptionsCarouselProps } from 'website/types/components/componentsMap'

import RightArrowIcon from '../icons/RightArrow'

import CLASS_NAME from './styles'

const CHECKOUT_INTERVAL = 8000

const DescriptionsCarousel: FC<DescriptionsCarouselProps> = ({ items, buttonProps }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [clickUpdates, setClickUpdates] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prev) => {
        if (prev === items.length - 1) {
          return 0
        }

        return prev + 1
      })
    }, CHECKOUT_INTERVAL)

    return () => {
      clearInterval(interval)
    }
  }, [items, clickUpdates])

  return (
    <div className={CLASS_NAME}>
      <p className='title'>{items[activeIndex].title}</p>
      <div className='wrapper-indicator'>
        {items.map((_, index) => {
          return (
            <div
              key={index}
              className={cnx('indicator', (index === activeIndex) && 'active')}
            />
          )
        })}
      </div>

      <Button {...buttonProps} />

      <div className='arrow-wrapper'>
        <IconButton
          className={cnx('arrow', 'left', activeIndex === 0 && 'disabled')}
          onClick={() => {
            if (activeIndex === 0) {
              return
            }

            setActiveIndex((prev) => prev - 1)
            setClickUpdates(prev => ++prev)
          }}
        >
          <RightArrowIcon />
        </IconButton>

        <IconButton
          className={cnx('arrow', 'right', (activeIndex === items.length - 1) && 'disabled')}
          onClick={() => {
            if (activeIndex === items.length - 1) {
              return
            }

            setActiveIndex((prev) => prev + 1)
            setClickUpdates(prev => ++prev)
          }}
        >
          {/* it is rotated */}
          <RightArrowIcon />
        </IconButton>
      </div>

      <img src={items[activeIndex].image} />

      <p className='text'>{items[activeIndex].text}</p>
    </div>
  )
}

export default DescriptionsCarousel
