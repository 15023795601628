import { type FC } from 'react'
import { type IconProps } from 'website/components/types'

const IconSVGFinancingCash: FC<IconProps> = ({
  fill = 'var(--mainColor)',
  height = 32,
  width = 33
}) => {
  return (
    <svg width={width} height={height} viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M15.7258 20.7457C15.9369 20.5347 15.9369 20.1931 15.7258 19.982C15.5146 19.7716 15.1723 19.7716 14.9609 19.982C14.75 20.1931 14.75 20.5347 14.9609 20.7457C15.1723 20.9562 15.5146 20.9562 15.7258 20.7457Z' fill={fill} />
      <path d='M13.1641 7.93586C13.1641 11.2089 15.8321 13.8719 19.1115 13.8719C22.391 13.8719 25.059 11.2089 25.059 7.93586C25.059 4.66283 22.391 2 19.1115 2C15.8321 2 13.1641 4.66283 13.1641 7.93586ZM23.9778 7.93586C23.9778 10.6139 21.7948 12.7926 19.1115 12.7926C16.4283 12.7926 14.2453 10.6139 14.2453 7.93586C14.2453 5.25806 16.4283 3.07934 19.1115 3.07934C21.7948 3.07934 23.9778 5.25806 23.9778 7.93586Z' fill={fill} />
      <path d='M9.22777 29.4774C9.43734 29.6882 9.77853 29.6906 9.99085 29.4818L12.6122 26.9044C13.0575 26.4599 13.193 25.8019 12.9971 25.2298L13.561 24.6871C13.8646 24.3944 14.2643 24.2331 14.6868 24.2331H21.8746C23.1498 24.2331 24.355 23.7444 25.2687 22.8569C25.3064 22.8204 24.9833 23.2002 30.1724 17.0104C30.942 16.1017 30.8269 14.7371 29.9155 13.9681C29.0098 13.2063 27.6531 13.3157 26.8813 14.2089L23.6908 17.4817C23.2879 16.9868 22.6736 16.6784 22.0098 16.6784H15.9806C15.1226 16.3201 14.2123 16.1388 13.273 16.1388C10.6694 16.1388 8.39602 17.339 7.17598 19.5891C6.66198 19.4919 6.11988 19.6471 5.72989 20.0364L3.15776 22.6111C2.94777 22.8213 2.94734 23.1614 3.15692 23.3722L9.22777 29.4774ZM13.2728 17.2179C14.101 17.2179 14.9017 17.3843 15.6531 17.7124C15.7216 17.7423 15.7953 17.7577 15.8699 17.7577H22.0098C22.5961 17.7577 23.0913 18.2349 23.0913 18.8368C23.0913 19.4321 22.6062 19.9162 22.0098 19.9162H17.5984C17.2999 19.9162 17.0578 20.1578 17.0578 20.4557C17.0578 20.7539 17.2999 20.9953 17.5984 20.9953H22.0098C23.2024 20.9953 24.1725 20.0271 24.1725 18.8368C24.1725 18.742 24.1657 18.6479 24.1535 18.5547C27.2398 15.3884 27.675 14.9435 27.6936 14.9213C28.079 14.4674 28.763 14.4098 29.2181 14.7927C29.6736 15.1771 29.7313 15.8594 29.3449 16.3159L24.4953 22.1021C23.7872 22.7806 22.8574 23.154 21.8746 23.154H14.6868C13.9824 23.154 13.3157 23.4228 12.8099 23.9105L12.3504 24.3531L8.11462 20.1258C9.1046 18.2722 10.9622 17.2179 13.2728 17.2179ZM6.49508 20.799C6.67339 20.621 6.94718 20.589 7.16457 20.7231C7.25816 20.78 6.98944 20.5359 11.8476 25.3778C12.0638 25.5935 12.0521 25.9372 11.8506 26.1384L9.61586 28.3356L4.30323 22.9929L6.49508 20.799Z' fill={fill} />
      <path d='M18.4637 4.69993V5.33227C17.8343 5.55493 17.3822 6.1548 17.3822 6.85841C17.3822 7.75094 18.1098 8.47732 19.0043 8.47732C19.3024 8.47732 19.5449 8.71937 19.5449 9.01688C19.5449 9.31439 19.3024 9.55645 19.0043 9.55645C18.7734 9.55645 18.5239 9.41159 18.3023 9.14824C18.11 8.9201 17.769 8.89079 17.5405 9.08246C17.3119 9.27433 17.2823 9.61464 17.4745 9.84278C17.7635 10.1858 18.1035 10.4249 18.4637 10.5455V11.1754C18.4637 11.4735 18.7058 11.7149 19.0043 11.7149C19.3028 11.7149 19.5449 11.4735 19.5449 11.1754V10.543C20.1743 10.3201 20.6264 9.72028 20.6264 9.01688C20.6264 8.12414 19.8988 7.39797 19.0043 7.39797C18.7062 7.39797 18.4637 7.15592 18.4637 6.85841C18.4637 6.5609 18.7062 6.31863 19.0043 6.31863C19.1959 6.31863 19.3981 6.41625 19.5891 6.60075C19.8037 6.80801 20.146 6.80232 20.3536 6.58831C20.5613 6.37408 20.5556 6.03251 20.3412 5.82524C20.0667 5.5602 19.7921 5.41029 19.5449 5.32848V4.69993C19.5449 4.40179 19.3028 4.16016 19.0043 4.16016C18.7058 4.16016 18.4637 4.40179 18.4637 4.69993Z' fill={fill} />
    </svg>

  )
}

export default IconSVGFinancingCash
