import { type FC } from 'react'
import { type IconProps } from 'website/components/types'

const IconSVGSellTradeIcon: FC<IconProps> = ({
  fill = 'var(--mainColor)',
  height = 72,
  width = 72
}) => {
  return (
    <svg width={width} height={height} viewBox='0 0 72 72' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_9033_57815)'>
        <path d='M32.8215 71.3497C39.9587 71.3497 45.7446 65.9124 45.7446 59.2051C45.7446 52.4978 39.9587 47.0605 32.8215 47.0605C25.6844 47.0605 19.8984 52.4978 19.8984 59.2051C19.8984 65.9124 25.6844 71.3497 32.8215 71.3497Z' fill='#F7F8FA' />
        <path d='M60.5281 42.4619C66.6458 42.4619 71.605 37.8013 71.605 32.0522C71.605 26.3031 66.6458 21.6426 60.5281 21.6426C54.4105 21.6426 49.4512 26.3031 49.4512 32.0522C49.4512 37.8013 54.4105 42.4619 60.5281 42.4619Z' fill='#F7F8FA' />
        <path d='M18.7506 35.148C28.9466 35.148 37.2121 27.3804 37.2121 17.7986C37.2121 8.21681 28.9466 0.449219 18.7506 0.449219C8.55457 0.449219 0.289062 8.21681 0.289062 17.7986C0.289062 27.3804 8.55457 35.148 18.7506 35.148Z' fill='#F7F8FA' />
        <path d='M32.8223 58.6841V10.1058C32.8223 9.62864 33.2377 9.23828 33.7453 9.23828H63.2838C63.7915 9.23828 64.2069 9.62864 64.2069 10.1058V58.6841C64.2069 59.1612 63.7915 59.5515 63.2838 59.5515H33.7453C33.2377 59.5515 32.8223 59.1612 32.8223 58.6841Z' fill='white' />
        <path d='M32.8223 56.9525V10.9766H60.5146C61.53 10.9766 62.3607 11.7573 62.3607 12.7115V56.085C61.3453 56.085 60.5146 56.8657 60.5146 57.8199H33.7453C33.2377 57.8199 32.8223 57.4296 32.8223 56.9525Z' fill='white' />
        <path d='M63.2867 60.2023H33.7482C32.862 60.2023 32.1328 59.517 32.1328 58.6843V10.1059C32.1328 9.27317 32.862 8.58789 33.7482 8.58789H63.2867C64.1728 8.58789 64.902 9.27317 64.902 10.1059V58.6843C64.902 59.5257 64.182 60.2023 63.2867 60.2023ZM33.7482 9.88908C33.619 9.88908 33.5174 9.9845 33.5174 10.1059V58.6843C33.5174 58.8057 33.619 58.9011 33.7482 58.9011H63.2867C63.4159 58.9011 63.5174 58.8057 63.5174 58.6843V10.1059C63.5174 9.9845 63.4159 9.88908 63.2867 9.88908H33.7482Z' fill='#222429' />
        <path d='M32.8223 51.7443V17.0455C37.41 17.0455 41.13 13.5496 41.13 9.23828H55.8992C55.8992 13.5496 59.6192 17.0455 64.2069 17.0455V51.7443C59.6192 51.7443 55.8992 55.2402 55.8992 59.5515H41.13C41.13 55.2402 37.4192 51.7443 32.8223 51.7443Z' fill='white' />
        <path d='M60.5154 16.2285V48.828C56.8601 49.9384 53.9708 52.6536 52.7893 56.0888H40.2539C40.8078 57.1384 41.1308 58.3095 41.1308 59.5586H55.9001C55.9001 55.2473 59.6201 51.7514 64.2077 51.7514V17.0526C62.8877 17.0439 61.6324 16.749 60.5154 16.2285Z' fill={fill} fillOpacity='0.48' />
        <path d='M55.902 60.2023H41.1328C40.7544 60.2023 40.4405 59.9074 40.4405 59.5517C40.4405 55.6047 37.0251 52.3951 32.8251 52.3951C32.4467 52.3951 32.1328 52.1002 32.1328 51.7445V17.0457C32.1328 16.69 32.4467 16.3951 32.8251 16.3951C37.0251 16.3951 40.4405 13.1855 40.4405 9.23848C40.4405 8.88281 40.7544 8.58789 41.1328 8.58789H55.902C56.2805 8.58789 56.5944 8.88281 56.5944 9.23848C56.5944 13.1855 60.0097 16.3951 64.2097 16.3951C64.5882 16.3951 64.902 16.69 64.902 17.0457V51.7445C64.902 52.1002 64.5882 52.3951 64.2097 52.3951C60.0097 52.3951 56.5944 55.6047 56.5944 59.5517C56.5944 59.9161 56.2897 60.2023 55.902 60.2023ZM41.8067 58.9011H55.2467C55.5882 54.7546 59.1144 51.4322 63.5267 51.1199V17.6703C59.1144 17.3493 55.579 14.0356 55.2467 9.88908H41.8067C41.4651 14.0356 37.939 17.358 33.5267 17.6703V51.1199C37.9297 51.4409 41.4651 54.7546 41.8067 58.9011Z' fill='#222429' />
        <path d='M48.5198 53.9199C50.3041 53.9199 51.7506 52.5606 51.7506 50.8838C51.7506 49.207 50.3041 47.8477 48.5198 47.8477C46.7355 47.8477 45.2891 49.207 45.2891 50.8838C45.2891 52.5606 46.7355 53.9199 48.5198 53.9199Z' fill='white' />
        <path d='M50.4583 48.4668C50.4491 50.141 49.0091 51.4943 47.2276 51.4943C46.4983 51.4943 45.8337 51.2601 45.2891 50.8784C45.2799 52.7955 47.1537 54.2962 49.2768 53.8451C50.4583 53.5935 51.4091 52.6914 51.6676 51.581C51.9722 50.3058 51.4183 49.1521 50.4583 48.4668Z' fill='white' />
        <path d='M48.5149 54.5747C46.3549 54.5747 44.5918 52.9178 44.5918 50.8879C44.5918 48.858 46.3549 47.2012 48.5149 47.2012C50.6749 47.2012 52.438 48.858 52.438 50.8879C52.438 52.9178 50.6841 54.5747 48.5149 54.5747ZM48.5149 48.5024C47.1118 48.5024 45.9764 49.5694 45.9764 50.8879C45.9764 52.2065 47.1118 53.2735 48.5149 53.2735C49.918 53.2735 51.0533 52.2065 51.0533 50.8879C51.0533 49.5694 49.918 48.5024 48.5149 48.5024Z' fill='#222429' />
        <path d='M48.5198 20.9512C50.3041 20.9512 51.7506 19.5919 51.7506 17.9151C51.7506 16.2382 50.3041 14.8789 48.5198 14.8789C46.7355 14.8789 45.2891 16.2382 45.2891 17.9151C45.2891 19.5919 46.7355 20.9512 48.5198 20.9512Z' fill='white' />
        <path d='M50.4583 15.5039C50.4491 17.1781 49.0091 18.5314 47.2276 18.5314C46.4983 18.5314 45.8337 18.2972 45.2891 17.9155C45.2799 19.8326 47.1537 21.3333 49.2768 20.8822C50.4583 20.6307 51.4091 19.7285 51.6676 18.6181C51.9722 17.3429 51.4183 16.1805 50.4583 15.5039Z' fill='white' />
        <path d='M48.5149 21.5981C46.3549 21.5981 44.5918 19.9412 44.5918 17.9114C44.5918 15.8815 46.3549 14.2246 48.5149 14.2246C50.6749 14.2246 52.438 15.8815 52.438 17.9114C52.438 19.9499 50.6841 21.5981 48.5149 21.5981ZM48.5149 15.5258C47.1118 15.5258 45.9764 16.5928 45.9764 17.9114C45.9764 19.2299 47.1118 20.2969 48.5149 20.2969C49.918 20.2969 51.0533 19.2299 51.0533 17.9114C51.0533 16.5928 49.918 15.5258 48.5149 15.5258Z' fill='#222429' />
        <path d='M48.514 43.0701C53.612 43.0701 57.7447 39.1864 57.7447 34.3954C57.7447 29.6045 53.612 25.7207 48.514 25.7207C43.416 25.7207 39.2832 29.6045 39.2832 34.3954C39.2832 39.1864 43.416 43.0701 48.514 43.0701Z' fill='white' />
        <path d='M55.405 28.625C55.6819 29.9262 55.6542 31.3315 55.1927 32.7802C54.1219 36.1199 50.9557 38.6096 47.2634 38.9392C44.1619 39.2168 41.3465 38.0457 39.4727 36.0766C40.405 40.5093 44.9096 43.7276 50.0511 42.9469C53.8173 42.3831 56.8911 39.5725 57.5927 36.0505C58.1557 33.1966 57.2142 30.5334 55.405 28.625Z' fill='white' />
        <path d='M48.5168 43.7209C43.043 43.7209 38.5938 39.5397 38.5938 34.3956C38.5938 29.2515 43.043 25.0703 48.5168 25.0703C53.9907 25.0703 58.4399 29.2515 58.4399 34.3956C58.4399 39.5397 53.9907 43.7209 48.5168 43.7209ZM48.5168 26.3715C43.8091 26.3715 39.9784 29.9715 39.9784 34.3956C39.9784 38.8197 43.8091 42.4197 48.5168 42.4197C53.2245 42.4197 57.0553 38.8197 57.0553 34.3956C57.0553 29.9715 53.2245 26.3715 48.5168 26.3715Z' fill='#222429' />
        <path d='M46.1815 37.4746C44.5107 37.4746 43.1445 36.0953 43.1445 34.4037C43.1445 33.1286 43.9015 32.0008 45.0738 31.5411C45.4338 31.4023 45.8307 31.5584 45.9784 31.8881C46.1261 32.2177 45.9599 32.5994 45.6091 32.7382C44.963 32.9898 44.5291 33.6664 44.5291 34.4037C44.5291 35.384 45.2676 36.1734 46.1815 36.1734C47.2799 36.1734 47.8338 35.5748 47.8338 34.3951C47.8338 32.131 49.403 31.3242 50.8707 31.3242C52.5415 31.3242 53.9076 32.7035 53.9076 34.3951C53.9076 35.6702 53.1507 36.798 51.9784 37.2577C51.6276 37.3965 51.2215 37.2404 51.0738 36.9107C50.9261 36.5811 51.0922 36.1994 51.443 36.0606C52.0891 35.8004 52.523 35.1324 52.523 34.3951C52.523 33.4148 51.7845 32.6254 50.8707 32.6254C49.7722 32.6254 49.2184 33.224 49.2184 34.3951C49.2091 36.6678 47.6399 37.4746 46.1815 37.4746Z' fill='#222429' />
        <path d='M54.4866 35.0434H53.1943C52.8158 35.0434 52.502 34.7485 52.502 34.3928C52.502 34.0371 52.8158 33.7422 53.1943 33.7422H54.4866C54.865 33.7422 55.1789 34.0371 55.1789 34.3928C55.1789 34.7485 54.8743 35.0434 54.4866 35.0434Z' fill='#222429' />
        <path d='M43.8342 35.0434H42.5419C42.1635 35.0434 41.8496 34.7485 41.8496 34.3928C41.8496 34.0371 42.1635 33.7422 42.5419 33.7422H43.8342C44.2127 33.7422 44.5265 34.0371 44.5265 34.3928C44.5265 34.7485 44.2127 35.0434 43.8342 35.0434Z' fill='#222429' />
        <path d='M34.6712 24.8541V63.0228C34.6712 63.977 33.8405 64.7577 32.8251 64.7577H6.97897C5.96358 64.7577 5.13281 63.977 5.13281 63.0228V24.8541C5.13281 23.8999 5.96358 23.1191 6.97897 23.1191H32.8251C33.8497 23.1191 34.6712 23.8999 34.6712 24.8541Z' fill={fill} fillOpacity='0.48' />
        <path d='M30.9789 23.1191V60.4203C30.9789 60.8975 30.5635 61.2878 30.0559 61.2878H5.13281V23.9866C5.13281 23.5095 5.5482 23.1191 6.05589 23.1191H30.9789Z' fill='white' />
        <path d='M32.8279 65.4046H6.98182C5.57874 65.4046 4.44336 64.3376 4.44336 63.0191V24.8504C4.44336 23.5318 5.57874 22.4648 6.98182 22.4648H32.8279C34.231 22.4648 35.3664 23.5318 35.3664 24.8504V63.0191C35.3664 64.3376 34.231 65.4046 32.8279 65.4046ZM6.98182 23.766C6.3449 23.766 5.82797 24.2518 5.82797 24.8504V63.0191C5.82797 63.6176 6.3449 64.1034 6.98182 64.1034H32.8279C33.4649 64.1034 33.9818 63.6176 33.9818 63.0191V24.8504C33.9818 24.2518 33.4649 23.766 32.8279 23.766H6.98182Z' fill='#222429' />
        <path d='M16.6478 52.2659V58.3382C16.6478 58.8153 16.2325 59.2057 15.7248 59.2057H12.0325C11.5248 59.2057 11.1094 58.8153 11.1094 58.3382V52.2659C11.1094 51.7888 11.5248 51.3984 12.0325 51.3984H15.7248C16.2417 51.3984 16.6478 51.7888 16.6478 52.2659Z' fill='white' />
        <path d='M14.8017 51.3984V56.6033C14.8017 57.0804 14.3863 57.4707 13.8786 57.4707H11.1094V52.2659C11.1094 51.7888 11.5248 51.3984 12.0325 51.3984H14.8017Z' fill='white' />
        <path d='M15.7276 59.8643H12.0353C11.1492 59.8643 10.4199 59.179 10.4199 58.3462V52.2739C10.4199 51.4412 11.1492 50.7559 12.0353 50.7559H15.7276C16.6138 50.7559 17.343 51.4412 17.343 52.2739V58.3462C17.343 59.179 16.623 59.8643 15.7276 59.8643ZM12.0353 52.0571C11.9061 52.0571 11.8045 52.1525 11.8045 52.2739V58.3462C11.8045 58.4677 11.9061 58.5631 12.0353 58.5631H15.7276C15.8568 58.5631 15.9584 58.4677 15.9584 58.3462V52.2739C15.9584 52.1525 15.8568 52.0571 15.7276 52.0571H12.0353Z' fill='#222429' />
        <path d='M27.5654 48.1026C27.1869 48.1026 26.873 47.8077 26.873 47.452V35.7151C26.873 35.3594 27.1869 35.0645 27.5654 35.0645C27.9438 35.0645 28.2577 35.3594 28.2577 35.7151V47.452C28.2577 47.8163 27.9438 48.1026 27.5654 48.1026Z' fill='#222429' />
        <path d='M27.5654 60.0138C27.1869 60.0138 26.873 59.7189 26.873 59.3632V51.5647C26.873 51.209 27.1869 50.9141 27.5654 50.9141C27.9438 50.9141 28.2577 51.209 28.2577 51.5647V59.3632C28.2577 59.7276 27.9438 60.0138 27.5654 60.0138Z' fill='#222429' />
        <path d='M22.0888 59.8623C21.7103 59.8623 21.3965 59.5673 21.3965 59.2116V54.8049C21.3965 54.4492 21.7103 54.1543 22.0888 54.1543C22.4673 54.1543 22.7811 54.4492 22.7811 54.8049V59.2116C22.7811 59.5673 22.4765 59.8623 22.0888 59.8623Z' fill='#222429' />
        <path d='M22.0888 51.0517C21.7103 51.0517 21.3965 50.7568 21.3965 50.4011V45.9944C21.3965 45.6387 21.7103 45.3438 22.0888 45.3438C22.4673 45.3438 22.7811 45.6387 22.7811 45.9944V50.4011C22.7811 50.7568 22.4765 51.0517 22.0888 51.0517Z' fill='#222429' />
        <path d='M22.0888 42.2353C21.7103 42.2353 21.3965 41.9404 21.3965 41.5847V37.1779C21.3965 36.8223 21.7103 36.5273 22.0888 36.5273C22.4673 36.5273 22.7811 36.8223 22.7811 37.1779V41.5847C22.7811 41.9404 22.4765 42.2353 22.0888 42.2353Z' fill='#222429' />
        <path d='M22.0888 33.4248C21.7103 33.4248 21.3965 33.1298 21.3965 32.7741V28.3674C21.3965 28.0117 21.7103 27.7168 22.0888 27.7168C22.4673 27.7168 22.7811 28.0117 22.7811 28.3674V32.7741C22.7811 33.1298 22.4765 33.4248 22.0888 33.4248Z' fill='#222429' />
        <path d='M13.8786 37.9079C15.4081 37.9079 16.6478 36.7428 16.6478 35.3055C16.6478 33.8682 15.4081 32.7031 13.8786 32.7031C12.3493 32.7031 11.1094 33.8682 11.1094 35.3055C11.1094 36.7428 12.3493 37.9079 13.8786 37.9079Z' fill='white' />
        <path d='M15.7093 33.3633C15.7186 33.4327 15.7278 33.5021 15.7278 33.5715C15.7278 35.0115 14.4909 36.1739 12.9586 36.1739C12.257 36.1739 11.6201 35.9223 11.1309 35.5146C11.2416 36.8505 12.4232 37.9088 13.8816 37.9088C15.4139 37.9088 16.6509 36.7464 16.6509 35.3064C16.6509 34.5257 16.2816 33.8404 15.7093 33.3633Z' fill='white' />
        <path d='M13.8815 38.5607C11.9707 38.5607 10.4199 37.1034 10.4199 35.3077C10.4199 33.512 11.9707 32.0547 13.8815 32.0547C15.7922 32.0547 17.343 33.512 17.343 35.3077C17.343 37.1034 15.7922 38.5607 13.8815 38.5607ZM13.8815 33.3559C12.7368 33.3559 11.8045 34.232 11.8045 35.3077C11.8045 36.3834 12.7368 37.2595 13.8815 37.2595C15.0261 37.2595 15.9584 36.3834 15.9584 35.3077C15.9584 34.232 15.0261 33.3559 13.8815 33.3559Z' fill='#222429' />
        <path d='M13.8786 33.5681C15.4081 33.5681 16.6478 32.4029 16.6478 30.9657C16.6478 29.5284 15.4081 28.3633 13.8786 28.3633C12.3493 28.3633 11.1094 29.5284 11.1094 30.9657C11.1094 32.4029 12.3493 33.5681 13.8786 33.5681Z' fill='white' />
        <path d='M15.7093 29.0254C15.7186 29.0948 15.7278 29.1642 15.7278 29.2336C15.7278 30.6736 14.4909 31.836 12.9586 31.836C12.257 31.836 11.6201 31.5844 11.1309 31.1767C11.2416 32.5126 12.4232 33.5709 13.8816 33.5709C15.4139 33.5709 16.6509 32.4085 16.6509 30.9685C16.6509 30.1878 16.2816 29.5025 15.7093 29.0254Z' fill='white' />
        <path d='M13.8815 34.2228C11.9707 34.2228 10.4199 32.7655 10.4199 30.9698C10.4199 29.1741 11.9707 27.7168 13.8815 27.7168C15.7922 27.7168 17.343 29.1741 17.343 30.9698C17.343 32.7655 15.7922 34.2228 13.8815 34.2228ZM13.8815 29.018C12.7368 29.018 11.8045 29.8941 11.8045 30.9698C11.8045 32.0455 12.7368 32.9216 13.8815 32.9216C15.0261 32.9216 15.9584 32.0455 15.9584 30.9698C15.9584 29.8941 15.0261 29.018 13.8815 29.018Z' fill='#222429' />
        <path d='M28.2069 11.6235H24.5146C24.1361 11.6235 23.8223 11.3285 23.8223 10.9729C23.8223 10.6172 24.1361 10.3223 24.5146 10.3223H28.2069C28.5853 10.3223 28.8992 10.6172 28.8992 10.9729C28.8992 11.3372 28.5946 11.6235 28.2069 11.6235Z' fill='#222429' />
        <path d='M26.3642 13.359C25.9857 13.359 25.6719 13.064 25.6719 12.7084V9.23848C25.6719 8.88281 25.9857 8.58789 26.3642 8.58789C26.7426 8.58789 27.0565 8.88281 27.0565 9.23848V12.7084C27.0565 13.0727 26.7519 13.359 26.3642 13.359Z' fill='#222429' />
        <path d='M20.8241 18.5629H17.1318C16.7533 18.5629 16.4395 18.268 16.4395 17.9123C16.4395 17.5567 16.7533 17.2617 17.1318 17.2617H20.8241C21.2025 17.2617 21.5164 17.5567 21.5164 17.9123C21.5164 18.2767 21.2118 18.5629 20.8241 18.5629Z' fill='#222429' />
        <path d='M18.9814 20.3004C18.6029 20.3004 18.2891 20.0054 18.2891 19.6498V16.1799C18.2891 15.8242 18.6029 15.5293 18.9814 15.5293C19.3598 15.5293 19.6737 15.8242 19.6737 16.1799V19.6498C19.6737 20.0141 19.3691 20.3004 18.9814 20.3004Z' fill='#222429' />
        <path d='M9.74981 15.0961H6.05754C5.67908 15.0961 5.36523 14.8012 5.36523 14.4455C5.36523 14.0899 5.67908 13.7949 6.05754 13.7949H9.74981C10.1283 13.7949 10.4421 14.0899 10.4421 14.4455C10.4421 14.8099 10.1375 15.0961 9.74981 15.0961Z' fill='#222429' />
        <path d='M7.89934 16.8297C7.52088 16.8297 7.20703 16.5347 7.20703 16.1791V12.7092C7.20703 12.3535 7.52088 12.0586 7.89934 12.0586C8.2778 12.0586 8.59165 12.3535 8.59165 12.7092V16.1791C8.59165 16.5434 8.28703 16.8297 7.89934 16.8297Z' fill='#222429' />
      </g>
      <defs>
        <clipPath id='clip0_9033_57815'>
          <rect width={width} height={height} fill='white' />
        </clipPath>
      </defs>
    </svg>

  )
}

export default IconSVGSellTradeIcon
