import { useEffect, useState, type FC, useCallback } from 'react'
import { cnx, Button, Modal, useModal } from '@carfluent/common'
import GeoApiProvider from 'website/api/geo.api'
import useMediaQueryByBreakpoints from 'website/hooks/useMediaQueryByBreakpoints'
import { setZipCodeLocationInLS } from 'website/services/storage/zipCodeLocation'
import SharedStateHook, { defaultInstance, StoreBranches } from 'website/store'
import { SHOULD_ZIP_CODE_POPOVER_OPEN } from 'website/constants/localStorageKeys'
import LS from 'services/storage.service'
import translate from 'website/utils/translate'
import { isStringEmpty } from 'website/utils/isStringEmpty'

import { SupportedComponents, type ZipCodeLocationModalProps } from '../types'
import CLASS_NAME from './styles'
import ZipCodeInput from '../VehiclesFilter/components/ZipCodeInput'

const MAX_WIDTH = 580

const useZipCodeLocationState = SharedStateHook<Store.ZipCodeLocationState>(StoreBranches.ZipCodeLocation)

const ZipCodeLocationModal: FC<ZipCodeLocationModalProps> = ({
  isOpen: _isOpen,
  breakpoints,
  onClose: _onClose,
  nameInLayout = SupportedComponents.ZipCodeLocation
}) => {
  const [zipCodeLocationState, setZipCodeLocationState] = useZipCodeLocationState(
    defaultInstance(StoreBranches.ZipCodeLocation), setZipCodeLocationInLS
  )

  const [zipCode, setZipCode] = useState<string | null>(null)

  const { isModalOpen, onOpenModal, onCloseModal } = useModal()
  const { isMatchingBreakpoints } = useMediaQueryByBreakpoints(breakpoints)

  const isOpen = isMatchingBreakpoints === true && (_isOpen ?? isModalOpen)
  const isControlled = _isOpen !== undefined
  const onClose = (): void => {
    const func = _onClose ?? onCloseModal
    func()
    setZipCode(null)
  }

  const updateZipCode = useCallback(async (zipCode: string): Promise<void> => {
    const geo = await GeoApiProvider.getGeoByZip(zipCode)

    setZipCodeLocationState({
      ...geo,
      city: geo?.city ?? '',
      country: geo?.country ?? '',
      zipCode,
      lastUpdateTs: Date.now()
    })
  }, [setZipCodeLocationState])

  // This modal might be opened by parent component or be an independent component on a page
  useEffect(() => {
    if (isControlled) {
      return
    }

    // if it is opened without parent it should try to fetch location to suggest to a user
    // if zip is already present it will not even show up (it shows up only once)
    if (isStringEmpty(zipCodeLocationState.zipCode)) {
      if (LS.get<boolean>(SHOULD_ZIP_CODE_POPOVER_OPEN) === true) {
        return
      }

      LS.set(SHOULD_ZIP_CODE_POPOVER_OPEN, true)
      onOpenModal()
    }
  }, [isControlled, zipCodeLocationState.zipCode, onOpenModal])

  const locationMessage = isStringEmpty(zipCodeLocationState.city)
    ? ''
    : `${zipCodeLocationState.city}, ${zipCodeLocationState.country}`

  const zipCodeValue = zipCode ?? zipCodeLocationState.zipCode ?? ''

  return (
    <Modal
      className={cnx(nameInLayout, CLASS_NAME)}
      isOpen={isOpen}
      maxWidth={MAX_WIDTH}
      onClose={onClose}
      title='Your location'
    >
      <>
        <p className='location-message'>{locationMessage}</p>
        <p className='location-disclaimer'>To ensure a better browsing experience, please enter your ZIP code</p>

        <ZipCodeInput onChange={setZipCode} value={zipCodeValue} />

        <Button
          className='btn-update-zip-code'
          onClick={() => {
            void updateZipCode(zipCodeValue)
            onClose()
          }}
        >
          {translate('UPDATE ZIP CODE')}
        </Button>

        <Button
          className='btn-use-detected-location'
          onClick={onClose}
          variant='text'
        >
          {translate('USE MY DETECTED LOCATION')}
        </Button>
      </>
    </Modal>
  )
}

export default ZipCodeLocationModal
