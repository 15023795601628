import { useCallback, useEffect } from 'react'
import SharedStateHook, { StoreBranches, defaultInstance } from 'website/store'

interface MetaTag {
  name: string
  content: string
}

interface OpenGraphTag {
  property: string
  content: string
}

interface HelmetProps {
  title?: string
  meta?: MetaTag[]
  canonical?: string
  ogTags?: OpenGraphTag[]
}

const useSharedState = SharedStateHook<Store.Dealership>(StoreBranches.Dealership)

// this hook is used to update the meta tags of the document head
// it accepts an object with the following properties:
// - title: the title of the page
// - meta: an array of meta tags to be added to the head
//   as usual it's description, keywords, author, etc.
// - canonical: the canonical URL of the page
// - ogTags: an array of open graph tags to be added to the head

const useHelmet = ({ title, meta, canonical, ogTags }: HelmetProps): void => {
  const [dealerInfo] = useSharedState(defaultInstance(StoreBranches.Dealership))
  const dealership = dealerInfo?.dealerships?.[0]

  // this function replaces the placeholders {{dealerName}}, {{dealerCity}}, and {{dealerState}} from i18n in template strings json
  const updatedDealerInfoInText = useCallback((originalText: string): string =>
    originalText.replace(/\{\{(dealerName|dealerCity|dealerState)\}\}/g,
      (_, p1) => {
        switch (p1) {
          case 'dealerName': return dealership?.dealerName ?? ''
          case 'dealerCity': return dealership?.city ?? ''
          case 'dealerState': return dealership?.state ?? ''
          default: return ''
        }
      }), [dealership])

  useEffect(() => {
    const head = document.head

    const updateTag = (selector: string, attributes: Record<string, string>): HTMLElement => {
      let tag = document.querySelector(selector) as HTMLElement
      if (tag === null) {
        tag = document.createElement(
          (attributes?.name !== undefined && attributes.name !== '') ||
          (attributes?.property !== undefined && attributes.property !== '')
            ? 'meta'
            : 'link'
        )
        Object.keys(attributes).forEach((key) => tag.setAttribute(key, attributes[key]))
        head.insertBefore(tag, head.firstChild)
      } else {
        Object.keys(attributes).forEach((key) => tag.setAttribute(key, attributes[key]))
      }
      return tag
    }

    if (title != null && title !== '') {
      const updatedTitle = updatedDealerInfoInText(title)
      document.title = updatedTitle
    }

    const metaTags = meta?.map(({ name, content }) =>
      updateTag(`meta[name="${name}"]`, { name, content: updatedDealerInfoInText(content) })
    )

    const canonicalTag = (canonical != null && canonical !== '')
      ? updateTag('link[rel="canonical"]', { rel: 'canonical', href: canonical })
      : null

    const ogMetaTags = ogTags?.map(({ property, content }) =>
      updateTag(`meta[property="${property}"]`, { property, content: updatedDealerInfoInText(content) })
    )

    return () => {
      metaTags?.forEach((tag) => head.removeChild(tag))
      if (canonicalTag != null) head.removeChild(canonicalTag)
      ogMetaTags?.forEach((tag) => head.removeChild(tag))
    }
  }, [title, meta, canonical, ogTags, updatedDealerInfoInText])
}

export default useHelmet
