import { ChangeEvent, useRef, useState, type FC, type KeyboardEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import type { InputBaseComponentProps } from '@carfluent/common/dist/UI/Input/types'
import { cnx } from '@carfluent/common'

import Input from 'website/components/Input'
import SvgIcon from 'website/components/_base/SvgIcon'

import { updateEntryInQs } from 'website/utils/qsfilters'
import { WebsiteRoutes } from 'website/routing/constants'

import { type SearchBarRoundedProps, SupportedComponents } from '../types'
import CLASS_NAME from './styles'

const SEARCH_INPUT_PROPS: InputBaseComponentProps = { inputMode: 'search' }

const SearchBarRounded: FC<SearchBarRoundedProps> = ({
  nameInLayout = SupportedComponents.SearchBarRounded,
  shouldResetOnSearch = false,
  onSearch: _onSearch,
  placeholder,
  label
}) => {
  const navigate = useNavigate()

  const [inputText, setInputText] = useState('')
  const inputRef = useRef<HTMLInputElement | null>(null)

  const onSearch = (): void => {
    navigate(WebsiteRoutes.Inventory)
    updateEntryInQs('text', inputText)

    if (shouldResetOnSearch === true) {
      setInputText('')
      inputRef.current?.blur()
    }

    _onSearch?.(inputText)
  }

  const onKeyPress = (evt: KeyboardEvent<HTMLInputElement>): void => {
    if (evt.key === 'Enter') {
      onSearch()
    }
  }

  return (
    <div className={cnx(nameInLayout, CLASS_NAME)}>
      <Input
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setInputText(e.target.value)
        }}
        onKeyUp={onKeyPress}
        maxLength={100}
        inputRef={inputRef}
        inputProps={SEARCH_INPUT_PROPS}
        value={inputText}
        label={label}
        placeholder={placeholder}
        endAdornment={(
          <div className='adornment-wrapper'>
            <SvgIcon onClick={onSearch} type='search' />
          </div>
        )}
      />
    </div>
  )
}

export default SearchBarRounded
