import appraiseCarIcon from 'website/assets/Template1/appraiseCar.svg'
import tradeSellIcon from 'website/assets/Template1/tradeSell.svg'
import getPaidIcon from 'website/assets/Template1/getPaid.svg'
import needForSpeed from 'website/assets/needForSpeed.png'

const assets: Record<string, string> = {
  needForSpeed,
  appraiseCarIcon,
  tradeSellIcon,
  getPaidIcon
}

export default assets
