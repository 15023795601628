import type { FC } from 'react'
import { cnx, formatters } from '@carfluent/common'

import type { VehicleSpecsOverviewProps } from 'website/components/types'
import Text from 'website/components/Text'
import { useLayoutStyles } from 'website/styles/useLayoutStyles'
import ExtendedImage from 'website/components/ExtendedImage'

import CLASS_NAME from './styles'

const { formatInteger } = formatters

const VehicleSpecsOverview: FC<VehicleSpecsOverviewProps> = ({
  nameInLayout,
  className,
  componentProps,
  vehicle,
  template,
  layout,
  odometerIcon,
  exteriorColorIcon,
  interiorColorIcon,
  engineIcon
}) => {
  const templateCls = useLayoutStyles({ template, layout })

  return (
    <div className={cnx(nameInLayout, className)}>
      <div className={cnx(CLASS_NAME, templateCls)}>
        <Text {...componentProps.Title} nameInLayout='Title' />

        <div className='cf-spec-item' style={{ gridArea: 'Mileage' }}>
          <ExtendedImage src={odometerIcon} />
          <Text
            config={componentProps.Text.config}
            label='Odometer'
            value={`${formatInteger(vehicle?.mileage ?? '-')} miles`}
          />
        </div>

        <div className='cf-spec-item' style={{ gridArea: 'ExteriorColor' }}>
          <ExtendedImage src={exteriorColorIcon} />

          <Text
            config={componentProps.Text.config}
            label='Exterior Color'
            value={vehicle?.genericColor ?? '-'}
          />
        </div>

        <div className='cf-spec-item' style={{ gridArea: 'InteriorColor' }}>
          <ExtendedImage src={interiorColorIcon} />

          <Text
            config={componentProps.Text.config}
            label='Interior Color'
            value={vehicle?.genericInterior ?? '-'}
          />
        </div>

        <div className='cf-spec-item' style={{ gridArea: 'Engine' }}>
          <ExtendedImage src={engineIcon} />
          <Text
            config={componentProps.Text.config}
            label='Engine'
            value={vehicle?.engine ?? '-'}
            className='additional-info'
          />
        </div>

        <Text
          {...componentProps.Text}
          nameInLayout='Fuel'
          label='Fuel type:'
          value={vehicle?.fuelType ?? '-'}
          className='additional-info'
        />

        <Text
          {...componentProps.Text}
          nameInLayout='Transmission'
          label='Transmission:'
          value={vehicle?.transmissionType ?? '-'}
          className='additional-info'
        />
        <Text
          {...componentProps.Text}
          nameInLayout='BodyType'
          label='Body style:'
          value={vehicle?.bodyType ?? '-'}
          className='additional-info'
        />
        <Text
          {...componentProps.Text}
          nameInLayout='Drivetrain'
          label='Drivetrain:'
          value={vehicle?.drivetrainType ?? '-'}
          className='additional-info'
        />
        <Text
          {...componentProps.Text}
          nameInLayout='Doors'
          label='Doors:'
          value={vehicle?.doorCount ?? '-'}
          className='additional-info'
        />
        <Text
          {...componentProps.Text}
          nameInLayout='Cylinders'
          label='Cylinders:'
          value={vehicle?.cylinderCount ?? '-'}
          className='additional-info'
        />
      </div>
    </div>
  )
}

export default VehicleSpecsOverview
