import { type FC } from 'react'
import { type IconProps } from 'website/components/types'

const IconSVGAppriseYourCar: FC<IconProps> = ({
  fill = 'var(--mainColor)',
  height = 72,
  width = 72
}) => {
  return (
    <svg width={width} height={height} viewBox='0 0 72 72' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_9034_17768)'>
        <path d='M23.4281 71.5219C30.3868 71.5219 36.0281 65.7361 36.0281 58.5989C36.0281 51.4616 30.3868 45.6758 23.4281 45.6758C16.4693 45.6758 10.8281 51.4616 10.8281 58.5989C10.8281 65.7361 16.4693 71.5219 23.4281 71.5219Z' fill='#F7F8FA' />
        <path d='M60.5539 37.4117C66.5186 37.4117 71.3539 32.4523 71.3539 26.3347C71.3539 20.2171 66.5186 15.2578 60.5539 15.2578C54.5892 15.2578 49.7539 20.2171 49.7539 26.3347C49.7539 32.4523 54.5892 37.4117 60.5539 37.4117Z' fill='#F7F8FA' />
        <path d='M18.0274 37.6829C27.9684 37.6829 36.0274 29.4174 36.0274 19.2213C36.0274 9.02528 27.9684 0.759766 18.0274 0.759766C8.08622 0.759766 0.0273438 9.02528 0.0273438 19.2213C0.0273438 29.4174 8.08622 37.6829 18.0274 37.6829Z' fill='#F7F8FA' />
        <path d='M63.2527 62.1584H31.1946L20.9527 67.6968V62.1584H17.3527C16.3627 62.1584 15.5527 61.3276 15.5527 60.3122V23.3891C15.5527 22.3737 16.3627 21.543 17.3527 21.543H63.2527C64.2427 21.543 65.0527 22.3737 65.0527 23.3891V60.3122C65.0527 61.3368 64.2427 62.1584 63.2527 62.1584Z' fill='white' />
        <path d='M54.2527 57.543H15.5527V23.3891C15.5527 22.3737 16.3627 21.543 17.3527 21.543H60.5527V51.0814C60.5527 54.6537 57.7267 57.543 54.2527 57.543Z' fill={fill} fillOpacity='0.48' />
        <path d='M20.9539 68.3939C20.8369 68.3939 20.7108 68.357 20.6028 68.2924C20.3958 68.1632 20.2789 67.9416 20.2789 67.7016V62.8555H17.3539C15.9859 62.8555 14.8789 61.7201 14.8789 60.317V23.3939C14.8789 21.9909 15.9859 20.8555 17.3539 20.8555H63.2539C64.6219 20.8555 65.7289 21.9909 65.7289 23.3939V60.317C65.7289 61.7201 64.6219 62.8555 63.2539 62.8555H31.3578L21.2688 68.3109C21.1698 68.3755 21.0619 68.3939 20.9539 68.3939ZM17.3539 22.2401C16.7329 22.2401 16.2289 22.757 16.2289 23.3939V60.317C16.2289 60.9539 16.7329 61.4709 17.3539 61.4709H20.9539C21.3229 61.4709 21.6289 61.7847 21.6289 62.1632V66.557L30.8808 61.5539C30.9798 61.4986 31.0878 61.4709 31.1958 61.4709H63.2539C63.8749 61.4709 64.3789 60.9539 64.3789 60.317V23.3939C64.3789 22.757 63.8749 22.2401 63.2539 22.2401H17.3539Z' fill='#222429' />
        <path d='M54.2532 52.9289H9.25317C8.26312 52.9289 7.45312 52.0982 7.45312 51.0828V16.0059H56.0532V51.0828C56.0532 52.1074 55.2432 52.9289 54.2532 52.9289Z' fill={fill} fillOpacity='0.48' />
        <path d='M52.4532 50.1597H7.45312V16.0059H53.3532V49.2366C53.3532 49.7443 52.9482 50.1597 52.4532 50.1597Z' fill='white' />
        <path d='M56.0532 16.0057H7.45312V10.4673C7.45312 9.45187 8.26312 8.62109 9.25317 8.62109H54.2532C55.2432 8.62109 56.0532 9.45187 56.0532 10.4673V16.0057Z' fill='white' />
        <path d='M51.5532 14.1596H7.45312V10.4673C7.45312 9.45187 8.26312 8.62109 9.25317 8.62109H53.3532V12.3134C53.3532 13.338 52.5432 14.1596 51.5532 14.1596Z' fill='white' />
        <path d='M54.2524 53.6221H9.25239C7.88434 53.6221 6.77734 52.4868 6.77734 51.0837V16.0068C6.77734 15.6283 7.08334 15.3145 7.45234 15.3145H56.0524C56.4214 15.3145 56.7274 15.6283 56.7274 16.0068V51.0837C56.7274 52.4868 55.6114 53.6221 54.2524 53.6221ZM8.12734 16.6991V51.0837C8.12734 51.7206 8.63134 52.2375 9.25239 52.2375H54.2524C54.8734 52.2375 55.3774 51.7206 55.3774 51.0837V16.6991H8.12734Z' fill='#222429' />
        <path d='M56.0524 16.7009H7.45234C7.08334 16.7009 6.77734 16.387 6.77734 16.0086V10.4701C6.77734 9.06703 7.88434 7.93164 9.25239 7.93164H54.2524C55.6204 7.93164 56.7274 9.06703 56.7274 10.4701V16.0086C56.7274 16.3963 56.4214 16.7009 56.0524 16.7009ZM8.12734 15.3163H55.3774V10.4701C55.3774 9.83319 54.8734 9.31627 54.2524 9.31627H9.25239C8.63134 9.31627 8.12734 9.83319 8.12734 10.4701V15.3163Z' fill='#222429' />
        <path d='M42.5539 13.0167C42.3739 13.0167 42.2028 12.9429 42.0768 12.8136C41.9508 12.6844 41.8789 12.4998 41.8789 12.3244C41.8789 12.1398 41.9508 11.9644 42.0768 11.8352C42.3288 11.5675 42.7698 11.5767 43.0308 11.8259C43.1568 11.9644 43.2289 12.1398 43.2289 12.3244C43.2289 12.4998 43.1568 12.6752 43.0308 12.8136C42.9048 12.9429 42.7249 13.0167 42.5539 13.0167Z' fill='#222429' />
        <path d='M46.3789 12.3212C46.3789 11.9335 46.6759 11.6289 47.0539 11.6289C47.4229 11.6289 47.7289 11.9335 47.7289 12.3212C47.7289 12.6997 47.4229 13.0135 47.0539 13.0135C46.6759 13.0135 46.3789 12.6997 46.3789 12.3212Z' fill='#222429' />
        <path d='M51.5539 13.016C51.3739 13.016 51.2028 12.9422 51.0768 12.8129C50.9508 12.6745 50.8789 12.4991 50.8789 12.3237C50.8789 12.1391 50.9508 11.9637 51.0768 11.8345C51.3288 11.576 51.7788 11.576 52.0308 11.8345C52.1568 11.9637 52.2289 12.1391 52.2289 12.3237C52.2289 12.4991 52.1568 12.6745 52.0308 12.8129C51.9048 12.9422 51.7249 13.016 51.5539 13.016Z' fill='#222429' />
        <path d='M62.3531 58.2459H58.7531C58.3841 58.2459 58.0781 57.9321 58.0781 57.5536C58.0781 57.1752 58.3841 56.8613 58.7531 56.8613H62.3531C62.7221 56.8613 63.0281 57.1752 63.0281 57.5536C63.0281 57.9321 62.7221 58.2459 62.3531 58.2459Z' fill='#222429' />
        <path d='M60.5539 60.0925C60.1849 60.0925 59.8789 59.7787 59.8789 59.4002V55.7079C59.8789 55.3295 60.1849 55.0156 60.5539 55.0156C60.9229 55.0156 61.2289 55.3295 61.2289 55.7079V59.4002C61.2289 59.7787 60.9229 60.0925 60.5539 60.0925Z' fill='#222429' />
        <path d='M60.5539 52.7049C60.9267 52.7049 61.2289 52.395 61.2289 52.0126C61.2289 51.6303 60.9267 51.3203 60.5539 51.3203C60.181 51.3203 59.8789 51.6303 59.8789 52.0126C59.8789 52.395 60.181 52.7049 60.5539 52.7049Z' fill='#222429' />
        <path d='M60.5539 49.0135C60.9267 49.0135 61.2289 48.7036 61.2289 48.3212C61.2289 47.9389 60.9267 47.6289 60.5539 47.6289C60.181 47.6289 59.8789 47.9389 59.8789 48.3212C59.8789 48.7036 60.181 49.0135 60.5539 49.0135Z' fill='#222429' />
        <path d='M60.5539 44.4022C60.9267 44.4022 61.2289 44.0922 61.2289 43.7099C61.2289 43.3275 60.9267 43.0176 60.5539 43.0176C60.181 43.0176 59.8789 43.3275 59.8789 43.7099C59.8789 44.0922 60.181 44.4022 60.5539 44.4022Z' fill='#222429' />
        <path d='M60.5539 37.9393C60.9267 37.9393 61.2289 37.6293 61.2289 37.247C61.2289 36.8647 60.9267 36.5547 60.5539 36.5547C60.181 36.5547 59.8789 36.8647 59.8789 37.247C59.8789 37.6293 60.181 37.9393 60.5539 37.9393Z' fill='#222429' />
        <path d='M37.5743 58.2401H27.8723C27.5033 58.2401 27.1973 57.9262 27.1973 57.5478C27.1973 57.1693 27.5033 56.8555 27.8723 56.8555H37.5743C37.9433 56.8555 38.2493 57.1693 38.2493 57.5478C38.2493 57.9355 37.9523 58.2401 37.5743 58.2401Z' fill='#222429' />
        <path d='M50.6493 58.2401H42.0813C41.7123 58.2401 41.4062 57.9262 41.4062 57.5478C41.4062 57.1693 41.7123 56.8555 42.0813 56.8555H50.6493C51.0183 56.8555 51.3243 57.1693 51.3243 57.5478C51.3243 57.9355 51.0183 58.2401 50.6493 58.2401Z' fill='#222429' />
        <path d='M31.7535 20.1406L33.4184 23.6114L37.1535 24.1652L34.4535 26.8606L35.0924 30.6729L31.7535 28.8729L28.4144 30.6729L29.0535 26.8606L26.3535 24.1652L30.0794 23.6114L31.7535 20.1406Z' fill='white' />
        <path d='M34.4559 26.8622L37.1559 24.1668L35.0138 23.8438L33.1508 25.7084C32.9168 25.9391 32.8178 26.2714 32.8718 26.6038L33.4028 29.7607L35.0948 30.6745L34.4559 26.8622Z' fill='white' />
        <path d='M28.4139 31.3667C28.2699 31.3667 28.1349 31.3205 28.0179 31.2375C27.8109 31.0805 27.7029 30.8221 27.7479 30.5636L28.3238 27.1113L25.8759 24.6651C25.6959 24.4805 25.6239 24.2036 25.7049 23.9544C25.7859 23.7051 25.9928 23.5205 26.2538 23.4836L29.6379 22.9759L31.1498 19.8375C31.3748 19.3667 32.1309 19.3667 32.3559 19.8375L33.8679 22.9759L37.2519 23.4836C37.5039 23.5205 37.7199 23.7051 37.8009 23.9544C37.8819 24.2036 37.8099 24.4805 37.6299 24.6651L35.1819 27.1113L35.7578 30.5636C35.8028 30.8221 35.6949 31.0898 35.4879 31.2375C35.2809 31.3944 35.0019 31.4128 34.7769 31.2928L31.7529 29.659L28.7288 31.2928C28.6298 31.339 28.5219 31.3667 28.4139 31.3667ZM27.8019 24.6467L29.5208 26.3636C29.6828 26.5205 29.7549 26.7513 29.7189 26.9728L29.3139 29.4005L31.4379 28.2559C31.6359 28.1451 31.8699 28.1451 32.0679 28.2559L34.1919 29.4005L33.7868 26.9728C33.7508 26.7513 33.8229 26.5205 33.9849 26.3636L35.7038 24.6467L33.3279 24.2959C33.1119 24.259 32.9138 24.1205 32.8238 23.9175L31.7618 21.7113L30.6998 23.9175C30.6008 24.1205 30.4118 24.2682 30.1958 24.2959L27.8019 24.6467Z' fill='#222429' />
        <path d='M17.3531 20.1406L19.018 23.6114L22.7531 24.1652L20.0531 26.8606L20.683 30.6729L17.3531 28.8729L14.014 30.6729L14.6531 26.8606L11.9531 24.1652L15.679 23.6114L17.3531 20.1406Z' fill='white' />
        <path d='M20.0555 26.8622L22.7555 24.1668L20.6134 23.8438L18.7504 25.7084C18.5164 25.9391 18.4174 26.2714 18.4714 26.6038L19.0024 29.7607L20.6944 30.6745L20.0555 26.8622Z' fill='white' />
        <path d='M20.6837 31.3681C20.5757 31.3681 20.4678 31.3404 20.3688 31.285L17.3448 29.6512L14.3207 31.285C14.0957 31.405 13.8168 31.3866 13.6098 31.2296C13.4028 31.0727 13.2948 30.8143 13.3398 30.5558L13.9158 27.1035L11.4678 24.6573C11.2878 24.4727 11.2157 24.1958 11.2967 23.9466C11.3777 23.6973 11.5847 23.5127 11.8457 23.4758L15.2297 22.9681L16.7417 19.8296C16.9667 19.3589 17.7228 19.3589 17.9478 19.8296L19.4598 22.9681L22.8438 23.4758C23.0958 23.5127 23.3118 23.6973 23.3928 23.9466C23.4738 24.1958 23.4017 24.4727 23.2217 24.6573L20.7738 27.1035L21.3497 30.5558C21.3947 30.8143 21.2867 31.0819 21.0797 31.2296C20.9627 31.3219 20.8277 31.3681 20.6837 31.3681ZM17.3538 28.1835C17.4618 28.1835 17.5698 28.2112 17.6688 28.2666L19.7928 29.4112L19.3877 26.9835C19.3517 26.7619 19.4238 26.5312 19.5858 26.3743L21.3047 24.6573L18.9288 24.3066C18.7128 24.2696 18.5147 24.1312 18.4247 23.9281L17.3627 21.7219L16.3007 23.9281C16.2017 24.1312 16.0127 24.2789 15.7967 24.3066L13.4207 24.6573L15.1398 26.3743C15.3018 26.5312 15.3737 26.7619 15.3377 26.9835L14.9328 29.4112L17.0568 28.2666C17.1378 28.2112 17.2458 28.1835 17.3538 28.1835Z' fill='#222429' />
        <path d='M46.1539 20.1406L47.8188 23.6114L51.5539 24.1652L48.8539 26.8606L49.4928 30.6729L46.1539 28.8729L42.8148 30.6729L43.4539 26.8606L40.7539 24.1652L44.4888 23.6114L46.1539 20.1406Z' fill='white' />
        <path d='M48.8563 26.8622L51.5563 24.1668L49.4142 23.8438L47.5512 25.7084C47.3172 25.9391 47.2182 26.2714 47.2722 26.6038L47.8032 29.7607L49.4952 30.6745L48.8563 26.8622Z' fill='white' />
        <path d='M49.4904 31.3676C49.3824 31.3676 49.2744 31.3399 49.1754 31.2845L46.1514 29.6506L43.1273 31.2845C42.9023 31.4045 42.6234 31.386 42.4164 31.2291C42.2094 31.0722 42.1014 30.8137 42.1464 30.5553L42.7223 27.1029L40.2744 24.6568C40.0944 24.4722 40.0224 24.1953 40.1034 23.946C40.1844 23.6968 40.3913 23.5122 40.6523 23.4753L44.0364 22.9676L45.5483 19.8291C45.6653 19.5891 45.8994 19.4414 46.1514 19.4414C46.4124 19.4414 46.6464 19.5891 46.7544 19.8291L48.2664 22.9676L51.6504 23.4753C51.9024 23.5122 52.1184 23.6968 52.1994 23.946C52.2804 24.1953 52.2084 24.4722 52.0284 24.6568L49.5804 27.1029L50.1563 30.5553C50.2013 30.8137 50.0934 31.0814 49.8864 31.2291C49.7694 31.3214 49.6254 31.3676 49.4904 31.3676ZM46.1514 28.1829C46.2594 28.1829 46.3674 28.2106 46.4664 28.266L48.5904 29.4106L48.1853 26.9829C48.1493 26.7614 48.2214 26.5306 48.3834 26.3737L50.1023 24.6568L47.7264 24.306C47.5104 24.2691 47.3123 24.1306 47.2223 23.9276L46.1603 21.7214L45.0983 23.9276C44.9993 24.1306 44.8103 24.2783 44.5943 24.306L42.2183 24.6568L43.9374 26.3737C44.0994 26.5306 44.1713 26.7614 44.1353 26.9829L43.7304 29.4106L45.8544 28.266C45.9354 28.2106 46.0434 28.1829 46.1514 28.1829Z' fill='#222429' />
        <path d='M50.6552 36.9061H43.8332C43.4642 36.9061 43.1582 36.5923 43.1582 36.2138C43.1582 35.8353 43.4642 35.5215 43.8332 35.5215H50.6552C51.0242 35.5215 51.3302 35.8353 51.3302 36.2138C51.3302 36.6015 51.0242 36.9061 50.6552 36.9061Z' fill='#222429' />
        <path d='M39.3271 36.9061H28.0051C27.6361 36.9061 27.3301 36.5923 27.3301 36.2138C27.3301 35.8353 27.6361 35.5215 28.0051 35.5215H39.3271C39.6961 35.5215 40.0021 35.8353 40.0021 36.2138C40.0021 36.6015 39.6961 36.9061 39.3271 36.9061Z' fill='#222429' />
        <path d='M23.5086 36.9061H12.8527C12.4837 36.9061 12.1777 36.5923 12.1777 36.2138C12.1777 35.8353 12.4837 35.5215 12.8527 35.5215H23.5086C23.8776 35.5215 24.1836 35.8353 24.1836 36.2138C24.1836 36.6015 23.8866 36.9061 23.5086 36.9061Z' fill='#222429' />
        <path d='M50.6513 42.4432H34.8742C34.5052 42.4432 34.1992 42.1294 34.1992 41.7509C34.1992 41.3724 34.5052 41.0586 34.8742 41.0586H50.6513C51.0203 41.0586 51.3263 41.3724 51.3263 41.7509C51.3263 42.1386 51.0203 42.4432 50.6513 42.4432Z' fill='#222429' />
        <path d='M30.3756 42.4432H12.8527C12.4837 42.4432 12.1777 42.1294 12.1777 41.7509C12.1777 41.3724 12.4837 41.0586 12.8527 41.0586H30.3756C30.7446 41.0586 31.0506 41.3724 31.0506 41.7509C31.0506 42.1386 30.7446 42.4432 30.3756 42.4432Z' fill='#222429' />
        <path d='M38.0517 47.9803H23.3996C23.0306 47.9803 22.7246 47.6665 22.7246 47.288C22.7246 46.9095 23.0306 46.5957 23.3996 46.5957H38.0517C38.4207 46.5957 38.7267 46.9095 38.7267 47.288C38.7267 47.6757 38.4207 47.9803 38.0517 47.9803Z' fill='#222429' />
        <path d='M18.9006 47.9803H12.8527C12.4837 47.9803 12.1777 47.6665 12.1777 47.288C12.1777 46.9095 12.4837 46.5957 12.8527 46.5957H18.9006C19.2696 46.5957 19.5756 46.9095 19.5756 47.288C19.5756 47.6757 19.2696 47.9803 18.9006 47.9803Z' fill='#222429' />
      </g>
      <defs>
        <clipPath id='clip0_9034_17768'>
          <rect width={width} height={height} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconSVGAppriseYourCar
