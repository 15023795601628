const MIN_DESKTOP_WIDTH = 1072
const MIN_DESKTOP = `${MIN_DESKTOP_WIDTH}px`

export default {
  VehiclesFilter: {
    default: {
      root: `
        @media (min-width: ${MIN_DESKTOP}) {
          position: static;
          height: auto;

          && {
            > div:first-child {
              height: auto;
            }
          }
        }
      `,
      content: `
        background: #fff;
        border-radius: 0px;
        padding: 0px 16px 16px;
        overflow-x: hidden;

        .cf-filter-section {
          margin-bottom: 28px;

          .cf-filter-header {
            display: flex;
            justify-content: space-between;
            padding-right: 10px;

            > p {
              font-weight: 500;
              font-size: 18px;
              margin-bottom: 16px;
            }
  
            > svg {
              display: none;
              transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  
              path {
                fill: var(--mainColor);
              }
            }
          }

          &.is-collapsible {
            margin-bottom: 0px;
            border-top: 1px solid #DBDBDB;
            padding-top: 16px;

            .cf-filter-header {
              &, :hover {
                cursor: pointer;
              }

              svg {
                display: block;
              }
            }

            .cf-filter-content {
              height: 0px; /* initially collapsed. height animation is managed in component */
              overflow: hidden;
              transition: height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

              .cf-input-container {
                padding: 0px 10px;
              }
            }

            &.is-expanded {
              .cf-filter-header svg {
                transform: rotate(180deg);
              }

              padding-bottom: 16px;
            }
          }

          @media (min-width: ${MIN_DESKTOP}) {
            &.zipCode {
              display: none;
            }
          }

          &.filter .is-disabled {
            pointer-events: none;

            &.cf-tile-filter-item, .g-checkbox {
              opacity: 0.4;
            }

            .checkmark {
              background: #F3F3F3;
              border-color: #F3F3F3;
            }

            &, :hover {
              cursor: normal;
              box-shadow: none;
            }
          }

          &.zipCode, &.filter {
            .cf-input-container, .cf-select-display-container {
              height: 48px;
              background: transparent;
              border: 1px solid #DBDBDB;

              :hover {
                border: 1px solid var(--mainColor);
              }

              input, .cf-display, .cf-end-adornment {
                padding: 10px 0px;
              }
            }
          }

          &.filter .cf-range-filter {
            padding-right: 10px;
            
            .cf-range-filter-inputs {
              display: flex;
              justify-content: space-between;
            }

            .cf-input-container {
              height: 32px;
              padding: 0px 8px;
              border: 1px solid #DBDBDB;
              max-width: 100px;
              min-width: 100px;
              border-radius: 8px;

              .cf-start-adornment {
                padding: 3px;
                font-size: 14px;
              }

              :hover {
                border: 1px solid var(--mainColor);
              }
              
              input {
                padding: 3px 0px;
                text-align: left;
                font-size: 14px;
              }
            }
          }
        }

        .cf-filters-title-section {
          margin-bottom: 8px;
          position: sticky;
          top: 0px;
          background: #fff;
          z-index: 4;
          padding: 16px 32px 16px 0px;
          display: grid;
          grid-template-rows: 36px;
          grid-template-columns: 1fr auto;
          grid-column-gap: 16px;
          align-items: center;

          .close-icon {
            position: absolute;
            right: 0px;
            top: 50%;
            padding: 16px;
            margin-right: -16px;

            transform: translateY(-50%);

            &, :hover {
              cursor: pointer;
            }
          }

          p {
            font-family: Roboto, sans-serif;
            font-size: 24px;
            font-weight: 600;
          }
        }

        @media (min-width: ${MIN_DESKTOP}) {
          border-radius: 12px;

          .cf-filter-section.sorting {
            display: none;
          }
        }

        .cf-list-filter {
          display: grid;
          grid-row-gap: 12px;
          padding-right: 10px;
          
          .cf-list-filter-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: max-content;

            &, :hover {
              cursor: pointer;
            }

            &.is-color-list-item {
              justify-content: space-between;
              width: 100%;
            }
          }

          .cf-list-filter-item-color {
            width: 24px;
            height: 24px;
            border-radius: 2px;
            box-sizing: border-box;
          }
        }

        .cf-tile-filter {
          display: grid;
          grid-template-columns: repeat(auto-fill, 93px);
          grid-auto-flow: row;
          gap: 8px;

          .cf-tile-filter-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 8px;
            border-radius: 12px;
            border: 1px solid #0000001F;
            height: 100px;
            box-sizing: border-box;
            font-size: 12px;
            
            :hover {
              cursor: pointer;
              box-shadow: 0px 4px 12px 0px #0000001A;
            }

            &.is-selected {
              border: 1px solid var(--mainColor);
            }

            img {
              margin: 10px 0px;
              width: 70px;
              height: 32px;
            }

            .cf-tile-item-count {
              color: #0000004D;
            }
          }
        }

        .cf-start-adornment {
          padding: 12px 0px;
        }

        .cf-dropdown-filter-placeholder {
          color: #0000004D;
          white-space: nowrap;
        }
      `
    }
  }
}
